import { useState } from 'react'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { MdOutlineEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import JsonEditorWithError from '@/components/JsonEditorWithError/JsonEditorWithError'
import MixTagifyInput from '@/components/MixTagifyInput/MixTagifyInput'
import RadioButton from '@/components/RadioButton/RadioButton'
import Tooltip from '@/components/Tooltip/Tooltip'
import { API_METHODS, BODY_TYPE_CONFIGS, FIELD_SCOPE_TYPES } from '@/constants'
import BuildApiRightPanelApiGroupPopup from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelProjectSettings/BuildApiRightPanelApiGroupPopup/BuildApiRightPanelApiGroupPopup'
import BuildApiRightPanelConfirmationSetting from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelProjectSettings/BuildApiRightPanelConfirmationSetting/BuildApiRightPanelConfirmationSetting'
import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import InputDropdown from '@/containers/BuildApi/components/Settings/InputDropdown/InputDropdown'
import Textarea from '@/containers/BuildApi/components/Settings/Textarea/Textarea'
import { buildApiReducerActions } from '@/store/reducers/buildApi'
import { getUpdatedBodyTypeConfig } from '@/utils'

import './buildApiRightPanelProjectSettings.scss'

const apiMethodOptions = Object.values(API_METHODS).map(method => ({ label: method, value: method }))

const BuildApiRightPanelProjectSettings = () => {
  const [isEnabledProjectApiGroupPopup, setIsEnabledProjectApiGroupPopup] = useState(false)

  const dispatch = useDispatch()

  const { projectApiGroups, api, projectSettings } = useSelector(state => {
    const { projectApiGroups, api } = state.buildApi
    const { projectSettings } = state.app

    return {
      projectApiGroups,
      api,
      projectSettings,
    }
  })

  const handleCloseProjectApiGroupPopup = () => {
    setIsEnabledProjectApiGroupPopup(false)
  }

  const handleOnClickOpenApiGroupPopup = () => {
    setIsEnabledProjectApiGroupPopup(true)
  }

  const handleChangeApiGroup = groupId => {
    dispatch(buildApiReducerActions.setApiGroupId(groupId))
  }

  const handleChangeApiMethod = method => {
    dispatch(buildApiReducerActions.setApiMethod(method))
  }

  const handleChangeApiUrl = value => {
    dispatch(buildApiReducerActions.setApiUrl(value.replace(/\s/g, '')))
  }

  const handleOnChangeDescription = e => {
    dispatch(buildApiReducerActions.setApiDescription(e.target.value))
  }

  const handleOnChangeApiHeader = value => {
    dispatch(buildApiReducerActions.setApiHeader(value))
  }

  const handleOnChangeApiDefaultBody = value => {
    dispatch(buildApiReducerActions.setApiDefaultBody(value))
  }

  const handleOnChangeApiDefaultUrlQueryParams = value => {
    dispatch(buildApiReducerActions.setApiDefaultUrlQueryParams(value))
  }

  const handleOnChangeApiConfigs = value => {
    dispatch(buildApiReducerActions.setApiConfigs(value))
  }

  const handleOnChangeApiOtherField = value => {
    dispatch(buildApiReducerActions.setApiOtherFields(value))
  }

  const renderApiBodyTypeSetting = () => {
    const { configs } = api
    const bodyTypeOptions = [
      {
        label: 'Form Data',
        key: BODY_TYPE_CONFIGS.FORM_DATA,
      },
      {
        label: 'Form Url Encoded',
        key: BODY_TYPE_CONFIGS.FORM_URL_ENCODED,
      },
      {
        label: 'Hiçbiri',
        key: BODY_TYPE_CONFIGS.NONE,
      },
    ]

    const handleOnChange = configKey => () => {
      const newConfigs = getUpdatedBodyTypeConfig(configs, configKey)
      handleOnChangeApiConfigs(newConfigs)
    }

    const checkIsChecked = configKey => {
      if (configKey === BODY_TYPE_CONFIGS.NONE && !configs[BODY_TYPE_CONFIGS.FORM_DATA] && !configs[BODY_TYPE_CONFIGS.FORM_URL_ENCODED]) {
        return true
      }

      return configs[configKey] === true
    }

    return (
      <div className='build-api-project-settings__body-type-wrapper'>
        {bodyTypeOptions.map(({ label, key }) => (
          <RadioButton
            onChange={handleOnChange(key)}
            key={key}
            text={label}
            checked={checkIsChecked(key)}
            className='build-api-project-settings__body-type-radio-button'
          />
        ))}
      </div>
    )
  }

  const projectApiGroupOptions = projectApiGroups.map(({ id, name }) => ({ label: name, value: id }))

  const selectedProjectApiGroupIndex = projectApiGroups.findIndex(group => group.id === api.apiGroupId)
  const selectedProjectApiMethodIndex = apiMethodOptions.findIndex(method => method.value === api.method)

  const defaultQueryParamsWhiteList = Object.keys({ ...projectSettings.apiDefaultUrlQueryParams, ...api.defaultUrlQueryParams }).map(
    key => ({
      value: `:${key}`,
      title: key,
    }),
  )
  const urlFieldsWhiteList = api.fields
    .filter(field => field.fieldScopeType === FIELD_SCOPE_TYPES.URL)
    .map(({ key, label }) => ({ value: `:${key}`, title: label }))
  const allUrlFieldWhiteList = [...defaultQueryParamsWhiteList, ...urlFieldsWhiteList]

  return (
    <>
      <BuildApiRightPanelApiGroupPopup isVisible={isEnabledProjectApiGroupPopup} handleClosePopup={handleCloseProjectApiGroupPopup} />
      <BuildApiSettingWrapper title='URL*'>
        <MixTagifyInput onChange={handleChangeApiUrl} whiteList={allUrlFieldWhiteList} value={api.url} />
        <div className='build-api-project-settings__url-description'>
          <span>Dinamik alan için &#39;:&#39; ile başlayın</span>
          <Tooltip
            tooltipTextClassName='build-api-project-settings__url-description-tooltip'
            text='Alan tipi url olan ve varsayılan olarak eklenmiş parametreler kullanılabilir'>
            <BsFillInfoCircleFill />
          </Tooltip>
        </div>
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Method*'>
        <InputDropdown
          items={apiMethodOptions}
          selectedItemIndex={selectedProjectApiMethodIndex}
          handleChange={handleChangeApiMethod}
          placeholder='Method seç...'
        />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Grup*'>
        <div className='build-api-project-settings__api-group'>
          <InputDropdown
            items={projectApiGroupOptions}
            selectedItemIndex={selectedProjectApiGroupIndex}
            handleChange={handleChangeApiGroup}
            placeholder='Grup seçiniz...'
          />
          <div onClick={handleOnClickOpenApiGroupPopup} className='build-api-project-settings__open-api-group-popup-btn'>
            <MdOutlineEdit />
          </div>
        </div>
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Body Tip'>{renderApiBodyTypeSetting()}</BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Onay Penceresi'>
        <BuildApiRightPanelConfirmationSetting />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Açıklama'>
        <Textarea
          onChange={handleOnChangeDescription}
          value={api.description}
          className='build-api-project-settings__input'
          placeholder='Bir şey yaz...'
        />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='API Default Headers'>
        <JsonEditorWithError value={api.header} onChange={handleOnChangeApiHeader} />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='API Default Body'>
        <JsonEditorWithError value={api.defaultBody} onChange={handleOnChangeApiDefaultBody} />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='API Default URL Query Params'>
        <JsonEditorWithError value={api.defaultUrlQueryParams} onChange={handleOnChangeApiDefaultUrlQueryParams} />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Diğer'>
        <JsonEditorWithError value={api.otherFields} onChange={handleOnChangeApiOtherField} />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelProjectSettings
