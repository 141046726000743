import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'

import { getProjectApiPermissionDetail } from '@/api'
import Popup from '@/components/Popup/Popup'

import './actionGroupPermissionDetailPopup.scss'

const ActionGroupPermissionDetailPopup = ({ isVisible, handleClosePopup, selectedActionGroupPermissionId }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [actionGroupPermissionDetail, setActionGroupPermissionDetail] = useState({})

  const handleGetActionGroupPermissionDetail = async () => {
    try {
      setIsLoading(true)

      const actionPermissionGroupDetailRes = await getProjectApiPermissionDetail(selectedActionGroupPermissionId)
      setActionGroupPermissionDetail(actionPermissionGroupDetailRes.data)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isVisible) {
      handleGetActionGroupPermissionDetail()
    }
  }, [isVisible])

  return (
    <Popup title={actionGroupPermissionDetail.name} isVisible={isVisible} handleClosePopup={handleClosePopup}>
      {isLoading ? (
        <Skeleton className='action-group-permission-detail__loading-skelton-item' count={Object.keys(Array(3).fill('')).length} />
      ) : (
        <>
          <div className='action-group-permission-detail__item'>
            <div className='action-group-permission-detail__title'>Grup İsmi</div>
            <div className='action-group-permission-detail__content'>{actionGroupPermissionDetail.name}</div>
          </div>
          <div className='action-group-permission-detail__item'>
            <div className='action-group-permission-detail__title'>Aksiyonlar</div>
            <div className='action-group-permission-detail__content'>
              <div className='action-group-permission-detail__list'>
                {actionGroupPermissionDetail.apis.map(action => (
                  <div key={action.id} className='action-group-permission-detail__list-item'>
                    <div className='action-group-permission-detail__text'>{action.title}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='action-group-permission-detail__item'>
            <div className='action-group-permission-detail__title'>Kullanıcılar</div>
            <div className='action-group-permission-detail__content'>
              <div className='action-group-permission-detail__list'>
                {actionGroupPermissionDetail.projectUsers.map(projectUser => (
                  <div key={projectUser.id} className='action-group-permission-detail__list-item'>
                    <div className='action-group-permission-detail__text'>{projectUser.user.email}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Popup>
  )
}

export default ActionGroupPermissionDetailPopup
