import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { HiArrowRight } from 'react-icons/hi'
import { MdCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import ModernDropdownArrow from '@/assets/icons/modern-dropdown-arrow.svg'
import InavoIcon from '@/assets/logos/circle-black-logo.svg'
import Input from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import UserTypeTag from '@/components/UserTypeTag/UserTypeTag'
import CreateProjectPopup from '@/containers/Root/components/CreateProjectPopup/CreateProjectPopup'
import useClickOutside from '@/hooks/useClickOutside'
import { appReducerActions } from '@/store/reducers/app'
import { filterText as filterTextUtil } from '@/utils'
import { setActiveProjectId } from '@/utils/localStorageActions'

import './projectSwitcher.scss'

const ProjectSwitcher = () => {
  const [isEnabledProjectSwitcher, setIsEnabledProjectSwitcher] = useState(false)
  const [isEnabledCreatedProjectPopup, setIsEnabledCreatedProjectPopup] = useState(false)
  const [filterText, setFilterText] = useState('')

  const switcherRef = useRef(null)
  const dispatch = useDispatch()

  const projects = useSelector(state => state.app.projects)
  const activeProjectId = useSelector(state => state.app.activeProjectId)

  const selectedProject = projects.find(project => project.id === activeProjectId)
  const preparedProjects = [...projects]
    .filter(({ name }) => filterTextUtil(filterText, name))
    .sort(project => (project.id === activeProjectId ? -1 : 1))

  const hasPreparedProjects = preparedProjects.length > 0

  const handleDisableProjectSwitcher = () => {
    setIsEnabledProjectSwitcher(false)
  }

  useClickOutside(switcherRef, handleDisableProjectSwitcher)

  const handleProjectSwitcherOpenButton = () => {
    setIsEnabledProjectSwitcher(!isEnabledProjectSwitcher)
  }

  const handleClickProjectItem = projectId => () => {
    dispatch(appReducerActions.setActiveProjectId(projectId))
    setActiveProjectId(projectId)

    handleDisableProjectSwitcher()
  }

  const renderProjectImage = (project, payload) => {
    const { className } = payload

    return project.imagePath ? <img className={className} src={project.imagePath} /> : <img className={className} src={InavoIcon} />
  }

  const checkIsSelectedProject = projectId => {
    return projectId === activeProjectId
  }

  const handleOnClickCreateProject = () => {
    setIsEnabledCreatedProjectPopup(true)
  }

  const handleClosePopup = () => {
    setIsEnabledCreatedProjectPopup(false)
  }

  const handleOnChangeProjectFilter = e => {
    setFilterText(e.target.value)
  }

  return (
    <div ref={switcherRef} className='project-switcher'>
      <CreateProjectPopup isVisible={isEnabledCreatedProjectPopup} handleClosePopup={handleClosePopup} />
      <motion.div
        onClick={handleProjectSwitcherOpenButton}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.97 }}
        className={clsx('project-switcher__selected-project-button', {
          'project-switcher__selected-project-button--enabled': isEnabledProjectSwitcher,
        })}>
        <div className='project-switcher__selected-project-button-left'>
          {renderProjectImage(selectedProject || {}, { className: 'project-switcher__selected-project-img' })}
          <div className='project-switcher__selected-project-name'>{selectedProject?.name}</div>
        </div>
        <img src={ModernDropdownArrow} className='project-switcher__selected-project-icon' />
      </motion.div>
      <motion.dev
        className='project-switcher__dropdown'
        initial={false}
        animate={isEnabledProjectSwitcher ? 'open' : 'closed'}
        transition={{ ease: 'linear', duration: 0.08 }}
        variants={{
          open: { opacity: 1, display: 'block', scale: 1 },
          closed: { opacity: 0, scale: 0.8, transitionEnd: { display: 'none' } },
        }}>
        <div className='project-switcher__dropdown-list'>
          <div className='project-switcher__search-input-wrapper'>
            {projects.length > 1 && (
              <Input
                value={filterText}
                onChange={handleOnChangeProjectFilter}
                className='project-switcher__search-input'
                placeholder='Proje ara...'
              />
            )}
          </div>
          {!hasPreparedProjects ? (
            <div className='project-switcher__no-projects'>Hiç proje yok</div>
          ) : (
            preparedProjects.map(project => (
              <div
                key={project.id}
                onClick={handleClickProjectItem(project.id)}
                className={clsx('project-switcher__project-item', {
                  'project-switcher__project-item--selected': checkIsSelectedProject(project.id),
                })}>
                {renderProjectImage(project, { className: 'project-switcher__project-img' })}
                <div className='project-switcher__project-info'>
                  <div className='project-switcher__project-name'>{project.name}</div>
                  <UserTypeTag className='project-switcher__project-user-type' isAdmin={project.isAdmin} />
                </div>
                {checkIsSelectedProject(project.id) ? (
                  <MdCircle className='project-switcher__icon project-switcher__selected-project-icon' />
                ) : (
                  <HiArrowRight className='project-switcher__icon project-switcher__project-click-icon' />
                )}
              </div>
            ))
          )}
        </div>
        <PrimaryButton onClick={handleOnClickCreateProject} className='project-switcher__create-project'>
          Proje Oluştur
        </PrimaryButton>
      </motion.dev>
    </div>
  )
}

export default ProjectSwitcher
