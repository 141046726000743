import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { FIELD_TYPES } from '@/constants'
import BuildApiRightPanelFileAdvancedSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldAdvancedSettings/BuildApiRightPanelFileAdvancedSettings/BuildApiRightPanelFileAdvancedSettings'
import BuildApiRightPanelRemoteSelectAdvancedSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldAdvancedSettings/BuildApiRightPanelRemoteSelectAdvancedSettings/BuildApiRightPanelRemoteSelectAdvancedSettings'
import BuildApiRightPanelToggleSwitchAdvancedSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldAdvancedSettings/BuildApiRightPanelRemoteToggleSwitchAdvancedSettings/BuildApiRightPanelToggleSwitchAdvancedSettings'
import BuildApiRightPanelSelectAdvancedSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldAdvancedSettings/BuildApiRightPanelSelectAdvancedSettings/BuildApiRightPanelSelectAdvancedSettings'
import BuildApiRightPanelFieldGeneralSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldGeneralSettings/BuildApiRightPanelFieldGeneralSettings'
import { buildApiReducerSelectors } from '@/store/reducers/buildApi'

import './buildApiRightPanelFieldSettings.scss'

const MENU_TYPES = {
  GENERAL: 'general',
  ADVANCED: 'advanced',
}

const ADVANCED_SETTINGS = {
  [FIELD_TYPES.SELECT]: <BuildApiRightPanelSelectAdvancedSettings />,
  [FIELD_TYPES.REMOTE_SELECT]: <BuildApiRightPanelRemoteSelectAdvancedSettings />,
  [FIELD_TYPES.TOGGLE_SWITCH]: <BuildApiRightPanelToggleSwitchAdvancedSettings />,
  [FIELD_TYPES.FILE]: <BuildApiRightPanelFileAdvancedSettings />,
}

const BuildApiRightPanelFieldSettings = () => {
  const [activeMenu, setActiveMenu] = useState(MENU_TYPES.GENERAL)

  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)

  const hasAdvancedSetting = !!Object.keys(ADVANCED_SETTINGS).find(fieldType => fieldType === selectedField.type)

  const handleSelectMenu = menuType => () => {
    setActiveMenu(menuType)
  }

  const renderSettings = () => {
    switch (activeMenu) {
      case MENU_TYPES.GENERAL:
        return <BuildApiRightPanelFieldGeneralSettings />
      case MENU_TYPES.ADVANCED:
        return ADVANCED_SETTINGS[selectedField.type]
    }
  }

  useEffect(() => {
    setActiveMenu(MENU_TYPES.GENERAL)
  }, [selectedField.type])

  const menuList = [
    {
      value: MENU_TYPES.GENERAL,
      text: 'GENEL',
    },
    {
      value: MENU_TYPES.ADVANCED,
      text: 'GELİŞMİŞ',
      isDisable: !hasAdvancedSetting,
    },
  ]

  return (
    <div className='build-api-field-settings'>
      <div className='build-api-field-settings__menu'>
        {menuList.map(({ value, text, isDisable }) => (
          <div
            key={value}
            onClick={handleSelectMenu(value)}
            className={clsx('build-api-field-settings__menu-item', {
              'build-api-field-settings__menu-item--selected': activeMenu === value,
              'build-api-field-settings__menu-item--disable': isDisable,
            })}>
            {text}
          </div>
        ))}
      </div>
      {renderSettings()}
    </div>
  )
}

export default BuildApiRightPanelFieldSettings
