import InavoIcon from '@/assets/logos/inavo.svg'

import './errorPage.scss'

const ErrorPage = () => {
  return (
    <div className='error-page'>
      <div className='error-page__content'>
        <img src={InavoIcon} className='error-page__icon' />
        <div className='error-page__text'>Bir hata oluştu, bununla ilgileniyoruz.</div>
      </div>
    </div>
  )
}

export default ErrorPage
