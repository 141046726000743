import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { HiPlus } from 'react-icons/hi'
import { IoIosMore } from 'react-icons/io'
import Skeleton from 'react-loading-skeleton'

import { getProjectApiPermissions } from '@/api'
import Dropdown from '@/components/Dropdown/Dropdown'
import Input from '@/components/Input/Input'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PageTitle from '@/components/PageTitle/PageTitle'
import ActionGroupPermissionDeletePopup from '@/pages/ActionGroupPermissions/ActionGroupPermissionDeletePopup/ActionGroupPermissionDeletePopup'
import ActionGroupPermissionDetailPopup from '@/pages/ActionGroupPermissions/ActionGroupPermissionDetailPopup/ActionGroupPermissionDetailPopup'
import ActionGroupPermissionFormPopup from '@/pages/ActionGroupPermissions/ActionGroupPermissionFormPopup/ActionGroupPermissionFormPopup'
import { filterText as filterTextUtil, isMobile } from '@/utils'

import './actionGroupPermissions.scss'

const ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_ACTION_TYPES = {
  DELETE: 'delete',
  EDIT: 'edit',
}

const ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_OPTIONS = [
  { label: 'düzenle', value: ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_ACTION_TYPES.EDIT },
  {
    label: 'sil',
    value: ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_ACTION_TYPES.DELETE,
    className: 'action-group-permissions__more-delete-item',
  },
]

const ActionGroupPermissions = () => {
  const [selectedActionGroupPermissionIdForDetail, setSelectedActionGroupPermissionIdForDetail] = useState(null)
  const [selectedActionGroupPermissionIdForEdit, setSelectedActionGroupPermissionIdForEdit] = useState(null)
  const [selectedActionGroupPermissionIdForDeleting, setSelectedActionGroupPermissionIdForDeleting] = useState(null)
  const [isVisibleActionGroupPermissionForm, setIsVisibleActionGroupPermissionForm] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [actionGroupPermissions, setActionGroupPermissions] = useState([])
  const [filterText, setFilterText] = useState('')

  const isVisibleActionGroupPermissionDetail = !!selectedActionGroupPermissionIdForDetail
  const isVisibleActionGroupPermissionDelete = !!selectedActionGroupPermissionIdForDeleting
  const hasActionGroupPermissions = actionGroupPermissions.length !== 0
  const filteredActionGroupPermissions = actionGroupPermissions.filter(actionGroupPermission =>
    filterTextUtil(filterText, actionGroupPermission.name),
  )

  const handleCloseActionGroupPermissionDetailPopup = () => {
    setSelectedActionGroupPermissionIdForDetail(null)
  }

  const handleClickActionGroupPermissionItem = id => () => {
    setSelectedActionGroupPermissionIdForDetail(id)
  }

  const handleCloseAddActionGroupPermissionPopup = () => {
    setIsVisibleActionGroupPermissionForm(false)
  }

  const handleClickAddActionGroupPermissionButton = () => {
    setIsVisibleActionGroupPermissionForm(true)
  }

  const handleOnClickItemMore = e => {
    e.stopPropagation()
  }

  const handleChangeFilterInput = e => {
    setFilterText(e.target.value)
  }

  const handleClickItemDelete = async item => {
    setSelectedActionGroupPermissionIdForDeleting(item.id)
  }

  const handleClickItemEdit = item => {
    setSelectedActionGroupPermissionIdForEdit(item.id)
    setIsVisibleActionGroupPermissionForm(true)
  }

  const handleClickDropdownItem = (type, item) => {
    switch (type) {
      case ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_ACTION_TYPES.DELETE:
        handleClickItemDelete(item)
        return
      case ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_ACTION_TYPES.EDIT:
        handleClickItemEdit(item)
        return
    }
  }

  const handleCloseActionGroupPermissionForm = () => {
    handleCloseAddActionGroupPermissionPopup()
    setSelectedActionGroupPermissionIdForEdit(null)
  }

  const handleAfterActionGroupPermissionFormSubmit = async () => {
    await handleGetActionGroupPermissions()
    setSelectedActionGroupPermissionIdForEdit(null)
  }

  const handleCloseDeletePopup = () => {
    setSelectedActionGroupPermissionIdForDeleting(null)
  }

  const handleAfterActionGroupPermissionDelete = async () => {
    await handleGetActionGroupPermissions()
    setSelectedActionGroupPermissionIdForDeleting(null)
  }

  const handleGetActionGroupPermissions = async () => {
    try {
      setIsLoading(true)

      const apiPermissionsRes = await getProjectApiPermissions()
      setActionGroupPermissions(apiPermissionsRes.data)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetActionGroupPermissions()
  }, [])

  const getActionGroupPermissionListContent = () => {
    switch (true) {
      case isLoading:
        return <Skeleton className='action-group-permissions__loading-skelton-item' count={Object.keys(Array(10).fill('')).length} />
      case !isLoading && !hasActionGroupPermissions:
        return <div className='action-group-permissions__no-action-group-permissions'>Hiç eklenmiş aksiyon yetki grubu yok</div>
      case !isLoading && hasActionGroupPermissions:
        return filteredActionGroupPermissions.length === 0 ? (
          <div className='action-group-permissions__no-action-group-permissions'>Bu filtreye göre hiç aksiyon yetki grubu yok</div>
        ) : (
          filteredActionGroupPermissions.map(actionGroupPermission => (
            <div
              key={actionGroupPermission.id}
              onClick={handleClickActionGroupPermissionItem(actionGroupPermission.id)}
              className='action-group-permissions__item'>
              <div className='action-group-permissions__group-name'>{actionGroupPermission.name}</div>
              <div onClick={handleOnClickItemMore} className='action-group-permissions__more'>
                <Dropdown
                  dropdownButtonClassName='action-group-permissions__more-button'
                  items={ACTION_GROUP_PERMISSION_ITEM_DROPDOWN_OPTIONS}
                  handleClickItem={type => handleClickDropdownItem(type, actionGroupPermission)}>
                  <IoIosMore />
                </Dropdown>
              </div>
            </div>
          ))
        )
    }
  }

  return (
    <div className='action-group-permissions'>
      <PageTitle>Kullanıcı Yetkileri</PageTitle>
      <div className='action-group-permissions__header'>
        <Input onChange={handleChangeFilterInput} value={filterText} placeholder='İsme göre ara...' />
        {isMobile() ? (
          <HiPlus
            onClick={handleClickAddActionGroupPermissionButton}
            className='action-group-permissions__add-group-button action-group-permissions__add-group-button--mobile'
          />
        ) : (
          <OutlinedButton onClick={handleClickAddActionGroupPermissionButton} className='action-group-permissions__add-group-button'>
            Aksiyon Yetki Grubu Ekle
          </OutlinedButton>
        )}
      </div>
      <div className='action-group-permissions__list'>{getActionGroupPermissionListContent()}</div>
      <ActionGroupPermissionDetailPopup
        isVisible={isVisibleActionGroupPermissionDetail}
        handleClosePopup={handleCloseActionGroupPermissionDetailPopup}
        selectedActionGroupPermissionId={selectedActionGroupPermissionIdForDetail}
      />
      <ActionGroupPermissionFormPopup
        isVisible={isVisibleActionGroupPermissionForm}
        handleClosePopup={handleCloseActionGroupPermissionForm}
        actionGroupPermissionId={selectedActionGroupPermissionIdForEdit}
        handleAfterSubmit={handleAfterActionGroupPermissionFormSubmit}
      />
      <ActionGroupPermissionDeletePopup
        actionGroupPermissionId={selectedActionGroupPermissionIdForDeleting}
        isVisible={isVisibleActionGroupPermissionDelete}
        handleClosePopup={handleCloseDeletePopup}
        handleAfterDelete={handleAfterActionGroupPermissionDelete}
      />
    </div>
  )
}

export default ActionGroupPermissions
