import { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const GridLayout = props => {
  const { renderItems, gridLayoutClassName, ...otherGridLayoutProps } = props

  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <ResponsiveReactGridLayout
      className={gridLayoutClassName}
      measureBeforeMount={false}
      useCSSTransforms={mounted}
      preventCollision={!props.compactType}
      isDroppable
      {...otherGridLayoutProps}>
      {renderItems()}
    </ResponsiveReactGridLayout>
  )
}

GridLayout.defaultProps = {
  rowHeight: 30,
  compactType: 'vertical',
  cols: { lg: 12, xxs: 2 },
  breakpoints: { lg: 800, xxs: 0 },
  containerPadding: [0, 0],
}

export default GridLayout
