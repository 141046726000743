import clsx from 'clsx'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { IoCaretDownOutline } from 'react-icons/io5'

import Checkbox from '@/components/Checkbox/Checkbox'

import './manageActionsList.scss'

const ManageActionsList = ({
  itemList,
  selectedItemIds,
  handleOnChange,
  handleChangeFolderName: _handleChangeFolderName,
  handleChangeActionName: _handleChangeActionName,
}) => {
  const [collapsedFolderId, setCollapsedFolderId] = useState(null)

  const getItemIdsByFolderId = _folderId => {
    const scopedAllItems = itemList.find(({ folderId }) => folderId === _folderId)?.items || []
    if (scopedAllItems.length === 0) return []

    const scopedItemIds = scopedAllItems.map(({ id }) => id)
    return scopedItemIds
  }

  const checkIsCollapsedFolder = _folderId => _folderId === collapsedFolderId
  const checkIsSelectedItem = _itemId => selectedItemIds.includes(_itemId)
  const checkIsSelectedAllItemsOfFolder = _folderId => {
    const scopedItemIds = getItemIdsByFolderId(_folderId)
    return scopedItemIds.every(id => selectedItemIds.includes(id))
  }

  const renderSelectedActionsCount = _folderId => {
    const scopedItemIds = getItemIdsByFolderId(_folderId)
    const count = scopedItemIds.filter(id => selectedItemIds.includes(id)).length

    return `${count} adet seçilmiş aksiyon`
  }

  const handleOnClickItemCollapse = _folderId => () => {
    console.log('_folderId', _folderId)
    console.log('collapsedFolderId', collapsedFolderId)

    setCollapsedFolderId(prev => (prev === null || prev !== _folderId ? _folderId : null))
  }

  const handleOnChangeItem = itemId => e => {
    const isChecked = e.target.checked

    if (!isChecked) {
      const newSelectedItems = selectedItemIds.filter(id => id !== itemId)
      handleOnChange(newSelectedItems)
      return
    }

    handleOnChange([...selectedItemIds, itemId])
  }

  const handleOnChangeFolderItem = _folderId => e => {
    const isSelectedAllItems = e.target.checked
    const scopedItemIds = getItemIdsByFolderId(_folderId)

    if (!isSelectedAllItems) {
      const newSelectedItems = [...selectedItemIds].filter(id => !scopedItemIds.includes(id))
      handleOnChange(newSelectedItems)

      return
    }

    const newSelectedItems = Array.from(new Set([...selectedItemIds, ...scopedItemIds]))
    handleOnChange(newSelectedItems)
  }

  const handleChangeFolderName = folderId => e => {
    _handleChangeFolderName({
      folderId,
      e,
    })
  }

  const handleChangeActionName = actionId => e => {
    _handleChangeActionName({
      actionId,
      e,
    })
  }

  return (
    <div className='manage-actions-list'>
      <div className='manage-actions-list__list'>
        {itemList.map(({ folderId, folderName, items }) => (
          <div
            key={folderId}
            className={clsx('manage-actions-list__item', { 'manage-actions-list__item--collapsed': checkIsCollapsedFolder(folderId) })}>
            <div className='manage-actions-list__item-header'>
              <Checkbox
                handleOnChange={handleOnChangeFolderItem(folderId)}
                checked={checkIsSelectedAllItemsOfFolder(folderId)}
                {...(!_handleChangeFolderName && { text: folderName })}
              />
              {_handleChangeFolderName && (
                <input onChange={handleChangeFolderName(folderId)} className='manage-actions-list__folder-input' value={folderName} />
              )}
              <div onClick={handleOnClickItemCollapse(folderId)} className='manage-actions-list__collapse-wrapper'>
                <div className='manage-actions-list__total-actions-information'>{renderSelectedActionsCount(folderId)}</div>
                <IoCaretDownOutline className='manage-actions-list__collapse-icon' />
              </div>
            </div>
            <motion.div
              className='manage-actions-list__actions-list'
              initial={false}
              animate={checkIsCollapsedFolder(folderId) ? 'open' : 'closed'}
              transition={{ ease: 'linear', duration: 0.08 }}
              variants={{
                open: { opacity: 1, height: '100%' },
                closed: { opacity: 0, height: 0.1 },
              }}>
              {items.map(item => (
                <div key={item.id} className='manage-actions-list__action-item'>
                  {_handleChangeActionName && (
                    <input onChange={handleChangeActionName(item.id)} className='manage-actions-list__action-input' value={item.label} />
                  )}
                  <Checkbox
                    handleOnChange={handleOnChangeItem(item.id)}
                    checked={checkIsSelectedItem(item.id)}
                    className='manage-actions-list__action-name'
                    {...(!_handleChangeActionName && { text: item.label })}
                  />
                </div>
              ))}
            </motion.div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ManageActionsList
