import { v4 as uuidv4 } from 'uuid'

import { convertToBase64 } from '@/utils'

import './image.scss'

const Image = ({ handleChangeValue, currentValue, id = uuidv4() }) => {
  const handleChangeImage = async e => {
    const image = e.target.files[0]
    const convertedBase64Image = await convertToBase64(image)

    handleChangeValue(convertedBase64Image)
  }

  return (
    <div className='image-field'>
      <div className='image-field__image-wrapper'>
        {currentValue ? (
          <img src={currentValue} className='image-field__image' />
        ) : (
          <div className='image-field__no-selected-image'>Henüz fotoğraf yok</div>
        )}
      </div>
      <input className='image-field__image-input' id={id} type='file' accept='image/*' onChange={handleChangeImage} />
      <label htmlFor={id} className='image-field__change-image'>
        Seç
      </label>
    </div>
  )
}

export default Image
