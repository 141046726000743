import _, { debounce, isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

import { getData } from '@/api'
import InputDropdown from '@/components/InputDropdown/InputDropdown'

import './remoteInputDropdown.scss'

const RemoteInputDropdown = ({
  field,
  currentValue,
  handleChangeValue,
  className,
  urlFields: _urlFields = [],
  bodyFields: _bodyFields = [],
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [choices, setChoices] = useState([])
  const [urlFields, setUrlFields] = useState([])
  const [bodyFields, setBodyFields] = useState([])
  const [filterText, setFilterText] = useState('')
  const [isSelectedItemFreshly, setIsSelectedItemFreshly] = useState(false)

  const hasSearchQueryParameter = !!field?.properties?.searchQueryParameter

  const selectedItemIndex = choices.findIndex(item => item.value === currentValue)

  const projectId = useSelector(state => state.app.activeProjectId) // Passing from prop would be better.

  const getPreparedUrl = payload => {
    const { searchQueryParameter, url, filterText } = payload

    switch (true) {
      case !searchQueryParameter || !filterText:
        return url
      default: {
        const urlParams = new URLSearchParams(url)
        urlParams.append(searchQueryParameter, filterText)
        return decodeURIComponent(urlParams.toString())
      }
    }
  }

  const handleGetChoices = async payload => {
    const { urlFields, bodyFields, filterText, field } = payload

    try {
      setIsLoading(true)

      const { isForeign = false, properties = {} } = field
      const { url: _url, method, header, dataKey, valueKey, labelKey, searchQueryParameter } = properties
      const url = getPreparedUrl({
        url: _url,
        searchQueryParameter,
        filterText,
      })

      const responseData = (
        await getData({
          projectId,
          isForeign,
          url,
          method,
          header,
          urlFields,
          bodyFields,
        })
      ).data.response.data

      const remoteResData = dataKey ? _.get(responseData, dataKey) : responseData
      const preparedChoices = [...(Array.isArray(remoteResData) ? remoteResData : [remoteResData])].map(item => {
        const value = _.get(item, valueKey)
        const label = _.get(item, labelKey)

        return {
          value,
          label,
        }
      })

      setChoices(preparedChoices)
    } catch (err) {
      toast.error('Bir hata oluştu(remote_select_dropdown)')
    } finally {
      setIsLoading(false)
    }
  }
  const debouncedHandleGetChoices = useMemo(() => debounce(handleGetChoices, 400), [])

  const handleRemoveSelectedItem = () => {
    selectedItemIndex && handleChangeValue('')
  }

  const handleChangeFilterInput = e => {
    if (isSelectedItemFreshly) setIsSelectedItemFreshly(false)
    handleRemoveSelectedItem() // we should clear the value due the item list can not include stale selected item

    setFilterText(e.target.value)
  }

  const handleClickItem = () => {
    setIsSelectedItemFreshly(true)
  }

  const handleDeleteSelectedItem = () => {
    setFilterText('')
  }

  useEffect(() => {
    if (!isEqual(urlFields, _urlFields)) {
      setUrlFields([..._urlFields])
    }
  }, [_urlFields])

  useEffect(() => {
    if (!isEqual(bodyFields, _bodyFields)) {
      setBodyFields([..._bodyFields])
    }
  }, [_bodyFields])

  useEffect(() => {
    debouncedHandleGetChoices({
      urlFields,
      bodyFields,
      filterText,
      field,
    })
  }, [urlFields, bodyFields, filterText]) // We don't need to add field as depend for now

  return (
    <div>
      <InputDropdown
        className={className}
        isLoading={isLoading}
        items={choices}
        handleChange={handleChangeValue}
        placeholder={field.label}
        {...(selectedItemIndex >= 0 ? { selectedItemIndex } : {})}
        {...(hasSearchQueryParameter
          ? {
              handleChangeFilterInput: handleChangeFilterInput,
              filterText: isSelectedItemFreshly ? '' : filterText,
              handleClickItem: handleClickItem,
              handleDeleteSelectedItem,
            }
          : {})}
      />
    </div>
  )
}

export default RemoteInputDropdown
