import { FaChartBar } from 'react-icons/fa'
import { LuTable } from 'react-icons/lu'
import { PiKey } from 'react-icons/pi'
import { RiAdminLine } from 'react-icons/ri'
import { TbHomeCog, TbSettingsPlus } from 'react-icons/tb'
import { TbHome2 } from 'react-icons/tb'

import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'

const PERMISSION_ACTION_TYPES = {
  CHECKBOX: 'checkbox',
}

const renderPermissionIcon = permissionSlug => {
  switch (permissionSlug) {
    case PROJECT_PERMISSIONS.VIEW_CHART_PAGE:
      return <FaChartBar className='project-user-permission-list__icon' />
    case PROJECT_PERMISSIONS.VIEW_TABLE_PAGE:
      return <LuTable className='project-user-permission-list__icon' />
    case PROJECT_PERMISSIONS.EDIT_HOME_WIDGETS:
      return <TbHomeCog className='project-user-permission-list__icon' />
    case PROJECT_PERMISSIONS.VIEW_HOME_WIDGETS:
      return <TbHome2 className='project-user-permission-list__icon' />
    case PROJECT_PERMISSIONS.ADMIN:
      return <RiAdminLine className='project-user-permission-list__icon' />
    case PROJECT_PERMISSIONS.ACTIONS_FOR_CHARTS:
    case PROJECT_PERMISSIONS.ACTIONS_FOR_TABLES:
      return <TbSettingsPlus className='project-user-permission-list__icon' />
    default:
      return <PiKey className='project-user-permission-list__icon' />
  }
}

const renderToggleSwitch = payload => {
  const { value, handleChangeValue, isUpdating, permission } = payload

  const _handleChangeValue = e => handleChangeValue(e.target.checked)

  return <ToggleSwitch checked={value} handleChangeValue={_handleChangeValue} isLoading={isUpdating} id={permission.slug} />
}

const renderPermissionAction = payload => {
  const { permission, value, handleChangeValue, isUpdating } = payload
  const { type } = permission

  switch (type) {
    case PERMISSION_ACTION_TYPES.CHECKBOX:
      return renderToggleSwitch({ value, handleChangeValue, isUpdating, permission })
  }
}

export const renderPermission = payload => {
  const { permission, projectUserPermissions, handleChangePermission, isUpdatingSlug } = payload

  const { slug } = permission
  const value = projectUserPermissions[slug]
  const isUpdating = isUpdatingSlug === slug

  const handleChangeValue = _value => {
    handleChangePermission({ [slug]: _value })
  }

  return (
    <div className='project-user-permission-list__item'>
      <div className='project-user-permission-list__icon-wrapper'>{renderPermissionIcon(permission.slug)}</div>
      <div className='project-user-permission-list__info'>
        <div className='project-user-permission-list__title'>{permission.title}</div>
        <div className='project-user-permission-list__desc'>{permission.label}</div>
      </div>
      <div className='project-user-permission-list__action'>
        {renderPermissionAction({ permission, value, handleChangeValue, isUpdating })}
      </div>
    </div>
  )
}
