import { Link } from 'react-router-dom'

import BlackLogoIcon from '@/assets/logos/circle-black-logo.svg'
import Logo from '@/assets/logos/inavo.svg'
import { ROUTE_URLS } from '@/constants/routeUrls'

import './authenticationFlow.scss'

const AuthenticationFlow = ({ children, title }) => {
  return (
    <div className='authentication-flow'>
      <div className='authentication-flow__left'>
        <img src={BlackLogoIcon} className='authentication-flow__brand' />
        <Link to={ROUTE_URLS.LOGIN}>
          <img src={BlackLogoIcon} className='authentication-flow__logo-icon' />
        </Link>
        <div className='authentication-flow__highlight-texts'>
          <div className='authentication-flow__highlight-text'>Basit.</div>
          <div className='authentication-flow__highlight-text'>Kolay.</div>
          <div className='authentication-flow__highlight-text'>İşlevsel.</div>
        </div>
      </div>
      <div className='authentication-flow__content'>
        <Link to={ROUTE_URLS.LOGIN}>
          <div className='authentication-flow__logo-wrapper'>
            <img src={Logo} className='authentication-flow__logo' />
          </div>
        </Link>
        <div className='authentication-flow__content-title'>{title}</div>
        <div className='authentication-flow__content-children'>{children}</div>
      </div>
    </div>
  )
}
export default AuthenticationFlow
