/* eslint-disable no-unused-vars */
import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'

// TODO: There are a few problems related to performance
// TODO: There are a few bugs we need to focus on them
// TODO: BU PAKETİ YAPANIN AQ - 14.05.2024

const MixTagifyInput = ({ whiteList: _whiteList, onChange, value }) => {
  const [whiteList, setWhiteList] = useState(_whiteList)

  const tagifyRef = useRef(null)

  const handleSetInitialValues = () => {
    if (!tagifyRef.current) return

    const normalizedValue = Object.values(whiteList).reduce((acc, item) => acc.replace(item.value, JSON.stringify([[item]])), value)
    tagifyRef.current.loadOriginalValues(normalizedValue)
  }

  const handleClearAllCustomListeners = () => {
    tagifyRef.current.removeAllCustomListeners()
  }

  const handleInitialProcess = () => {
    if (tagifyRef.current) handleClearAllCustomListeners()

    const textareaElement = document.querySelector('#tagify-textarea')

    tagifyRef.current = new Tagify(textareaElement, {
      mode: 'mix',
      pattern: ':',
      whitelist: whiteList.map(item => (typeof item == 'string' ? { value: item } : item)),
      enforceWhitelist: true,
      dropdown: {
        enabled: 1,
        position: 'text',
        highlightFirst: true,
      },
    })

    tagifyRef.current.on('input', e => {
      const prefix = e.detail.prefix

      if (prefix) {
        if (prefix == ':') tagifyRef.current.whitelist = whiteList
        if (e.detail.value.length > 1) tagifyRef.current.dropdown.show.call(tagifyRef.current, e.detail.value)
      }

      onChange(e.detail.textContent)
    })
    tagifyRef.current.on('add', e => {
      onChange(e.detail.tagify.DOM.input.textContent)
    })
    tagifyRef.current.on('remove', e => {
      onChange(e.detail.tagify.DOM.input.textContent)
    })

    handleSetInitialValues()
  }

  useEffect(() => {
    if (!isEqual(_whiteList, whiteList)) {
      setWhiteList(_whiteList)
    }
  }, [_whiteList])

  useEffect(() => {
    handleInitialProcess()
  }, [whiteList])

  return <textarea id='tagify-textarea' />
}

export default MixTagifyInput
