import { FileUploader } from 'react-drag-drop-files'
import toast from 'react-hot-toast'
import { IoCloudUploadOutline } from 'react-icons/io5'

import './fileUpload.scss'

const FileUpload = ({ label, ...props }) => {
  const handleOnTypeError = () => {
    toast.error('Geçerli dosya tipi değil')
  }

  const handleOnSizeError = () => {
    toast.error('Geçerli boyutta değil')
  }

  return (
    <FileUploader
      classes='file-upload__wrapper'
      hoverTitle='Buraya bırak'
      dropMessageStyle={{ backgroundColor: '#dddddd', color: '#5d5d5d', opacity: 1, fontWeight: 400 }}
      onTypeError={handleOnTypeError}
      onSizeError={handleOnSizeError}
      {...props}>
      <div className='file-upload'>
        <IoCloudUploadOutline className='file-upload__icon' />
        <div className='file-upload__text'>{label || 'Sürükle bırak ya da yükle'}</div>
      </div>
    </FileUploader>
  )
}

export default FileUpload
