import { useEffect, useRef, useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import { MdOutlineDelete } from 'react-icons/md'
import { MdOutlinePreview } from 'react-icons/md'
import { v4 as uuidv4 } from 'uuid'

import { getFileType } from '@/utils'

import PreviewFileModal, { FILE_TYPES } from './PreviewFileModal/PreviewFileModal'
import './fileInputList.scss'

const FileInputList = ({
  id = uuidv4(),
  handleOnChange: _handleOnChange,
  isMultiple,
  accept,
  files: _files,
  onlySetFilesWhileMounting,
}) => {
  const [files, setFiles] = useState([])
  const [previewFileIndex, setPreviewFileIndex] = useState(null)
  const [isEnabledPreviewFile, setIsEnabledPreviewFile] = useState(false)

  const fileProcess = useRef(null) // We should use it for adjusting the files in this state

  const hasFiles = files.length > 0

  const handleOnChange = async e => {
    const allFiles = await Promise.all([...e.target.files])

    fileProcess.isAddedFile = true

    setFiles(allFiles)
    _handleOnChange && _handleOnChange(allFiles)
  }
  const handleOnClickDeleteItem = deleteItemIndex => () => {
    const filteredAllFiles = files.filter((_, index) => index !== deleteItemIndex)
    setFiles(filteredAllFiles)

    _handleOnChange && _handleOnChange(filteredAllFiles)
  }
  const handleOnClickPreviewButton = itemIndex => () => {
    setPreviewFileIndex(itemIndex)
    setIsEnabledPreviewFile(true)
  }
  const handleClosePreviewFileModal = () => {
    setTimeout(() => {
      setPreviewFileIndex(null)
    }, 300)
    setIsEnabledPreviewFile(false)
  }

  const checkIsPreviewableFileType = item => Object.values(FILE_TYPES).includes(getFileType(item))

  useEffect(() => {
    if (onlySetFilesWhileMounting && fileProcess.isAddedFile) return
    _files && setFiles(_files)
  }, [_files])

  return (
    <div className='file-input-list'>
      <PreviewFileModal file={files[previewFileIndex]} isVisible={isEnabledPreviewFile} handleClosePopup={handleClosePreviewFileModal} />
      <input
        id={id}
        type='file'
        className='file-input-list__input'
        onChange={handleOnChange}
        {...(isMultiple ? { multiple: 'multiple' } : {})}
        {...(accept ? { accept } : {})}
      />
      <label className='file-input-list__upload-btn' htmlFor={id}>
        <BsUpload />
        <span className='file-input-list__upload-text'>Yükle</span>
      </label>
      <div className='file-input-list__list'>
        {!hasFiles ? (
          <div className='file-input-list__no-files'>Henüz hiçbir şey yüklenmemiş</div>
        ) : (
          files.map((item, index) => (
            <div key={index} className='file-input-list__item'>
              <div className='file-input-list__item-name'>{item.name}</div>
              {checkIsPreviewableFileType(item) && (
                <MdOutlinePreview
                  onClick={handleOnClickPreviewButton(index)}
                  className='file-input-list__icon file-input-list__icon--preview'
                />
              )}

              <MdOutlineDelete onClick={handleOnClickDeleteItem(index)} className='file-input-list__icon file-input-list__icon--delete' />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default FileInputList
