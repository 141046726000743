import { useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'

import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import RichTextEditor from '@/components/RichTextEditor/RichTextEditor'

import './richTextEditorPopup.scss'

const CONTENT_TYPES = {
  EDIT: 'edit',
  PREVIEW: 'preview',
}

const RichTextEditorPopup = ({ isVisible, handleClosePopup, value, handleChangeValue }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [activeContentType, setActiveContentType] = useState(CONTENT_TYPES.EDIT)

  const checkHasText = () => {
    const hasOrdinaryText = currentValue.replace(/<(.|\n)*?>/g, '').trim().length !== 0
    const hasEmbedFiles = currentValue.includes('<img') || currentValue.includes('<iframe')

    return hasOrdinaryText || hasEmbedFiles
  }
  const hasText = checkHasText()

  const handleChangeActiveContentType = contentType => () => {
    setActiveContentType(contentType)
  }

  const renderRichTextEditor = () => {
    const handleChangeRichTextEditor = value => {
      setCurrentValue(value)
    }

    return <RichTextEditor onChange={handleChangeRichTextEditor} value={currentValue} />
  }
  const renderPreviewContent = () => {
    return (
      <div className='rich-text-editor-popup__preview-content'>
        <div dangerouslySetInnerHTML={{ __html: currentValue }} />
      </div>
    )
  }
  const renderCurrentContent = () => {
    switch (activeContentType) {
      case CONTENT_TYPES.EDIT:
        return renderRichTextEditor()
      case CONTENT_TYPES.PREVIEW:
        return renderPreviewContent()
    }
  }

  const handleOnClickSaveButton = () => {
    handleChangeValue(currentValue)
    handleClosePopup()
  }

  const renderActionButton = () => {
    switch (activeContentType) {
      case CONTENT_TYPES.EDIT:
        return (
          hasText && (
            <FaEye onClick={handleChangeActiveContentType(CONTENT_TYPES.PREVIEW)} className='rich-text-editor-popup__action-button' />
          )
        )
      case CONTENT_TYPES.PREVIEW:
        return <MdEdit onClick={handleChangeActiveContentType(CONTENT_TYPES.EDIT)} className='rich-text-editor-popup__action-button' />
    }
  }

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (!isVisible) {
      setCurrentValue(value)
      setActiveContentType(CONTENT_TYPES.EDIT)
    }
  }, [isVisible])

  return (
    <Popup modalClassName='rich-text-editor-popup' title='Düzenle' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='rich-text-editor-popup__wrapper'>
        {renderActionButton()}
        <div className='rich-text-editor-popup__content'>{renderCurrentContent()}</div>
        <div className='rich-text-editor-popup__bottom'>
          <PrimaryButton onClick={handleOnClickSaveButton}>Kaydet</PrimaryButton>
        </div>
      </div>
    </Popup>
  )
}

export default RichTextEditorPopup
