import clsx from 'clsx'
import { motion } from 'framer-motion'
import { IoIosCloseCircleOutline } from 'react-icons/io'

import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './popup.scss'

const Popup = ({
  children,
  title,
  isVisible,
  modalClassName,
  handleClosePopup,
  isVisibleStepActions,
  handleOnClickBackButton: _handleOnClickBackButton,
  handleOnClickNextButton: _handleOnClickNextButton,
  backButtonText = 'Geri',
  nextButtonText = 'İleri',
  isLoadingNextButton,
  isDisableNextButton,
  isDisableBackButton,
  isVisibleBackButton = true,
  isDisableCloseButton = false,
  isCenteredTitle = false,
}) => {
  const modalProps = {
    className: 'popup',
    initial: false,
    animate: isVisible ? 'open' : 'closed',
    transition: { duration: 0.12 },
    variants: {
      open: { opacity: 1, display: 'flex' },
      closed: { opacity: 0, transitionEnd: { display: 'none' } },
    },
  }

  const popupModalProps = {
    className: clsx(modalClassName, 'popup__modal'),
    initial: false,
    animate: isVisible ? 'open' : 'closed',
    transition: { duration: 0.12 },
    variants: {
      open: { scale: 1 },
      closed: { scale: 0.92 },
    },
  }

  const handleOnClickBackButton = () => _handleOnClickBackButton && _handleOnClickBackButton()
  const handleOnClickNextButton = () => _handleOnClickNextButton && _handleOnClickNextButton()

  return (
    <motion.div {...modalProps}>
      <div className='popup__filter' onClick={handleClosePopup} />
      <motion.div {...popupModalProps}>
        <div className='popup__header'>
          <div className={clsx('popup__title', { 'popup__title--centered': isCenteredTitle })}>{title}</div>
          {!isDisableCloseButton && <IoIosCloseCircleOutline className='popup__close' onClick={handleClosePopup} />}
        </div>
        <div className='popup__content'>{children}</div>
        {isVisibleStepActions && (
          <div className='popup__bottom'>
            {isVisibleBackButton && (
              <OutlinedButton disable={isDisableBackButton} onClick={handleOnClickBackButton} className='popup__action-button'>
                {backButtonText}
              </OutlinedButton>
            )}
            <PrimaryButton
              disable={isDisableNextButton}
              isLoading={isLoadingNextButton}
              onClick={handleOnClickNextButton}
              className='popup__action-button popup__action-button--next'>
              {nextButtonText}
            </PrimaryButton>
          </div>
        )}
      </motion.div>
    </motion.div>
  )
}

export default Popup
