import { createSlice } from '@reduxjs/toolkit'

import { getApiObject } from '@/utils/buildApi'

const getUpdatedFields = ({ state, item }) =>
  state.api.fields.map(field =>
    field.id === state.selectedFieldId
      ? {
          ...field,
          ...item,
        }
      : field,
  )

const buildApiSlice = createSlice({
  name: 'buildApi',
  initialState: {
    api: {},
    isActiveRightPanel: false,
    selectedFieldId: null,
    projectApiGroups: [],
    isDirty: false,
    isEnableSettingsPopup: false,
  },
  reducers: {
    setIsActiveRightPanel: (state, action) => {
      state.isActiveRightPanel = action.payload
    },
    setInitialApi: state => {
      state.api = getApiObject()
    },
    setApi: (state, action) => {
      state.api = {
        ...action.payload,
      }
    },
    setApiTitle: (state, action) => {
      state.api.title = action.payload
    },
    setApiFields: (state, action) => {
      state.api.fields = action.payload.map(({ properties = {}, ...otherPayload }, index) => ({
        ...otherPayload,
        properties: {
          ...properties,
          orderIndex: index,
        },
      }))
    },
    setSelectedFieldId: (state, action) => {
      state.selectedFieldId = action.payload || null
    },
    setProjectApiGroups: (state, action) => {
      state.projectApiGroups = action.payload
    },
    addProjectApiGroups: (state, action) => {
      state.projectApiGroups = [...state.projectApiGroups, action.payload]
    },
    setApiGroupId: (state, action) => {
      state.api.apiGroupId = action.payload
    },
    setApiMethod: (state, action) => {
      state.api.method = action.payload
    },
    setApiUrl: (state, action) => {
      state.api.url = action.payload
    },
    setApiDescription: (state, action) => {
      state.api.description = action.payload
    },
    setApiHeader: (state, action) => {
      state.api.header = { ...action.payload }
    },
    setApiOtherFields: (state, action) => {
      state.api.otherFields = { ...action.payload }
    },
    setApiDefaultBody: (state, action) => {
      state.api.defaultBody = { ...action.payload }
    },
    setApiDefaultUrlQueryParams: (state, action) => {
      state.api.defaultUrlQueryParams = { ...action.payload }
    },
    setApiConfigs: (state, action) => {
      state.api.configs = { ...action.payload }
    },
    setApiFieldKey: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { key: action.payload } })
    },
    setApiFieldLabel: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { label: action.payload } })
    },
    setApiFieldDefaultValue: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { defaultValue: action.payload } })
    },
    setApiFieldScopeType: (state, action) => {
      state.api.fields = getUpdatedFields({ state, item: { fieldScopeType: action.payload } })
    },
    setApiFieldProperties: (state, action) => {
      state.api.fields = state.api.fields.map(field =>
        field.id === state.selectedFieldId
          ? {
              ...field,
              properties: {
                ...field?.properties,
                ...action.payload,
              },
            }
          : field,
      )
    },
    setIsDirty: (state, action) => {
      state.isDirty = action.payload
    },
    setInitialState: state => {
      state.isActiveRightPanel = true
      state.selectedFieldId = null
      state.isDirty = false
      state.isEnableSettingsPopup = false
    },
    setIsEnableSettingsPopup: (state, action) => {
      state.isEnableSettingsPopup = action.payload
    },
  },
})

const selectedFieldSelector = state => {
  const buildApi = state.buildApi

  return buildApi.api.fields.find(field => field.id === buildApi.selectedFieldId)
}

export const buildApiReducerActions = buildApiSlice.actions
export const buildApiReducerSelectors = {
  selectedFieldSelector,
}

export default buildApiSlice.reducer
