import clsx from 'clsx'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import DeleteProjectPopup from '@/pages/ProjectSettings/ProjectOtherSettings/DeleteProjectPopup/DeleteProjectPopup'
import ExportApiPopupWizard from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/ExportApiPopupWizard'
import ImportApiPopupWizard from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/ImportApiPopupWizard'

import './projectOtherSettings.scss'

const ProjectOtherSettings = () => {
  const [isVisibleDeleteProjectPopup, setIsVisibleDeleteProjectPopup] = useState(false)
  const [isVisibleExportApiPopup, setIsVisibleExportApiPopup] = useState(false)
  const [isVisibleImportApiPopup, setIsVisibleImportApiPopup] = useState(false)

  const hasProjectApis = useSelector(state => state.app.projectApis).length > 0

  const handleCloseDeleteProjectPopup = () => {
    setIsVisibleDeleteProjectPopup(false)
  }

  const handleCloseExportApiPopup = () => {
    setIsVisibleExportApiPopup(false)
  }

  const handleCloseImportApiPopup = () => {
    setIsVisibleImportApiPopup(false)
  }

  const handleOnClickDeleteProjectButton = () => {
    setIsVisibleDeleteProjectPopup(true)
  }

  const handleOnClickExportApiButton = () => {
    setIsVisibleExportApiPopup(true)
  }

  const handleOnClickImportApiButton = () => {
    setIsVisibleImportApiPopup(true)
  }

  return (
    <div className='other-settings'>
      <DeleteProjectPopup isVisible={isVisibleDeleteProjectPopup} handleClosePopup={handleCloseDeleteProjectPopup} />
      <ExportApiPopupWizard isVisible={isVisibleExportApiPopup} handleClosePopup={handleCloseExportApiPopup} />
      <ImportApiPopupWizard isVisible={isVisibleImportApiPopup} handleClosePopup={handleCloseImportApiPopup} />
      <div className='other-settings__item'>
        <div className='other-settings__item-text'>İçe Aktar</div>
        <div className='other-settings__item-content'>
          <div className='other-settings__desc'>Aksiyon koleksiyonunu içeri aktarabilirsiniz.</div>
          <PrimaryButton onClick={handleOnClickImportApiButton} className='other-settings__action-button'>
            Aksiyonları İçe Aktar
          </PrimaryButton>
        </div>
      </div>
      <div className={clsx('other-settings__item', { 'other-settings__item--disable': !hasProjectApis })}>
        <div className='other-settings__item-text'>Dışa Aktar</div>
        <div className='other-settings__item-content'>
          <div className='other-settings__desc'>Mevcut tüm aksiyonları JSON dosyası olarak dışarı aktarabilirsiniz.</div>
          <PrimaryButton onClick={handleOnClickExportApiButton} className='other-settings__action-button'>
            Aksiyonları Dışa Aktar
          </PrimaryButton>
        </div>
      </div>
      <div className='other-settings__item other-settings__item--delete'>
        <div className='other-settings__item-text'>Projeyi Sil</div>
        <div className='other-settings__item-content'>
          <div className='other-settings__desc'>Projeyi tamamen silebilirsiniz. Proje silindikten sonra tekrar kurtarılamaz</div>
          <PrimaryButton
            onClick={handleOnClickDeleteProjectButton}
            className='other-settings__action-button other-settings__delete-project'>
            Projeyi Sil
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export default ProjectOtherSettings
