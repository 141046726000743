import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { MdDeleteOutline } from 'react-icons/md'
import { MdEdit } from 'react-icons/md'

import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './multipleInputList.scss'

export const INPUT_TYPES = {
  SELECT: 'select',
}

const MultipleInputList = props => {
  const { inputs, itemTextKeys: _itemTextKeys, items, onSave, onDelete, listClassName, onUpdate, validateItem } = props

  const [values, setValues] = useState({})
  const [editItemIndex, setEditItemIndex] = useState(null)
  const [editItemValues, setEditItemValues] = useState({})

  const itemTextKeys = _itemTextKeys || inputs.reduce((acc, item) => [...acc, item.key], [])
  const enableEditMode = !!onUpdate

  const handleUpdateValue = item => setValues(prev => ({ ...prev, ...item }))
  const handleClearValues = () => {
    const newValues = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
    setValues(newValues)
  }
  const handleUpdateEditItemValue = item => setEditItemValues(prev => ({ ...prev, ...item }))

  const checkIsEditingItem = index => index === editItemIndex

  const handleClearEditStates = () => {
    setEditItemValues({})
    setEditItemIndex(null)
  }

  const handleOnClickEditButton = index => () => {
    setEditItemIndex(index)
    setEditItemValues({ ...items[index] })
  }

  const handleChangeInput = key => e => {
    handleUpdateValue({ [key]: e.target.value })
  }

  const handleChangeSelect = key => value => {
    handleUpdateValue({ [key]: value })
  }

  const handleClickDeleteItem = index => () => {
    onDelete(index)
    handleClearEditStates()
  }

  const handleClickAddButton = () => {
    if (validateItem && !validateItem(values)) return

    onSave(values)
    handleClearValues()
  }

  const handleClickEditSaveButton = editItemIndex => () => {
    if (validateItem && !validateItem(editItemValues)) return

    const newItems = items.map((item, index) => (editItemIndex === index ? { ...item, ...editItemValues } : item))

    onUpdate(newItems)
    handleClearEditStates()
  }

  const renderInputItem = ({ key, placeholder, type, ...otherPayload }) => {
    const itemInputClassNames = 'multiple-input-list__bottom-item'

    switch (type) {
      case INPUT_TYPES.SELECT: {
        const { items } = otherPayload
        const selectedItemIndex = items.findIndex(item => item.value === values[key])

        return (
          <InputDropdown
            items={items}
            selectedItemIndex={selectedItemIndex}
            handleChange={handleChangeSelect(key)}
            placeholder={placeholder}
            className={itemInputClassNames}
          />
        )
      }

      default:
        return (
          <Input
            value={values[key]}
            key={key}
            placeholder={placeholder}
            onChange={handleChangeInput(key)}
            className={clsx(itemInputClassNames, 'multiple-input-list__input')}
          />
        )
    }
  }

  const renderItemCell = ({ key, item, index }) => {
    if (checkIsEditingItem(index)) {
      const handleUpdateEditInput = e => handleUpdateEditItemValue({ [key]: e.target.value })

      return (
        <Input
          value={editItemValues[key]}
          key={key}
          placeholder={key}
          onChange={handleUpdateEditInput}
          className='multiple-input-list__item-cell'
        />
      )
    }

    return (
      <div key={key} className='multiple-input-list__item-cell multiple-input-list__item-text'>
        {item[key]}
      </div>
    )
  }

  const renderEditActionButton = index => {
    if (checkIsEditingItem(index)) {
      return (
        <IoCheckmarkSharp
          onClick={handleClickEditSaveButton(index)}
          className='multiple-input-list__action-item multiple-input-list__action-item--save'
        />
      )
    }

    return (
      <MdEdit
        onClick={handleOnClickEditButton(index)}
        className='multiple-input-list__action-item multiple-input-list__action-item--edit'
      />
    )
  }

  useEffect(() => {
    const _values = inputs.reduce((acc, input) => ({ ...acc, [input.key]: input.value || '' }), {})
    setValues(_values)
  }, [])

  return (
    <div className='multiple-input-list'>
      <div className={clsx('multiple-input-list__list', listClassName)}>
        {items.length === 0 ? (
          <div className='multiple-input-list__no-item'>Hiç bir şey eklenmemiş</div>
        ) : (
          items.map((item, index) => (
            <div key={index} className='multiple-input-list__item'>
              <div className='multiple-input-list__item-left'>{itemTextKeys.map(key => renderItemCell({ key, item, index }))}</div>
              <div className='multiple-input-list__item-right'>
                {enableEditMode && renderEditActionButton(index)}
                <MdDeleteOutline
                  onClick={handleClickDeleteItem(index)}
                  className='multiple-input-list__action-item multiple-input-list__action-item--delete'
                />
              </div>
            </div>
          ))
        )}
      </div>
      <div className='multiple-input-list__bottom'>
        {inputs.map(item => renderInputItem(item))}
        <PrimaryButton onClick={handleClickAddButton} className='multiple-input-list__save-btn multiple-input-list__bottom-item'>
          Ekle
        </PrimaryButton>
      </div>
    </div>
  )
}

export default MultipleInputList
