import * as Sentry from '@sentry/react'

const ErrorBoundary = ({ children, fallback }) => {
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
