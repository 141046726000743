import clsx from 'clsx'
import dayjs from 'dayjs'
import { useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'

import JsonView from '@/components/JsonView/JsonView'
import Popup from '@/components/Popup/Popup'
import Tabs from '@/components/Tabs/Tabs'
import { API_STATUSES } from '@/constants'

import './apiLogDetailPopup.scss'

const checkIsSuccessLog = logStatus => logStatus === API_STATUSES.SUCCESS
const getLogStatusText = logStatus => (checkIsSuccessLog(logStatus) ? 'başarılı' : 'hata')
const getLogDate = logDate => dayjs(logDate).format('HH:mm, DD MMM YYYY')

const REQUEST_DETAIL_TABS = {
  DATA: 'data',
  HEADER: 'header',
  OTHERS: 'other',
}
const RESPONSE_DETAIL_TABS = {
  DATA: 'data',
  STATUS: 'status',
}

const requestDetailTabs = [
  { value: REQUEST_DETAIL_TABS.DATA, title: 'data' },
  { value: REQUEST_DETAIL_TABS.HEADER, title: 'header' },
  { value: REQUEST_DETAIL_TABS.OTHERS, title: 'diğer' },
]
const responseDetailTabs = [
  { value: RESPONSE_DETAIL_TABS.DATA, title: 'data' },
  { value: RESPONSE_DETAIL_TABS.STATUS, title: 'status' },
]

const ApiLogDetailPopup = ({ selectedApiLogIdForDetail, apiLogs, handleClosePopup, isVisible }) => {
  const [currentRequestDetailTab, setCurrentRequestDetailTab] = useState(REQUEST_DETAIL_TABS.DATA)
  const [currentResponseDetailTab, setCurrentResponseDetailTab] = useState(RESPONSE_DETAIL_TABS.DATA)

  const apiLogDetail = apiLogs.find(log => log.id === selectedApiLogIdForDetail) || {}

  const handleOnChangeRequestDetailTab = requestDetailTab => {
    setCurrentRequestDetailTab(requestDetailTab)
  }

  const handleOnChangeResponseDetailTab = responseDetailTab => {
    setCurrentResponseDetailTab(responseDetailTab)
  }

  const getCurrentRequestDetailJsonData = () => {
    switch (currentRequestDetailTab) {
      case REQUEST_DETAIL_TABS.DATA:
        return apiLogDetail.request?.axiosPayload?.data
      case REQUEST_DETAIL_TABS.HEADER:
        return apiLogDetail.request?.axiosPayload?.headers
      case REQUEST_DETAIL_TABS.OTHERS: {
        // eslint-disable-next-line no-unused-vars
        const { headers, data, ...others } = apiLogDetail.request?.axiosPayload || {}
        return others
      }
    }
  }

  const renderCurrentResponseDetailContent = () => {
    switch (currentResponseDetailTab) {
      case RESPONSE_DETAIL_TABS.DATA: {
        const responseData = apiLogDetail?.response?.data

        if (typeof responseData === 'object') {
          return <JsonView value={responseData} />
        }

        return responseData
      }
      case RESPONSE_DETAIL_TABS.STATUS:
        return <JsonView value={{ status: apiLogDetail?.response?.status }} />
    }
  }

  return (
    <Popup modalClassName='api-log-detail__popup' title='Detay' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='api-log-detail'>
        <div className='api-log-detail__list'>
          <div className='api-log-detail__item'>
            <div className='api-log-detail__other-list'>
              <div className='api-log-detail__other-list-item api-log-detail__content-user'>
                {apiLogDetail.user?.image ? (
                  <img src={apiLogDetail.user?.image} className='api-log-detail__content-user-img' />
                ) : (
                  <BiUserCircle className='api-log-detail__content-user-img' />
                )}
                <div className='api-log-detail__content-user-name'>{apiLogDetail.user?.name}</div>
              </div>
              <div className='api-log-detail__other-list-item'>{getLogDate(apiLogDetail.date)}</div>
            </div>
          </div>
          <div className='api-log-detail__item'>
            <div className='api-log-detail__detail'>
              <div className='api-log-detail__detail-top'>
                <div
                  className={clsx('api-log-detail__content-status', {
                    'api-log-detail__content-status--error': !checkIsSuccessLog(apiLogDetail.status),
                    'api-log-detail__content-status--success': checkIsSuccessLog(apiLogDetail.status),
                  })}>
                  {getLogStatusText(apiLogDetail.status)}
                </div>
                <div title={apiLogDetail.request?.axiosPayload?.url} className='api-log-detail__content-url'>
                  {apiLogDetail.request?.axiosPayload?.url}
                </div>
              </div>
              <div className='api-log-detail__detail-request'>
                <div className='api-log-detail__detail-title'>İstek Verileri:</div>
                <div className='api-log-detail__detail-tabs'>
                  <Tabs onChange={handleOnChangeRequestDetailTab} value={currentRequestDetailTab}>
                    {requestDetailTabs.map(item => (
                      <Tabs.Item key={item.value} value={item.value} className='api-log-detail__detail-tab-item'>
                        {item.title}
                      </Tabs.Item>
                    ))}
                  </Tabs>
                </div>
                <JsonView value={getCurrentRequestDetailJsonData()} />
              </div>
              <div className='api-log-detail__detail-response'>
                <div className='api-log-detail__detail-title'>Sonuç:</div>
                <div className='api-log-detail__detail-tabs'>
                  <Tabs onChange={handleOnChangeResponseDetailTab} value={currentResponseDetailTab}>
                    {responseDetailTabs.map(item => (
                      <Tabs.Item key={item.value} value={item.value} className='api-log-detail__detail-tab-item'>
                        {item.title}
                      </Tabs.Item>
                    ))}
                  </Tabs>
                </div>
                {renderCurrentResponseDetailContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ApiLogDetailPopup
