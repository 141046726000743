import ToggleSwitchComponent from '@/components/ToggleSwitch/ToggleSwitch'

export const DEFAULT_VALUES = {
  ON: true,
  OFF: false,
}

const ToggleSwitch = ({ field, handleChangeFieldValue }) => {
  const { value, properties = {} } = field
  const SWITCH_VALUES = {
    ON: properties.onValue || DEFAULT_VALUES.ON,
    OFF: properties.offValue || DEFAULT_VALUES.OFF,
  }
  const isChecked = value === SWITCH_VALUES.ON

  const getPreparedValue = _isChecked => (_isChecked ? SWITCH_VALUES.ON : SWITCH_VALUES.OFF)
  const handleChangeValue = e => {
    handleChangeFieldValue(getPreparedValue(e.target.checked))
  }

  return <ToggleSwitchComponent checked={isChecked} handleChangeValue={handleChangeValue} />
}

export default ToggleSwitch
