import _ from 'lodash'
import { v4 as uuid } from 'uuid'

import { BODY_TYPE_CONFIGS, FIELD_SCOPE_TYPES, FIELD_TYPES } from '@/constants'
import { POSTMAN_BODY_TYPES } from '@/containers/PostmanBuilder/constants'
import { getClearedConsoleDynamicPrefix } from '@/containers/PostmanBuilder/utils'
import { getApiObject, getFieldObject } from '@/utils/buildApi'

import {
  CONSOLE_DYNAMIC_PREFIX_PATTERN,
  POSTMAN_REPLACE_PATTERN,
  getBody,
  getBodyType,
  getDescription,
  getHeader,
  getMethod,
  getUrl,
  getUrlQueryParameters,
  isDynamicPostmanVariable,
  replaceWithConsoleDynamicPrefix,
} from '.'

const getField = ({ type, customFieldObject }) => getFieldObject({ type, customFieldObject })

const getUrlFields = ({ request }) => {
  const urlQueryParameters = getUrlQueryParameters({ request })
  if (!urlQueryParameters) return []

  const urlFields = urlQueryParameters
    .filter(item => !isDynamicPostmanVariable(item.value))
    .map(({ key, value }) =>
      getField({
        type: FIELD_TYPES.STRING,
        customFieldObject: {
          key: key,
          label: key,
          defaultValue: value,
          fieldScopeType: FIELD_SCOPE_TYPES.URL,
        },
      }),
    )

  return urlFields || []
}

const getBodyFields = ({ request }) => {
  const body = getBody({
    request,
  })
  if (!body) return {}

  const bodyFields = body.items
    .filter(({ value }) => !isDynamicPostmanVariable(value))
    .map(({ key, value }) =>
      getField({
        type: FIELD_TYPES.STRING,
        customFieldObject: {
          key: key,
          label: key,
          defaultValue: value,
          fieldScopeType: FIELD_SCOPE_TYPES.BODY,
        },
      }),
    )
  const dynamicBodies = body.items
    .filter(({ value }) => isDynamicPostmanVariable(value))
    .reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: replaceWithConsoleDynamicPrefix(value),
      }),
      {},
    )

  return {
    bodyFields,
    dynamicBodies,
  }
}

const getHeaders = request => {
  const headers = getHeader(request)
  if (!headers) return {}

  const activeHeaders = headers.filter(({ disabled }) => !disabled)

  const dynamicHeaders = activeHeaders
    .filter(({ value }) => isDynamicPostmanVariable(value))
    .reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: replaceWithConsoleDynamicPrefix(value),
      }),
      {},
    )

  const header = activeHeaders
    .filter(({ value }) => !isDynamicPostmanVariable(value))
    .reduce(
      (acc, { key, value }) => ({
        ...acc,
        [key]: value,
      }),
      {},
    )

  return {
    header,
    dynamicHeaders,
  }
}

const getBodyTypeConfig = request => {
  const bodyType = getBodyType(request)

  switch (bodyType) {
    case POSTMAN_BODY_TYPES.FORM_DATA:
      return {
        [BODY_TYPE_CONFIGS.FORM_DATA]: true,
      }
    case POSTMAN_BODY_TYPES.URL_ENCODED:
      return {
        [BODY_TYPE_CONFIGS.FORM_URL_ENCODED]: true,
      }
    default:
      return {}
  }
}
const getConfigs = request => {
  const bodyTypeConfigs = getBodyTypeConfig(request)

  return {
    ...bodyTypeConfigs,
  }
}

const processUrl = ({ url, postmanDefaultUrlQueryParam }) => {
  const replacedUrl = url.replaceAll(POSTMAN_REPLACE_PATTERN, match => {
    const dynamicKeyWithConsoleDynamicPrefix = match.replace(POSTMAN_REPLACE_PATTERN, CONSOLE_DYNAMIC_PREFIX_PATTERN)

    postmanDefaultUrlQueryParam.add(getClearedConsoleDynamicPrefix(dynamicKeyWithConsoleDynamicPrefix))

    return dynamicKeyWithConsoleDynamicPrefix
  })

  return replacedUrl
}

const processApi = ({ action, postmanDefaultHeader, postmanDefaultBody, postmanDefaultUrlQueryParam }) => {
  const { item, folderName } = action
  const { name: title, request } = item

  const method = getMethod(request)
  const description = getDescription(request)
  const urlFields = getUrlFields({ request })
  const { bodyFields = [], dynamicBodies = {} } = getBodyFields({ request })
  const { header = {}, dynamicHeaders = {} } = getHeaders(request)
  const configs = getConfigs(request)

  const _url = getUrl(request)
  const url = processUrl({
    url: _url,
    postmanDefaultUrlQueryParam,
  })

  Object.values(dynamicBodies).forEach(dynamicKey => postmanDefaultBody.add(getClearedConsoleDynamicPrefix(dynamicKey)))
  Object.values(dynamicHeaders).forEach(dynamicKey => postmanDefaultHeader.add(getClearedConsoleDynamicPrefix(dynamicKey)))

  return {
    folderName,
    api: getApiObject({
      __id: uuid(),
      __dynamicDefaultBody: dynamicBodies,
      __dynamicDefaultHeaders: dynamicHeaders,
      title,
      method,
      description,
      url,
      configs,
      header,
      fields: [...urlFields, ...bodyFields],
    }),
  }
}

export const processAllActions = ({ allActions }) => {
  const postmanDefaultHeader = new Set()
  const postmanDefaultBody = new Set()
  const postmanDefaultUrlQueryParam = new Set()

  const adjustedAllActions = allActions.map(action =>
    processApi({ action, postmanDefaultHeader, postmanDefaultBody, postmanDefaultUrlQueryParam }),
  )

  return {
    normalizedActions: _.groupBy(adjustedAllActions, ({ folderName }) => folderName),
    postmanDefaultHeader,
    postmanDefaultBody,
    postmanDefaultUrlQueryParam,
  }
}
