import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { IoChevronBack } from 'react-icons/io5'
import { VscSettings } from 'react-icons/vsc'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { postProjectApi, updateProjectApi } from '@/api'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { FIELD_SCOPE_TYPES } from '@/constants'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildApiTop.scss'

const filterFieldsByScopeType = (fields, scopeType) => fields.filter(({ fieldScopeType }) => fieldScopeType === scopeType)
// eslint-disable-next-line no-unused-vars
const normalizeFields = fields => fields.map(({ fieldScopeType, id, ...otherFieldValues }) => otherFieldValues)

const BuildApiTop = () => {
  const dispatch = useDispatch()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isDirty } = useSelector(state => state.buildApi)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const api = useSelector(state => state.buildApi.api)
  const apiId = searchParams.get('id')
  const isEdit = !!apiId

  const handleOnChangeTitle = e => {
    dispatch(buildApiReducerActions.setApiTitle(e.target.value))
  }

  const normalizeApiDetail = () => {
    const { fields, ...otherApiDetails } = api

    const urlFields = normalizeFields(filterFieldsByScopeType(fields, FIELD_SCOPE_TYPES.URL))
    const bodyFields = normalizeFields(filterFieldsByScopeType(fields, FIELD_SCOPE_TYPES.BODY))

    return {
      ...otherApiDetails,
      urlFields,
      bodyFields,
    }
  }

  const handleOnClickSaveButton = async () => {
    try {
      setIsSubmitting(true)

      const normalizedApiDetail = normalizeApiDetail()
      const projectApiRes = isEdit ? await updateProjectApi(apiId, normalizedApiDetail) : await postProjectApi(normalizedApiDetail)

      toast.success(isEdit ? 'Aksiyon başarıyla güncellendi' : 'Aksiyon başarıyla oluşturuldu')
      dispatch(buildApiReducerActions.setIsDirty(false))
      setTimeout(() => {
        const _apiID = isEdit ? apiId : projectApiRes.data.api.id
        navigate(`/api-execute/${_apiID}`)
      }, 500)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleOnClickRedirectOutside = navigateUrl => () => {
    if (!isDirty) {
      navigate(navigateUrl)
      return
    }

    const result = confirm('Henüz değişiklikler kaydedilmedi, devam etmeye emin misin?')
    if (result) {
      navigate(navigateUrl)
    }
  }

  const handleOnClickSettingsButton = () => {
    dispatch(buildApiReducerActions.setIsEnableSettingsPopup(true))
  }

  return (
    <div className='build-api-top'>
      <div className='build-api-top__left'>
        <div onClick={handleOnClickRedirectOutside('/')} className='build-api-top__home-link' to='/'>
          <IoChevronBack className='build-api-top__home-link-icon' />
          <span>geri</span>
        </div>
      </div>
      <div className='build-api-top__middle'>
        <input
          value={api.title}
          onChange={handleOnChangeTitle}
          className={clsx('build-api-top__title-input', { 'build-api-top__title-input--no-data': !api.title })}
          placeholder='Başlık gir'
        />
      </div>
      <div className='build-api-top__right'>
        {isEdit && (
          <div onClick={handleOnClickRedirectOutside(`/api-execute/${apiId}`)} className='build-api-top__back-to-action'>
            kaydetmeden git
          </div>
        )}
        <PrimaryButton isLoading={isSubmitting} onClick={handleOnClickSaveButton} className='build-api-top__save-btn'>
          Kaydet
        </PrimaryButton>
        {isEdit && (
          <div onClick={handleOnClickSettingsButton} className='build-api-top__settings-button'>
            <VscSettings />
          </div>
        )}
      </div>
    </div>
  )
}

export default BuildApiTop
