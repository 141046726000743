import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Input from '@/containers/BuildApi/components/Settings/Input/Input'
import Textarea from '@/containers/BuildApi/components/Settings/Textarea/Textarea'
import ToggleSwitch from '@/containers/BuildApi/components/Settings/ToggleSwitch/ToggleSwitch'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildApiRightPanelConfirmationSetting.scss'

const checkHasConfirmation = configs => !!configs?.confirmation
const INITIAL_CONFIRMATION_INFO = {
  title: 'Emin misiniz?',
  description: 'Bu işlemi gerçekleştirmek istediğinize emin misiniz?',
}

const BuildApiRightPanelConfirmationSetting = () => {
  const { api } = useSelector(state => ({
    api: state.buildApi.api,
  }))
  const configs = api?.configs

  const [isEnabledConfirmation, setIsEnabledConfirmation] = useState(checkHasConfirmation(configs))

  const previousConfirmationInfo = useRef({})
  const dispatch = useDispatch()

  const handleOnChangeConfirmationStatus = e => {
    const _status = e.target.checked

    setIsEnabledConfirmation(_status)
    handleProcessConfirmationByStatus(_status)
  }

  const handleProcessConfirmationByStatus = _isEnabledConfirmation => {
    if (_isEnabledConfirmation) {
      dispatch(
        buildApiReducerActions.setApiConfigs({
          ...configs,
          confirmation: { ...INITIAL_CONFIRMATION_INFO, ...previousConfirmationInfo.current },
        }),
      )
      return
    }

    // eslint-disable-next-line no-unused-vars
    const { confirmation, ...otherConfigs } = configs
    dispatch(buildApiReducerActions.setApiConfigs({ ...otherConfigs }))
    previousConfirmationInfo.current = configs?.confirmation ? configs?.confirmation : {}
  }

  const handleOnChangeConfirmationInput = key => e => {
    dispatch(buildApiReducerActions.setApiConfigs({ ...configs, confirmation: { ...configs.confirmation, [key]: e.target.value } }))
  }

  return (
    <div className='build-api-confirmation-setting'>
      <ToggleSwitch checked={isEnabledConfirmation} handleChangeValue={handleOnChangeConfirmationStatus} />
      {isEnabledConfirmation && (
        <div className='build-api-confirmation-setting__content'>
          <div className='build-api-confirmation-setting__content-item'>
            <div className='build-api-confirmation-setting__item-title'>Başlık</div>
            <Input value={configs?.confirmation?.title} placeholder='Başlık' onChange={handleOnChangeConfirmationInput('title')} />
          </div>
          <div className='build-api-confirmation-setting__content-item'>
            <div className='build-api-confirmation-setting__item-title'>Açıklama</div>
            <Textarea
              value={configs?.confirmation?.description}
              className='build-api-confirmation-setting__description-input'
              placeholder='Açıklama'
              onChange={handleOnChangeConfirmationInput('description')}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default BuildApiRightPanelConfirmationSetting
