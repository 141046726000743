import { v4 as uuidv4 } from 'uuid'

import InavoLoading from '@/components/InavoLoading/InavoLoading'

import './toggleSwitch.scss'

const ToggleSwitch = ({ checked, handleChangeValue, isLoading, id = uuidv4() }) => {
  return (
    <div className='toggle-switch'>
      <input checked={checked} onChange={handleChangeValue} type='checkbox' id={id} />
      <label htmlFor={id} />
      {isLoading && (
        <div className='toggle-switch__loading-wrapper'>
          <InavoLoading className='toggle-switch__loading-img' />
        </div>
      )}
    </div>
  )
}

export default ToggleSwitch
