import { useState } from 'react'
import toast from 'react-hot-toast'
import { MdOutlineCheckCircleOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { postForgetPassword } from '@/api'
import AuthenticationFlow from '@/components/AuthenticationFlow/AuthenticationFlow'
import Input, { INPUT_VARIANTS } from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { ROUTE_URLS } from '@/constants/routeUrls'
import { isValidEmail } from '@/utils'

import './forgetPassword.scss'

const ForgetPassword = () => {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSentEmail, setIsSentEmail] = useState(false)

  const isValidUserEmail = email !== '' && isValidEmail(email)

  const handleChangeEmail = e => {
    setEmail(e.target.value)
  }

  const handleClickSendButton = async () => {
    try {
      setIsLoading(true)

      await postForgetPassword({ email })
      setIsSentEmail(true)
    } catch (err) {
      if (err.response.status === 400) {
        toast.error('Böyle bir email sistemde kayıtlı değil')
        return
      }

      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthenticationFlow title='Şifremi Unuttum'>
      {isSentEmail ? (
        <div className='forget-password__success'>
          <MdOutlineCheckCircleOutline className='forget-password__success-icon' />
          <div className='forget-password__success-title'>Başarılı</div>
          <div className='forget-password__success-desc'>{email} adresine email gönderdik.</div>
          <Link to={ROUTE_URLS.LOGIN} className='forget-password__text forget-password__text--login'>
            Şimdi giriş yap
          </Link>
        </div>
      ) : (
        <>
          <Input
            variant={INPUT_VARIANTS.BOLD}
            onChange={handleChangeEmail}
            value={email}
            className='forget-password__input'
            placeholder='Email'
            name='forget_password_email'
          />
          <PrimaryButton
            className='forget-password__send-btn'
            isLoading={isLoading}
            disable={!isValidUserEmail}
            onClick={handleClickSendButton}>
            Gönder
          </PrimaryButton>
          <Link to={ROUTE_URLS.LOGIN} className='forget-password__text forget-password__text--login'>
            Hesabın mı var? Giriş Yap
          </Link>
        </>
      )}
    </AuthenticationFlow>
  )
}

export default ForgetPassword
