import { Outlet } from 'react-router-dom'

import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import ErrorPage from '@/components/ErrorPage/ErrorPage'

const ErrorBoundaryWrapper = () => {
  return (
    <ErrorBoundary fallback={ErrorPage}>
      <Outlet />
    </ErrorBoundary>
  )
}

export default ErrorBoundaryWrapper
