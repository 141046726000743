import clsx from 'clsx'
import { useState } from 'react'

import InavoLoading from '@/components/InavoLoading/InavoLoading'
import PageTitle from '@/components/PageTitle/PageTitle'
import Tabs, { TAB_SIZES } from '@/components/Tabs/Tabs'
import ProjectAdvancedSettings from '@/pages/ProjectSettings/ProjectAdvancedSettings/ProjectAdvancedSettings'
import ProjectGeneralSettings from '@/pages/ProjectSettings/ProjectGeneralSettings/ProjectGeneralSettings'
import ProjectOtherSettings from '@/pages/ProjectSettings/ProjectOtherSettings/ProjectOtherSettings'

import './projectSettings.scss'

const MENU_TYPES = {
  SETTINGS: 'settings',
  ADVANCED_SETTINGS: 'advanced_settings',
  OTHERS: 'others',
}

const MENU_LIST = [
  { value: MENU_TYPES.SETTINGS, title: 'Ayarlar' },
  { value: MENU_TYPES.ADVANCED_SETTINGS, title: 'Gelişmiş Ayarlar' },
  { value: MENU_TYPES.OTHERS, title: 'Diğer' },
]

const ProjectSettings = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activeSettingsType, setActiveSettingsType] = useState(MENU_TYPES.SETTINGS)

  const renderContent = () => {
    switch (activeSettingsType) {
      case MENU_TYPES.SETTINGS:
        return <ProjectGeneralSettings setIsLoading={setIsLoading} />
      case MENU_TYPES.ADVANCED_SETTINGS:
        return <ProjectAdvancedSettings setIsLoading={setIsLoading} />
      case MENU_TYPES.OTHERS:
        return <ProjectOtherSettings setIsLoading={setIsLoading} />
    }
  }

  const handleOnClickMenuItem = menuType => {
    setActiveSettingsType(menuType)
  }

  return (
    <div className='project-settings'>
      <PageTitle>Proje Ayarları</PageTitle>
      <div className='project-settings__menu'>
        <Tabs size={TAB_SIZES.XL} onChange={handleOnClickMenuItem} value={activeSettingsType}>
          {MENU_LIST.map(item => (
            <Tabs.Item key={item.value} value={item.value} className='project-settings__tab-item'>
              {item.title}
            </Tabs.Item>
          ))}
        </Tabs>
      </div>
      {isLoading && (
        <div className='project-settings__content-loading'>
          <InavoLoading className='project-settings__content-loading-img' />
        </div>
      )}
      <div className={clsx('project-settings__content', { 'project-settings__content--is-loading': isLoading })}>{renderContent()}</div>
    </div>
  )
}

export default ProjectSettings
