import clsx from 'clsx'
import { useState } from 'react'
import { BiHomeAlt2, BiSolidHomeAlt2 } from 'react-icons/bi'
import { PiUserBold, PiUsersThreeBold, PiUsersThreeFill } from 'react-icons/pi'
import { TbSettings, TbSettingsFilled } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'
import { ROUTE_URLS } from '@/constants/routeUrls'
import MobileAllApisModal from '@/containers/Root/MobileRoot/MobileRootBottom/MobileAllApisModal/MobileAllApisModal'
import MobileUserSettingsModal from '@/containers/Root/MobileRoot/MobileRootBottom/MobileUserSettingsModal/MobileUserSettingsModal'
import usePermission from '@/hooks/usePermission'
import { appReducerSelectors } from '@/store/reducers/app'

import './mobileRootBottom.scss'

const MobileRootBottom = () => {
  const [isEnabledAllApisModal, setIsEnabledAllApisModal] = useState(false)
  const [isEnabledUserMenuModal, setIsEnabledUserMenuModal] = useState(false)

  const location = useLocation()
  const { user } = useSelector(state => {
    const { user } = state.auth

    return {
      user,
    }
  })
  const hasProjects = useSelector(appReducerSelectors.checkHasProjectsSelector)
  const { checkPagePermission } = usePermission()

  const checkIsActivePath = currentPath => location.pathname === currentPath

  const getActiveIcon = path => {
    switch (path) {
      case ROUTE_URLS.HOME:
        return <BiSolidHomeAlt2 />
      case ROUTE_URLS.USERS:
        return <PiUsersThreeFill />
      case ROUTE_URLS.PROJECT_SETTINGS:
        return <TbSettingsFilled />
    }
  }

  const getIcon = path => {
    switch (path) {
      case ROUTE_URLS.HOME:
        return <BiHomeAlt2 />
      case ROUTE_URLS.USERS:
        return <PiUsersThreeBold />
      case ROUTE_URLS.PROJECT_SETTINGS:
        return <TbSettings />
    }
  }

  const getLinkIcon = path => (checkIsActivePath(path) ? getActiveIcon(path) : getIcon(path))

  const handleOnClickAllApisIcon = () => {
    setIsEnabledAllApisModal(true)
  }

  const handleCloseAllApisModal = () => {
    setIsEnabledAllApisModal(false)
  }

  const handleCloseUserMenuModal = () => {
    setIsEnabledUserMenuModal(false)
  }

  const handleOnClickProfile = () => {
    setIsEnabledUserMenuModal(true)
  }

  const renderUserImage = () => {
    const { image } = user

    if (image) return <img className='mobile-root-bottom__item-image' src={image} />

    return (
      <div className='mobile-root-bottom__item-icon'>
        <PiUserBold />
      </div>
    )
  }

  const getItemClasses = path =>
    clsx('mobile-root-bottom__item', {
      'mobile-root-bottom__item--active': checkIsActivePath(path),
      'mobile-root-bottom__item--disable': !checkPagePermission(path),
    })

  return (
    <div className='mobile-root-bottom'>
      {hasProjects && <MobileAllApisModal isVisible={isEnabledAllApisModal} handleCloseModal={handleCloseAllApisModal} />}
      <MobileUserSettingsModal isVisible={isEnabledUserMenuModal} handleCloseModal={handleCloseUserMenuModal} />
      <div className='mobile-root-bottom__list'>
        <Link to={ROUTE_URLS.HOME} className={getItemClasses(ROUTE_URLS.HOME)}>
          <div className='mobile-root-bottom__item-icon'>{getLinkIcon(ROUTE_URLS.HOME)}</div>
        </Link>
        <Link to={ROUTE_URLS.USERS} className={getItemClasses(ROUTE_URLS.USERS)}>
          <div className='mobile-root-bottom__item-icon'>{getLinkIcon(ROUTE_URLS.USERS)}</div>
        </Link>
        <div onClick={handleOnClickAllApisIcon} className='mobile-root-bottom__item'>
          <img src={CircleBlackLogo} className='mobile-root-bottom__all-apis-icon' />
        </div>
        <Link to={ROUTE_URLS.PROJECT_SETTINGS} className={getItemClasses(ROUTE_URLS.PROJECT_SETTINGS)}>
          <div className='mobile-root-bottom__item-icon'>{getLinkIcon(ROUTE_URLS.PROJECT_SETTINGS)}</div>
        </Link>
        <div onClick={handleOnClickProfile} className='mobile-root-bottom__item'>
          {renderUserImage()}
        </div>
      </div>
    </div>
  )
}

export default MobileRootBottom
