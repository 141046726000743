import clsx from 'clsx'
import { motion } from 'framer-motion'
import { isEqual } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { IoIosClose } from 'react-icons/io'
import { RiArrowDropDownLine } from 'react-icons/ri'

import InavoLoading from '@/components/InavoLoading/InavoLoading'
import useClickOutside from '@/hooks/useClickOutside'
import { filterText as filterTextUtil } from '@/utils'

import './inputDropdown.scss'

const getSelectedItemIndex = selectedItemIndex => {
  if (selectedItemIndex >= 0) return selectedItemIndex
  return null
}

const InputDropdown = ({
  items: _items,
  handleChange,
  placeholder,
  isLoading,
  selectedItemIndex: _selectedItemIndex,
  className,
  dropdownClassName,
  dropdownItemClassName,
  filterInputClassName,
  handleChangeFilterInput,
  filterText: filterTextProp,
  handleClickItem: _handleClickItem,
  handleDeleteSelectedItem,
  isEnableDeleteItemIcon = true,
}) => {
  const [items, setItems] = useState([])
  const [filterText, setFilterText] = useState('')
  const [isEnabledDropdown, setIsEnabledDropdown] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(getSelectedItemIndex(_selectedItemIndex))

  const inputDropdownRef = useRef(null)
  const inputFilterRef = useRef(null)

  const inputFilterText = filterTextProp || filterText

  const handleDisableDropdown = () => {
    setIsEnabledDropdown(false)
  }

  const isSelectedItem = selectedItemIndex !== null
  const selectedItem = items[selectedItemIndex]
  const visibleSelectedItemLabel = inputFilterText === '' && isSelectedItem

  useClickOutside(inputDropdownRef, handleDisableDropdown)

  const handleSelectButton = () => {
    setIsEnabledDropdown(true)
    inputFilterRef.current.focus()
  }

  const handleClickItem = index => () => {
    const filteredItem = filteredItems[index]
    const itemIndex = items.findIndex(item => item.value === filteredItem.value && item.label === filteredItem.label)

    setSelectedItemIndex(itemIndex)
    handleChange(filteredItem.value)
    setFilterText('')
    handleDisableDropdown()

    _handleClickItem && _handleClickItem()
  }

  const handleOnChange = e => {
    setFilterText(e.target.value)
  }

  useEffect(() => {
    if (!isEqual(items, _items)) {
      setItems([..._items])
    }
  }, [_items])

  useEffect(() => {
    setSelectedItemIndex(getSelectedItemIndex(_selectedItemIndex))
  }, [_selectedItemIndex])

  const renderInputIcon = () => {
    switch (true) {
      case isLoading:
        return <InavoLoading className='input-dropdown__loading-icon' />
      case isEnableDeleteItemIcon && isSelectedItem: {
        const handleClickDeleteSelectedItem = () => {
          handleChange('')
          handleDeleteSelectedItem && handleDeleteSelectedItem()
        }

        return (
          <IoIosClose onClick={handleClickDeleteSelectedItem} className='input-dropdown__input-icon input-dropdown__input-icon--delete' />
        )
      }
      default:
        return <RiArrowDropDownLine className='input-dropdown__input-icon' />
    }
  }

  const inputDropdownClassNames = clsx('input-dropdown', className)
  const filteredItems = items.filter(item => filterTextUtil(filterText, item.label))

  return (
    <div ref={inputDropdownRef} className={inputDropdownClassNames}>
      <div onClick={handleSelectButton} className='input-dropdown__select'>
        {visibleSelectedItemLabel && <div className='input-dropdown__selected-item-label'>{selectedItem?.label}</div>}
        <input
          ref={inputFilterRef}
          onChange={handleChangeFilterInput || handleOnChange}
          value={inputFilterText}
          className={clsx('input-dropdown__filter-input', filterInputClassName, {
            'input-dropdown__filter-input--enabled-dropdown': isEnabledDropdown,
          })}
          {...(isSelectedItem ? {} : { placeholder })}
        />
        {renderInputIcon()}
      </div>
      <motion.div
        initial={false}
        animate={isEnabledDropdown ? 'open' : 'closed'}
        transition={{ ease: 'linear', duration: 0.08 }}
        variants={{
          open: { opacity: 1, display: 'flex' },
          closed: { opacity: 0, transitionEnd: { display: 'none' } },
        }}
        className={clsx('input-dropdown__dropdown', dropdownClassName)}>
        {filteredItems.length > 0 ? (
          filteredItems.map(({ label }, key) => (
            <div onClick={handleClickItem(key)} key={key} className={clsx('input-dropdown__item', dropdownItemClassName)}>
              {label}
            </div>
          ))
        ) : (
          <div className='input-dropdown__no-item-warning'>Hiç veri yok</div>
        )}
      </motion.div>
    </div>
  )
}

export default InputDropdown
