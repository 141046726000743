import axios from 'axios'

import { getUserTokenStorage } from '@/utils/localStorageActions'

const v1Api = axios.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL}/v1`,
})
v1Api.interceptors.request.use(
  function (config) {
    const userToken = getUserTokenStorage()
    if (userToken) {
      config.headers['TOKEN'] = userToken
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

export { v1Api }
