import Popup from '@/components/Popup/Popup'
import ProjectLogsFilters from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogsFilters'

import './projectLogsFiltersPopup.scss'

const ProjectLogsFiltersPopup = ({ isVisible, handleClosePopup, ...otherProps }) => {
  return (
    <Popup title='Filtre' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <ProjectLogsFilters {...otherProps} handleClosePopup={handleClosePopup} />
    </Popup>
  )
}

export default ProjectLogsFiltersPopup
