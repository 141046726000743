import Popup from '@/components/Popup/Popup'
import { getFileType } from '@/utils'

import './previewFileModal.scss'

export const FILE_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
}

const PreviewFileModal = ({ file, isVisible, handleClosePopup }) => {
  const renderContent = () => {
    const fileType = getFileType(file)

    switch (fileType) {
      case FILE_TYPES.IMAGE:
        return <img className='preview-file__file' src={URL.createObjectURL(file)} />
      case FILE_TYPES.AUDIO:
        return (
          <audio controls className='preview-file__file'>
            <source src={URL.createObjectURL(file)} />
          </audio>
        )
      case FILE_TYPES.VIDEO:
        return (
          <video controls className='preview-file__file'>
            <source src={URL.createObjectURL(file)} />
          </video>
        )
      default:
        return 'Bilinmeyen dosya'
    }
  }

  return (
    <Popup isVisible={isVisible} handleClosePopup={handleClosePopup} title='Önizleme'>
      {renderContent()}
    </Popup>
  )
}

export default PreviewFileModal
