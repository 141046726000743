import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getProjectUserImprint } from '@/api'
import PageTitle from '@/components/PageTitle/PageTitle'
import ProjectUserLeave from '@/pages/ProjectUserImprint/ProjectUserLeave/ProjectUserLeave'
import { appReducerSelectors } from '@/store/reducers/app'

import './projectUserImprint.scss'

const ProjectUserImprint = () => {
  const [user, setUser] = useState({})

  const project = useSelector(appReducerSelectors.selectedProjectSelector)

  const handleGetProjectUserImprint = async () => {
    const user = await getProjectUserImprint()
    setUser(user)
  }

  useEffect(() => {
    handleGetProjectUserImprint()
  }, [])

  return (
    <div className='project-user-imprint'>
      <PageTitle>Kullanıcı Künyesi </PageTitle>
      <div className='project-user-imprint__section-list'>
        <div className='project-user-imprint__section'>
          <div className='project-user-imprint__section-content'>
            <div className='project-user-imprint__item'>
              <div className='project-user-imprint__item-title'>Projeye Katılma Tarihi</div>
              <div className='project-user-imprint__item-content'>
                <div className='project-user-imprint__text-content'>{dayjs(user.createdAt).format('DD MMMM YYYY')}</div>
              </div>
            </div>
          </div>
        </div>
        {!project.isOwner && (
          <div className='project-user-imprint__section'>
            <div className='project-user-imprint__section-content'>
              <div className='project-user-imprint__item'>
                <div className='project-user-imprint__item-content'>
                  <ProjectUserLeave />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProjectUserImprint
