import { useEffect, useState } from 'react'

import { getProjectUsers } from '@/api'
import InputDropdown from '@/components/InputDropdown/InputDropdown'

import './projectLogUserFilter.scss'

const ProjectLogUserFilter = ({ value, handleUpdateFilters }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [userItems, setUserItems] = useState([])

  const selectedItemIndex = userItems.findIndex(item => item.value === value)

  const handleChangeSelect = _value => handleUpdateFilters({ userId: _value })

  const handleGetProjectUsers = async () => {
    try {
      const projectUsersRes = await getProjectUsers({ isIncludedProjectOwner: true })
      const newUserItems = projectUsersRes.data.map(({ user }) => ({ value: user.id, label: user.name }))

      setUserItems(newUserItems)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetProjectUsers()
  }, [])

  return (
    <InputDropdown
      className='project-log-user-filter'
      items={userItems}
      selectedItemIndex={selectedItemIndex}
      handleChange={handleChangeSelect}
      placeholder='Kullanıcı Seç'
      isLoading={isLoading}
    />
  )
}

export default ProjectLogUserFilter
