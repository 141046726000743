import clsx from 'clsx'

import './input.scss'

export const INPUT_VARIANTS = {
  DEFAULT: 'default',
  BOLD: 'bold',
}

const Input = ({ className, variant = INPUT_VARIANTS.DEFAULT, ...otherProps }) => {
  const allClassnames = clsx('input-component', className, `input-component--${variant}`)

  return <input className={allClassnames} type='string' {...otherProps} />
}

export default Input
