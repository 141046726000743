import ColorPickerInput from '@/components/ColorPickerInput/ColorPickerInput'
import FileInputList from '@/components/FileInputList/FileInputList'
import Image from '@/components/FormFieldComponents/Image/Image'
import RemoteInputDropdown from '@/components/FormFieldComponents/RemoteInputDropdown/RemoteInputDropdown'
import RichTextEditor from '@/components/FormFieldComponents/RichTextEditor/RichTextEditor'
import ToggleSwitch from '@/components/FormFieldComponents/ToggleSwitch/ToggleSwitch'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import JsonEditorWithError from '@/components/JsonEditorWithError/JsonEditorWithError'
import Textarea from '@/components/Textarea/Textarea'
import { FIELD_SCOPE_TYPES, FIELD_TYPES } from '@/constants'
import { convertFile, convertToBase64 } from '@/utils'

const fieldClassName = 'dynamic-form__field-input'

const getDependencyFields = (dependencyKeys, dependencyFields) => {
  const mapDependencyKey = ({ key }) => {
    const value = dependencyFields.find(field => field.key === key)?.value
    return {
      key,
      value,
    }
  }

  const urlFields = dependencyKeys.filter(field => field.type === FIELD_SCOPE_TYPES.URL).map(mapDependencyKey)
  const bodyFields = dependencyKeys.filter(field => field.type === FIELD_SCOPE_TYPES.BODY).map(mapDependencyKey)

  return {
    bodyFields,
    urlFields,
  }
}

const renderStringType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Input id={field.key} className={fieldClassName} onChange={handleOnChange} placeholder={label} value={value} />
}

const renderNumberType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Input id={field.key} className={fieldClassName} type='number' onChange={handleOnChange} placeholder={label} value={value} />
}

const renderPasswordType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Input id={field.key} className={fieldClassName} type='password' onChange={handleOnChange} placeholder={label} value={value} />
}

const renderTextareaType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Textarea id={field.key} className={fieldClassName} value={value} onChange={handleOnChange} placeholder={label} />
}

const renderSelectType = (field, payload) => {
  const {
    label,
    value,
    properties: { choices },
  } = field
  const { handleChangeFieldValue } = payload

  const selectedItemIndex = choices.findIndex(choice => choice.value === value)

  return (
    <InputDropdown
      handleChange={handleChangeFieldValue}
      placeholder={label}
      items={choices}
      className={fieldClassName}
      {...(selectedItemIndex >= 0 ? { selectedItemIndex } : {})}
    />
  )
}

const renderRemoteSelectType = (field, payload) => {
  const { value, properties } = field
  const { handleChangeFieldValue, dependencyFields } = payload

  const hasDependencyFields = Array.isArray(properties.dependencyKeys)
  const { bodyFields, urlFields } = hasDependencyFields ? getDependencyFields(properties?.dependencyKeys, dependencyFields) : {}

  return (
    <RemoteInputDropdown
      className={fieldClassName}
      field={field}
      currentValue={value}
      handleChangeValue={handleChangeFieldValue}
      {...(hasDependencyFields ? { urlFields, bodyFields } : {})}
    />
  )
}

const renderImageType = (field, payload) => {
  const { value } = field
  const { handleChangeFieldValue } = payload

  return <Image handleChangeValue={handleChangeFieldValue} currentValue={value} />
}

const renderRichTextType = (field, payload) => {
  const { value } = field
  const { handleChangeFieldValue } = payload

  return <RichTextEditor value={value} handleChangeValue={handleChangeFieldValue} />
}

const renderToggleSwitchType = (field, payload) => {
  const { handleChangeFieldValue } = payload

  return <ToggleSwitch field={field} handleChangeFieldValue={handleChangeFieldValue} />
}

const renderDateType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Input id={field.key} className={fieldClassName} type='date' onChange={handleOnChange} placeholder={label} value={value} />
}

const renderHourType = (field, payload) => {
  const { label, value } = field
  const { handleChangeFieldValue } = payload

  const handleOnChange = e => {
    handleChangeFieldValue(e.target.value)
  }

  return <Input id={field.key} className={fieldClassName} type='time' onChange={handleOnChange} placeholder={label} value={value} />
}

const renderFileType = (field, payload) => {
  const { handleChangeFieldValue } = payload

  const { value } = field
  const files = Array.isArray(value) && value.map(itemValue => convertFile(itemValue))

  const handleOnChange = async files => {
    const convertedFiles = []
    for (const file of files) convertedFiles.push(await convertToBase64(file))

    handleChangeFieldValue(convertedFiles)
  }

  return (
    <FileInputList
      isMultiple={field.properties.isMultiple}
      accept={field.properties.onlyAccepts.join(', ')}
      id={field.key}
      handleOnChange={handleOnChange}
      onlySetFilesWhileMounting={true}
      {...(files.length > 0
        ? {
            files,
          }
        : {})}
    />
  )
}

const renderColorPickerType = (field, payload) => {
  const { value } = field
  const { handleChangeFieldValue } = payload

  const handleChangeValue = _value => {
    handleChangeFieldValue(_value)
  }

  return <ColorPickerInput value={value} handleChangeValue={handleChangeValue} />
}

const renderBeautifyType = (field, payload) => {
  const { value } = field
  const { handleChangeFieldValue } = payload

  const handleChangeValue = _value => {
    handleChangeFieldValue(_value)
  }

  return <JsonEditorWithError value={value} onChange={handleChangeValue} />
}

const getFieldValue = (fieldValues, key) => fieldValues.find(field => field.key === key)?.value

export const renderField = (field, payload) => {
  const { type, ...otherFieldParameters } = field
  const { handleChangeFieldValue: _handleChangeFieldValue, fieldValues, dependencyFields } = payload

  const fieldValue = getFieldValue(fieldValues, otherFieldParameters.key)
  const preparedFieldParameters = { ...otherFieldParameters, value: fieldValue }

  const handleChangeFieldValue = value => {
    _handleChangeFieldValue(otherFieldParameters.key, { value })
  }

  switch (type) {
    case FIELD_TYPES.STRING:
      return renderStringType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.NUMBER:
      return renderNumberType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.PASSWORD:
      return renderPasswordType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.TEXTAREA:
      return renderTextareaType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.SELECT:
      return renderSelectType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.REMOTE_SELECT:
      return renderRemoteSelectType(preparedFieldParameters, { handleChangeFieldValue, dependencyFields })
    case FIELD_TYPES.IMAGE:
      return renderImageType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.RICH_TEXT:
      return renderRichTextType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.TOGGLE_SWITCH:
      return renderToggleSwitchType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.DATE:
      return renderDateType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.HOUR:
      return renderHourType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.FILE:
      return renderFileType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.COLOR_PICKER:
      return renderColorPickerType(preparedFieldParameters, { handleChangeFieldValue })
    case FIELD_TYPES.BEAUTIFY_JSON:
      return renderBeautifyType(preparedFieldParameters, { handleChangeFieldValue })
  }
}
