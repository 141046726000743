import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Input from '@/components/Input/Input'
import Step from '@/containers/PostmanBuilder/components/Step/Step'
import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'
import { postmanBuilderReducerActions } from '@/store/reducers/postmanBuilder'

import './askQuestions.scss'

const AskQuestions = () => {
  const [questionAnswers, setQuestionAnswers] = useState({ baseUrl: '', baseUrlKey: '' })

  const { actions } = useSelector(state => state.postmanBuilder)
  const dispatch = useDispatch()

  const handleUpdateQuestionAnswers = item => setQuestionAnswers(prev => ({ ...prev, ...item }))

  const handleOnClickContinueButton = () => {
    const newActions = Object.entries(actions).reduce((acc, [folderName, items]) => {
      const newItems = items.map(item => {
        const { api, ...otherPayload } = item

        return {
          ...otherPayload,
          api: {
            ...api,
            url: api.url.replace(questionAnswers.baseUrl, '').replace(`:${questionAnswers.baseUrlKey}`, ''),
          },
        }
      })

      return {
        [folderName]: newItems,
        ...acc,
      }
    }, {})
    dispatch(postmanBuilderReducerActions.setActions(newActions))

    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.COMPLETE_PROCESS))
  }

  const handleOnClickBackButton = () => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.ADJUST_DEFAULT_VARIABLES))
  }

  const handleOnChangeInput = key => e => {
    handleUpdateQuestionAnswers({ [key]: e.target.value })
  }

  return (
    <Step
      handleOnClickContinueButton={handleOnClickContinueButton}
      handleOnClickBackButton={handleOnClickBackButton}
      title='Son bir adım daha...'>
      <div className='ask-questions'>
        <div className='ask-questions__list'>
          <div className='ask-questions__item'>
            <div className='ask-questions__label'>Temel URL Bilgileri</div>
            <div className='ask-questions__content ask-questions__base-url-content'>
              <div>
                <Input placeholder="Örn: https://exp.com/api" value={questionAnswers.baseUrl} onChange={handleOnChangeInput('baseUrl')} />
                <span>adında temel URL sahibiyim</span>.
              </div>
              <div>
                <Input placeholder="Örn: base_url" value={questionAnswers.baseUrlKey} onChange={handleOnChangeInput('baseUrlKey')} />{' '}
                <span>key isminde Postman içinde temel URL olarak kullanıyorum.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Step>
  )
}

export default AskQuestions
