import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { MdEdit } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'

import { getProjectTables } from '@/api'
import DynamicTable from '@/components/DynamicTable/DynamicTable'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PagePagination from '@/components/PagePagination/PagePagination'
import PageTitle from '@/components/PageTitle/PageTitle'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import usePermission from '@/hooks/usePermission'
import TablePopupForm, { TABLE_ACTIONS } from '@/pages/Tables/TablePopupForm/TablePopupForm'

import './tables.scss'

const INITIAL_PAGE = 1
const PAGINATION_RANGE_PAGE_SIZE = 8
const PAGE_QUERY_PARAMETER = 'page'

const Tables = () => {
  const [tables, setTables] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [isVisibleTableFormPopup, setIsVisibleTableFormPopup] = useState(false)
  const [editTableId, setEditTableId] = useState(null)

  const [searchParams, setSearchParams] = useSearchParams()
  const { checkPermission } = usePermission()

  const hasTables = tables.length > 0
  const slicedTables = tables.slice((currentPage - 1) * PAGINATION_RANGE_PAGE_SIZE, currentPage * PAGINATION_RANGE_PAGE_SIZE)
  const isAllowedTableActions = checkPermission(PROJECT_PERMISSIONS.ACTIONS_FOR_TABLES)

  const handleChangePagePagination = value => {
    setSearchParams({ [PAGE_QUERY_PARAMETER]: value })
    setCurrentPage(value)
  }

  const handleOnChangePagePagination = value => handleChangePagePagination(value)

  const handleClearPagePagination = () => handleChangePagePagination(INITIAL_PAGE)

  const handleClickCreateTableButton = () => setIsVisibleTableFormPopup(true)

  const handleCloseTablePopupForm = () => {
    setIsVisibleTableFormPopup(false)
    setEditTableId(null)
  }

  const handleAfterActionTable = payload => {
    const { type } = payload || {}

    if ([TABLE_ACTIONS.CREATE, TABLE_ACTIONS.DELETE].includes(type)) {
      handleClearPagePagination()
    }

    handleGetTables()
  }

  const handleGetTables = async () => {
    try {
      setIsLoading(true)

      const projectTablesRes = await getProjectTables()
      setTables(projectTablesRes.data.tables)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetInitialCurrentPage = () => {
    const initialCurrentPage = searchParams.get(PAGE_QUERY_PARAMETER)
    if (initialCurrentPage) setCurrentPage(parseInt(initialCurrentPage))
  }

  const handleClickTableEditButton = tableId => () => {
    setEditTableId(tableId)
    setIsVisibleTableFormPopup(true)
  }

  useEffect(() => {
    handleGetTables()
    handleSetInitialCurrentPage()
  }, [])

  const renderContent = () => {
    switch (true) {
      case isLoading:
        return (
          <div className='tables__list'>
            <Skeleton className='tables__loading-skelton' count={5} />
          </div>
        )
      case !hasTables:
        return (
          <div className='tables__list'>
            <div className='tables__no-table'>Hiç eklenmiş tablo yok</div>
          </div>
        )
      default:
        return (
          <>
            <div className='tables__list'>
              {slicedTables.map(table => (
                <div className='tables__item-wrapper' key={table.id}>
                  {isAllowedTableActions && (
                    <div onClick={handleClickTableEditButton(table.id)} className='tables__top-item-wrapper'>
                      <MdEdit />
                      <span>düzenle</span>
                    </div>
                  )}
                  <DynamicTable table={table} />
                </div>
              ))}
            </div>
            <PagePagination
              total={tables.length}
              rangePageSize={PAGINATION_RANGE_PAGE_SIZE}
              onChange={handleOnChangePagePagination}
              currentPage={currentPage}
            />
          </>
        )
    }
  }

  return (
    <div className='tables'>
      {isAllowedTableActions && (
        <TablePopupForm
          tableId={editTableId}
          isVisible={isVisibleTableFormPopup}
          handleClosePopup={handleCloseTablePopupForm}
          handleAfterActionTable={handleAfterActionTable}
        />
      )}
      <PageTitle>Tablolar</PageTitle>
      {isAllowedTableActions && (
        <div className='tables__top'>
          <OutlinedButton onClick={handleClickCreateTableButton} className='tables__create-table-btn'>
            Tablo Oluştur
          </OutlinedButton>
        </div>
      )}
      {renderContent()}
    </div>
  )
}

export default Tables
