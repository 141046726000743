import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allApi: [],
  selectedApiIndexes: [],
  parameters: {
    apiDefaultHeader: {},
    apiDefaultBody: {},
    apiDefaultUrlQueryParams: {},
    configs: {},
  },
  uploadedFile: '',
}

const importApiCollectionSlice = createSlice({
  name: 'importApiCollection',
  initialState,
  reducers: {
    setSelectedApiIndexes: (state, action) => {
      state.selectedApiIndexes = action.payload
    },
    setAllApi: (state, action) => {
      state.allApi = action.payload
    },
    setParameters: (state, action) => {
      state.parameters = { ...action.payload }
    },
    updateParameters: (state, action) => {
      state.parameters = { ...state.parameters, ...action.payload }
    },
    updateUploadedFile: (state, action) => {
      state.uploadedFile = action.payload
    },
    setInitialState: () => {
      return { ...initialState }
    },
  },
})

export const importApiCollectionReducerActions = importApiCollectionSlice.actions

export default importApiCollectionSlice.reducer
