import clsx from 'clsx'

import ColorPickerInput from '@/components/ColorPickerInput/ColorPickerInput'
import FileInputList from '@/components/FileInputList/FileInputList'
import Image from '@/components/FormFieldComponents/Image/Image'
import RichTextEditor from '@/components/FormFieldComponents/RichTextEditor/RichTextEditor'
import ToggleSwitch from '@/components/FormFieldComponents/ToggleSwitch/ToggleSwitch'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import JsonEditor from '@/components/JsonEditor/JsonEditor'
import Textarea from '@/components/Textarea/Textarea'
import { FIELD_TYPES } from '@/constants'

import './buildFormPageItem.scss'

const fieldClassName = 'build-api-field__field'

const BuildFormPageItem = props => {
  const { field } = props

  const fieldProperties = field?.properties
  const isInVisible = fieldProperties?.invisible
  const fieldLabel = field.label
  const fieldDefaultValue = field.defaultValue
  const fieldId = field.id

  const renderItemContent = () => {
    switch (field.type) {
      case FIELD_TYPES.STRING:
        return <Input id={fieldId} placeholder={fieldLabel} value={fieldDefaultValue} className={fieldClassName} />
      case FIELD_TYPES.NUMBER:
        return <Input type='number' id={fieldId} placeholder={fieldLabel} value={fieldDefaultValue} className={fieldClassName} />
      case FIELD_TYPES.PASSWORD:
        return <Input type='password' id={fieldId} placeholder={fieldLabel} value={fieldDefaultValue} className={fieldClassName} />
      case FIELD_TYPES.DATE:
        return <Input type='date' id={fieldId} placeholder={fieldLabel} value={fieldDefaultValue} className={fieldClassName} />
      case FIELD_TYPES.HOUR:
        return <Input type='time' id={fieldId} placeholder={fieldLabel} value={fieldDefaultValue} className={fieldClassName} />
      case FIELD_TYPES.TEXTAREA:
        return <Textarea id={fieldId} className={fieldClassName} value={fieldDefaultValue} placeholder={field.label} />
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.REMOTE_SELECT: {
        const choices = fieldProperties?.choices || []
        const selectedItemIndex = choices.findIndex(choice => choice?.value === field?.defaultValue)

        return (
          <InputDropdown
            items={choices}
            id={field.id}
            className={fieldClassName}
            placeholder={field.label}
            selectedItemIndex={selectedItemIndex}
          />
        )
      }
      case FIELD_TYPES.IMAGE:
        return <Image currentValue={fieldDefaultValue} />
      case FIELD_TYPES.RICH_TEXT:
        return <RichTextEditor value={fieldDefaultValue} />
      case FIELD_TYPES.TOGGLE_SWITCH:
        return <ToggleSwitch field={{ ...field, value: fieldDefaultValue }} />
      case FIELD_TYPES.FILE:
        return <FileInputList />
      case FIELD_TYPES.COLOR_PICKER:
        return <ColorPickerInput value={fieldDefaultValue} />
      case FIELD_TYPES.BEAUTIFY_JSON:
        return <JsonEditor value={JSON.stringify(fieldDefaultValue)} />
      default:
        return 'Unknown field type'
    }
  }

  return (
    <div className={clsx('build-api-field', { 'build-api-field--invisible': isInVisible })}>
      <div className={clsx('build-api-field__label', { 'build-api-field__label--no-label': !fieldLabel })}>
        {fieldLabel || 'Bir şey yaz...'}
      </div>
      <div className='build-api-field__content'>{renderItemContent()}</div>
    </div>
  )
}

export default BuildFormPageItem
