import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { postLogin } from '@/api'
import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'
import AuthenticationFlow from '@/components/AuthenticationFlow/AuthenticationFlow'
import Input, { INPUT_VARIANTS } from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { ROUTE_URLS } from '@/constants/routeUrls'
import { isValidEmail } from '@/utils'
import { setUserTokenStorage } from '@/utils/localStorageActions'

import './login.scss'

const Login = () => {
  const [userParams, setUserParams] = useState({ email: '', password: '' })
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()

  const isValidateUserParams = Object.values(userParams).every(value => value !== '') && isValidEmail(userParams.email)

  const handleLogin = async () => {
    try {
      setIsLoading(true)

      const res = await postLogin(userParams)
      setUserTokenStorage(res.data.token)

      navigate('/')
    } catch (err) {
      const errorRes = err.response
      if (errorRes.status === 422 || errorRes.status === 401) {
        toast.error('E-mail ya da şifre yanlış')
        return
      }

      toast.error('Bir hata oluştu, bu konun üzerinde çalışıyoruz.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickLoginButton = async () => {
    await handleLogin()
  }

  const handleOnChange = key => e => {
    setUserParams({ ...userParams, [key]: e.target.value })
  }

  const handleOnKeyDown = e => {
    if (e.key !== 'Enter' || !isValidateUserParams) return

    handleLogin()
  }

  return (
    <AuthenticationFlow title='Şimdi Giriş Yap!'>
      <Input
        variant={INPUT_VARIANTS.BOLD}
        name='login_email'
        value={userParams.email}
        onChange={handleOnChange('email')}
        onKeyDown={handleOnKeyDown}
        className='login__input'
        placeholder='E-mail'
      />
      <Input
        variant={INPUT_VARIANTS.BOLD}
        name='login_password'
        value={userParams.password}
        onChange={handleOnChange('password')}
        onKeyDown={handleOnKeyDown}
        type='password'
        className='login__input'
        placeholder='Şifre'
      />
      <Link to={ROUTE_URLS.FORGET_PASSWORD} className='login__text login__text--forget-password'>
        Şifremi unuttum
      </Link>
      <PrimaryButton onClick={handleClickLoginButton} className='login__button' isLoading={isLoading} disable={!isValidateUserParams}>
        <img className='login__circle-logo' src={CircleBlackLogo} />
        <span>Giriş Yap</span>
      </PrimaryButton>
      <Link to={ROUTE_URLS.REGISTER} className='login__text login__text--register'>
        Hesabın mı yok? Kayıt ol
      </Link>
    </AuthenticationFlow>
  )
}

export default Login
