import { Outlet } from 'react-router-dom'

import Header from '@/containers/Root/Header/Header'
import LeftMenu from '@/containers/Root/LeftMenu/LeftMenu'
import withRootLogic from '@/containers/Root/withRootLogic'

import './root.scss'

const Content = () => {
  return (
    <div className='root'>
      <div className='root__left-menu'>
        <LeftMenu />
      </div>
      <div className='root__content'>
        <div className='root__header'>
          <Header />
        </div>
        <div className='root__outlet'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const Root = ({ getContent }) => {
  return getContent(<Content />)
}

export default withRootLogic(Root)
