import JsonEditor from '@/components/JsonEditor/JsonEditor'
import Popup from '@/components/Popup/Popup'
import { CHART_TYPES } from '@/constants'

import './chartTypeInfoPopup.scss'

const getExampleChartJson = chartType => {
  switch (chartType) {
    case CHART_TYPES.SIMPLE_VALUE:
      return {
        value: 'example_value',
      }
    case CHART_TYPES.BAR:
    case CHART_TYPES.AREA:
      return {
        xaxisCategories: [1991, 1992, 1993],
        series: [
          {
            name: 'example-series-1',
            data: [30, 40, 45],
          },
        ],
      }
    case CHART_TYPES.DONUT:
      return {
        series: [1, 3, 5],
        labels: ['t1', 't2', 't3'],
      }
  }
}

const ChartTypeInfoPopup = ({ isVisible, handleClosePopup, value }) => {
  const stringChartValue = JSON.stringify(getExampleChartJson(value))

  return (
    <Popup title='Örnek Çıktı' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='chart-type-info__detail'>
        Bu grafik tipini kullanmak için, belirlediğin url&#39;den aşağıdakine benzer bir response dönmesi gerekir.
      </div>
      <JsonEditor key={stringChartValue} value={stringChartValue} readOnly={true} />
    </Popup>
  )
}

export default ChartTypeInfoPopup
