import { useDispatch, useSelector } from 'react-redux'

import BuildApiRightPanelFileAdvancedOnlyAcceptSetting from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldAdvancedSettings/BuildApiRightPanelFileAdvancedSettings/BuildApiRightPanelFileAdvancedOnlyAcceptSetting/BuildApiRightPanelFileAdvancedOnlyAcceptSetting'
import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import ToggleSwitch from '@/containers/BuildApi/components/Settings/ToggleSwitch/ToggleSwitch'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'

import './buildApiRightPanelFileAdvancedSettings.scss'

const BuildApiRightPanelFileAdvancedSettings = () => {
  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const fieldProperties = selectedField?.properties || {}

  const dispatch = useDispatch()

  const handleChangeProperties = properties => dispatch(buildApiReducerActions.setApiFieldProperties(properties))

  const handleOnChangeMultipleStatus = e => {
    handleChangeProperties({
      isMultiple: e.target.checked,
    })
  }

  return (
    <>
      <BuildApiSettingWrapper title='Çoklu Dosya Yükleme'>
        <ToggleSwitch checked={fieldProperties.isMultiple} handleChangeValue={handleOnChangeMultipleStatus} />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Desteklenen Dosya Tipleri'>
        <BuildApiRightPanelFileAdvancedOnlyAcceptSetting
          value={fieldProperties.onlyAccepts || []}
          handleChangeProperties={handleChangeProperties}
        />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelFileAdvancedSettings
