import _ from 'lodash'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import { getValues } from '@/api'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import DynamicForm from '@/pages/ApiExecute/components/DynamicForm/DynamicForm'
import { handleChangeFieldValue, mapUpdateFieldValue } from '@/utils/dynamicFields'
import { checkIsFieldVisible } from '@/utils/dynamicForms/utils'

import './apiInitialValuesDynamicForm.scss'

const getAllKeysFromFields = fields =>
  fields.reduce((acc, { key }) => {
    return [...acc, key]
  }, [])

const ApiInitialValuesDynamicForm = ({
  initialValuesForm = {},
  setFieldValues: setDynamicParameterFieldValues,
  fieldValues: dynamicParameterFieldValues,
  setIsEnableInitialForm,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [fieldValues, setFieldValues] = useState([])
  const [fields, setFields] = useState([])
  const [mounted, setMounted] = useState(false)

  const { id } = useParams()

  const handleSetInitialValues = responseData => {
    const { dataKey, initialValuesMappings } = initialValuesForm
    const scopeData = dataKey ? _.get(responseData, dataKey) : responseData

    const updatedDynamicParametersFieldValues = initialValuesMappings.reduce((dynamicParameterFieldValues, { key, remoteKey }) => {
      const value = _.get(scopeData, remoteKey)
      const updatedDynamicParameterFieldValues = dynamicParameterFieldValues.map(mapUpdateFieldValue(key, { value }))

      return updatedDynamicParameterFieldValues
    }, dynamicParameterFieldValues)

    setDynamicParameterFieldValues([...updatedDynamicParametersFieldValues])
  }

  const handleProcessForm = async () => {
    try {
      setIsSubmitting(true)

      const { isForeign = false, url, method, header, bodyFields, urlFields } = initialValuesForm

      const bodyFieldKeys = getAllKeysFromFields(bodyFields)
      const bodyFieldValues = fieldValues.filter(({ key }) => bodyFieldKeys.includes(key))

      const urlFieldKeys = getAllKeysFromFields(urlFields)
      const urlFieldValues = fieldValues.filter(({ key }) => urlFieldKeys.includes(key))

      const responseData = (
        await getValues({
          projectApiId: id,
          urlFields: urlFieldValues,
          bodyFields: bodyFieldValues,
          isForeign,
          url,
          method,
          header,
        })
      ).data.response.data

      handleSetInitialValues(responseData)
      setIsEnableInitialForm(false)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClickSendButton = async () => {
    handleProcessForm()
  }

  const handleAutoContinueProcess = () => {
    const filteredFields = fields.filter(field => checkIsFieldVisible(field))
    if (filteredFields.length == 0) {
      handleProcessForm()
    }
  }

  const handleFillFieldStates = apiDetailData => {
    const { bodyFields, urlFields } = apiDetailData

    const allFields = [...bodyFields, ...urlFields]
    setFields(allFields)

    const allFieldValues = allFields.map(({ key, defaultValue }) => ({ value: defaultValue ?? '', key }))
    setFieldValues([...allFieldValues])

    setMounted(true)
  }

  useEffect(() => {
    handleFillFieldStates({ bodyFields: initialValuesForm.bodyFields, urlFields: initialValuesForm.urlFields })
  }, [])

  useEffect(() => {
    if (mounted) {
      handleAutoContinueProcess()
    }
  }, [mounted])

  return (
    <div className='api-initial-values-form'>
      {fields.length > 0 && (
        <div className='api-initial-values-form__dynamic-form-wrapper'>
          <DynamicForm
            fieldValues={fieldValues}
            fields={fields}
            handleChangeFieldValue={handleChangeFieldValue(fieldValues, setFieldValues)}
          />
        </div>
      )}
      <OutlinedButton onClick={handleClickSendButton} isLoading={isSubmitting} className='api-initial-values-form__send-button'>
        Devam Et
      </OutlinedButton>
    </div>
  )
}

export default ApiInitialValuesDynamicForm
