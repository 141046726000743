import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import { updateProject } from '@/api'
import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'
import InputWithLabel from '@/components/InputWithLabel/InputWithLabel'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import TextareaWithLabel from '@/components/TextareaWithLabel/TextareaWithLabel'
import { appReducerActions } from '@/store/reducers/app'

import './projectGeneralSettings.scss'

const ProjectGeneralSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [projectDetail, setProjectDetail] = useState({})
  const [projectDetailErrors, setProjectDetailErrors] = useState({
    name: false,
  })
  const [image, setImage] = useState(null)

  const dispatch = useDispatch()

  const projectSettings = useSelector(state => state.app.projectSettings)

  const handleOnChangeDetail = item => setProjectDetail(prev => ({ ...prev, ...item }))
  const handleUpdateProjectDetailError = item => setProjectDetailErrors(prev => ({ ...prev, ...item }))

  const handleChangeInput = key => e => handleOnChangeDetail({ [key]: e.target.value })

  const handleChangeInputWithErrorCheck = key => e => {
    const value = e.target.value

    handleUpdateProjectDetailError({ [key]: !value })
    handleChangeInput(key)(e)
  }

  const handleChangeImage = e => {
    const image = e.target.files[0]

    setImage(URL.createObjectURL(image))
    handleOnChangeDetail({ image })
  }

  const clearInputImage = () => {
    const clonedProjectDetail = { ...projectDetail }

    delete clonedProjectDetail.image

    setProjectDetail({ ...clonedProjectDetail })
  }

  const validateProjectDetail = () => {
    const hasErrors = Object.values(projectDetailErrors).some(Boolean)

    return !hasErrors
  }

  const getAllProjectDetailAsFormData = () => {
    const formData = new FormData()

    Object.keys(projectDetail).forEach(detailKey => {
      formData.append(detailKey, projectDetail[detailKey])
    })

    return formData
  }

  const handleOnClick = async () => {
    try {
      setIsSubmitting(true)

      const projectDetailFormData = getAllProjectDetailAsFormData()
      const updatedProjectRes = await updateProject(projectDetailFormData)

      dispatch(appReducerActions.updateProjectSettings(updatedProjectRes.data.updatedProject))

      toast.success('Bilgiler güncellendi')
      clearInputImage()
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSetProjectDetail = () => {
    const { name, description, imagePath } = projectSettings

    setProjectDetail({ name, description })
    setImage(imagePath)
  }

  useEffect(() => {
    handleSetProjectDetail()
  }, [])

  return (
    <div className='project-general-settings'>
      <div className='project-general-settings__item project-general-settings__item--image'>
        <img src={image || CircleBlackLogo} className='project-general-settings__image' />
        <input
          className='project-general-settings__image-input'
          id='project_image'
          type='file'
          onChange={handleChangeImage}
          accept='image/*'
        />
        <label htmlFor='project_image' className='project-general-settings__change-image-button'>
          Değiştir
        </label>
      </div>
      <div className='project-general-settings__item'>
        <InputWithLabel
          inputProps={{
            className: 'project-general-settings__input',
            value: projectDetail.name,
            onChange: handleChangeInputWithErrorCheck('name'),
          }}
          label='Proje Adı'
        />
        {projectDetailErrors.name && <div className='project-general-settings__item-error'>Boş olamaz</div>}
      </div>
      <div className='project-general-settings__item'>
        <TextareaWithLabel
          inputProps={{
            className: 'project-general-settings__input',
            value: projectDetail.description,
            onChange: handleChangeInput('description'),
          }}
          label='Açıklama'
        />
      </div>
      <PrimaryButton
        disable={!validateProjectDetail()}
        isLoading={isSubmitting}
        className='project-general-settings__save-button'
        onClick={handleOnClick}>
        Kaydet
      </PrimaryButton>
    </div>
  )
}

export default ProjectGeneralSettings
