import { Draggable, Droppable } from '@hello-pangea/dnd'
import clsx from 'clsx'
import { BsCalendarDate } from 'react-icons/bs'
import { FaRegImage } from 'react-icons/fa'
import { FaToggleOff } from 'react-icons/fa6'
import { IoList } from 'react-icons/io5'
import { MdOutlineFileCopy } from 'react-icons/md'
import { PiTextboxBold } from 'react-icons/pi'
import { TbPassword } from 'react-icons/tb'
import { TbEdit } from 'react-icons/tb'
import { TbNumbers } from 'react-icons/tb'
import { TbClockHour2 } from 'react-icons/tb'
import { VscSymbolColor } from 'react-icons/vsc'
import { VscJson } from 'react-icons/vsc'
import { useSelector } from 'react-redux'

import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'
import { FIELD_TYPES, FIELD_TYPE_VALUES } from '@/constants'
import BuildApiPanel, { PANEL_TYPES } from '@/containers/BuildApi/components/BuildApiPanel/BuildApiPanel'
import { LEFT_MENU_DROPPABLE_ID } from '@/containers/BuildApi/constants/index'
import { getFieldText } from '@/utils/buildApi'

import './buildApiLeftPanel.scss'

const renderItemIcon = fieldType => {
  switch (fieldType) {
    case FIELD_TYPES.STRING:
    case FIELD_TYPES.TEXTAREA:
      return <PiTextboxBold className='build-api-left__item-icon' />
    case FIELD_TYPES.NUMBER:
      return <TbNumbers className='build-api-left__item-icon' />
    case FIELD_TYPES.PASSWORD:
      return <TbPassword className='build-api-left__item-icon' />
    case FIELD_TYPES.SELECT:
    case FIELD_TYPES.REMOTE_SELECT:
      return <IoList className='build-api-left__item-icon' />
    case FIELD_TYPES.IMAGE:
      return <FaRegImage className='build-api-left__item-icon' />
    case FIELD_TYPES.RICH_TEXT:
      return <TbEdit className='build-api-left__item-icon' />
    case FIELD_TYPES.TOGGLE_SWITCH:
      return <FaToggleOff className='build-api-left__item-icon' />
    case FIELD_TYPES.DATE:
      return <BsCalendarDate className='build-api-left__item-icon' />
    case FIELD_TYPES.HOUR:
      return <TbClockHour2 className='build-api-left__item-icon' />
    case FIELD_TYPES.FILE:
      return <MdOutlineFileCopy className='build-api-left__item-icon' />
    case FIELD_TYPES.COLOR_PICKER:
      return <VscSymbolColor className='build-api-left__item-icon' />
    case FIELD_TYPES.BEAUTIFY_JSON:
      return <VscJson />
    default:
      return <img src={CircleBlackLogo} className='build-api-left__item-default-icon' />
  }
}

const BuildApiLeftPanel = props => {
  const { handleAddItemOnList } = props

  const fieldsLength = useSelector(state => state.buildApi.api.fields).length

  const renderItem = fieldType => {
    return (
      <>
        <div className='build-api-left__item-icon-wrapper'>{renderItemIcon(fieldType)}</div>
        <div className='build-api-left__item-text'>{getFieldText(fieldType)}</div>
      </>
    )
  }

  const handleOnClickFieldItem = index => () => {
    handleAddItemOnList({ index }, { index: fieldsLength })
  }

  return (
    <BuildApiPanel type={PANEL_TYPES.LEFT} title='Form Elemanları'>
      <Droppable droppableId={LEFT_MENU_DROPPABLE_ID} isDropDisabled={true}>
        {provided => (
          <div className='build-api-left__list'>
            <div ref={provided.innerRef}>
              {FIELD_TYPE_VALUES.map((fieldType, index) => (
                <Draggable key={fieldType} draggableId={fieldType} index={index}>
                  {(provided, snapshot) => (
                    <>
                      <div
                        className={clsx('build-api-left__item', {
                          'build-api-left__item--is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={provided.draggableProps.style}
                        onClick={handleOnClickFieldItem(index)}>
                        {renderItem(fieldType)}
                      </div>
                      {snapshot.isDragging && (
                        <div className='build-api-left__item build-api-left__item--clone'>{renderItem(fieldType)}</div>
                      )}
                    </>
                  )}
                </Draggable>
              ))}
            </div>
          </div>
        )}
      </Droppable>
    </BuildApiPanel>
  )
}

export default BuildApiLeftPanel
