import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { deleteProjectApi } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import Popup from '@/components/Popup/Popup'
import { ROUTE_URLS } from '@/constants/routeUrls'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildApiSettingsPopup.scss'

const BuildApiSettingsPopup = () => {
  const [isVisibleDeleteApiContinueAskPopup, setIsVisibleDeleteApiContinueAskPopup] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isEnableSettingsPopup = useSelector(state => state.buildApi.isEnableSettingsPopup)
  const apiId = searchParams.get('id')

  const handleClosePopup = () => {
    dispatch(buildApiReducerActions.setIsEnableSettingsPopup(false))
  }

  const handleCloseContinueAskPopup = () => {
    setIsVisibleDeleteApiContinueAskPopup(false)
  }

  const handleOnClickDeleteButton = () => {
    setIsVisibleDeleteApiContinueAskPopup(true)
  }

  const handleDeleteApi = async () => {
    try {
      handleCloseContinueAskPopup()

      await deleteProjectApi(apiId)

      toast.success('Aksiyon başarıyla silindi')
      navigate(ROUTE_URLS.HOME)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Popup title='Ayarlar' isVisible={isEnableSettingsPopup} handleClosePopup={handleClosePopup}>
      <ContinueAskPopup
        title='Silmek istediğine emin misin?'
        isVisible={isVisibleDeleteApiContinueAskPopup}
        handleClosePopup={handleCloseContinueAskPopup}
        description='Bu aksiyon silindikten sonra geri alınamaz.'
        noButtonProps={{ onClick: handleCloseContinueAskPopup }}
        yestButtonProps={{ onClick: handleDeleteApi }}
      />
      <div className='build-api-settings'>
        <OutlinedButton isLoading={isDeleting} onClick={handleOnClickDeleteButton} className='build-api-settings__delete-button'>
          Aksiyonu Sil
        </OutlinedButton>
      </div>
    </Popup>
  )
}

export default BuildApiSettingsPopup
