import clsx from 'clsx'
import { useRef } from 'react'

import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import useClickOutside from '@/hooks/useClickOutside'
import { renderField } from '@/utils/dynamicForms'
import { handleChangeFieldValue } from '@/utils/dynamicFields'

import './dynamicChartFiltersDropdown.scss'

const DynamicChartFiltersDropdown = ({
  isEnabled,
  fieldValues,
  setFieldValues,
  allFields,
  handleOnClickApply,
  handleDisableFilterDropdown,
}) => {
  const dropdownRef = useRef(null)

  useClickOutside(dropdownRef, handleDisableFilterDropdown)

  return (
    <div ref={dropdownRef} className={clsx('dynamic-chart-filter-dropdown', { 'dynamic-chart-filter-dropdown--enabled': isEnabled })}>
      <div className='dynamic-chart-filter-dropdown__list'>
        {allFields.map((item, key) => (
          <div className='dynamic-chart-filter-dropdown__field-wrapper' key={key}>
            {renderField(item, { handleChangeFieldValue: handleChangeFieldValue(fieldValues, setFieldValues), fieldValues })}
          </div>
        ))}
      </div>
      <PrimaryButton onClick={handleOnClickApply} className='dynamic-chart-filter-dropdown__apply-button'>
        Uygula
      </PrimaryButton>
    </div>
  )
}

export default DynamicChartFiltersDropdown
