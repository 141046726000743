import { DragDropContext } from '@hello-pangea/dnd'
import { useDispatch, useSelector } from 'react-redux'

import { FIELD_TYPE_VALUES } from '@/constants'
import BuildApiLeftPanel from '@/containers/BuildApi/BuildApiContent/BuildApiLeftPanel/BuildApiLeftPanel'
import BuildApiRightPanel from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanel'
import BuildFormPage from '@/containers/BuildApi/BuildApiContent/BuildFormPage/BuildFormPage'
import { LEFT_MENU_DROPPABLE_ID } from '@/containers/BuildApi/constants'
import { buildApiReducerActions } from '@/store/reducers/buildApi'
import { getFieldObject } from '@/utils/buildApi'

import './buildApiContent.scss'

const BuildApiContent = () => {
  const dispatch = useDispatch()

  const api = useSelector(state => state.buildApi.api)
  const apiFields = api.fields

  const handleGetReOrderedList = (startIndex, endIndex) => {
    const result = [...apiFields]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleGetAddedItemList = (droppableSource, droppableDestination) => {
    const sourceClone = [...FIELD_TYPE_VALUES]
    const destClone = [...apiFields]
    const itemType = sourceClone[droppableSource.index]

    const item = getFieldObject({
      type: itemType,
    })

    destClone.splice(droppableDestination.index, 0, item)
    return destClone
  }

  const handleAddItemOnList = (source, destination) => {
    dispatch(buildApiReducerActions.setApiFields(handleGetAddedItemList(source, destination)))
  }

  const handleOnDragEnd = result => {
    const { source, destination } = result

    // dropped outside the list
    if (!destination) {
      return
    }

    switch (source.droppableId) {
      case destination.droppableId:
        dispatch(buildApiReducerActions.setApiFields(handleGetReOrderedList(source.index, destination.index)))
        break
      case LEFT_MENU_DROPPABLE_ID:
        handleAddItemOnList(source, destination)
        break
    }
  }

  return (
    <div className='build-api-content'>
      <BuildApiRightPanel />
      <div className='build-api-content__page-wrapper'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <BuildApiLeftPanel handleAddItemOnList={handleAddItemOnList} />
          <BuildFormPage />
        </DragDropContext>
      </div>
    </div>
  )
}

export default BuildApiContent
