import _ from 'lodash'

import AreaChart from '@/components/Charts/AreaChart/AreaChart'
import BarChart from '@/components/Charts/BarChart/BarChart'
import DonutChart from '@/components/Charts/DonutChart/DonutChart'
import SimpleValueChart from '@/components/Charts/SimpleValueChart/SimpleValueChart'
import { CHART_TYPES } from '@/constants'

const getScopedData = (data, scopeKey) => (scopeKey ? _.get(data, scopeKey) : data)

const renderSimpleValue = ({ scopedData }) => {
  const { value } = scopedData

  return <SimpleValueChart value={value} />
}

const renderBarChart = ({ chart, scopedData }) => {
  const { properties } = chart
  const { xaxisCategories, series } = scopedData

  const options = {
    xaxis: {
      categories: xaxisCategories,
    },
    ...(properties?.isHorizontal === true
      ? {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        }
      : {}),
  }

  return <BarChart options={options} series={series} />
}

const renderAreaChart = ({ chart, scopedData }) => {
  const { properties } = chart
  const { series, xaxisCategories } = scopedData

  const options = {
    xaxis: {
      categories: xaxisCategories,
    },
    ...(properties?.isHorizontal === true
      ? {
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
        }
      : {}),
  }

  return <AreaChart series={series} options={options} />
}

const renderDonutChart = ({ scopedData }) => {
  const { series, labels } = scopedData

  return <DonutChart series={series} labels={labels} />
}

export const renderChart = ({ chart, data }) => {
  const { type, dataKey } = chart

  const scopedData = getScopedData(data, dataKey)

  switch (type) {
    case CHART_TYPES.SIMPLE_VALUE:
      return renderSimpleValue({ chart, scopedData })
    case CHART_TYPES.BAR:
      return renderBarChart({ chart, scopedData })
    case CHART_TYPES.AREA:
      return renderAreaChart({ chart, scopedData })
    case CHART_TYPES.DONUT:
      return renderDonutChart({ chart, scopedData })
  }
}
