import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { deleteProjectUser } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'

import './deleteUserPopup.scss'

const DeleteUserPopup = ({ isVisible, handleClosePopup, deleteProjectUserId, afterHandleDeleteProjectUser, isDeletingYourself }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const navigate = useNavigate()

  const handleClickNoButton = () => {
    handleClosePopup()
  }

  const handleClickYesButton = async () => {
    try {
      setIsDeleting(true)

      await deleteProjectUser(deleteProjectUserId)

      !isDeletingYourself && afterHandleDeleteProjectUser()

      toast.success(isDeletingYourself ? 'Projeden ayrıldın' : 'Kullanıcı silindi')
      isDeletingYourself
        ? setTimeout(() => {
            navigate('/')
            window.location.reload()
          }, 700)
        : handleClosePopup()
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <ContinueAskPopup
      title={isDeletingYourself ? 'Projeden ayrılmak istediğine emin misin?' : 'Silmek istediğine emin misin?'}
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}
      noButtonProps={{ onClick: handleClickNoButton }}
      yestButtonProps={{ onClick: handleClickYesButton, isLoading: isDeleting }}
      {...(isDeletingYourself && { description: 'Kendini projeden çıkarmak üzeresin.', yesButtonText: 'Evet, ayrıl' })}
    />
  )
}

export default DeleteUserPopup
