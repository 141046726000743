import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

import router from '@/router'

function App() {
  useEffect(() => {
    dayjs.extend(customParseFormat)
    dayjs.locale('tr') // We should extend with other languages
  }, [])

  return <RouterProvider router={router} />
}

export default App
