import clsx from 'clsx'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { IoIosCloseCircleOutline } from 'react-icons/io'

import useClickOutside from '@/hooks/useClickOutside'
import { filterText as filterTextUtil } from '@/utils'

import './inputMultipleSelect.scss'

const InputMultipleSelect = ({
  items,
  placeholder,
  handleOnSelect,
  handleOnDelete,
  renderItem,
  renderSelectedItem: _renderSelectedItem,
  value,
  className,
}) => {
  const [isEnabledDropdown, setIsEnabledDropdown] = useState(false)
  const [selectedItemValues, setSelectedItemValues] = useState([])
  const [filterText, setFilterText] = useState('')

  const inputMultipleSelectRef = useRef(null)

  useClickOutside(inputMultipleSelectRef, () => setIsEnabledDropdown(false))

  const hasSelectedItems = selectedItemValues.length > 0
  const filteredItems = items.filter(item => filterTextUtil(filterText, item.label))

  const handleAddSelectedItemValue = value => {
    setSelectedItemValues(prev => [...prev, value])
    handleOnSelect && handleOnSelect(value)
  }
  const handleDeleteSelectedItemValue = value => {
    setSelectedItemValues([...selectedItemValues.filter(selectedItemValue => selectedItemValue !== value)])
    handleOnDelete && handleOnDelete(value)
  }
  const handleOnChangeFilterText = e => setFilterText(e.target.value)
  const handleClearFilterText = () => setFilterText('')

  const checkIsSelectedItem = value => selectedItemValues.includes(value)

  const handleOnClickItem = value => () => {
    if (checkIsSelectedItem(value)) {
      handleDeleteSelectedItemValue(value)
      return
    }

    handleAddSelectedItemValue(value)
    handleClearFilterText()
  }

  const handleOnClickDeleteItemButton = value => () => {
    handleDeleteSelectedItemValue(value)
  }

  const handleOnClickMultipleSelect = () => {
    setIsEnabledDropdown(true)
  }

  const renderSelectedItem = value => {
    const item = items.find(item => item.value === value)

    return (
      <div key={value} className='input-multiple-select__selected-item'>
        <IoIosCloseCircleOutline
          onClick={handleOnClickDeleteItemButton(value)}
          className='input-multiple-select__selected-item-delete-icon'
        />
        <span>{_renderSelectedItem ? _renderSelectedItem(item.label, item.value) : item.label}</span>
      </div>
    )
  }

  useEffect(() => {
    if (!_.isEqual(value, selectedItemValues)) {
      setSelectedItemValues(value)
    }
  }, [value])

  return (
    <div
      ref={inputMultipleSelectRef}
      className={clsx('input-multiple-select', className, { 'input-multiple-select--enabled-dropdown': isEnabledDropdown })}
      onClick={handleOnClickMultipleSelect}>
      <div className='input-multiple-select__selection'>
        {selectedItemValues.map(renderSelectedItem)}
        <input
          onChange={handleOnChangeFilterText}
          value={filterText}
          placeholder={hasSelectedItems ? 'Ara..' : placeholder}
          className={clsx('input-multiple-select__search-input', {
            'input-multiple-select__search-input--selected-items': hasSelectedItems,
          })}
        />
      </div>
      <motion.div
        initial={false}
        animate={isEnabledDropdown ? 'open' : 'closed'}
        transition={{ ease: 'linear', duration: 0.08 }}
        variants={{
          open: { opacity: 1, display: 'flex' },
          closed: { opacity: 0, transitionEnd: { display: 'none' } },
        }}
        className={'input-multiple-select__dropdown'}>
        {filteredItems.length === 0 ? (
          <div className='input-multiple-select__no-items'>Hiç veri yok</div>
        ) : (
          filteredItems.map(({ value, label }) => (
            <div
              key={value}
              onClick={handleOnClickItem(value)}
              className={clsx('input-multiple-select__item', checkIsSelectedItem(value) && 'input-multiple-select__item--selected')}>
              <div className='input-multiple-select__selected-text'>{renderItem ? renderItem(label, value) : label}</div>
              <FaCheck className='input-multiple-select__selected-icon' />
            </div>
          ))
        )}
      </motion.div>
    </div>
  )
}

export default InputMultipleSelect
