import clsx from 'clsx'
import { Children, cloneElement } from 'react'

import './tabs.scss'

export const TAB_SIZES = {
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
}

const Tabs = ({ children, onChange, value, size = TAB_SIZES.LG }) => {
  return (
    <div className={clsx('tabs-component', `tabs-component--${size}`)}>
      {Children.map(children, child => {
        return cloneElement(child, { onChange, currentValue: value, size })
      })}
    </div>
  )
}

Tabs.Item = ({ children, value, onChange, currentValue, className, size }) => {
  const isSelectedTab = currentValue === value

  const handleOnClick = () => {
    onChange(value)
  }

  const allTabItemClassNames = clsx('tabs-component-item', `tabs-component-item--${size}`, className, {
    'tabs-component-item--selected': isSelectedTab,
  })

  return (
    <div onClick={handleOnClick} className={allTabItemClassNames}>
      {children}
    </div>
  )
}
Tabs.Item.displayName = 'TabItem'

export default Tabs
