import { useEffect, useReducer, useState } from 'react'
import toast from 'react-hot-toast'
import { MdArrowBackIos } from 'react-icons/md'
import { TbDeviceImacSearch } from 'react-icons/tb'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'

import { getApiDetail } from '@/api'
import PageTitle from '@/components/PageTitle/PageTitle'
import ApiDynamicParametersForm from '@/pages/ApiExecute/ApiDynamicParametersForm/ApiDynamicParametersForm'
import ApiExecuteHeader from '@/pages/ApiExecute/ApiExecuteHeader/ApiExecuteHeader'
import ApiExecuteLogs from '@/pages/ApiExecute/ApiExecuteLogs/ApiExecuteLogs'
import ApiInitialValuesDynamicForm from '@/pages/ApiExecute/ApiInitialValuesDynamicForm/ApiInitialValuesDynamicForm'
import { isMobile } from '@/utils'
import { getAllFields, getFieldValuesFromFields, handleChangeFieldValue } from '@/utils/dynamicFields'

import './apiExecute.scss'

const ApiExecute = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [fields, setFields] = useState([])
  const [fieldValues, setFieldValues] = useState([])
  const [apiDetail, setApiDetail] = useState({})
  const [apiLogs, setApiLogs] = useState([])
  const [freshApiLogId, setFreshApiLogId] = useState(null)
  const [isEnableInitialForm, setIsEnableInitialForm] = useState(false)
  const [isEnableContentRight, setIsEnableContentRight] = useState(!isMobile())
  const [dynamicParameterFormKey, updateDynamicParameterFormKey] = useReducer(acc => acc + 1, 0) // we use it to mount again

  const { id } = useParams()
  const otherFields = apiDetail?.otherFields || {}

  const initialValuesForm = otherFields.initialValuesForm

  const handleOnClickMobileLogButton = () => {
    setIsEnableContentRight(true)
  }

  const handleAdjustInitialFormEnableStatus = (_apiDetail = apiDetail) => {
    setIsEnableInitialForm(!!_apiDetail.otherFields.initialValuesForm)
  }

  const handleAfterExecuteApi = () => {
    handleSetInitialFieldValues()
    updateDynamicParameterFormKey()
    handleAdjustInitialFormEnableStatus()
  }

  const handleOnClickMobileBackButton = () => {
    setIsEnableContentRight(false)
  }

  const handleAddApiLog = apiLog => {
    setApiLogs(prev => [{ ...apiLog }, ...prev])
    setFreshApiLogId(apiLog.id)
  }

  const handleSetInitialFieldValues = _allFields => {
    const allFields = _allFields || getAllFields(apiDetail)

    const allFieldValues = getFieldValuesFromFields(allFields)
    setFieldValues([...allFieldValues])
  }

  const handleFillFieldStates = apiDetailData => {
    const allFields = getAllFields(apiDetailData)

    setFields(allFields)
    handleSetInitialFieldValues(allFields)

    handleAdjustInitialFormEnableStatus(apiDetailData)
  }

  const handleGetApiDetail = async () => {
    try {
      setIsLoading(true)

      const apiDetailRes = await getApiDetail(id)
      const apiDetailData = apiDetailRes.data.apiDetail
      setApiDetail(apiDetailData)

      handleFillFieldStates(apiDetailData)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetApiDetail()
  }, [id])

  return (
    <div className='api-execute'>
      {isLoading ? (
        <Skeleton className='api-execute__loading-skelton-item' count={2} />
      ) : (
        <>
          <PageTitle>
            <div className='api-execute__title'>
              {apiDetail.title}
              <div className='api-execute__slash'>/</div>
              <div className='api-execute__api-title'>{apiDetail.group.name}</div>
            </div>
          </PageTitle>
          <div className='api-execute__content'>
            <div className='api-execute__content-left'>
              <ApiExecuteHeader
                handleAfterExecuteApi={handleAfterExecuteApi}
                apiDetail={apiDetail}
                fieldValues={fieldValues}
                handleSetInitialFieldValues={handleSetInitialFieldValues}
                handleAddApiLog={handleAddApiLog}
              />
              <div className='api-execute__forms-wrapper'>
                {isEnableInitialForm ? (
                  <ApiInitialValuesDynamicForm
                    initialValuesForm={initialValuesForm}
                    setFieldValues={setFieldValues}
                    fieldValues={fieldValues}
                    setIsEnableInitialForm={setIsEnableInitialForm}
                  />
                ) : (
                  <ApiDynamicParametersForm
                    key={dynamicParameterFormKey}
                    fieldValues={fieldValues}
                    fields={fields}
                    handleChangeFieldValue={handleChangeFieldValue(fieldValues, setFieldValues)}
                  />
                )}
              </div>
            </div>
            {isEnableContentRight && (
              <div className='api-execute__content-right'>
                <div className='api-execute__logs-wrapper'>
                  {isMobile() && (
                    <div onClick={handleOnClickMobileBackButton} className='api-execute__logs-wrapper-mobile-back-button'>
                      <MdArrowBackIos />
                      <span>geri</span>
                    </div>
                  )}
                  <ApiExecuteLogs apiLogs={apiLogs} freshApiLogId={freshApiLogId} setApiLogs={setApiLogs} />
                </div>
              </div>
            )}
            {isMobile() && (
              <div onClick={handleOnClickMobileLogButton} className='api-execute__mobile-log-button'>
                <TbDeviceImacSearch />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ApiExecute
