import { IoIosSettings } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

import BuildApiRightPanelFieldSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelFieldSettings/BuildApiRightPanelFieldSettings'
import BuildApiRightPanelProjectSettings from '@/containers/BuildApi/BuildApiContent/BuildApiRightPanel/BuildApiRightPanelProjectSettings/BuildApiRightPanelProjectSettings'
import BuildApiPanel, { PANEL_TYPES } from '@/containers/BuildApi/components/BuildApiPanel/BuildApiPanel'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'
import { getFieldText } from '@/utils/buildApi'

import './buildApiRightPanel.scss'

const BuildApiRightPanel = () => {
  const dispatch = useDispatch()

  const buildApi = useSelector(state => state.buildApi)
  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const hasSelectedField = !!selectedField

  const handleClosePanel = () => {
    dispatch(buildApiReducerActions.setIsActiveRightPanel(false))
  }

  const handleOnClickSettingsButton = () => {
    dispatch(buildApiReducerActions.setIsActiveRightPanel(true))
    dispatch(buildApiReducerActions.setSelectedFieldId())
  }

  return (
    <>
      <div onClick={handleOnClickSettingsButton} className='build-api-right-panel__settings-button'>
        <IoIosSettings className='build-api-right-panel__settings-button-icon' />
      </div>
      <BuildApiPanel
        title={hasSelectedField ? getFieldText(selectedField.type) : 'Ayarlar'}
        type={PANEL_TYPES.RIGHT}
        isEnablePanel={buildApi.isActiveRightPanel}
        handleClosePanel={handleClosePanel}>
        {hasSelectedField ? <BuildApiRightPanelFieldSettings /> : <BuildApiRightPanelProjectSettings />}
      </BuildApiPanel>
    </>
  )
}

export default BuildApiRightPanel
