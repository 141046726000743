import clsx from 'clsx'

import Input from '@/components/Input/Input'

import './inputWithLabel.scss'

const InputWithLabel = ({ label, inputProps, className }) => {
  const allClassNames = clsx('input-with-label', className)

  return (
    <div className={allClassNames}>
      <div className='input-with-label__text'>{label}</div>
      <Input placeholder={label} {...inputProps} />
    </div>
  )
}

export default InputWithLabel
