import Input from '@/components/Input/Input'

import './manageActionDefaultVariables.scss'

const ManageActionDefaultVariables = ({ list, handleOnChange, renderCustomActionsContent }) => {
  const handleOnChangeInput = payload => e => {
    handleOnChange({ ...payload, value: e.target.value })
  }

  return (
    <div className='manage-actions-default-variables'>
      {list.map((listItem, index) => (
        <div key={index} className='manage-actions-default-variables__list'>
          <div className='manage-actions-default-variables__title'>{listItem.title}</div>
          <div className='manage-actions-default-variables__item-list'>
            {!listItem.items.length ? (
              <div className='manage-actions-default-variables__no-item-list'>Hiç değer yok</div>
            ) : (
              listItem.items.map(({ name, value }) => (
                <div key={name} className='manage-actions-default-variables__item'>
                  <div title={name} className='manage-actions-default-variables__item-text'>
                    {name}
                  </div>
                  <Input
                    value={value}
                    onChange={handleOnChangeInput({ name, parentKey: listItem.key })}
                    className='manage-actions-default-variables__item-input'
                  />
                  {renderCustomActionsContent && renderCustomActionsContent({ name, value }, listItem.key)}
                </div>
              ))
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ManageActionDefaultVariables
