import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import InavoLoading from '@/components/InavoLoading/InavoLoading'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './exportApiDownloadStep.scss'

const ExportApiDownloadStep = () => {
  const { isLoading, downloadUrl } = useSelector(state => {
    const { isLoading, downloadUrl } = state.apiCollection

    return {
      isLoading,
      downloadUrl,
    }
  })
  const linkRef = useRef()

  const handleDownloadFileAutomatically = () => {
    linkRef.current.click()
  }

  useEffect(() => {
    if (!isLoading) {
      handleDownloadFileAutomatically()
    }
  }, [isLoading])

  return (
    <div className='export-api-download'>
      {isLoading ? (
        <div className='export-api-download__loading'>
          <InavoLoading className='export-api-download__loading-icon' />
          <div className='export-api-download__loading-text'>Dosyan hazırlanıyor...</div>
        </div>
      ) : (
        <>
          <div className='export-api-download__desc'>
            Dosyan hazırlandı! Otomatik olarak indirilme başlamadıysa aşağıdaki butonu kullanabilirsin.{' '}
          </div>
          <a ref={linkRef} href={downloadUrl} download={true}>
            <PrimaryButton className='export-api-download__button'>İndir</PrimaryButton>
          </a>
        </>
      )}
    </div>
  )
}

export default ExportApiDownloadStep
