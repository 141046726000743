import clsx from 'clsx'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsDoorClosed } from 'react-icons/bs'
import { HiPlus } from 'react-icons/hi'
import { MdOutlineDelete } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'

import { getProjectUsers } from '@/api'
import Input from '@/components/Input/Input'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PageTitle from '@/components/PageTitle/PageTitle'
import Tooltip from '@/components/Tooltip/Tooltip'
import UserAvatar from '@/components/UserAvatar/UserAvatar'
import UserTypeTag from '@/components/UserTypeTag/UserTypeTag'
import AddUserPopup from '@/pages/Users/AddUserPopup/AddUserPopup'
import DeleteUserPopup from '@/pages/Users/DeleteUserPopup/DeleteUserPopup'
import { filterText as filterTextUtil, isMobile } from '@/utils'

import './users.scss'

const Users = () => {
  const [filterText, setFilterText] = useState('')
  const [addUserModalVisible, setAddUserModalVisible] = useState(false)
  const [deleteProjectUserId, setDeleteProjectUserId] = useState(null)
  const [isDeletingYourself, setIsDeletingYourself] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [projectUsers, setProjectUsers] = useState([])

  const user = useSelector(state => state.auth.user)

  const hasProjectUsers = projectUsers.length > 0
  const filteredProjectUsers = projectUsers.filter(projectUser => filterTextUtil(filterText, projectUser.user.email))
  const deleteProjectUserModalVisible = deleteProjectUserId !== null

  const handleChangeFilterInput = e => {
    setFilterText(e.target.value)
  }

  const handleClearDeleteProjectUserId = () => {
    setDeleteProjectUserId(null)
    setIsDeletingYourself(false)
  }

  const handleCloseAddUserPopup = () => {
    setAddUserModalVisible(false)
  }

  const handleOpenAddUserPopup = () => {
    setAddUserModalVisible(true)
  }

  const handleClickProjectUserDeleteIcon = projectUserId => () => {
    setDeleteProjectUserId(projectUserId)
  }

  const renderDeleteUserIcon = projectUser => {
    const isYourself = projectUser.userId === user.id
    const IconComponent = isYourself ? BsDoorClosed : MdOutlineDelete

    // eslint-disable-next-line react/display-name
    const IconComponentWrapper = ({ children }) =>
      projectUser.isProjectOwner ? (
        <Tooltip text='Kullanıcı silinemez' tooltipTextClassName='users__project-owner-tooltip'>
          {children}
        </Tooltip>
      ) : (
        children
      )

    return (
      <IconComponentWrapper>
        <IconComponent
          className={clsx('users__delete-icon', {
            'users__delete-icon--disable': projectUser.isProjectOwner,
          })}
          onClick={handleClickProjectUserDeleteIcon(projectUser.id)}
        />
      </IconComponentWrapper>
    )
  }

  const handleGetProjectUsers = async () => {
    try {
      setIsLoading(true)

      const projectUsersRes = await getProjectUsers({
        isIncludedProjectOwner: true,
      })
      setProjectUsers(projectUsersRes.data)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetProjectUsers()
  }, [])

  useEffect(() => {
    if (!deleteProjectUserId) return

    const userId = projectUsers.find(({ id }) => id === deleteProjectUserId)?.userId
    const _isDeletingYourself = userId === user.id

    setIsDeletingYourself(_isDeletingYourself)
  }, [deleteProjectUserId])

  return (
    <div className='users'>
      <AddUserPopup isVisible={addUserModalVisible} handleClose={handleCloseAddUserPopup} afterHandleProjectUser={handleGetProjectUsers} />
      <DeleteUserPopup
        isDeletingYourself={isDeletingYourself}
        isVisible={deleteProjectUserModalVisible}
        handleClosePopup={handleClearDeleteProjectUserId}
        deleteProjectUserId={deleteProjectUserId}
        afterHandleDeleteProjectUser={handleGetProjectUsers}
      />
      <PageTitle>Tüm Kullanıcılar</PageTitle>
      <div className='users__top'>
        <Input
          onChange={handleChangeFilterInput}
          value={filterText}
          className='users__search-user-input'
          placeholder="Email'e göre kullanıcı ara..."
        />
        {isMobile() ? (
          <HiPlus onClick={handleOpenAddUserPopup} className='users__add-user-button users__add-user-button--mobile' />
        ) : (
          <OutlinedButton onClick={handleOpenAddUserPopup} className='users__add-user-button'>
            Kullanıcı Ekle
          </OutlinedButton>
        )}
      </div>
      <div className='users__list'>
        {isLoading && <Skeleton className='users__loading-skelton-item' count={Object.keys(Array(10).fill('')).length} />}
        {!isLoading && !hasProjectUsers && <div className='users__no-users'>Hiç eklenmiş kullanıcı yok</div>}
        {!isLoading &&
          hasProjectUsers &&
          (filteredProjectUsers.length === 0 ? (
            <div className='users__no-users'>Bu filtreye göre hiç kullanıcı yok</div>
          ) : (
            filteredProjectUsers.map(projectUser => (
              <div key={projectUser.id} className='users__item'>
                <div className='users__user-info-wrapper'>
                  <UserAvatar image={projectUser.user.image} />
                  <div className='users__basic-infos'>
                    <div className='users__name'>{projectUser.user.name}</div>
                    <div className='users__email'>{projectUser.user.email}</div>
                  </div>
                  <div className='users__type-tag-wrapper'>
                    <UserTypeTag isAdmin={projectUser.isAdmin} />
                  </div>
                </div>
                {renderDeleteUserIcon(projectUser)}
              </div>
            ))
          ))}
      </div>
    </div>
  )
}

export default Users
