import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedApiIds: [],
  parameters: {
    apiDefaultHeader: {},
    apiDefaultBody: {},
    apiDefaultUrlQueryParams: {},
    configs: {},
  },
  isLoading: false,
  downloadUrl: '',
}

const apiCollectionSlice = createSlice({
  name: 'apiCollection',
  initialState,
  reducers: {
    updateSelectedApiIds: (state, action) => {
      state.selectedApiIds = action.payload
    },
    updateIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    updateParameters: (state, action) => {
      state.parameters = { ...state.parameters, ...action.payload }
    },
    updateDownloadUrl: (state, action) => {
      state.downloadUrl = action.payload
    },
    setInitialState: () => {
      return { ...initialState }
    },
  },
})

export const apiCollectionReducerActions = apiCollectionSlice.actions

export default apiCollectionSlice.reducer
