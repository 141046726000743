import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

import { getData } from '@/api'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import Table, { TABLE_ACTIONS } from '@/components/Table/Table'
import { setUrlQueryParameters } from '@/utils'

import './dynamicTable.scss'

const DynamicTable = ({ table }) => {
  const [data, setData] = useState([])
  const [totalCount, setTotalCount] = useState(null) // only for pagination
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isLoadingContent, setIsLoadingContent] = useState(false)
  const [hasError, setHasError] = useState(false)

  const currentPagination = useRef(1)

  const projectId = useSelector(state => state.app.activeProjectId)
  const hasPagination = !!table.limitKey

  const FallBackError = () => (
    <span className='dynamic-table__fallback-error'>Tablo oluşturulurken bir hata oluştu.({`${table.label}`})</span>
  )

  const getPaginationParameters = value => ({
    [table.limitKey]: value,
  })

  const handleGetData = async queryParams => {
    try {
      const { url: _url, method, header, dataKey, totalKey } = table

      const url = queryParams ? setUrlQueryParameters(_url, queryParams) : _url

      const res = await getData({
        projectId,
        url,
        method,
        header,
      })
      const data = res.data.response.data
      const scopedData = dataKey ? _.get(data, dataKey) : data

      setData(Array.isArray(scopedData) ? scopedData : [scopedData])
      hasPagination && setTotalCount(_.get(data, totalKey))
    } catch (err) {
      setHasError(true)
    }
  }

  const handleGetInitialData = async () => {
    try {
      setIsLoadingData(true)
      await handleGetData()
    } catch (err) {
      setHasError(true)
    } finally {
      setIsLoadingData(false)
    }
  }

  const handleOnChangePagination = async data => {
    if (!hasPagination) return

    try {
      setIsLoadingContent(true)

      currentPagination.current = data
      await handleGetData(getPaginationParameters(data))
    } finally {
      setIsLoadingContent(false)
    }
  }

  const handleOnClickRefreshAction = async () => {
    try {
      setIsLoadingContent(true)

      await handleGetData(hasPagination && getPaginationParameters(currentPagination.current))
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoadingContent(false)
    }
  }

  useEffect(() => {
    handleGetInitialData()
  }, [])

  const renderContent = () => {
    switch (true) {
      case hasError:
        return <FallBackError />
      default:
        return (
          <ErrorBoundary fallback={FallBackError}>
            <Table
              label={table.label}
              columns={table.columns}
              data={data}
              handleOnChangePagination={handleOnChangePagination}
              isLoadingData={isLoadingData}
              isLoadingContent={isLoadingContent}
              isRenderEmptyDataWrapper={!!table.properties.isRenderEmptyDataWrapper}
              activeActions={[TABLE_ACTIONS.REFRESH]}
              handleOnClickRefreshAction={handleOnClickRefreshAction}
              {...(hasPagination && totalCount
                ? {
                    totalResult: totalCount,
                    paginationRangeSize: data.length,
                  }
                : {})}
            />
          </ErrorBoundary>
        )
    }
  }

  return renderContent()
}

export default DynamicTable
