import Textarea from '@/components/Textarea/Textarea'

import './textareaWithLabel.scss'

const TextareaWithLabel = ({ label, inputProps }) => {
  return (
    <div className='textarea-with-label'>
      <div className='textarea-with-label__text'>{label}</div>
      <Textarea placeholder={label} {...inputProps} />
    </div>
  )
}

export default TextareaWithLabel
