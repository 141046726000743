import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'

import { getProjectUsers } from '@/api'
import Input from '@/components/Input/Input'
import PageTitle from '@/components/PageTitle/PageTitle'
import UserAvatar from '@/components/UserAvatar/UserAvatar'
import ProjectUserPermissionsDetail from '@/pages/ProjectUserPermissions/ProjectUserPermissionsDetail/ProjectUserPermissionsDetail'
import { filterText as filterTextUtil } from '@/utils'

import './projectUserPermissions.scss'

const ProjectUserPermissions = () => {
  const [projectUsers, setProjectUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [filterText, setFilterText] = useState('')

  const projectUserId = searchParams.get('projectUserId')
  const isSelectedProjectUser = !!projectUserId
  const hasFilter = filterText !== ''
  const hasProjectUsers = projectUsers.length > 0
  const filteredProjectUsers = projectUsers.filter(projectUser => filterTextUtil(filterText, projectUser.user.email))

  const handleSetProjectUsers = _projectUsers => {
    const newProjectUsers = _projectUsers.map(({ id, user }) => ({ id, user }))
    setProjectUsers(newProjectUsers)
  }

  const handleOnClickItem = projectUserId => () => {
    setSearchParams({ projectUserId })
  }

  const handleChangeFilterText = e => {
    setFilterText(e.target.value)
  }

  const handleGetProjectUsers = async () => {
    try {
      setIsLoading(true)

      const projectUsers = await getProjectUsers()
      handleSetProjectUsers(projectUsers.data)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetProjectUsers()
  }, [])

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <Skeleton className='project-user-permissions__loading-item' count={4} />
      case isSelectedProjectUser && hasProjectUsers: {
        const projectUser = projectUsers.find(projectUser => projectUser.id === Number(projectUserId))

        return <ProjectUserPermissionsDetail projectUser={projectUser} />
      }
      case !hasProjectUsers:
        return <div className='project-user-permissions__no-project-users'>Hiç projeye eklenmiş kullanıcı yok</div>
      default:
        return (
          <>
            <Input
              onChange={handleChangeFilterText}
              value={filterText}
              placeholder="Email'e göre kullanıcı ara..."
              className='project-user-permissions__filter-input'
            />
            <div className='project-user-permissions__list'>
              {hasFilter && filteredProjectUsers.length === 0 && (
                <div className='project-user-permissions__no-project-users'>Bu filtreye göre hiç projeye eklenmiş kullanıcı yok</div>
              )}
              {filteredProjectUsers.map((projectUser, key) => (
                <div key={key} className='project-user-permissions__item' onClick={handleOnClickItem(projectUser.id)}>
                  <div className='project-user-permissions__user-info'>
                    <UserAvatar image={projectUser.user.image} />
                    <div className='project-user-permissions__name'>{projectUser.user.name}</div>
                  </div>
                  <div className='project-user-permissions__email'>{projectUser.user.email}</div>
                </div>
              ))}
            </div>
          </>
        )
    }
  }

  return (
    <div className='project-user-permissions'>
      <PageTitle>Proje Yetkileri</PageTitle>
      {getContent()}
    </div>
  )
}

export default ProjectUserPermissions
