import clsx from 'clsx'
import { motion } from 'framer-motion'
import { IoCloseSharp } from 'react-icons/io5'

import './buildApiPanel.scss'

export const PANEL_TYPES = {
  RIGHT: 'right',
  LEFT: 'left',
}

const BuildApiPanel = props => {
  const { title, isEnablePanel = true, handleClosePanel, type, children } = props

  const isLeftType = type === PANEL_TYPES.LEFT
  const isEnableCloseButton = typeof handleClosePanel === 'function'

  const getPanelProps = () => {
    const panelAllClasses = clsx('build-api-panel', isLeftType ? 'build-api-panel--left' : 'build-api-panel--right')

    return {
      className: panelAllClasses,
      initial: false,
      animate: isEnablePanel ? 'open' : 'closed',
      transition: { ease: 'linear', duration: 0.08 },
      variants: {
        open: { opacity: 1, width: '300px' },
        closed: { opacity: 0, width: '0' },
      },
    }
  }

  return (
    <motion.div {...getPanelProps()}>
      <div className='build-api-panel__top'>
        <div className='build-api-panel__title'>{title}</div>
        {isEnableCloseButton && <IoCloseSharp onClick={handleClosePanel} className='build-api-panel__close' />}
      </div>
      <div className='build-api-panel__content'>{children}</div>
    </motion.div>
  )
}

export default BuildApiPanel
