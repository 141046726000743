import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'

import { postChangePassword } from '@/api'
import AuthenticationFlow from '@/components/AuthenticationFlow/AuthenticationFlow'
import Input from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './changePassword.scss'

const ChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')

  const { token } = useParams()
  const navigate = useNavigate()

  const isValidPassword = !!newPassword && !!repeatNewPassword

  const handleChangeNewPassword = e => {
    setNewPassword(e.target.value)
  }

  const handleChangeRepeatNewPassword = e => {
    setRepeatNewPassword(e.target.value)
  }

  const handleChangePasswordButton = async () => {
    const isSameText = newPassword === repeatNewPassword
    if (!isSameText) {
      toast.error('Girilen şifreler aynı olmalıdır')
      return
    }

    try {
      setIsLoading(true)

      await postChangePassword({ token, password: newPassword })

      toast.success('Şifre başarıyla değiştirildi')
      setTimeout(() => {
        navigate('/login')
      }, 1500)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <AuthenticationFlow>
      <Input
        name='new_password'
        className='change-password__input'
        placeholder='Yeni şifre'
        type='password'
        onChange={handleChangeNewPassword}
        value={newPassword}
      />
      <Input
        name='new_password_repeat'
        className='change-password__input'
        placeholder='Yeni şifre tekrar'
        type='password'
        onChange={handleChangeRepeatNewPassword}
        value={repeatNewPassword}
      />
      <PrimaryButton
        onClick={handleChangePasswordButton}
        className='change-password__change-password'
        isLoading={isLoading}
        disable={!isValidPassword}>
        Şifre Değiştir
      </PrimaryButton>
    </AuthenticationFlow>
  )
}

export default ChangePassword
