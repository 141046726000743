import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { IoIosWarning } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { deleteProject } from '@/api'
import Input from '@/components/Input/Input'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './deleteProjectPopup.scss'

const STEPS = {
  FIRST: 'first',
  LAST: 'last',
}

const CONFIRMATION_TEXT = 'projemi silmek istiyorum'
const FirstStep = ({ handleSetActiveStep }) => {
  const [confirmationText, setConfirmationText] = useState('')

  const isDisable = confirmationText !== CONFIRMATION_TEXT

  const handleOnChangeText = e => {
    setConfirmationText(e.target.value)
  }

  const handleOnClickContinueButton = () => {
    handleSetActiveStep(STEPS.LAST)
  }

  return (
    <div className='delete-project'>
      <div className='delete-project__item delete-project__item--desc'>
        Projeyi silmek üzeresin. Silme işlemine devam etmek için <span>&quot;{CONFIRMATION_TEXT}&quot;</span> yazmalısın.
      </div>
      <div className='delete-project__item'>
        <Input className='delete-project__input' value={confirmationText} onChange={handleOnChangeText} />
      </div>
      <div className='delete-project__item delete-project__item--continue'>
        <OutlinedButton onClick={handleOnClickContinueButton} disable={isDisable}>
          Devam Et
        </OutlinedButton>
      </div>
    </div>
  )
}

const LastStep = ({ handleClosePopup }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const navigate = useNavigate()

  const handleOnClickDeleteProjectButton = async () => {
    try {
      setIsDeleting(true)

      await deleteProject()

      toast.success('Proje başarıyla silindi')
      setTimeout(() => {
        toast.success('Yönlendiriliyorsunuz...')

        setTimeout(() => {
          navigate('/')
          window.location.reload()
        }, 1500)
      }, 1000)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <div className='delete-project'>
      <div className='delete-project__item delete-project__item--warning-icon'>
        <IoIosWarning />
      </div>
      <div className='delete-project__item delete-project__item--desc'>
        <span>Proje silindikten sonra geri alınamaz. Şu an projeyi silmek için son aşamadasın. Silmek istediğine emin misin?</span>
      </div>
      <div className='delete-project__item delete-project__item--actions'>
        <PrimaryButton onClick={handleClosePopup} className='delete-project__action-button delete-project__action-button--back'>
          Vazgeç
        </PrimaryButton>
        <OutlinedButton
          isLoading={isDeleting}
          onClick={handleOnClickDeleteProjectButton}
          className='delete-project__action-button delete-project__action-button--delete'>
          Eminim, Sil
        </OutlinedButton>
      </div>
    </div>
  )
}

const DeleteProjectPopup = ({ isVisible, handleClosePopup }) => {
  const [activeStep, setActiveStep] = useState(STEPS.FIRST)

  const handleSetActiveStep = step => setActiveStep(step)

  const renderContent = () => {
    switch (activeStep) {
      case STEPS.FIRST:
        return <FirstStep handleSetActiveStep={handleSetActiveStep} />
      case STEPS.LAST:
        return <LastStep handleClosePopup={handleClosePopup} />
    }
  }

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setActiveStep(STEPS.FIRST)
      }, 300)
    }
  }, [isVisible])

  return (
    <Popup isVisible={isVisible} handleClosePopup={handleClosePopup} title='Projeyi Sil'>
      {renderContent()}
    </Popup>
  )
}

export default DeleteProjectPopup
