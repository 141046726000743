import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { PiWarningDiamond } from 'react-icons/pi'
import Skeleton from 'react-loading-skeleton'

import { getProjectWidgetItems, getProjectWidgets, updateProjectWidgets } from '@/api'
import DynamicChart from '@/components/DynamicChart/DynamicChart'
import DynamicTable from '@/components/DynamicTable/DynamicTable'
import GridLayout from '@/components/GridLayout/GridLayout'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { PROJECT_WIDGET_TYPES } from '@/constants'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import usePermission from '@/hooks/usePermission'
import HomeDragAndDropItemList from '@/pages/Home/HomeGridLayout/HomeDragAndDropItemList/HomeDragAndDropItemList'
import HomeWidgetGuide from '@/pages/Home/HomeGridLayout/HomeWidgetGuide/HomeWidgetGuide'

import './homeGridLayout.scss'

const deleteItemAccordingToIndex = (items, index) => {
  const numberIndex = Number(index)
  return [...items.slice(0, numberIndex), ...items.slice(numberIndex + 1)]
}

const DeletedWidgetInformation = () => (
  <div className='home__deleted-widget'>
    <PiWarningDiamond />
    <span>Bu widget silinmiş</span>
  </div>
)

// TODO: we should consider to refactor at some points

const HomeGridLayout = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isActiveEditing, setIsActiveEditing] = useState(false)
  const [layouts, setLayouts] = useState({})
  const [widgets, setWidgets] = useState([])
  const [widgetItems, setWidgetItems] = useState([])

  const { checkPermission } = usePermission()
  const prevGrid = useRef(null)

  const scopedLayouts = layouts?.lg || []
  const hasWidgetItems = widgetItems.length > 0
  const hasWidgets = widgets.length > 0

  const handleSetLayouts = layouts => setLayouts({ lg: [...layouts] })
  const handleAddLayout = layout => setLayouts(prev => ({ lg: [...prev.lg, layout] }))
  const handleAddWidget = widget => setWidgets(prevWidgets => [...prevWidgets, widget])

  const handleSetInitialPrevGrid = () => {
    prevGrid.current = {
      layouts: {},
      widgets: [],
    }
  }

  const handleAddProjectWidget = ({ layout, widget }) => {
    handleAddLayout(layout)
    handleAddWidget(widget)
  }

  const handleClickEditButton = () => {
    setIsActiveEditing(true)

    prevGrid.current = {
      layouts,
      widgets,
    }
  }

  const handleClickCloseButton = () => {
    setLayouts(prevGrid.current.layouts)
    setWidgets(prevGrid.current.widgets)

    handleSetInitialPrevGrid()
    setIsActiveEditing(false)
  }

  const handleSaveLayouts = async () => {
    try {
      setIsSaving(true)

      await updateProjectWidgets({ layouts, widgets })

      setIsActiveEditing(false)
      handleSetInitialPrevGrid()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsSaving(false)
    }
  }

  const handleOnLayoutChange = (_, layouts) => {
    setLayouts({ ...layouts })
  }

  const handleDeleteItem = index => () => {
    const newScopedLayouts = deleteItemAccordingToIndex(scopedLayouts, index)
    handleSetLayouts(newScopedLayouts)

    const newWidgets = deleteItemAccordingToIndex(widgets, index)
    setWidgets(newWidgets)
  }

  const renderItemContent = widget => {
    switch (widget.type) {
      case PROJECT_WIDGET_TYPES.CHART: {
        const chart = widgetItems.find(
          widgetItem => widgetItem.widgetType === PROJECT_WIDGET_TYPES.CHART && widgetItem.id === Number(widget.properties.id),
        )

        if (!chart) return <DeletedWidgetInformation />
        return <DynamicChart chart={chart} provideFullScreen={false} />
      }
      case PROJECT_WIDGET_TYPES.TABLE: {
        const table = widgetItems.find(
          widgetItem => widgetItem.widgetType === PROJECT_WIDGET_TYPES.TABLE && widgetItem.id === Number(widget.properties.id),
        )

        if (!table) return <DeletedWidgetInformation />
        return <DynamicTable table={table} />
      }
    }
  }

  const renderItems = () =>
    scopedLayouts.map((_, index) => {
      const widget = widgets[index]
      const layoutItemClasses = clsx('home__layout-item', {
        'home__layout-item--active': isActiveEditing,
      })

      return (
        <div key={index} className={layoutItemClasses}>
          {isActiveEditing && (
            <div onClick={handleDeleteItem(index)} className='home__layout-item-top home__layout-item-top--delete'>
              sil
            </div>
          )}
          <div className='home__layout-item-content'>{renderItemContent(widget)}</div>
        </div>
      )
    })

  const handleGetData = async () => {
    try {
      setIsLoading(true)

      const [projectWidgetsRes, projectWidgetItemsRes] = await Promise.all([getProjectWidgets(), getProjectWidgetItems()])

      setWidgets(projectWidgetsRes.data.widgets)
      setLayouts(projectWidgetsRes.data.layouts)
      setWidgetItems(projectWidgetItemsRes.data.widgets)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetData()
    handleSetInitialPrevGrid()
  }, [])

  const renderContent = () => {
    switch (true) {
      case isLoading:
        return <Skeleton className='home__loading-item' count={7} />
      case !hasWidgetItems:
        return <HomeWidgetGuide />
      default:
        return (
          <div className='home__wrapper'>
            {checkPermission(PROJECT_PERMISSIONS.EDIT_HOME_WIDGETS) && (
              <div className='home__top'>
                <div className='home__actions'>
                  {isActiveEditing ? (
                    <>
                      <OutlinedButton onClick={handleClickCloseButton} className='home__action-button'>
                        İptal Et
                      </OutlinedButton>
                      <PrimaryButton onClick={handleSaveLayouts} className='home__action-button'>
                        Kaydet
                      </PrimaryButton>
                    </>
                  ) : (
                    <OutlinedButton isLoading={isSaving} onClick={handleClickEditButton} className='home__action-button'>
                      Düzenle
                    </OutlinedButton>
                  )}
                </div>
                {isActiveEditing && (
                  <div className='home__item-list-wrapper'>
                    <div className='home__list-title'>Ekle</div>
                    <HomeDragAndDropItemList handleAddProjectWidget={handleAddProjectWidget} widgets={widgets} widgetItems={widgetItems} />
                  </div>
                )}
              </div>
            )}
            <div className='home__grid-layout-wrapper'>
              {isActiveEditing && !hasWidgets && <div className='home__no-grid-layouts'>Henüz hiç widget eklenmedi</div>}
              {hasWidgets && (
                <GridLayout
                  layouts={layouts}
                  onLayoutChange={handleOnLayoutChange}
                  renderItems={renderItems}
                  {...(isActiveEditing
                    ? {
                        gridLayoutClassName: 'home__grid-layout',
                      }
                    : {
                        isDraggable: false,
                        isResizable: false,
                      })}
                />
              )}
            </div>
          </div>
        )
    }
  }

  return renderContent()
}

export default HomeGridLayout
