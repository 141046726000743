import { BsFillInfoCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import JsonEditorWithError from '@/components/JsonEditorWithError/JsonEditorWithError'
import MixTagifyInput from '@/components/MixTagifyInput/MixTagifyInput'
import MultipleInputList, { INPUT_TYPES } from '@/components/MultipleInputList/MultipleInputList'
import Tooltip from '@/components/Tooltip/Tooltip'
import { API_METHODS, FIELD_SCOPE_TYPES } from '@/constants'
import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import Input from '@/containers/BuildApi/components/Settings/Input/Input'
import InputDropdown from '@/containers/BuildApi/components/Settings/InputDropdown/InputDropdown'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'

import './buildApiRightPanelRemoteSelectAdvancedSettings.scss'

const fieldMethodOptions = Object.values(API_METHODS).map(method => ({ label: method, value: method }))

const BuildApiRightPanelRemoteSelectAdvancedSettings = () => {
  const dispatch = useDispatch()

  const { api, projectSettings } = useSelector(state => {
    const { api } = state.buildApi
    const { projectSettings } = state.app

    return {
      api,
      projectSettings,
    }
  })
  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const fieldProperties = selectedField.properties

  const fieldDependencies = fieldProperties?.dependencyKeys || []

  const handleOnChangeHeader = header => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ header }))
  }

  const handleOnChangeMethod = method => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ method }))
  }

  const handleChangeInput = key => e => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ [key]: e.target.value }))
  }

  const handleOnChangeUrl = value => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ url: value.replace(/\s/g, '') }))
  }

  const handleOnSaveDependency = item => {
    const isValid = Object.values(item).every(value => value !== '')
    if (!isValid) return

    dispatch(buildApiReducerActions.setApiFieldProperties({ dependencyKeys: [...fieldDependencies, item] }))
  }

  const handleOnDeleteDependency = index => {
    const newDependencies = fieldDependencies.filter((_, dependencyIndex) => dependencyIndex !== index)
    dispatch(buildApiReducerActions.setApiFieldProperties({ dependencyKeys: [...newDependencies] }))
  }

  const selectedFieldMethodIndex = fieldMethodOptions.findIndex(method => method.value === fieldProperties.method)

  const dependencyKeyOptions = api.fields
    .filter(field => {
      const isDependency = Boolean(field.properties.isDependency)
      const isAdded = !!fieldDependencies.find(fieldDependency => fieldDependency.key === field.key)

      return isDependency && !isAdded
    })
    .map(({ key }) => ({ value: key, label: key }))

  const fieldDependencyKeysOptionInputs = [
    { key: 'key', placeholder: 'Key', type: INPUT_TYPES.SELECT, items: dependencyKeyOptions },
    {
      key: 'type',
      placeholder: 'Tip',
      type: INPUT_TYPES.SELECT,
      items: Object.values(FIELD_SCOPE_TYPES).map(fieldScopeType => ({ label: fieldScopeType, value: fieldScopeType })),
    },
  ]

  const allDependencyKeys = selectedField.properties.dependencyKeys
    .filter(item => item.type === FIELD_SCOPE_TYPES.URL)
    .reduce((acc, item) => [...acc, item.key], [])

  const defaultQueryParamsWhiteList = Object.keys(projectSettings.apiDefaultUrlQueryParams || {}).map(key => ({
    value: `:${key}`,
    title: key,
  }))
  const dependenciesUrlFieldsWhiteList = api.fields
    .filter(field => !!field?.properties?.isDependency && allDependencyKeys.includes(field.key))
    .map(({ key, label }) => ({ value: `:${key}`, title: label }))
  const allUrlFieldWhiteList = [...dependenciesUrlFieldsWhiteList, ...defaultQueryParamsWhiteList]

  return (
    <>
      <BuildApiSettingWrapper title='Url*'>
        <MixTagifyInput onChange={handleOnChangeUrl} whiteList={allUrlFieldWhiteList} value={selectedField?.properties?.url} />
        <div className='build-api-remote-select-advanced-settings__url-description'>
          <span>Dinamik alan için &#39;:&#39; ile başlayın</span>
          <Tooltip
            tooltipTextClassName='build-api-remote-select-advanced-settings__url-description-tooltip'
            text='Alan tipi url olup bağımlılık olarak eklenmiş ve varsayılan olarak eklenmiş parametreler kullanılabilir'>
            <BsFillInfoCircleFill />
          </Tooltip>
        </div>
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Method*'>
        <InputDropdown
          items={fieldMethodOptions}
          selectedItemIndex={selectedFieldMethodIndex}
          handleChange={handleOnChangeMethod}
          placeholder='Method seç...'
        />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Data Key'>
        <Input value={fieldProperties.dataKey} onChange={handleChangeInput('dataKey')} placeholder='Data key' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Başlık Key*'>
        <Input value={fieldProperties.labelKey} onChange={handleChangeInput('labelKey')} placeholder='Başlık key' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Değer Key*'>
        <Input value={fieldProperties.valueKey} onChange={handleChangeInput('valueKey')} placeholder='Değer key' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Arama Parametresi'>
        <Input
          value={fieldProperties.searchQueryParameter}
          onChange={handleChangeInput('searchQueryParameter')}
          placeholder='Arama Parametresi'
        />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Bağımlılıklar'>
        <MultipleInputList
          items={fieldDependencies}
          inputs={fieldDependencyKeysOptionInputs}
          onSave={handleOnSaveDependency}
          onDelete={handleOnDeleteDependency}
        />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Header'>
        <JsonEditorWithError value={fieldProperties.header} onChange={handleOnChangeHeader} />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelRemoteSelectAdvancedSettings
