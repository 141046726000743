import { useEffect } from 'react'
import { IoChevronBack } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'
import AdjustDefaultVariables from '@/containers/PostmanBuilder/Steps/AdjustDefaultVariables/AdjustDefaultVariables'
import AskQuestions from '@/containers/PostmanBuilder/Steps/AskQuestions/AskQuestions'
import CompleteProcess from '@/containers/PostmanBuilder/Steps/CompleteProcess/CompleteProcess'
import ManageActions from '@/containers/PostmanBuilder/Steps/ManageActions/ManageActions'
import PostmanDataStep from '@/containers/PostmanBuilder/Steps/PostmanDataStep/PostmanDataStep'
import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'
import { postmanBuilderReducerActions } from '@/store/reducers/postmanBuilder'

import './postmanBuilder.scss'

const PostmanBuilder = () => {
  const activeStep = useSelector(state => state.postmanBuilder.activeStep)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleOnClickBackButton = () => {
    navigate(ROUTE_URLS.HOME)
  }

  const renderStep = () => {
    switch (activeStep) {
      case POSTMAN_BUILDER_STEPS.DATA:
        return <PostmanDataStep />
      case POSTMAN_BUILDER_STEPS.MANAGE_ACTIONS:
        return <ManageActions />
      case POSTMAN_BUILDER_STEPS.ADJUST_DEFAULT_VARIABLES:
        return <AdjustDefaultVariables />
      case POSTMAN_BUILDER_STEPS.ASK_QUESTIONS:
        return <AskQuestions />
      case POSTMAN_BUILDER_STEPS.COMPLETE_PROCESS:
        return <CompleteProcess />
    }
  }

  useEffect(() => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.DATA))
  }, [])

  return (
    <div className='postman-builder'>
      <div onClick={handleOnClickBackButton} className='postman-builder__back-button'>
        <IoChevronBack className='postman-builder__back-button-icon' />
        <span>geri</span>
      </div>
      {renderStep()}
    </div>
  )
}

export default PostmanBuilder
