import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useClickOutside from '@/hooks/useClickOutside'
import { renderField } from '@/utils/dynamicForms'
import { checkIsFieldVisible } from '@/utils/dynamicForms/utils'

import './dynamicForm.scss'

const DynamicForm = ({ fields, fieldValues, handleChangeFieldValue: _handleChangeFieldValue, fieldWrapperClassName }) => {
  const [dependencyFields, setDependencyFields] = useState([])
  const [selectedFieldKey, setSelectedFieldKey] = useState(null)

  const contentRef = useRef(null)
  const filteredFields = fields
    .filter(field => checkIsFieldVisible(field))
    .sort((a, b) => a?.properties?.orderIndex - b?.properties?.orderIndex)

  const handleClearSelectedFieldKey = () => setSelectedFieldKey(null)
  useClickOutside(contentRef, handleClearSelectedFieldKey)

  const handleChangeDependencyFieldValue = (key, value) =>
    setDependencyFields(prev => prev.map(field => (field.key === key ? { ...field, value, isDirty: true } : field)))
  const checkIsDependency = key => !!dependencyFields.find(field => field.key === key)
  const checkHasDependencyKeys = dependencyKeys => (dependencyKeys || []).length > 0
  const checkIsSelectedFieldKey = fieldKey => selectedFieldKey === fieldKey

  const checkHasNotDirtyDependencyFields = field => {
    const dependencyKeys = field?.properties?.dependencyKeys
    if (!checkHasDependencyKeys(dependencyKeys)) return false

    const preparedDependencyKeys = dependencyKeys.map(({ key }) => key)
    return dependencyFields.some(({ key, isDirty }) => preparedDependencyKeys.includes(key) && !isDirty)
  }

  const handleChangeFieldValue = (...payload) => {
    const [key, fieldPayload] = payload

    if (checkIsDependency(key)) {
      const { value } = fieldPayload
      handleChangeDependencyFieldValue(key, value)
    }

    _handleChangeFieldValue(...payload)
  }

  const checkIsDisabled = field => {
    if (checkHasNotDirtyDependencyFields(field) || !!field?.properties?.isDisabled) {
      return true
    }

    return false
  }

  const handleOnClickFieldContent = fieldKey => () => {
    setSelectedFieldKey(fieldKey)
  }

  useEffect(() => {
    const dependencyFields = filteredFields
      .filter(field => field?.properties?.isDependency)
      .map(({ key }) => {
        const value = fieldValues.find(fieldValue => fieldValue.key === key)?.value

        return {
          key,
          value,
          isDirty: false,
        }
      })
    setDependencyFields([...dependencyFields])
  }, [])

  return (
    <div className='dynamic-form'>
      <div className='dynamic-form__content'>
        {filteredFields.map(field => (
          <div
            key={field.key}
            className={clsx('dynamic-form__field-wrapper', fieldWrapperClassName, {
              'dynamic-form__field-wrapper--disable': checkIsDisabled(field),
              'dynamic-form__field-wrapper--selected-field': checkIsSelectedFieldKey(field.key),
            })}>
            <label htmlFor={field.key} className='dynamic-form__field-label'>
              {field.label}
            </label>
            <div ref={contentRef} onClick={handleOnClickFieldContent(field.key)} className='dynamic-form__field-content'>
              {renderField(field, { fieldValues, handleChangeFieldValue, dependencyFields })}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DynamicForm
