import CircleLightLogo from '@/assets/logos/circle-light-logo.svg'
import LeftMenuList from '@/containers/Root/LeftMenu/LeftMenuList/LeftMenuList'
import LeftMenuLogo from '@/containers/Root/LeftMenu/LeftMenuLogo/LeftMenuLogo'
import ProjectSwitcher from '@/containers/Root/components/ProjectSwitcher/ProjectSwitcher'
import withMenuLogic from '@/containers/Root/withMenuLogic'

import './leftMenu.scss'

const LeftMenu = props => {
  const { renderContent } = props

  return (
    <div className='left-menu'>
      <img src={CircleLightLogo} className='left-menu__brand' />
      <div className='left-menu__item left-menu__item--logo'>
        <LeftMenuLogo />
      </div>
      {renderContent(
        <>
          <div className='left-menu__item'>
            <ProjectSwitcher />
          </div>
          <div className='left-menu__item'>
            <LeftMenuList />
          </div>
        </>,
      )}
    </div>
  )
}

export default withMenuLogic(LeftMenu)
