import clsx from 'clsx'

import './radioButton.scss'

const RadioButton = ({ text, className, ...otherProps }) => {
  const radioButtonWrapperClassNames = clsx('radio-button', className)

  return (
    <label className={radioButtonWrapperClassNames}>
      {text}
      <input {...otherProps} type='radio' />
      <span className='checkmark' />
    </label>
  )
}

export default RadioButton
