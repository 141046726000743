import MobileRootBottomSliderPopup from '@/components/MobileRootBottomSliderPopup/MobileRootBottomModal'
import ActionMenuList from '@/containers/Root/components/ActionMenuList/ActionMenuList'

import './mobileAllApisModal.scss'

const MobileAllApisModal = ({ isVisible, handleCloseModal }) => {
  const handleClickActionItem = () => {
    handleCloseModal()
  }

  return (
    <MobileRootBottomSliderPopup isVisible={isVisible} handleCloseModal={handleCloseModal} title='Aksiyonlar'>
      <ActionMenuList handleClickActionItem={handleClickActionItem} />
    </MobileRootBottomSliderPopup>
  )
}

export default MobileAllApisModal
