import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { leaveProjectUser } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { ROUTE_URLS } from '@/constants/routeUrls'

import './projectUserLeave.scss'

const LeaveProjectSection = () => {
  const [isVisibleConfirmation, setIsVisibleConfirmation] = useState(false)
  const [isLeavingProject, setIsLeavingProject] = useState(false)

  const navigate = useNavigate()

  const handleCloseConfirmationPopup = () => {
    setIsVisibleConfirmation(false)
  }

  const handleOnClickLeaveProjectButton = () => {
    setIsVisibleConfirmation(true)
  }

  const handleLeaveProject = async () => {
    try {
      setIsLeavingProject(true)

      await leaveProjectUser()

      navigate(ROUTE_URLS.HOME)
      window.location.reload()
    } catch (error) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLeavingProject(false)
    }
  }

  return (
    <>
      <ContinueAskPopup
        title='Projeden ayrılmak istediğine emin misin?'
        description='Projeden ayrıldıktan sonra işlem geri alınamaz.'
        isVisible={isVisibleConfirmation}
        handleClosePopup={handleCloseConfirmationPopup}
        noButtonProps={{ onClick: handleCloseConfirmationPopup }}
        yestButtonProps={{ onClick: handleLeaveProject, isLoading: isLeavingProject }}
      />
      <PrimaryButton onClick={handleOnClickLeaveProjectButton} className='project-user-leave__button'>
        Projeden Ayrıl
      </PrimaryButton>
    </>
  )
}

export default LeaveProjectSection
