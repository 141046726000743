import clsx from 'clsx'
import { IoIosRadioButtonOn, IoIosTrash, IoMdAdd, IoMdRefresh } from 'react-icons/io'

import { PROJECT_LOG_TYPES } from '@/constants'
import { getTagText } from '@/pages/ProjectLogs/utils'

import './projectLogTypeTag.scss'

const getTagIcon = logType => {
  switch (logType) {
    case PROJECT_LOG_TYPES.CREATED_PROJECT_USER:
    case PROJECT_LOG_TYPES.CREATED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.CREATED_API:
    case PROJECT_LOG_TYPES.CREATED_CHART:
    case PROJECT_LOG_TYPES.CREATED_TABLE:
    case PROJECT_LOG_TYPES.CREATED_API_COLLECTION:
      return <IoMdAdd className='project-log-type-tag__log-tag-icon' />
    case PROJECT_LOG_TYPES.DELETED_CHART:
    case PROJECT_LOG_TYPES.DELETED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.DELETED_TABLE:
    case PROJECT_LOG_TYPES.DELETED_API:
      return <IoIosTrash className='project-log-type-tag__log-tag-icon' />
    case PROJECT_LOG_TYPES.UPDATED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.UPDATED_PROJECT:
    case PROJECT_LOG_TYPES.UPDATED_CHART:
    case PROJECT_LOG_TYPES.UPDATED_TABLE:
      return <IoMdRefresh className='project-log-type-tag__log-tag-icon' />
    default:
      return <IoIosRadioButtonOn className='project-log-type-tag__log-tag-icon' />
  }
}

const getTagClassName = logType => {
  switch (logType) {
    case PROJECT_LOG_TYPES.CREATED_PROJECT_USER:
    case PROJECT_LOG_TYPES.CREATED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.CREATED_API:
    case PROJECT_LOG_TYPES.CREATED_CHART:
    case PROJECT_LOG_TYPES.CREATED_TABLE:
    case PROJECT_LOG_TYPES.CREATED_API_COLLECTION:
      return 'project-log-type-tag__log-tag--add'
    case PROJECT_LOG_TYPES.DELETED_CHART:
    case PROJECT_LOG_TYPES.DELETED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.DELETED_TABLE:
    case PROJECT_LOG_TYPES.DELETED_API:
      return 'project-log-type-tag__log-tag--delete'
    case PROJECT_LOG_TYPES.UPDATED_PROJECT_API_PERMISSION:
    case PROJECT_LOG_TYPES.UPDATED_PROJECT:
    case PROJECT_LOG_TYPES.UPDATED_CHART:
    case PROJECT_LOG_TYPES.UPDATED_TABLE:
      return 'project-log-type-tag__log-tag--update'
    default:
      return 'project-log-type-tag__log-tag--general'
  }
}

const ProjectLogTypeTag = ({ type }) => {
  return (
    <div className='project-log-type-tag__log-tag-wrapper'>
      <div className={clsx('project-log-type-tag__log-tag', getTagClassName(type))}>
        {getTagIcon(type)}
        <span>{getTagText(type)}</span>
      </div>
    </div>
  )
}

export default ProjectLogTypeTag
