export const getAllFields = ({ bodyFields, urlFields }) => [...bodyFields, ...urlFields]
export const getFieldValuesFromFields = allFields => allFields.map(({ key, defaultValue }) => ({ value: defaultValue ?? '', key }))

export const mapUpdateFieldValue = (fieldKey, item) => field => (field.key === fieldKey ? { ...field, ...item } : field)
export const handleChangeFieldValue = (fieldValues, setFieldValues) => (fieldKey, item) => {
  const updatedFieldValues = fieldValues.map(mapUpdateFieldValue(fieldKey, item))

  setFieldValues([...updatedFieldValues])
}

const getAllKeysFromFields = fields =>
  fields.reduce((acc, { key }) => {
    return [...acc, key]
  }, [])
export const getFieldValues = (fieldValues, fields) => {
  const fieldKeys = getAllKeysFromFields(fields)
  return fieldValues.filter(({ key }) => fieldKeys.includes(key))
}
