import InputMultipleSelect from '@/components/InputMultipleSelect/InputMultipleSelect'
import { PROJECT_LOG_TYPES } from '@/constants'
import { getTagText } from '@/pages/ProjectLogs/utils'

import './projectLogTypeFilter.scss'

const items = Object.values(PROJECT_LOG_TYPES).map(type => ({ label: getTagText(type), value: type }))
const joinTypes = types => types.join(',')

const ProjectLogTypeFilter = ({ handleUpdateFilters, value }) => {
  const preparedValue = value ? value.split(',') : []

  const handleOnSelect = itemValue => {
    handleUpdateFilters({ types: joinTypes([...preparedValue, itemValue]) })
  }

  const handleOnDelete = itemValue => {
    handleUpdateFilters({ types: joinTypes([...preparedValue].filter(_value => _value !== itemValue)) })
  }

  return (
    <InputMultipleSelect
      InputMultipleSelect
      items={items}
      handleOnSelect={handleOnSelect}
      handleOnDelete={handleOnDelete}
      value={preparedValue}
      placeholder='Tip seç'
      className='project-log-type-filter__multiple-select'
    />
  )
}

export default ProjectLogTypeFilter
