import clsx from 'clsx'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'

import { getProjectPermissions, getProjectUserPermissions, updateProjectUserPermissions } from '@/api'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import { renderPermission } from '@/pages/ProjectUserPermissions/ProjectUserPermissionsDetail/ProjectUserPermissionsDetailPermissionList/utils'

import './projectUserPermissionsDetailPermissionList.scss'

const ProjectUserPermissionsDetailPermissionList = () => {
  const [projectPermissions, setProjectPermissions] = useState([])
  const [projectUserPermissions, setProjectUserPermissions] = useState({})
  const [isUpdatingSlug, setIsUpdatingSlug] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams] = useSearchParams()
  const projectUserId = searchParams.get('projectUserId')

  const handleGetAllProjectPermissions = async () => {
    try {
      const res = await getProjectPermissions()
      setProjectPermissions(res.data.permissions)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    }
  }

  const handleGetProjectUserPermissions = async () => {
    try {
      const res = await getProjectUserPermissions(projectUserId)
      setProjectUserPermissions(res.data.permissions)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    }
  }

  const handleUpdateProjectUserPermissions = async newProjectUserPermissions => {
    try {
      await updateProjectUserPermissions({ projectUserId, permissions: newProjectUserPermissions })
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    }
  }

  const handleChangePermission = async updatedPermission => {
    try {
      const permissionSlug = Object.keys(updatedPermission)[0]
      setIsUpdatingSlug(permissionSlug)

      const newProjectUserPermissions = { ...projectUserPermissions, ...updatedPermission }
      await handleUpdateProjectUserPermissions(newProjectUserPermissions)

      setProjectUserPermissions(newProjectUserPermissions)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsUpdatingSlug(null)
    }
  }

  const handleGetRequests = async () => {
    try {
      setIsLoading(true)

      await Promise.all([handleGetAllProjectPermissions(), handleGetProjectUserPermissions()])
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetRequests()
  }, [])

  const allClassNames = clsx('project-user-permission-list', {
    'project-user-permission-list--is-admin': projectUserPermissions[PROJECT_PERMISSIONS.ADMIN] === true,
  })

  return (
    <div className={allClassNames}>
      {isLoading ? (
        <Skeleton className='project-user-permission-list__loading-item' />
      ) : (
        projectPermissions.map(permission =>
          renderPermission({ permission, projectUserPermissions, handleChangePermission, isUpdatingSlug }),
        )
      )}
    </div>
  )
}

export default ProjectUserPermissionsDetailPermissionList
