import clsx from 'clsx'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { IoUnlinkOutline } from 'react-icons/io5'
import { IoSend } from 'react-icons/io5'
import { LuGroup } from 'react-icons/lu'
import { Link, useParams } from 'react-router-dom'

import { postSendApi } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import usePermission from '@/hooks/usePermission'
import ApiMoreInfoPopup from '@/pages/ApiExecute/ApiExecuteHeader/ApiMoreInfoPopup/ApiMoreInfoPopup'
import { isMobile } from '@/utils'
import { getFieldValues } from '@/utils/dynamicFields'

import './apiExecuteHeader.scss'

const ApiExecuteHeader = ({ apiDetail, fieldValues, handleAddApiLog, handleAfterExecuteApi }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isVisibleMoreInfoPopup, setIsVisibleMoreInfoPopup] = useState(false)
  const [isVisibleConfirmationPopup, setIsVisibleConfirmationPopup] = useState(false)

  const { id: apiId } = useParams()
  const { isProjectAdmin } = usePermission()

  const apiConfigConfirmation = apiDetail.configs?.confirmation
  const hasConfirmationPopup = !!apiConfigConfirmation

  const handleCloseMoreInfoPopup = () => {
    setIsVisibleMoreInfoPopup(false)
  }

  const handleClickMoreInfoButton = () => {
    setIsVisibleMoreInfoPopup(true)
  }

  const handleCloseConfirmationPopup = () => {
    setIsVisibleConfirmationPopup(false)
  }

  const handleExecuteApi = async () => {
    try {
      setIsLoading(true)

      const bodyFields = getFieldValues(fieldValues, apiDetail.bodyFields)
      const urlFields = getFieldValues(fieldValues, apiDetail.urlFields)

      const response = await postSendApi({
        projectApiId: apiId,
        urlFields,
        bodyFields,
      })

      const apiLog = response.data.apiLog
      handleAddApiLog(apiLog)

      handleAfterExecuteApi()

      toast.success('İşlem başarıyla gerçekleşti')
    } catch (err) {
      const apiLog = err?.response?.data?.message?.apiLog
      if (apiLog) {
        handleAddApiLog(apiLog)
        toast.error('Aksiyon sırasında bir hata gerçekleşti')
        return
      }

      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  const handleOnClickApiExecuteButton = async () => {
    // We need to show confirmation popup if it has confirmation popup
    if (hasConfirmationPopup) {
      setIsVisibleConfirmationPopup(true)
      return
    }

    handleExecuteApi()
  }

  const handleClickContinueYesButton = () => {
    handleExecuteApi()
    setIsVisibleConfirmationPopup(false)
  }

  const ITEM_LIST = [
    {
      text: apiDetail.group?.name,
      Icon: LuGroup,
      isDisable: isMobile(),
    },
    {
      text: apiDetail.title,
      Icon: IoUnlinkOutline,
      isDisable: isMobile(),
    },
  ]

  return (
    <div className='api-execute-header'>
      {hasConfirmationPopup && (
        <ContinueAskPopup
          isDisableCloseButton={true}
          isCenteredTitle={true}
          title={apiConfigConfirmation.title}
          description={apiConfigConfirmation.description}
          isVisible={isVisibleConfirmationPopup}
          handleClosePopup={handleCloseConfirmationPopup}
          noButtonProps={{ onClick: handleCloseConfirmationPopup }}
          yestButtonProps={{ onClick: handleClickContinueYesButton }}
        />
      )}

      <ApiMoreInfoPopup isVisible={isVisibleMoreInfoPopup} handleClosePopup={handleCloseMoreInfoPopup} apiDetail={apiDetail} />
      <div className='api-execute-header__item-list'>
        {ITEM_LIST.map(({ text, Icon, isDisable }, key) => (
          <div key={key} className={clsx('api-execute-header__item', { 'api-execute-header__item--disable': isDisable })}>
            {<Icon className='api-execute-header__item-icon' />}
            <div title={text} className='api-execute-header__item-text'>
              {text}
            </div>
          </div>
        ))}
        <div onClick={handleClickMoreInfoButton} className='api-execute-header__other-item api-execute-header__other-item--info-btn'>
          tüm bilgileri gör
        </div>
        {isProjectAdmin && !isMobile() && (
          <Link to={`/build-api?id=${apiId}`} onClick={handleClickMoreInfoButton} className='api-execute-header__other-item'>
            düzenle
          </Link>
        )}
      </div>
      <PrimaryButton isLoading={isLoading} onClick={handleOnClickApiExecuteButton} className='api-execute-header__execute-button'>
        Uygula
        <IoSend className='api-execute-header__execute-button-icon' />
      </PrimaryButton>
    </div>
  )
}

export default ApiExecuteHeader
