import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { IoChevronBack } from 'react-icons/io5'

import { TEMPLATE_SIZES } from '@/constants/template'

import './pagePagination.scss'

const MAX_PAGINATION_NUMBERS_LENGTH = 5
const MAX_PAGINATION_NUMBERS_LENGTH_AFTER_CURRENT_PAGE = 2

const PagePagination = ({ total, rangePageSize = 10, currentPage: _currentPage = 1, onChange, size = TEMPLATE_SIZES.MD }) => {
  const [currentPage, setCurrentPage] = useState(_currentPage)

  const allPaginationNumbers = Array(Math.ceil(total / rangePageSize))
    .fill(0)
    .map((_, index) => index + 1)
  const lastPageNumber = allPaginationNumbers.length
  const isSelectedFirstPageNumber = currentPage === 1
  const isSelectedLastPageNumber = currentPage === lastPageNumber
  const hasPaginations = allPaginationNumbers.length > 0
  const endIndex = (() => {
    const totalTempIndex = currentPage + MAX_PAGINATION_NUMBERS_LENGTH_AFTER_CURRENT_PAGE
    const currentEndIndex = totalTempIndex >= lastPageNumber ? lastPageNumber : totalTempIndex

    switch (true) {
      case currentEndIndex <= MAX_PAGINATION_NUMBERS_LENGTH:
        return MAX_PAGINATION_NUMBERS_LENGTH
      case currentEndIndex >= lastPageNumber:
        return lastPageNumber
      default:
        return currentEndIndex
    }
  })()
  const slicedAllPaginationNumbers = allPaginationNumbers.slice(endIndex - MAX_PAGINATION_NUMBERS_LENGTH, endIndex)

  const checkIsSelected = page => page === currentPage

  const handleClickPaginationNumber = page => () => {
    setCurrentPage(page)
  }

  const handleOnClickBackPagination = () => {
    setCurrentPage(currentPage - 1)
  }

  const handleOnClickNextPagination = () => {
    setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    setCurrentPage(_currentPage)
  }, [_currentPage])

  useEffect(() => {
    onChange && onChange(currentPage)
  }, [currentPage])

  if (!hasPaginations) return null
  return (
    <div className={clsx('page-pagination', `page-pagination__size-${size}`)}>
      <div className='page-pagination__list'>
        <div
          onClick={handleOnClickBackPagination}
          className={clsx('page-pagination__item page-pagination__item--icon', {
            'page-pagination__item--disabled': isSelectedFirstPageNumber,
          })}>
          <IoChevronBack />
        </div>
        {slicedAllPaginationNumbers.map(number => (
          <div
            key={number}
            className={clsx('page-pagination__item page-pagination__item--number', {
              'page-pagination__item--selected': checkIsSelected(number),
            })}
            onClick={handleClickPaginationNumber(number)}>
            {number}
          </div>
        ))}
        <div
          onClick={handleOnClickNextPagination}
          className={clsx('page-pagination__item page-pagination__item--icon page-pagination__item--rotate', {
            'page-pagination__item--disabled': isSelectedLastPageNumber,
          })}>
          <IoChevronBack />
        </div>
      </div>
    </div>
  )
}

export default PagePagination
