import { PROJECT_LOG_TYPES } from '@/constants'

const getTagText = logType => {
  switch (logType) {
    case PROJECT_LOG_TYPES.CREATED_PROJECT_USER:
      return 'Kullanıcı Ekleme'
    case PROJECT_LOG_TYPES.DELETED_PROJECT_USER:
      return 'Kullanıcı Silme'
    case PROJECT_LOG_TYPES.LEAVE_USER_FROM_PROJECT:
      return 'Projeden Ayrılma'
    case PROJECT_LOG_TYPES.CREATED_PROJECT_API_PERMISSION:
      return 'Aksiyon İzin Ekleme'
    case PROJECT_LOG_TYPES.CREATED_API:
      return 'Aksiyon Ekleme'
    case PROJECT_LOG_TYPES.CREATED_API_COLLECTION:
      return 'Aksiyon Dışa Aktarma'
    case PROJECT_LOG_TYPES.EXECUTED_API_COLLECTION:
      return 'Aksiyon İçe Aktarma'
    case PROJECT_LOG_TYPES.DELETED_API:
      return 'Aksiyon Silme'
    case PROJECT_LOG_TYPES.CREATED_CHART:
      return 'Grafik Ekleme'
    case PROJECT_LOG_TYPES.CREATED_TABLE:
      return 'Tablo Ekleme'
    case PROJECT_LOG_TYPES.DELETED_CHART:
      return 'Grafik Silme'
    case PROJECT_LOG_TYPES.DELETED_PROJECT_API_PERMISSION:
      return 'Aksiyon İzin Silme'
    case PROJECT_LOG_TYPES.DELETED_TABLE:
      return 'Tablo Silme'
    case PROJECT_LOG_TYPES.UPDATED_PROJECT_API_PERMISSION:
      return 'Aksiyon İzin Güncelleme'
    case PROJECT_LOG_TYPES.UPDATED_PROJECT:
      return 'Proje Güncelleme'
    case PROJECT_LOG_TYPES.UPDATED_CHART:
      return 'Grafik Güncelleme'
    case PROJECT_LOG_TYPES.UPDATED_TABLE:
      return 'Tablo Güncelleme'
    case PROJECT_LOG_TYPES.EXECUTED_API:
      return 'Aksiyon İşleme'
    case PROJECT_LOG_TYPES.USER_LOGGED:
      return 'Kullanıcı Girişi'
    default:
      return 'Genel'
  }
}

export { getTagText }
