import clsx from 'clsx'

import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'

import './inavoLoading.scss'

const InavoLoading = ({ className }) => {
  const allClassNames = clsx('inavo-loading', className)

  return <img src={CircleBlackLogo} className={allClassNames} />
}

export default InavoLoading
