import { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { getApiDetail, getProjectApiGroups } from '@/api'
import InavoLoading from '@/components/InavoLoading/InavoLoading'
import { FIELD_SCOPE_TYPES } from '@/constants'
import BuildApiContent from '@/containers/BuildApi/BuildApiContent/BuildApiContent'
import BuildApiTop from '@/containers/BuildApi/BuildApiTop/BuildApiTop'
import BuildApiSettingsPopup from '@/containers/BuildApi/components/BuildApiSettingsPopup/BuildApiSettingsPopup'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildApi.scss'

const BuildApi = () => {
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const isLoadedData = useRef()

  const { api, isDirty } = useSelector(state => {
    const { api, isDirty } = state.buildApi
    return { api, isDirty }
  })
  const apiId = searchParams.get('id')
  const isEdit = !!apiId

  const handleSetApiDetail = apiDetail => {
    const {
      apiGroupId,
      configs,
      defaultBody,
      defaultUrlQueryParams,
      description,
      header,
      method,
      title,
      url,
      otherFields,
      bodyFields,
      urlFields,
    } = apiDetail

    const preparedBodyFields = bodyFields.map(field => ({ ...field, fieldScopeType: FIELD_SCOPE_TYPES.BODY }))
    const preparedUrlFields = urlFields.map(field => ({ ...field, fieldScopeType: FIELD_SCOPE_TYPES.URL }))

    const fields = [...preparedBodyFields, ...preparedUrlFields]
      .map(field => ({ ...field, id: uuid() }))
      .sort((a, b) => a?.properties?.orderIndex - b?.properties?.orderIndex)

    dispatch(
      buildApiReducerActions.setApi({
        apiGroupId,
        configs,
        defaultBody,
        defaultUrlQueryParams,
        description,
        header,
        method,
        title,
        url,
        otherFields,
        fields,
      }),
    )
  }

  const handleSetData = async () => {
    try {
      const projectApiGroups = await getProjectApiGroups()
      dispatch(buildApiReducerActions.setProjectApiGroups(projectApiGroups.data.groups))

      if (isEdit) {
        const apiDetailRes = await getApiDetail(apiId)
        handleSetApiDetail(apiDetailRes.data.apiDetail)
      } else {
        dispatch(buildApiReducerActions.setInitialApi())
      }

      setTimeout(() => {
        isLoadedData.current = true
      }, 1000)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetInitialState = () => {
    dispatch(buildApiReducerActions.setInitialState())
  }

  useEffect(() => {
    handleSetData()
    handleSetInitialState()
  }, [])

  useEffect(() => {
    if (!isDirty) return

    const handleBeforeUnload = event => event.preventDefault()
    window.addEventListener('beforeunload', handleBeforeUnload)

    window.history.pushState(null, document.title, window.location.href)
    const handlePopState = event => {
      event.preventDefault()
      window.history.pushState(null, document.title, window.location.href)
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [isDirty])

  useEffect(() => {
    if (isLoadedData.current) {
      dispatch(buildApiReducerActions.setIsDirty(true))
    }
  }, [api])

  const getContent = () => {
    switch (true) {
      case isLoading:
        return (
          <div className='build-api__inavo-loading-wrapper'>
            <InavoLoading className='build-api__inavo-loading' />
          </div>
        )
      default:
        return (
          <div className='build-api'>
            {isEdit && <BuildApiSettingsPopup />}
            <div className='build-api__top'>
              <BuildApiTop />
            </div>
            <div className='build-api__content'>
              <BuildApiContent />
            </div>
          </div>
        )
    }
  }

  return getContent()
}

export default BuildApi
