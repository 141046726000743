import clsx from 'clsx'

import './textarea.scss'

const Textarea = ({ className, ...otherProps }) => {
  const allClassNames = clsx('textarea-component', className)

  return <textarea className={allClassNames} {...otherProps} />
}

export default Textarea
