import { useState } from 'react'
import toast from 'react-hot-toast'

import { postProjectUser } from '@/api'
import Input from '@/components/Input/Input'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { isValidEmail } from '@/utils'

import './addUserPopup.scss'

const AddUserPopup = ({ isVisible, handleClose, afterHandleProjectUser }) => {
  const [projectUserEmail, setProjectUserEmail] = useState('')
  const [isAdding, setIsAdding] = useState(false)

  const handleChangeProjectUserEmail = e => {
    setProjectUserEmail(e.target.value)
  }

  const handlePostProjectUser = async () => {
    const isValidProjectUserEmail = isValidEmail(projectUserEmail)
    if (!isValidProjectUserEmail) {
      toast.error('Geçerli bir email giriniz')
      return
    }

    try {
      setIsAdding(true)

      await postProjectUser({ email: projectUserEmail })

      afterHandleProjectUser()

      setProjectUserEmail('')
      handleClose()

      toast.success('Kullanıcı başarıyla eklendi')
    } catch (err) {
      const errorStatusCode = err?.response?.status

      if (errorStatusCode === 400) {
        toast.error('Bu kullanıcı zaten ekli')
        return
      }

      if (errorStatusCode === 422) {
        toast.error('Geçerli bir email giriniz')
        return
      }

      toast.error('Bir hata oluştu')
    } finally {
      setIsAdding(false)
    }
  }

  return (
    <Popup title='Kullanıcı Ekle' isVisible={isVisible} handleClosePopup={handleClose}>
      <div className='add-user-popup'>
        <Input
          value={projectUserEmail}
          onChange={handleChangeProjectUserEmail}
          className='add-user-popup__email'
          placeholder='Kullanıcı E-mail'
        />
        <PrimaryButton isLoading={isAdding} onClick={handlePostProjectUser} className='add-user-popup__button'>
          Ekle
        </PrimaryButton>
      </div>
    </Popup>
  )
}

export default AddUserPopup
