import dayjs from 'dayjs'
import { BsFillHeartFill } from 'react-icons/bs'

import './copyright.scss'

const Copyright = () => {
  const currentYear = dayjs().format('YYYY')

  return (
    <div className='copyright'>
      <span className='copyright__text'>Türkiye&#39;de </span>
      <BsFillHeartFill className='copyright__heart' />
      <span className='copyright__text'>ile geliştirildi © {currentYear}</span>
    </div>
  )
}

export default Copyright
