import { v1Api } from '@/api/apiInstance'
import { getActiveProjectId } from '@/utils/localStorageActions'

export const postLogin = async params => {
  const { data } = await v1Api.post('/users/login', params)
  return data
}

export const postRegister = async params => {
  const { data } = await v1Api.post('/users/register', params)
  return data
}

export const postLogout = async () => {
  await v1Api.post('/users/logout')
}

export const getMe = async () => {
  const { data } = await v1Api.get('/users/me')
  return data
}

export const getMeProjectPermissions = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/users/me/projects/${projectId}/permissions`)
  return data
}

export const updateMe = async params => {
  const { data } = await v1Api.put('/users/me/settings', params, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return data
}

export const updatePassword = async params => {
  const { data } = await v1Api.put('/users/me/password', params)
  return data
}

export const postForgetPassword = async params => {
  await v1Api.post('/users/forget-password', params)
}

export const postChangePassword = async params => {
  await v1Api.post('/users/change-password', params)
}

export const getAllProjects = async () => {
  const { data } = await v1Api.get('/projects')
  return data
}

export const getProjectDetail = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}`)
  return data
}

export const updateProject = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}`, params, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return data
}

export const postProject = async params => {
  const { data } = await v1Api.post('/projects', params)
  return data
}

export const deleteProject = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}`)
  return data
}

export const getProjectApis = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/apis`)
  return data
}

export const postProjectApiCollection = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/api-collections/generate-file`, params)
  return data
}

export const postProjectApiCollectionExecute = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/api-collections/execute-file`, params, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return data
}

export const getProjectUsers = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/users`, {
    params,
  })
  return data
}

export const postProjectUser = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/users`, params)
  return data
}

export const deleteProjectUser = async projectUserId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}/users/${projectUserId}`)
  return data
}

export const leaveProjectUser = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/users/leave`)
  return data
}

export const getProjectUserImprint = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/users/imprint`)
  return data
}

export const getProjectApiPermissions = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/api-permissions`)
  return data
}

export const getProjectApiPermissionDetail = async apiPermissionGroupId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/api-permissions/${apiPermissionGroupId}`)
  return data
}

export const postProjectApiPermission = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/api-permissions`, params)
  return data
}

export const deleteProjectApiPermission = async apiPermissionGroupId => {
  const projectId = getActiveProjectId()

  await v1Api.delete(`/projects/${projectId}/api-permissions/${apiPermissionGroupId}`)
}

export const updateProjectApiPermission = async (apiPermissionGroupId, params) => {
  const projectId = getActiveProjectId()

  await v1Api.put(`/projects/${projectId}/api-permissions/${apiPermissionGroupId}`, params)
}

export const getValues = async params => {
  const { data } = await v1Api.post('/send-api/get-values', params)

  return data
}

export const getApiDetail = async apiId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/apis/${apiId}`)
  return data
}

export const postSendApi = async params => {
  const { data } = await v1Api.post('/send-api', params)
  return data
}

export const getApiLogs = async (apiId, params) => {
  const { data } = await v1Api.get(`/apis/${apiId}/logs`, {
    params,
  })
  return data
}

export const getProjectLogs = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/logs`, {
    params,
  })
  return data
}

export const getData = async params => {
  const { data } = await v1Api.post('/data/get-data', params)
  return data
}

export const getCharts = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/charts`)
  return data
}

export const getChartDetail = async chartId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/charts/${chartId}`)
  return data
}

export const postChart = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/charts`, params)
  return data
}

export const deleteChart = async chartId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}/charts/${chartId}`)
  return data
}

export const updateChart = async (chartId, params) => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/charts/${chartId}`, params)
  return data
}

export const getProjectPermissions = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/permissions`)
  return data
}

export const getProjectUserPermissions = async projectUserId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/users/${projectUserId}/permissions`)
  return data
}

export const updateProjectUserPermissions = async ({ projectUserId, permissions }) => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/users/${projectUserId}/permissions`, { permissions })
  return data
}

export const getProjectWidgets = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/widgets`)
  return data
}

export const updateProjectWidgets = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/widgets`, params)
  return data
}

export const getProjectWidgetItems = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/widget/items`)
  return data
}

export const postDeleteAccount = async () => {
  const { data } = await v1Api.post('/users/delete-account')
  return data
}

export const getProjectApiGroups = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/api-groups`)
  return data
}

export const postProjectApiGroup = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/api-groups`, params)
  return data
}

export const deleteProjectApiGroup = async projectApiGroupId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}/api-groups/${projectApiGroupId}`)
  return data
}

export const updateProjectApiGroup = async (projectApiGroupId, params) => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/api-groups/${projectApiGroupId}`, params)
  return data
}

export const postProjectApi = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/apis`, params)
  return data
}

export const updateProjectApi = async (apiId, params) => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/apis/${apiId}`, params)
  return data
}

export const deleteProjectApi = async apiId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}/apis/${apiId}`)
  return data
}

export const postPostmanApis = async params => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/postman`, params)
  return data
}

export const getProjectTables = async () => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/tables`)
  return data
}

export const getProjectTableDetail = async tableId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.get(`/projects/${projectId}/tables/${tableId}`)
  return data
}

export const deleteProjectTable = async tableId => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.delete(`/projects/${projectId}/tables/${tableId}`)
  return data
}

export const postProjectTable = async payload => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.post(`/projects/${projectId}/tables`, payload)
  return data
}

export const updateProjectTable = async (tableId, payload) => {
  const projectId = getActiveProjectId()

  const { data } = await v1Api.put(`/projects/${projectId}/tables/${tableId}`, payload)
  return data
}
