import clsx from 'clsx'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiUserCircle } from 'react-icons/bi'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'

import { getApiLogs } from '@/api'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import { API_STATUSES } from '@/constants'
import ApiLogDetailPopup from '@/pages/ApiExecute/ApiExecuteLogs/ApiLogDetailPopup/ApiLogDetailPopup'

import './apiExecuteLogs.scss'

const API_LOG_LIMIT = 25

const checkIsSuccessLog = logStatus => logStatus === API_STATUSES.SUCCESS
const getLogStatusText = logStatus => (checkIsSuccessLog(logStatus) ? 'başarılı' : 'hata')
const getLogDesc = logStatus => (checkIsSuccessLog(logStatus) ? 'İşlem başarıyla gerçekleşti' : 'Bir hata oluştu')
const getLogDate = logDate => dayjs(logDate).format('HH:mm, DD MMM YYYY')

const ApiExecuteLogs = ({ apiLogs, freshApiLogId, setApiLogs }) => {
  const [isLoadingApiLogs, setIsLoadingApiLogs] = useState(false)
  const [isLoadingMoreApiLogs, setIsLoadingMoreApiLogs] = useState(false)
  const [isEnabledMoreButton, setIsEnabledMoreButton] = useState(false)
  const [selectedApiLogIdForDetail, setSelectedApiLogIdForDetail] = useState(null)
  const [isVisibleLogDetail, setIsVisibleLogDetail] = useState(false)

  const { id } = useParams()
  const hasApiLogs = apiLogs.length > 0

  const handleCloseApiLogDetailPopup = () => {
    setIsVisibleLogDetail(false)
    setTimeout(() => {
      setSelectedApiLogIdForDetail(null)
    }, 100)
  }

  const handleGetApiLogs = async (payload = {}) => {
    const { params } = payload

    const apiLogsRes = await getApiLogs(id, params)
    const apiLogsData = apiLogsRes.data.apiLogs

    setIsEnabledMoreButton(apiLogsData.length === API_LOG_LIMIT)
    return apiLogsData
  }

  const handleGetFirstRequest = async () => {
    try {
      setIsLoadingApiLogs(true)

      const apiLogsData = await handleGetApiLogs()
      setApiLogs([...apiLogsData])
    } catch (err) {
      toast.error('Aksiyon hareketleri çekilirken bir hata oluştu')
    } finally {
      setIsLoadingApiLogs(false)
    }
  }

  useEffect(() => {
    handleGetFirstRequest()
  }, [])

  const handleClickMoreButton = async () => {
    try {
      setIsLoadingMoreApiLogs(true)

      const lastApiLogId = apiLogs[apiLogs.length - 1]?.id
      const apiLogsData = await handleGetApiLogs({ params: { lastApiLogId } })
      setApiLogs([...apiLogs, ...apiLogsData])
    } catch (err) {
      toast.error('Aksiyon hareketleri çekilirken bir hata oluştu')
    } finally {
      setIsLoadingMoreApiLogs(false)
    }
  }

  const handleOnClickMoreInfoDetail = apiId => () => {
    setIsVisibleLogDetail(true)
    setSelectedApiLogIdForDetail(apiId)
  }

  return (
    <div className='api-execute-logs'>
      <ApiLogDetailPopup
        isVisible={isVisibleLogDetail}
        selectedApiLogIdForDetail={selectedApiLogIdForDetail}
        handleClosePopup={handleCloseApiLogDetailPopup}
        apiLogs={apiLogs}
      />
      <div className='api-execute-logs__list'>
        {isLoadingApiLogs && <Skeleton className='api-execute-logs__loading-item' count={7} />}
        {!isLoadingApiLogs && !hasApiLogs && <div className='api-execute-logs__no-logs'>Hiç aksiyon geçmişi yok</div>}
        {!isLoadingApiLogs &&
          hasApiLogs &&
          apiLogs.map(log => (
            <div
              key={`api_log_${log.id}`}
              className={clsx('api-execute-logs__item', {
                'api-execute-logs__item--success-fresh': freshApiLogId === log.id && checkIsSuccessLog(log.status),
                'api-execute-logs__item--error-fresh': freshApiLogId === log.id && !checkIsSuccessLog(log.status),
              })}>
              <div className='api-execute-logs__item-top'>
                <div className='api-execute-logs__user'>
                  {log.user.image ? (
                    <img src={log.user.image} className='api-execute-logs__user-image' />
                  ) : (
                    <BiUserCircle className='api-execute-logs__user-image' />
                  )}
                  <div className='api-execute-logs__user-username'>{log.user.name}</div>
                </div>
                <div
                  className={clsx('api-execute-logs__status', {
                    'api-execute-logs__status--success': checkIsSuccessLog(log.status),
                    'api-execute-logs__status--error': !checkIsSuccessLog(log.status),
                  })}>
                  {getLogStatusText(log.status)}
                </div>
              </div>
              <div className='api-execute-logs__content'>{getLogDesc(log.status)}</div>
              <div className='api-execute-logs__item-bottom'>
                <div className='api-execute-logs__date'>{getLogDate(log.date)}</div>
                <div onClick={handleOnClickMoreInfoDetail(log.id)} className='api-execute-logs__more-info-button'>
                  detay gör
                </div>
              </div>
            </div>
          ))}
        {isEnabledMoreButton && (
          <div className='api-execute-logs__more-button-wrapper'>
            <OutlinedButton isLoading={isLoadingMoreApiLogs} onClick={handleClickMoreButton} className='api-execute-logs__more-button'>
              daha fazla
            </OutlinedButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default ApiExecuteLogs
