import clsx from 'clsx'

import JsonEditor from '@/components/JsonEditor/JsonEditor'

import './jsonEditorWithLabel.scss'

const JsonEditorWithLabel = ({ label, jsonEditorProps, className }) => {
  const allClassNames = clsx('json-editor-with-label', className)

  return (
    <div className={allClassNames}>
      <div className='json-editor-with-label__text'>{label}</div>
      <JsonEditor placeholder={label} {...jsonEditorProps} />
    </div>
  )
}

export default JsonEditorWithLabel
