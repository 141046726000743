import { useState } from 'react'
import toast from 'react-hot-toast'

import { deleteProjectApiPermission } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'

import './actionGroupPermissionDeletePopup.scss'

const ActionGroupPermissionDeletePopup = ({ isVisible, handleClosePopup, actionGroupPermissionId, handleAfterDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    try {
      setIsDeleting(true)

      await deleteProjectApiPermission(actionGroupPermissionId)

      handleAfterDelete()

      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <ContinueAskPopup
      title='Silmek istediğine emin misin?'
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}
      noButtonProps={{ onClick: handleClosePopup }}
      yestButtonProps={{ isLoading: isDeleting, onClick: handleDelete }}
    />
  )
}

export default ActionGroupPermissionDeletePopup
