import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Area from '@/assets/icons/chart_types/area.svg'
import Donut from '@/assets/icons/chart_types/donut.svg'
import HorizontalBar from '@/assets/icons/chart_types/horizontal_bar.svg'
import SimpleValue from '@/assets/icons/chart_types/simple_value.png'
import VerticalBar from '@/assets/icons/chart_types/vertical_bar.svg'
import { CHART_TYPES } from '@/constants'
import ChartTypeInfoPopup from '@/pages/Charts/ChartFormPopup/ChartTypeSelect/ChartTypeInfoPopup/ChartTypeInfoPopup'

import './chartTypeSelect.scss'

const ALL_CHART_TYPES = {
  ...CHART_TYPES,
  VERTICAL_BAR: 'vertical_bar',
  HORIZONTAL_BAR: 'horizontal_bar',
}

const getMutatedChartType = ({ chartType, chartProperties }) => {
  switch (chartType) {
    case ALL_CHART_TYPES.BAR:
      return chartProperties?.isHorizontal ? ALL_CHART_TYPES.HORIZONTAL_BAR : ALL_CHART_TYPES.VERTICAL_BAR
    default:
      return chartType
  }
}
const getRealChartType = chartType => {
  switch (chartType) {
    case ALL_CHART_TYPES.VERTICAL_BAR:
    case ALL_CHART_TYPES.HORIZONTAL_BAR:
      return ALL_CHART_TYPES.BAR
    default:
      return chartType
  }
}

const getChartProperties = _mutatedChartType => {
  switch (_mutatedChartType) {
    case ALL_CHART_TYPES.HORIZONTAL_BAR:
      return {
        isHorizontal: true,
      }
    default:
      return {}
  }
}

const chartTypeOptions = [
  { type: ALL_CHART_TYPES.AREA, image: Area },
  { type: ALL_CHART_TYPES.DONUT, image: Donut },
  { type: ALL_CHART_TYPES.HORIZONTAL_BAR, image: HorizontalBar },
  { type: ALL_CHART_TYPES.VERTICAL_BAR, image: VerticalBar },
  { type: ALL_CHART_TYPES.SIMPLE_VALUE, image: SimpleValue },
]

const ChartTypeSelect = ({ value, chartProperties, handleUpdateChartDetail }) => {
  const [mutatedChartType, setMutatedChartType] = useState(getMutatedChartType({ chartType: value, chartProperties }))
  const [isEnabledChartTypeInfoPopup, setIsEnabledChartTypeInfoPopup] = useState(false)

  const isSelectedValue = !!value

  const checkIsSelectedType = chartType => chartType === mutatedChartType

  const handleClickTypeItem = _mutatedChartType => () => {
    const realChartType = getRealChartType(_mutatedChartType)
    handleUpdateChartDetail({ type: realChartType })

    const properties = getChartProperties(_mutatedChartType)
    handleUpdateChartDetail({ properties })

    setMutatedChartType(_mutatedChartType)
  }

  const handleOnClickChartInfoButton = () => {
    setIsEnabledChartTypeInfoPopup(true)
  }

  const handleCloseChartInfoPopup = () => {
    setIsEnabledChartTypeInfoPopup(false)
  }

  useEffect(() => {
    setMutatedChartType(
      getMutatedChartType({
        chartType: value,
        chartProperties,
      }),
    )
  }, [value])

  return (
    <div className='chart-type-select'>
      <div className='chart-type-select__list'>
        {chartTypeOptions.map(({ image, type }, index) => (
          <div
            key={index}
            onClick={handleClickTypeItem(type)}
            className={clsx('chart-type-select__item', { 'chart-type-select__item--selected': checkIsSelectedType(type) })}>
            <img className='chart-type-select__item-icon' src={image} />
          </div>
        ))}
      </div>
      <div className='chart-type-select__actions'>
        {isSelectedValue && (
          <>
            <ChartTypeInfoPopup isVisible={isEnabledChartTypeInfoPopup} handleClosePopup={handleCloseChartInfoPopup} value={value} />
            <div onClick={handleOnClickChartInfoButton} className='chart-type-select__doc-btn'>
              örnek çıktı
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default ChartTypeSelect
