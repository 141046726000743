import clsx from 'clsx'
import { useState } from 'react'
import { AiOutlineGlobal } from 'react-icons/ai'
import { MdOutlineLocalLibrary } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import ManageActionDefaultVariables from '@/components/ManageActionDefaultVariables/ManageActionDefaultVariables'
import Step from '@/containers/PostmanBuilder/components/Step/Step'
import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'
import { postmanBuilderReducerActions } from '@/store/reducers/postmanBuilder'

import { getClearedConsoleDynamicPrefix } from '../../utils'
import './adjustDefaultVariables.scss'

const KEYS = {
  HEADER: 'header',
  BODY: 'body',
  QUERY_PARAMETER: 'query_parameter',
}
const VARIABLE_TYPES = {
  GLOBAL: 'global',
  LOCAL: 'local',
}

const mapperListItem = ([name, value]) => ({ name, value })

const reduceDefaultGlobalVariables =
  keys =>
  (acc, [key, value]) => {
    if (!keys.includes(key)) return acc

    return {
      ...acc,
      [key]: value,
    }
  }

const reduceDefaultLocalVariables =
  ({ globalKeys, defaultVariables }) =>
  (acc, [key, value]) => {
    const clearedText = getClearedConsoleDynamicPrefix(value)
    if (globalKeys.includes(clearedText)) return acc

    return {
      ...acc,
      [key]: defaultVariables[clearedText] || '',
    }
  }

const AdjustDefaultVariables = () => {
  const [globalDynamicHeaderKeys, setGlobalDynamicHeaderKeys] = useState([])
  const [globalDynamicBodyKeys, setGlobalDynamicBodyKeys] = useState([])

  const dispatch = useDispatch()

  const { defaultHeader, defaultBody, defaultUrlQueryParam, actions } = useSelector(state => state.postmanBuilder)

  const handleProcessDefaultVariables = () => {
    // Adjust local variables to each actions
    const newActions = Object.entries(actions).reduce((acc, [folderName, items]) => {
      const newItems = items.map(item => {
        const { api, ...otherPayload } = item

        const __dynamicDefaultBody = api?.__dynamicDefaultBody || {}
        const __dynamicDefaultHeaders = api?.__dynamicDefaultHeaders || {}

        const dynamicDefaultBody = Object.entries(__dynamicDefaultBody).reduce(
          reduceDefaultLocalVariables({
            globalKeys: globalDynamicBodyKeys,
            defaultVariables: defaultBody,
          }),
          {},
        )
        const dynamicDefaultHeaders = Object.entries(__dynamicDefaultHeaders).reduce(
          reduceDefaultLocalVariables({
            globalKeys: globalDynamicHeaderKeys,
            defaultVariables: defaultHeader,
          }),
          {},
        )

        return {
          ...otherPayload,
          api: {
            ...api,
            defaultBody: {
              ...api.defaultBody,
              ...dynamicDefaultBody,
            },
            header: {
              ...api.header,
              ...dynamicDefaultHeaders,
            },
          },
        }
      })

      return {
        [folderName]: newItems,
        ...acc,
      }
    }, {})
    dispatch(postmanBuilderReducerActions.setActions(newActions))

    // Adjust global variables
    const _defaultHeader = Object.entries(defaultHeader).reduce(reduceDefaultGlobalVariables(globalDynamicHeaderKeys), {})
    const _defaultBody = Object.entries(defaultBody).reduce(reduceDefaultGlobalVariables(globalDynamicBodyKeys), {})
    dispatch(
      postmanBuilderReducerActions.setGlobalVariables({
        defaultHeader: _defaultHeader,
        defaultBody: _defaultBody,
        defaultUrlQueryParam,
      }),
    )
  }

  const handleOnClickContinueButton = () => {
    handleProcessDefaultVariables()

    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.ASK_QUESTIONS))
  }

  const handleOnClickBackButton = () => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.MANAGE_ACTIONS))
  }

  const handleOnChange = ({ parentKey, name, value }) => {
    const newItem = { [name]: value }

    switch (parentKey) {
      case KEYS.HEADER: {
        const _apiDefaultHeader = {
          ...defaultHeader,
          ...newItem,
        }
        dispatch(postmanBuilderReducerActions.setDefaultHeader(_apiDefaultHeader))

        return
      }
      case KEYS.BODY: {
        const _apiDefaultBody = {
          ...defaultBody,
          ...newItem,
        }
        dispatch(postmanBuilderReducerActions.setDefaultBody(_apiDefaultBody))

        return
      }
      case KEYS.QUERY_PARAMETER: {
        const _apiDefaultUrlQueryParams = {
          ...defaultUrlQueryParam,
          ...newItem,
        }
        dispatch(postmanBuilderReducerActions.setDefaultUrlQueryParam(_apiDefaultUrlQueryParams))

        return
      }
    }
  }

  const getSetMethod = listKey => {
    switch (listKey) {
      case KEYS.BODY:
        return setGlobalDynamicBodyKeys
      case KEYS.HEADER:
        return setGlobalDynamicHeaderKeys
    }
  }

  const handleOnClickVariableType =
    ({ listKey, name }, type) =>
    () => {
      const setGlobalKeyMethod = getSetMethod(listKey)

      switch (type) {
        case VARIABLE_TYPES.GLOBAL:
          return setGlobalKeyMethod(prev => [...prev, name])
        case VARIABLE_TYPES.LOCAL:
          return setGlobalKeyMethod(prev => prev.filter(prevItem => prevItem !== name))
      }
    }

  const getGlobalDynamicKeys = listKey => {
    switch (listKey) {
      case KEYS.BODY:
        return globalDynamicBodyKeys
      case KEYS.HEADER:
        return globalDynamicHeaderKeys
    }
  }

  const renderCustomActionsContent = ({ name }, listKey) => {
    if (listKey === KEYS.QUERY_PARAMETER) return null

    const isGlobal = getGlobalDynamicKeys(listKey).includes(name)

    return (
      <div className='adjust-default-variables__variable-type-actions'>
        <div
          onClick={handleOnClickVariableType({ listKey, name }, VARIABLE_TYPES.LOCAL)}
          className={clsx('adjust-default-variables__variable-action', {
            'adjust-default-variables__variable-action--selected': !isGlobal,
          })}>
          <MdOutlineLocalLibrary size={22} />
        </div>
        <div
          onClick={handleOnClickVariableType({ listKey, name }, VARIABLE_TYPES.GLOBAL)}
          className={clsx('adjust-default-variables__variable-action', {
            'adjust-default-variables__variable-action--selected': isGlobal,
          })}>
          <AiOutlineGlobal size={22} />
        </div>
      </div>
    )
  }

  const list = [
    {
      key: KEYS.HEADER,
      title: 'Default Header',
      items: Object.entries(defaultHeader).map(mapperListItem),
    },
    {
      key: KEYS.BODY,
      title: 'Default Body',
      items: Object.entries(defaultBody).map(mapperListItem),
    },
    {
      key: KEYS.QUERY_PARAMETER,
      title: 'Default Url Query Params',
      items: Object.entries(defaultUrlQueryParam).map(mapperListItem),
    },
  ]

  return (
    <Step
      handleOnClickContinueButton={handleOnClickContinueButton}
      handleOnClickBackButton={handleOnClickBackButton}
      title='Varsayılan Değerleri Yönetme'>
      <ManageActionDefaultVariables list={list} handleOnChange={handleOnChange} renderCustomActionsContent={renderCustomActionsContent} />
    </Step>
  )
}

export default AdjustDefaultVariables
