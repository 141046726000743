import clsx from 'clsx'

import CommonToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch'

import './toggleSwitch.scss'

const ToggleSwitch = ({ className, ...otherProps }) => {
  const allClassNames = clsx('build-api-toggle-switch', className)

  return <CommonToggleSwitch className={allClassNames} {...otherProps} />
}

export default ToggleSwitch
