import PagePagination from '@/components/PagePagination/PagePagination'
import { TEMPLATE_SIZES } from '@/constants/template'

import './tablePagination.scss'

const TablePagination = ({ total, handleChangePagination, paginationRangeSize }) => {
  return <PagePagination total={total} onChange={handleChangePagination} rangePageSize={paginationRangeSize} size={TEMPLATE_SIZES.SM} />
}

export default TablePagination
