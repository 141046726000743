import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { MdModeEdit } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import { useSearchParams } from 'react-router-dom'

import { getCharts } from '@/api'
import DynamicChart from '@/components/DynamicChart/DynamicChart'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PagePagination from '@/components/PagePagination/PagePagination'
import PageTitle from '@/components/PageTitle/PageTitle'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import usePermission from '@/hooks/usePermission'
import ChartFormPopup, { CHART_ACTION_TYPES } from '@/pages/Charts/ChartFormPopup/ChartFormPopup'

import './charts.scss'

const INITIAL_PAGE = 1
const PAGE_QUERY_PARAMETER = 'page'
const PAGINATION_RANGE_PAGE_SIZE = 4

const Charts = () => {
  const [charts, setCharts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [editChartId, setEditChartId] = useState(null)
  const [isVisibleChartFormPopup, setIsVisibleChartFormPopup] = useState(false)
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)

  const { checkPermission } = usePermission()
  const [searchParams, setSearchParams] = useSearchParams()

  const slicedCharts = charts.slice((currentPage - 1) * PAGINATION_RANGE_PAGE_SIZE, currentPage * PAGINATION_RANGE_PAGE_SIZE)
  const hasCharts = charts.length > 0
  const isAllowedChartAction = checkPermission(PROJECT_PERMISSIONS.ACTIONS_FOR_CHARTS)

  const handleOnChangePagePagination = value => {
    setSearchParams({ [PAGE_QUERY_PARAMETER]: value })
    setCurrentPage(value)
  }

  const handleCloseChartFormPopup = () => {
    setIsVisibleChartFormPopup(false)
    setEditChartId(null)
  }

  const handleClickCreateChartButton = () => {
    setIsVisibleChartFormPopup(true)
  }

  const handleClearCurrentPage = () => {
    setSearchParams({ [PAGE_QUERY_PARAMETER]: INITIAL_PAGE })
    setCurrentPage(INITIAL_PAGE)
  }

  const handleAfterActionChart = payload => {
    const { type } = payload || {}

    if (type === CHART_ACTION_TYPES.CREATE) {
      handleClearCurrentPage()
    }

    handleGetCharts()
  }

  const handleGetCharts = async () => {
    try {
      setIsLoading(true)

      const res = await getCharts()
      setCharts(res.data.charts)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetInitialCurrentPage = () => {
    const initialCurrentPage = searchParams.get(PAGE_QUERY_PARAMETER)
    if (initialCurrentPage) setCurrentPage(parseInt(initialCurrentPage))
  }

  useEffect(() => {
    handleGetCharts()
    handleSetInitialCurrentPage()
  }, [])

  const renderChartCustomActionsContent = ({ id }) => {
    const handleClickEditChart = () => {
      setEditChartId(id)
      setIsVisibleChartFormPopup(true)
    }

    return <MdModeEdit onClick={handleClickEditChart} className='charts__edit-chart-btn' />
  }

  const getContent = () => {
    switch (true) {
      case !hasCharts:
        return <div className='charts__no-charts'>Hiç eklenmiş grafik yok</div>
      default:
        return slicedCharts.map(chart => (
          <div className='charts__item' key={chart.id}>
            <DynamicChart
              chart={chart}
              {...(isAllowedChartAction
                ? {
                    customActionsContent: renderChartCustomActionsContent(chart),
                  }
                : {})}
            />
          </div>
        ))
    }
  }

  return (
    <div className='charts'>
      {isAllowedChartAction && (
        <ChartFormPopup
          isVisible={isVisibleChartFormPopup}
          chartId={editChartId}
          handleClosePopup={handleCloseChartFormPopup}
          handleAfterActionChart={handleAfterActionChart}
        />
      )}
      <PageTitle>Grafikler</PageTitle>
      <div className='charts__top'>
        {isAllowedChartAction && (
          <OutlinedButton onClick={handleClickCreateChartButton} className='charts__create-chart-btn'>
            Grafik Oluştur
          </OutlinedButton>
        )}
      </div>
      {isLoading ? (
        <div className='charts__loading-items-list'>
          <Skeleton className='charts__loading-item' count={5} />
        </div>
      ) : (
        <div className='charts__list'>{getContent()}</div>
      )}
      <div className='charts__pagination'>
        <PagePagination
          total={charts.length}
          rangePageSize={PAGINATION_RANGE_PAGE_SIZE}
          currentPage={currentPage}
          onChange={handleOnChangePagePagination}
        />
      </div>
    </div>
  )
}

export default Charts
