import { Link } from 'react-router-dom'

import LogoBlack from '@/assets/logos/inavo.svg'

import './leftMenuLogo.scss'

const LeftMenuLogo = () => {
  return (
    <div className='left-menu-logo'>
      <Link to='/'>
        <img className='left-menu-logo__img' src={LogoBlack} />
      </Link>
    </div>
  )
}

export default LeftMenuLogo
