import clsx from 'clsx'
import { FaChartBar } from 'react-icons/fa'
import { LuTable } from 'react-icons/lu'

import { PROJECT_WIDGET_TYPES } from '@/constants'

import './homeDragAndDropItemList.scss'

const HomeDragAndDropItemList = props => {
  const { handleAddProjectWidget, widgetItems, widgets } = props

  const filterWidgetItem = widgetItem => {
    switch (widgetItem.widgetType) {
      case PROJECT_WIDGET_TYPES.CHART:
        return !widgets.find(widget => widget.type === PROJECT_WIDGET_TYPES.CHART && Number(widget.properties.id) === Number(widgetItem.id))
      case PROJECT_WIDGET_TYPES.TABLE:
        return !widgets.find(widget => widget.type === PROJECT_WIDGET_TYPES.TABLE && Number(widget.properties.id) === Number(widgetItem.id))
      default:
        return true
    }
  }
  const notUsedWidgetItems = widgetItems.filter(filterWidgetItem)

  const getWidgetAccordingToType = widgetItem => {
    const type = widgetItem.widgetType

    switch (type) {
      case PROJECT_WIDGET_TYPES.CHART:
        return {
          type,
          properties: {
            id: widgetItem.id,
          },
        }
      case PROJECT_WIDGET_TYPES.TABLE:
        return {
          type,
          properties: {
            id: widgetItem.id,
          },
        }
    }
  }
  const handleClickItem = widgetItem => () => {
    handleAddProjectWidget({
      layout: {},
      widget: getWidgetAccordingToType(widgetItem),
    })
  }

  const renderWidgetContent = widgetItem => {
    switch (widgetItem.widgetType) {
      case PROJECT_WIDGET_TYPES.CHART:
      case PROJECT_WIDGET_TYPES.TABLE:
        return widgetItem.label || <i>Bilinmeyen Widget</i>
      default:
        return ''
    }
  }

  const renderWidgetIcon = widgetItem => {
    switch (widgetItem.widgetType) {
      case PROJECT_WIDGET_TYPES.CHART:
        return <FaChartBar className='home-drag-and-drop-item-list__item-icon home-drag-and-drop-item-list__item-icon--chart' />
      case PROJECT_WIDGET_TYPES.TABLE:
        return <LuTable className='home-drag-and-drop-item-list__item-icon home-drag-and-drop-item-list__item-icon--table' />
      default:
        return ''
    }
  }

  const getWidgetItemClass = widgetType => {
    switch (widgetType) {
      case PROJECT_WIDGET_TYPES.CHART:
        return 'home-drag-and-drop-item-list__item--chart'
      case PROJECT_WIDGET_TYPES.TABLE:
        return 'home-drag-and-drop-item-list__item--table'
    }
  }
  const getWidgetItemClasses = widgetItem => clsx('home-drag-and-drop-item-list__item', getWidgetItemClass(widgetItem.widgetType))

  return (
    <div className='home-drag-and-drop-item-list__list'>
      {notUsedWidgetItems.length === 0 ? (
        <div className='home-drag-and-drop-item-list__no-widgets'>Eklenebilecek widget yok</div>
      ) : (
        notUsedWidgetItems.map((widgetItem, key) => (
          <div key={key} onClick={handleClickItem(widgetItem)} className={getWidgetItemClasses(widgetItem)}>
            {renderWidgetIcon(widgetItem)}
            {renderWidgetContent(widgetItem)}
          </div>
        ))
      )}
    </div>
  )
}

export default HomeDragAndDropItemList
