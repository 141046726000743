import { useEffect } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'

import './importApiSuccessfulStep.scss'

const ImportApiSuccessfulStep = () => {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTE_URLS.HOME)
      window.location.reload()
    }, 1000)
  }, [])

  return (
    <div className='import-successful'>
      <FaCheckCircle className='import-successful__icon' />
      <div className='import-successful__text'>İşlem başarıyla gerçekleşti. Anasayfaya yönlendiriliyorsunuz...</div>
    </div>
  )
}

export default ImportApiSuccessfulStep
