import { useSelector } from 'react-redux'

import MixTagifyInput from '@/components/MixTagifyInput/MixTagifyInput'

import './tableUrlInput.scss'

const TableUrlInput = ({ value, handleUpdateTableDetail }) => {
  const apiDefaultUrlQueryParams = useSelector(state => state.app.projectSettings.apiDefaultUrlQueryParams)

  // There are can be null default objects for old data
  const chartUrlWhiteList = Object.keys(apiDefaultUrlQueryParams || {}).map(queryParam => {
    const preparedKey = `:${queryParam}`

    return { value: preparedKey, label: preparedKey }
  })

  const handleOnChangeTabletUrl = val => handleUpdateTableDetail({ url: val.replace(/\s/g, '') })

  return <MixTagifyInput whiteList={chartUrlWhiteList} onChange={handleOnChangeTabletUrl} value={value} />
}

export default TableUrlInput
