import clsx from 'clsx'
import { useState } from 'react'

import './dropdown.scss'

const Dropdown = ({ children, items, handleClickItem, dropdownButtonClassName }) => {
  const [isVisibleDropdown, setIsVisibleDropdown] = useState(false)

  const onHandleClickItem = value => () => {
    handleClickItem(value)
  }

  const handleOnMouseEnter = () => {
    setIsVisibleDropdown(true)
  }

  const handleOnMouseLeave = () => {
    setIsVisibleDropdown(false)
  }

  const dropdownListAllClassNames = clsx('dropdown__list', { 'dropdown__list--enable': isVisibleDropdown })
  const dropdownButtonAllClassNames = clsx('dropdown__button', dropdownButtonClassName)

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className='dropdown'>
      <div className={dropdownButtonAllClassNames}>{children}</div>
      <div className={dropdownListAllClassNames}>
        {items.map(({ value, label, className }) => (
          <div key={value} className={`dropdown__item ${className ? className : ''}`} onClick={onHandleClickItem(value)}>
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Dropdown
