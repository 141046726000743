import { useSelector } from 'react-redux'

import NoProjects from '@/containers/Root/components/NoProjects/NoProjects'
import { appReducerSelectors } from '@/store/reducers/app'

const withMenuLogic =
  WrapperComponent =>
  // eslint-disable-next-line react/display-name
  props => {
    const hasProjects = useSelector(appReducerSelectors.checkHasProjectsSelector)

    const renderContent = content => {
      return hasProjects ? content : <NoProjects />
    }

    return <WrapperComponent renderContent={renderContent} {...props} />
  }

export default withMenuLogic
