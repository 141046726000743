import dayjs from 'dayjs'

import DatePicker from '@/components/DatePicker/DatePicker'

import './projectLogDateFilter.scss'

const LOG_DATE_FILTER_FORMAT = 'YYYY-MM-DD'
const convertFormat = date => dayjs(date).format(LOG_DATE_FILTER_FORMAT)

const ProjectLogDateFilter = ({ endDate, startDate, handleUpdateFilters }) => {
  const handleOnChange = payload => {
    const [_startDate, _endDate] = payload

    handleUpdateFilters({
      startDate: _startDate ? convertFormat(_startDate) : null,
      endDate: _endDate ? convertFormat(_endDate) : null,
    })
  }

  return (
    <DatePicker
      wrapperClassName='project-log-date-filter'
      className='project-log-date-filter'
      selectsRange={true}
      onChange={handleOnChange}
      isClearable={true}
      placeholderText='Tarih Seç'
      startDate={startDate ? new Date(startDate) : null}
      endDate={endDate ? new Date(endDate) : null}
    />
  )
}

export default ProjectLogDateFilter
