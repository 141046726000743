import { createSlice } from '@reduxjs/toolkit'

import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'

const postmanBuilderSlice = createSlice({
  name: 'postmanBuilder',
  initialState: {
    activeStep: POSTMAN_BUILDER_STEPS.DATA,
    actions: {},
    selectedActionIDs: [],
    defaultHeader: {},
    defaultBody: {},
    defaultUrlQueryParam: {},
    globalVariables: {
      defaultHeader: {},
      defaultBody: {},
      defaultUrlQueryParam: {},
    },
  },
  reducers: {
    updateActiveStep: (state, action) => {
      state.activeStep = action.payload
    },
    setActions: (state, action) => {
      state.actions = action.payload
    },
    setSelectedActionIDs: (state, action) => {
      state.selectedActionIDs = action.payload
    },
    setDefaultHeader: (state, action) => {
      state.defaultHeader = action.payload
    },
    setDefaultBody: (state, action) => {
      state.defaultBody = action.payload
    },
    setDefaultUrlQueryParam: (state, action) => {
      state.defaultUrlQueryParam = action.payload
    },
    setGlobalVariables: (state, action) => {
      state.globalVariables = action.payload
    },
  },
})

export const postmanBuilderReducerActions = postmanBuilderSlice.actions

export default postmanBuilderSlice.reducer
