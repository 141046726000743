import { useState } from 'react'
import { IoMenuOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'

import LogoBlack from '@/assets/logos/inavo.svg'
import MobileRootTopLeftMenu from '@/containers/Root/MobileRoot/MobileRootTop/MobileRootTopLeftMenu/MobileRootTopLeftMenu'

import './mobileRootTop.scss'

const MobileRootTop = () => {
  const [isVisibleLeftMenu, setIsVisibleLeftMenu] = useState(false)

  const handleClickMenuIcon = () => {
    setIsVisibleLeftMenu(true)
  }

  const handleCloseMenu = () => {
    setIsVisibleLeftMenu(false)
  }

  return (
    <div className='mobile-root-top'>
      <MobileRootTopLeftMenu isVisible={isVisibleLeftMenu} handleCloseMenu={handleCloseMenu} />
      <IoMenuOutline onClick={handleClickMenuIcon} className='mobile-root-top__menu-icon' />
      <Link to='/'>
        <img className='mobile-root-top__logo' src={LogoBlack} />
      </Link>
    </div>
  )
}

export default MobileRootTop
