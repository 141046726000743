import clsx from 'clsx'

import CommonInputDropdown from '@/components/InputDropdown/InputDropdown'

import './inputDropdown.scss'

const InputDropdown = ({ className, ...otherProps }) => {
  const allClassNames = clsx('build-api-input-dropdown', className)

  return (
    <CommonInputDropdown
      filterInputClassName='build-api-input-dropdown__input'
      dropdownClassName='build-api-input-dropdown__dropdown'
      dropdownItemClassName='build-api-input-dropdown__item'
      className={allClassNames}
      {...otherProps}
    />
  )
}

export default InputDropdown
