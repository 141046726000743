import { BODY_TYPE_CONFIGS } from '@/constants'

export const isValidEmail = str => {
  return str.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const convertToBase64 = async file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = reject
  })
}

export const isMobile = () => window.innerWidth < 900

export const filterText = (filterText = '', text = '') =>
  text.toString().toLocaleLowerCase('en-US').includes(filterText.toString().toLocaleLowerCase('en-US'))

export const setUrlQueryParameters = (url, queryObject) => {
  const base = 'http://dummy-base'
  const urlObj = new URL(url, base)

  const existingParams = new URLSearchParams(urlObj.search)

  Object.entries(queryObject).forEach(([key, value]) => {
    existingParams.set(key, value)
  })

  const newSearchParams = existingParams.toString()
  const resultUrl = url.startsWith('http') ? `${urlObj.origin}${urlObj.pathname}` : urlObj.pathname

  return newSearchParams ? `${resultUrl}?${newSearchParams}` : resultUrl
}

export const convertFile = (value, fileName = 'Bilinmeyen Dosya İsmi') => new File([new Blob([value])], fileName)

export const getFileType = file => (file?.type || '').split('/')[0]

export const getUserType = isAdmin => (isAdmin ? 'Yönetici' : 'Üye')

export const getValueOfJsonFile = async file => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(JSON.parse(event.target.result))
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })
}

export const setApexColor = color => {
  // eslint-disable-next-line no-undef
  Apex.colors = [color] // Yeah, it is not a problem :))
}

// We know, it is bad practice.
export const getUpdatedBodyTypeConfig = (configs, configKey) => {
  // eslint-disable-next-line no-unused-vars
  const { isFormData, isFormUrlEncoded, ...otherConfigs } = configs

  return { ...otherConfigs, ...(configKey === BODY_TYPE_CONFIGS.NONE ? {} : { [configKey]: true }) }
}

export const getParsedJsonFile = async file => {
  return await new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(JSON.parse(event.target.result))
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })
}
