import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import withRootLogic from '@/containers/Root/withRootLogic'
import { getUserTokenStorage } from '@/utils/localStorageActions'

// eslint-disable-next-line react/display-name
const withWrapperRootLogic = WrapperComponent => () => {
  const userToken = getUserTokenStorage()
  if (!userToken) return <Navigate to='/login' />

  const RootLogicComponent = ({ getContent }) => {
    const isLoading = useSelector(state => state.app.isLoading)

    return getContent(isLoading ? null : <WrapperComponent />) // We should render empty content while loading states
  }

  const ReadyComponent = withRootLogic(RootLogicComponent)
  return <ReadyComponent />
}

export default withWrapperRootLogic
