import { useDispatch, useSelector } from 'react-redux'

import ManageActionDefaultVariables from '@/components/ManageActionDefaultVariables/ManageActionDefaultVariables'
import { importApiCollectionReducerActions } from '@/store/reducers/importApiCollection'

import './importApiDynamicValueStep.scss'

const KEYS = {
  HEADER: 'header',
  BODY: 'body',
  QUERY_PARAMETER: 'query_parameter',
  CONFIG: 'config',
}

const mapperListItem = ([name, value]) => ({ name, value })

const ImportApiDynamicValueStep = () => {
  const { apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs } = useSelector(state => {
    const { apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs } = state.importApiCollection.parameters

    return {
      apiDefaultHeader,
      apiDefaultBody,
      apiDefaultUrlQueryParams,
      configs,
    }
  })

  const dispatch = useDispatch()

  const list = [
    {
      key: KEYS.HEADER,
      title: 'Default Header',
      items: Object.entries(apiDefaultHeader).map(mapperListItem),
    },
    {
      key: KEYS.BODY,
      title: 'Default Body',
      items: Object.entries(apiDefaultBody).map(mapperListItem),
    },
    {
      key: KEYS.QUERY_PARAMETER,
      title: 'Default Url Query Params',
      items: Object.entries(apiDefaultUrlQueryParams).map(mapperListItem),
    },
    {
      key: KEYS.CONFIG,
      title: 'Configs',
      items: Object.entries(configs).map(mapperListItem),
    },
  ]

  const getNewParameter = ({ name, parentKey, value }) => {
    const newItem = { [name]: value }

    switch (parentKey) {
      case KEYS.HEADER: {
        return {
          apiDefaultHeader: {
            ...apiDefaultHeader,
            ...newItem,
          },
        }
      }
      case KEYS.BODY: {
        return {
          apiDefaultBody: {
            ...apiDefaultBody,
            ...newItem,
          },
        }
      }
      case KEYS.QUERY_PARAMETER: {
        return {
          apiDefaultUrlQueryParams: {
            ...apiDefaultUrlQueryParams,
            ...newItem,
          },
        }
      }
      case KEYS.CONFIG: {
        return {
          configs: {
            ...configs,
            ...newItem,
          },
        }
      }
    }
  }

  const handleOnChange = payload => {
    const newParameters = getNewParameter(payload)

    dispatch(importApiCollectionReducerActions.updateParameters(newParameters))
  }

  return <ManageActionDefaultVariables list={list} handleOnChange={handleOnChange} />
}

export default ImportApiDynamicValueStep
