import clsx from 'clsx'
import { motion } from 'framer-motion'
import { IoIosCloseCircleOutline } from 'react-icons/io'

import './mobileRootBottomSliderPopup.scss'

const MobileRootBottomSliderPopup = ({ isVisible, handleCloseModal, title, children, modalStrictContent }) => {
  const handleOnClickFilter = () => {
    handleCloseModal()
  }

  const modalSlideProps = {
    className: 'mobile-slide-popup',
    initial: false,
    animate: isVisible ? 'open' : 'closed',
    transition: { duration: 0.25 },
    variants: {
      open: { opacity: 1, display: 'flex' },
      closed: { opacity: 0, transitionEnd: { display: 'none' } },
    },
  }

  const modalSlidePopupProps = {
    className: 'mobile-slide-popup__modal',
    initial: false,
    animate: isVisible ? 'open' : 'closed',
    transition: { duration: 0.25 },
    variants: {
      open: { height: '85%' },
      closed: { height: 0 },
    },
  }

  return (
    <motion.div {...modalSlideProps}>
      <div onClick={handleOnClickFilter} className='mobile-slide-popup__filter' />
      <motion.div {...modalSlidePopupProps}>
        {modalStrictContent}
        <IoIosCloseCircleOutline className='mobile-slide-popup__close' onClick={handleCloseModal} />
        <div className={clsx('mobile-slide-popup__title', { 'mobile-slide-popup__title--no-title': !title })}>{title}</div>
        <div className='mobile-slide-popup__content'>{children}</div>
      </motion.div>
    </motion.div>
  )
}

export default MobileRootBottomSliderPopup
