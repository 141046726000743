import { Navigate } from 'react-router-dom'

import { getUserTokenStorage } from '@/utils/localStorageActions'

/* eslint-disable react/display-name */
const withNotAuthenticated = WrapperComponent => props => {
  const userToken = getUserTokenStorage()

  if (userToken) {
    return <Navigate to='/' />
  }

  return <WrapperComponent {...props} />
}

export default withNotAuthenticated
