import PermissionError from '@/components/PermissionError/PermissionError'
import usePermission from '@/hooks/usePermission'

/* eslint-disable react/display-name */
const withUserPermission = WrapperComponent => props => {
  const { checkPagePermission } = usePermission()

  if (!checkPagePermission()) return <PermissionError />

  return <WrapperComponent {...props} />
}

export default withUserPermission
