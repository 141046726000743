import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BiUserCircle } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'

import { updateMe } from '@/api'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { LANGUAGES } from '@/constants'
import UserAdvancedSettings from '@/containers/Root/components/UserSettings/UserAdvancedSettings/UserAdvancedSettings'
import { authReducerActions } from '@/store/reducers/auth'

import './userSettings.scss'

const languagesItems = [
  { label: 'English', value: LANGUAGES.EN },
  { label: 'Türkçe', value: LANGUAGES.TR },
]

const UserSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [image, setImage] = useState(null)
  const [userDetail, setUserDetail] = useState({})

  const authUserDetail = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const handleChangeUserDetail = item => setUserDetail({ ...userDetail, ...item })

  const handleChangeImage = e => {
    const image = e.target.files[0]

    setImage(URL.createObjectURL(image))
    handleChangeUserDetail({ image })
  }

  const handleOnChangeInput = key => e => {
    handleChangeUserDetail({ [key]: e.target.value })
  }

  const handleOnChangeLanguageDropdown = value => {
    handleChangeUserDetail({ language: value })
  }

  const handleSetUpdatedUser = updatedUser => {
    const { image, name, language, phone } = updatedUser

    setImage(image)
    dispatch(authReducerActions.updateUser({ image, name, language, phone }))
  }

  const handleUpdateMe = async () => {
    try {
      setIsSubmitting(true)

      const updatedUser = await updateMe(userDetail)
      handleSetUpdatedUser(updatedUser.data.user)

      toast.success('Başarıyla kaydedildi')
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const { image, language, name, phone } = authUserDetail

    setImage(image)
    setUserDetail({ image, language, name, phone })
  }, [])

  const renderUserImage = () => {
    return image ? <img src={image} className='user-settings__image' /> : <BiUserCircle className='user-settings__image' />
  }

  const languageSelectedItemIndex = languagesItems.findIndex(language => language.value === userDetail.language)

  return (
    <div className='user-settings__list'>
      <div className='user-settings__item'>
        <div className='user-settings__label'>Fotoğraf</div>
        <div className='user-settings__content user-settings__content--image'>
          {renderUserImage()}
          <input className='user-settings__image-input' id='user_image' type='file' onChange={handleChangeImage} accept='image/*' />
          <label htmlFor='user_image' className='user-settings__change-image'>
            Değiştir
          </label>
        </div>
      </div>
      <div className='user-settings__item user-settings__item--disabled'>
        <div className='user-settings__label'>E-mail</div>
        <div className='user-settings__content'>
          <Input className='user-settings__input' value={authUserDetail.email} />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>İsim</div>
        <div className='user-settings__content'>
          <Input onChange={handleOnChangeInput('name')} className='user-settings__input' value={userDetail.name} />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>Dil</div>
        <div className='user-settings__content'>
          <InputDropdown
            className='user-settings__input'
            items={languagesItems}
            handleChange={handleOnChangeLanguageDropdown}
            selectedItemIndex={languageSelectedItemIndex}
          />
        </div>
      </div>
      <div className='user-settings__item'>
        <div className='user-settings__label'>Telefon</div>
        <div className='user-settings__content'>
          <Input onChange={handleOnChangeInput('phone')} className='user-settings__input' value={userDetail.phone} />
        </div>
      </div>
      <div className='user-settings__item'>
        <UserAdvancedSettings />
      </div>
      <div className='user-settings__item'>
        <PrimaryButton onClick={handleUpdateMe} isLoading={isSubmitting} className='user-settings__save-btn'>
          Kaydet
        </PrimaryButton>
      </div>
    </div>
  )
}

export default UserSettings
