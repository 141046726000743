import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'

import './checkbox.scss'

const Checkbox = ({ id = uuidv4(), checked, handleOnChange, text, disabled, className }) => {
  return (
    <div className={clsx('checkbox', className, { 'checkbox--disabled': disabled })}>
      <input id={id} type='checkbox' checked={checked} onChange={handleOnChange} />
      <label htmlFor={id}>{text}</label>
    </div>
  )
}

export default Checkbox
