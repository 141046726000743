import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import { postProject } from '@/api'
import InputWithLabel from '@/components/InputWithLabel/InputWithLabel'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import TextareaWithLabel from '@/components/TextareaWithLabel/TextareaWithLabel'
import { appReducerActions } from '@/store/reducers/app'
import { setActiveProjectId } from '@/utils/localStorageActions'

import './createProjectPopup.scss'

const CreateProjectPopup = ({ isVisible, handleClosePopup }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [projectDetail, setProjectDetail] = useState({ name: '', apiBaseUrl: '', description: '' })

  const dispatch = useDispatch()

  const isValidProjectDetail = projectDetail.name !== '' && projectDetail.apiBaseUrl !== ''

  const handleChangeProjectDetail = detail => setProjectDetail({ ...projectDetail, ...detail })

  const handleChangeInput = detailKey => e => handleChangeProjectDetail({ [detailKey]: e.target.value })

  const handleChangeClickCreateButton = async () => {
    try {
      setIsLoading(true)

      const projectRes = await postProject(projectDetail)
      const project = projectRes.data
      const projectId = project.id

      dispatch(
        appReducerActions.addProject({
          ...project,
          isAdmin: true,
        }),
      )
      dispatch(appReducerActions.setActiveProjectId(projectId))
      setActiveProjectId(projectId)

      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Popup title='Proje Oluştur' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='create-project'>
        <div className='create-project__list'>
          <InputWithLabel
            className='create-project__item'
            label='Proje İsmi*'
            inputProps={{ className: 'create-project__input', value: projectDetail.name, onChange: handleChangeInput('name') }}
          />
          <InputWithLabel
            className='create-project__item'
            label='URL*'
            inputProps={{ className: 'create-project__input', value: projectDetail.apiBaseUrl, onChange: handleChangeInput('apiBaseUrl') }}
          />
          <TextareaWithLabel
            className='create-project__item'
            label='Açıklama'
            inputProps={{
              className: 'create-project__input',
              value: projectDetail.description,
              onChange: handleChangeInput('description'),
            }}
          />
        </div>
        <PrimaryButton
          onClick={handleChangeClickCreateButton}
          isLoading={isLoading}
          disable={!isValidProjectDetail}
          className='create-project__create-btn'>
          Oluştur
        </PrimaryButton>
      </div>
    </Popup>
  )
}

export default CreateProjectPopup
