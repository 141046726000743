import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { MdOutlineDelete } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'

import { getProjectApiPermissionDetail, getProjectApis, getProjectUsers, postProjectApiPermission, updateProjectApiPermission } from '@/api'
import InputWithLabel from '@/components/InputWithLabel/InputWithLabel'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import ActionGroupPermissionFormDropdown from '@/pages/ActionGroupPermissions/ActionGroupPermissionFormPopup/ActionGroupPermissionFormDropdown/ActionGroupPermissionFormDropdown'

import './actionGroupPermissionFormPopup.scss'

const getMappedProjectApis = projectApis =>
  projectApis.reduce((acc, apiGroup) => {
    const preparedApis = apiGroup.api.map(api => ({ value: api.id, text: api.title, description: apiGroup.name }))

    return [...acc, ...preparedApis]
  }, [])
const getMappedProjectUsers = projectUsers =>
  projectUsers.map(({ user, isAdmin, id }) => ({
    isAdmin,
    text: user.email,
    value: id,
    description: user.name,
  }))

const renderAdminUserInformation = () => <div className='action-group-permission-form-popup__admin-user-info'>(Zaten yönetici)</div>

const actionGroupPermissionInitialValues = { name: '', apis: [], projectUserIds: [] }

const ActionGroupPermissionFormPopup = ({ isVisible, handleClosePopup, actionGroupPermissionId, handleAfterSubmit }) => {
  const [allActions, setAllActions] = useState([])
  const [allProjectUsers, setAllProjectUsers] = useState([])
  const [actionGroupPermissionValues, setActionGroupPermissionValues] = useState(actionGroupPermissionInitialValues)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const isEditActinGroupPermission = !!actionGroupPermissionId
  const saveButtonText = isEditActinGroupPermission ? 'Kaydet' : 'Ekle'
  const popupTitle = isEditActinGroupPermission ? 'Aksiyon Yetki Grubunu Düzenle' : 'Aksiyon Yetki Grubu Ekle'

  const handleSetActionGroupPermissionValues = item => setActionGroupPermissionValues(prev => ({ ...prev, ...item }))

  const addedAllActions = actionGroupPermissionValues.apis.map(id => allActions.find(action => action.value === id))
  const notAddedAllActions = allActions.filter(action => !actionGroupPermissionValues.apis.includes(action.value))

  const addedProjectUsers = actionGroupPermissionValues.projectUserIds.map(id =>
    allProjectUsers.find(projectUser => projectUser.value === id),
  )
  const notAddedProjectUsers = allProjectUsers.filter(
    projectUser => !actionGroupPermissionValues.projectUserIds.includes(projectUser.value),
  )

  const handleAddAction = actionId => handleSetActionGroupPermissionValues({ apis: [...actionGroupPermissionValues.apis, actionId] })
  const handleOnClickActionItem = item => {
    handleAddAction(item.value)
  }
  const handleDeleteAction = actionId =>
    handleSetActionGroupPermissionValues({ apis: [...actionGroupPermissionValues.apis.filter(apiId => apiId !== actionId)] })
  const handleOnClickDeleteActionItem = item => () => {
    handleDeleteAction(item.value)
  }

  const handleAddProjectUser = projectUserId =>
    handleSetActionGroupPermissionValues({ projectUserIds: [...actionGroupPermissionValues.projectUserIds, projectUserId] })
  const handleOnClickProjectUserItem = item => {
    handleAddProjectUser(item.value)
  }
  const handleDeleteProjectUser = projectUserId =>
    handleSetActionGroupPermissionValues({
      projectUserIds: [...actionGroupPermissionValues.projectUserIds.filter(_projectUserId => _projectUserId !== projectUserId)],
    })
  const handleOnClickDeleteProjectUserItem = item => () => {
    handleDeleteProjectUser(item.value)
  }

  const renderItemAdditionalContent = item =>
    item.isAdmin && (
      <div className='action-group-permission-form-popup__user-dropdown-admin-info-wrapper'>{renderAdminUserInformation(item)}</div>
    )

  const handleChangGroupName = e => handleSetActionGroupPermissionValues({ name: e.target.value })

  const validateGroupPermissionForm = () => {
    const isEmptyName = actionGroupPermissionValues.name === ''
    if (isEmptyName) {
      toast.error('Grup ismi boş olamaz')
      return false
    }

    const notAnyActions = actionGroupPermissionValues.apis.length === 0
    if (notAnyActions) {
      toast.error('En az bir aksiyon eklenmelidir')
      return false
    }

    const notAnyUsers = actionGroupPermissionValues.projectUserIds.length === 0
    if (notAnyUsers) {
      toast.error('En az bir kullanıcı eklenmelidir')
      return false
    }

    return true
  }

  const handlePostValues = async () => {
    try {
      setIsSubmitting(true)

      if (!validateGroupPermissionForm()) return

      isEditActinGroupPermission
        ? await updateProjectApiPermission(actionGroupPermissionId, actionGroupPermissionValues)
        : await postProjectApiPermission(actionGroupPermissionValues)

      handleAfterSubmit()

      handleClosePopup()
      toast.success('İşlem başarıyla  gerçekleşti')
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  const setInitialEditValues = projectApiPermissionDetail => {
    if (!projectApiPermissionDetail) {
      handleSetActionGroupPermissionValues({ ...actionGroupPermissionInitialValues })
      return
    }

    const projectUserIds = projectApiPermissionDetail.projectUsers.map(projectUser => projectUser.id)
    handleSetActionGroupPermissionValues({ projectUserIds })

    const apiIds = projectApiPermissionDetail.apis.map(api => api.id)
    handleSetActionGroupPermissionValues({ apis: apiIds })

    handleSetActionGroupPermissionValues({ name: projectApiPermissionDetail.name })
  }

  const handleGetRequests = async () => {
    try {
      setIsLoading(true)

      const [projectApisRes, projectUsers, projectApiPermissionDetail] = await Promise.all([
        getProjectApis(),
        getProjectUsers(),
        ...(isEditActinGroupPermission ? [getProjectApiPermissionDetail(actionGroupPermissionId)] : []),
      ])

      const mappedProjectApis = getMappedProjectApis(projectApisRes.data)
      const mappedProjectUsers = getMappedProjectUsers(projectUsers.data)

      setInitialEditValues(projectApiPermissionDetail?.data)

      setAllActions(mappedProjectApis)
      setAllProjectUsers(mappedProjectUsers)
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isVisible) {
      handleGetRequests()
    }
  }, [isVisible])

  return (
    <Popup modalClassName='action-group-permission-form-popup' title={popupTitle} isVisible={isVisible} handleClosePopup={handleClosePopup}>
      {isLoading ? (
        <Skeleton className='action-group-permission-form-popup__loading-skelton-item' count={Object.keys(Array(3).fill('')).length} />
      ) : (
        <>
          <div className='action-group-permission-form-popup__item'>
            <InputWithLabel
              label='Grup İsmi'
              inputProps={{
                className: 'action-group-permission-form-popup__input',
                onChange: handleChangGroupName,
                value: actionGroupPermissionValues.name,
              }}
            />
          </div>
          <div className='action-group-permission-form-popup__item'>
            <div className='action-group-permission-form-popup__list-wrapper'>
              <div className='action-group-permission-form-popup__list-section add-action-group-permission-popup__list-section--action'>
                <div className='action-group-permission-form-popup__list-title'>Aksiyonlar</div>
                <ActionGroupPermissionFormDropdown
                  inputPlaceholder='+ Aksiyon Ekle'
                  items={notAddedAllActions}
                  handleOnClickItem={handleOnClickActionItem}
                  noItemText='Hiç eklenecek aksiyon yok'
                  noItemTextByFilter='Bu filtreye göre hiç aksiyon yok'
                />
                <div className='action-group-permission-form-popup__list'>
                  {addedAllActions.length === 0 ? (
                    <div className='action-group-permission-form-popup__list-no-item'>Hiç eklenmiş aksiyon yok</div>
                  ) : (
                    addedAllActions.map(action => (
                      <div key={action.id} className='action-group-permission-form-popup__list-item'>
                        <div className='action-group-permission-form-popup__list-item-content'>
                          <div className='action-group-permission-form-popup__list-item-text'>{action.text}</div>
                          <div className='action-group-permission-form-popup__list-item-desc'>{action.description}</div>
                        </div>
                        <MdOutlineDelete
                          onClick={handleOnClickDeleteActionItem(action)}
                          className='action-group-permission-form-popup__list-delete-icon'
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className='action-group-permission-form-popup__list-section action-group-permission-form-popup__list-section--user'>
                <div className='action-group-permission-form-popup__list-title'>Kullanıcılar</div>
                <ActionGroupPermissionFormDropdown
                  inputPlaceholder='+ Kullanıcı Ekle'
                  items={notAddedProjectUsers}
                  handleOnClickItem={handleOnClickProjectUserItem}
                  noItemText='Hiç eklenecek kullanıcı yok'
                  noItemTextByFilter='Bu filtreye göre hiç kullanıcı yok'
                  renderItemAdditionalContent={renderItemAdditionalContent}
                />
                <div className='action-group-permission-form-popup__list'>
                  {addedProjectUsers.length === 0 ? (
                    <div className='action-group-permission-form-popup__list-no-item'>Hiç eklenmiş kullanıcı yok</div>
                  ) : (
                    addedProjectUsers.map(projectUser => (
                      <div key={projectUser.value} className='action-group-permission-form-popup__list-item'>
                        <div className='action-group-permission-form-popup__list-item-content'>
                          <div className='action-group-permission-form-popup__list-item-text'>{projectUser.text}</div>
                          <div className='action-group-permission-form-popup__list-item-desc'>{projectUser.description}</div>
                          {projectUser.isAdmin && (
                            <div className='action-group-permission-form-popup__list-item-admin-information-wrapper'>
                              {renderAdminUserInformation()}
                            </div>
                          )}
                        </div>
                        <MdOutlineDelete
                          onClick={handleOnClickDeleteProjectUserItem(projectUser)}
                          className='action-group-permission-form-popup__list-delete-icon'
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='action-group-permission-form-popup__item'>
            <PrimaryButton isLoading={isSubmitting} onClick={handlePostValues} className='action-group-permission-form-popup__save-btn'>
              {saveButtonText}
            </PrimaryButton>
          </div>
        </>
      )}
    </Popup>
  )
}

export default ActionGroupPermissionFormPopup
