import dayjs from 'dayjs'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ReactDomServer from 'react-dom/server'
import toast from 'react-hot-toast'
import { BiUserCircle } from 'react-icons/bi'
import { LuFilter } from 'react-icons/lu'
import Skeleton from 'react-loading-skeleton'

import { getProjectLogs } from '@/api'
import PageTitle from '@/components/PageTitle/PageTitle'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { ORDER_BYS, PROJECT_LOG_TYPES } from '@/constants'
import ProjectLogTypeTag from '@/pages/ProjectLogs/ProjectLogTypeTag/ProjectLogTypeTag'
import ProjectLogsFilters from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogsFilters'
import ProjectLogsFiltersPopup from '@/pages/ProjectLogs/ProjectLogsFiltersPopup/ProjectLogsFiltersPopup'
import { isMobile } from '@/utils'

import './projectLogs.scss'

const renderLogText = projectLog => {
  const { type, text, properties } = projectLog

  switch (type) {
    case PROJECT_LOG_TYPES.CREATED_PROJECT_USER: {
      const { addedUserEmail } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{addedUserEmail}</span>

      return text.replace('{addedUserEmail}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.DELETED_PROJECT_USER: {
      const { deletedUserEmail } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{deletedUserEmail}</span>

      return text.replace('{deletedUserEmail}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.LEAVE_USER_FROM_PROJECT: {
      const { leavedUserName } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{leavedUserName}</span>

      return text.replace('{leavedUserName}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.CREATED_PROJECT_API_PERMISSION: {
      const { createdProjectApiPermissionName } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{createdProjectApiPermissionName}</span>

      return text.replace('{createdProjectApiPermissionName}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.UPDATED_PROJECT_API_PERMISSION: {
      const { updatedProjectApiPermissionName } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{updatedProjectApiPermissionName}</span>

      return text.replace('{updatedProjectApiPermissionName}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.DELETED_PROJECT_API_PERMISSION: {
      const { deletedProjectApiPermissionName } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{deletedProjectApiPermissionName}</span>

      return text.replace('{deletedProjectApiPermissionName}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.EXECUTED_API: {
      const { apiTitle } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{apiTitle}</span>

      return text.replace('{apiTitle}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.USER_LOGGED: {
      const { userEmail } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{userEmail}</span>

      return text.replace('{userEmail}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.CREATED_API: {
      const { createdProjectApiTitle } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{createdProjectApiTitle}</span>

      return text.replace('{createdProjectApiTitle}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.DELETED_API: {
      const { deletedProjectApiTitle } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{deletedProjectApiTitle}</span>

      return text.replace('{deletedProjectApiTitle}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.CREATED_CHART: {
      const { createdProjectChartLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{createdProjectChartLabel}</span>

      return text.replace('{createdProjectChartLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.UPDATED_CHART: {
      const { updatedProjectChartLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{updatedProjectChartLabel}</span>

      return text.replace('{updatedProjectChartLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.DELETED_CHART: {
      const { deletedProjectChartLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{deletedProjectChartLabel}</span>

      return text.replace('{deletedProjectChartLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.CREATED_TABLE: {
      const { createdProjectTableLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{createdProjectTableLabel}</span>

      return text.replace('{createdProjectTableLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.UPDATED_TABLE: {
      const { updatedProjectTableLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{updatedProjectTableLabel}</span>

      return text.replace('{updatedProjectTableLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.DELETED_TABLE: {
      const { deletedProjectTableLabel } = properties
      const highlightText = <span className='project-logs__log-text-highlight'>{deletedProjectTableLabel}</span>

      return text.replace('{deletedProjectTableLabel}', ReactDomServer.renderToString(highlightText))
    }
    case PROJECT_LOG_TYPES.UPDATED_PROJECT:
      return text
    case PROJECT_LOG_TYPES.CREATED_API_COLLECTION:
    case PROJECT_LOG_TYPES.EXECUTED_API_COLLECTION: {
      return text
    }
    default:
      return type
  }
}

const renderProjectUser = user => {
  const { image, name, email } = user

  return (
    <>
      {image ? <img src={image} className='project-logs__user-img' /> : <BiUserCircle className='project-logs__user-img' />}
      <div className='project-logs__user-info'>
        <div className='project-logs__name'>{name}</div>
        {!isMobile() && <div className='project-logs__email'>{email}</div>}
      </div>
    </>
  )
}

const renderDate = date => <div className='project-logs__log-date'>{dayjs(date).format('DD MMM YYYY, HH:mm')}</div>

const DesktopProjectLogItem = ({ projectLog }) => {
  return (
    <div className='project-logs__item'>
      <div className='project-logs__user'>{renderProjectUser(projectLog.user)}</div>
      <div className='project-logs__log-text' dangerouslySetInnerHTML={{ __html: renderLogText(projectLog) }} />
      <div className='project-logs__log-infos'>
        {renderDate(projectLog.createdAt)}
        <div className='project-logs__log-wrapper'>
          <ProjectLogTypeTag type={projectLog.type} />
        </div>
      </div>
    </div>
  )
}

const MobileProjectLogItem = ({ projectLog }) => {
  return (
    <div className='project-logs__item project-logs__mobile-item'>
      <div className='project-logs__mobile-top'>
        <div className='project-logs__user'>{renderProjectUser(projectLog.user)}</div>
        <div className='project-logs__mobile-infos'>
          {renderDate(projectLog.createdAt)}
          <ProjectLogTypeTag type={projectLog.type} />
        </div>
      </div>
      <div className='project-logs__mobile-content'>
        <div className='project-logs__log-text' dangerouslySetInnerHTML={{ __html: renderLogText(projectLog) }} />
      </div>
    </div>
  )
}

const PROJECT_LOGS_LIMIT = 50
const SPLITTER_LOG_DATE_FORMAT = 'DD-MM-YYYYY'
const INITIAL_FILTERS = { types: '', userId: '', orderBy: ORDER_BYS.DESC, startDate: null, endDate: null }

const ProjectLogs = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [projectLogs, setProjectLogs] = useState([])
  const [splitterProjectLogs, setSplitterProjectLogs] = useState({})
  const [isEnableMoreButton, setIsEnableMoreButton] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isVisibleLogsFiltersPopup, setIsVisibleLogsFiltersPopup] = useState(false)
  const [filters, setFilters] = useState(INITIAL_FILTERS)

  const hasProjectLogs = Object.keys(projectLogs).length > 0
  const filledFilters = Object.entries(filters)
    .filter(([key, value]) => {
      switch (true) {
        case key === 'orderBy':
          return value !== ORDER_BYS.DESC
        case key === 'startDate':
          return !!value && !!filters.endDate
        case key === 'endDate':
          return false
        default:
          return !!value
      }
    })
    .map(([key]) => key)
  const hasFilledFilter = filledFilters.length > 0
  const filledFiltersCount = filledFilters.length

  const handleAddProjectLogs = projectLogsData => setProjectLogs(prev => [...prev, ...projectLogsData])
  const handleSetProjectLogs = projectLogsData => setProjectLogs([...projectLogsData])
  const handleUpdateFilters = item => setFilters(prev => ({ ...prev, ...item }))
  const handleClearFilters = () => setFilters({ ...INITIAL_FILTERS })

  const handleCloseLogsFiltersPopup = () => {
    setIsVisibleLogsFiltersPopup(false)
  }

  const handleOnClickMobileFilterButton = () => {
    setIsVisibleLogsFiltersPopup(true)
  }

  const handleGetProjectLogs = async payload => {
    try {
      const { params, isInitial } = payload

      const projectLogsRes = await getProjectLogs(params)
      const projectLogsData = projectLogsRes.data.projectLogs

      isInitial ? handleSetProjectLogs(projectLogsData) : handleAddProjectLogs(projectLogsData)
      setIsEnableMoreButton(projectLogsData.length === PROJECT_LOGS_LIMIT)
    } catch (err) {
      toast.error('Bir hata oluştu')
    }
  }

  const handleClickMoreLogButton = async () => {
    setIsLoadingMore(true)

    const lastProjectLogId = projectLogs[projectLogs.length - 1].id
    await handleGetProjectLogs({
      params: { lastProjectLogId, ...filters },
    })

    setIsLoadingMore(false)
  }

  const handleGetInitialProjectLogs = async params => {
    try {
      setIsLoading(true)

      await handleGetProjectLogs({ params, isInitial: true })
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetInitialProjectLogs()
  }, [])

  useEffect(() => {
    const _splitterProjectLogs = _.groupBy(projectLogs, item => dayjs(item.createdAt).format(SPLITTER_LOG_DATE_FORMAT))
    setSplitterProjectLogs(_splitterProjectLogs)
  }, [projectLogs])

  return (
    <div className='project-logs'>
      <PageTitle>Proje Hareketleri</PageTitle>
      {isMobile() && (
        <ProjectLogsFiltersPopup
          isVisible={isVisibleLogsFiltersPopup}
          handleClosePopup={handleCloseLogsFiltersPopup}
          handleGetInitialProjectLogs={handleGetInitialProjectLogs}
          handleUpdateFilters={handleUpdateFilters}
          handleClearFilters={handleClearFilters}
          filters={filters}
          filledFiltersCount={filledFiltersCount}
        />
      )}
      <div className='project-logs__top'>
        {isMobile() ? (
          <div className='project-logs__mobile-filters-button-wrapper'>
            {hasFilledFilter && <div className='project-logs__mobile-filter-count'>{filledFiltersCount}</div>}
            <LuFilter onClick={handleOnClickMobileFilterButton} className='project-logs__mobile-filters-button' />
          </div>
        ) : (
          <ProjectLogsFilters
            handleGetInitialProjectLogs={handleGetInitialProjectLogs}
            handleUpdateFilters={handleUpdateFilters}
            handleClearFilters={handleClearFilters}
            filters={filters}
            hasFilledFilter={hasFilledFilter}
            filledFiltersCount={filledFiltersCount}
          />
        )}
      </div>
      <div className='project-logs__list'>
        {isLoading &&
          Array(5)
            .fill('')
            .map(key => <Skeleton key={key} className='project-logs__skelton-loading-item' />)}
        {!isLoading && !hasProjectLogs && <div className='project-logs__no-project-logs'>Hiç hareket yok</div>}
        {!isLoading &&
          hasProjectLogs &&
          Object.entries(splitterProjectLogs).map(([splitDate, projectLogs]) =>
            projectLogs.map((projectLog, projectLogIndex) => (
              <>
                {projectLogIndex === 0 && (
                  <div className='project-logs__date-splitter-wrapper'>
                    <div className='project-logs__date-splitter' />
                    <div className='project-logs__date'>{dayjs(splitDate, SPLITTER_LOG_DATE_FORMAT).format('DD MMMM')}</div>
                    <div className='project-logs__date-splitter project-logs__date-splitter--reverse' />
                  </div>
                )}
                {isMobile() ? (
                  <MobileProjectLogItem key={projectLog.id} projectLog={projectLog} />
                ) : (
                  <DesktopProjectLogItem key={projectLog.id} projectLog={projectLog} />
                )}
              </>
            )),
          )}
      </div>
      {isEnableMoreButton && !isLoading && (
        <div className='project-logs__more-button-wrapper'>
          <PrimaryButton isLoading={isLoadingMore} onClick={handleClickMoreLogButton} className='project-logs__more-button'>
            Daha fazla
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export default ProjectLogs
