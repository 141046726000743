import { useSelector } from 'react-redux'
import { matchPath as matchPathBase, useLocation } from 'react-router-dom'

import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import { MAPPED_PAGE_PERMISSIONS } from '@/constants/permissions'
import { ROUTE_URLS } from '@/constants/routeUrls'
import { appReducerSelectors } from '@/store/reducers/app'

const matchPath = (pattern, pathname) => matchPathBase(pattern, pathname)
const getRoutePattern = pathname => Object.values(ROUTE_URLS).find(url => matchPath(url, pathname))

const checkMatchPermission = permission => permission === true
const usePermission = () => {
  const projectUserPermissions = useSelector(store => store.app.projectUserPermissions)
  const selectedProject = useSelector(appReducerSelectors.selectedProjectSelector)
  const { pathname } = useLocation()

  const isProjectAdmin = selectedProject?.isOwner || checkMatchPermission(projectUserPermissions[PROJECT_PERMISSIONS.ADMIN])

  const checkPermission = permission => {
    return isProjectAdmin || checkMatchPermission(projectUserPermissions[permission])
  }

  const checkPagePermission = (path = pathname) => {
    const pattern = getRoutePattern(path)
    const permission = MAPPED_PAGE_PERMISSIONS[pattern]

    return !permission || checkPermission(permission)
  }

  return {
    isProjectAdmin,
    checkPermission,
    checkPagePermission,
  }
}

export default usePermission
