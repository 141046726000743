import { useDispatch } from 'react-redux'

import { authReducerActions } from '@/store/reducers/auth'
import { removeUserTokenStorage } from '@/utils/localStorageActions'

const useLogout = () => {
  const dispatch = useDispatch()

  const logoutFromClient = () => {
    dispatch(authReducerActions.removeUser())
    removeUserTokenStorage()
  }

  return {
    logoutFromClient,
  }
}

export default useLogout
