import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import './richTextEditor.scss'

const modules = {
  toolbar: [
    [
      {
        header: [1, 2, 3, 4, 5, 6, false],
      },
    ],
    [{ font: [] }],
    [{ align: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    ['blockquote', 'code-block'],
    ['link', 'formula', 'image', 'video'],
    [
      {
        color: [],
      },
    ],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    ['clean'],
  ],
}

const RichTextEditor = ({ onChange, value }) => {
  return <ReactQuill className='rich-text-editor' modules={modules} theme='snow' onChange={onChange} value={value} />
}

export default RichTextEditor
