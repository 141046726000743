import { createSlice } from '@reduxjs/toolkit'

import { getActiveProjectId } from '@/utils/localStorageActions'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isLoading: true,
    projects: [],
    projectApis: [],
    activeProjectId: Number(getActiveProjectId()) || null,
    projectUserPermissions: {},
    projectSettings: {},
    isEnabledUserSettingsDropdown: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setProjects: (state, action) => {
      state.projects = action.payload
    },
    addProject: (state, action) => {
      state.projects = [...state.projects, action.payload]
    },
    updateProject: (state, action) => {
      const { id, ...projectDetail } = action.payload

      state.projects = state.projects.map(project => (project.id === id ? { ...project, ...projectDetail } : project))
    },
    setActiveProjectId: (state, action) => {
      state.activeProjectId = action.payload
    },
    setProjectApis: (state, action) => {
      state.projectApis = action.payload
    },
    setProjectUserPermissions: (state, action) => {
      state.projectUserPermissions = action.payload
    },
    setProjectSettings: (state, action) => {
      state.projectSettings = action.payload
    },
    updateProjectSettings: (state, action) => {
      const { id, name, description, imagePath } = action.payload

      // Update projects
      state.projects = state.projects.map(project => (project.id === id ? { ...project, name, description, imagePath } : project))

      // Update project settings
      state.projectSettings = action.payload
    },
    setIsEnabledUserSettingsDropdown: (state, action) => {
      state.isEnabledUserSettingsDropdown = action.payload
    },
  },
})

const selectedProjectSelector = state => {
  const selectedProjectId = state.app.activeProjectId
  const allProjects = state.app.projects

  return allProjects.find(project => project.id === selectedProjectId)
}
const checkHasProjectsSelector = state => state.app.projects.length > 0

export const appReducerActions = appSlice.actions
export const appReducerSelectors = {
  selectedProjectSelector,
  checkHasProjectsSelector,
}

export default appSlice.reducer
