import clsx from 'clsx'

import CommonInput from '@/components/Input/Input'

import './input.scss'

const Input = ({ className, ...otherProps }) => {
  const allClassNames = clsx('build-api-input', className)

  return <CommonInput className={allClassNames} {...otherProps} />
}

export default Input
