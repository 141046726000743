import clsx from 'clsx'

import './tooltip.scss'

const Tooltip = ({ children, text, tooltipTextClassName, className }) => {
  const allClassNames = clsx('tooltip', className)

  return (
    <div className={allClassNames}>
      <div className={clsx('tooltip__tooltip-text', tooltipTextClassName)}>{text}</div>
      <div className='tooltip__text'>{children}</div>
    </div>
  )
}

export default Tooltip
