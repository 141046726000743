import clsx from 'clsx'
import { useRef, useState } from 'react'

import useClickOutside from '@/hooks/useClickOutside'
import { filterText as filterTextUtil } from '@/utils'

import './actionGroupPermissionFormDropdown.scss'

const ActionGroupPermissionFormDropdown = ({
  inputPlaceholder,
  items,
  handleOnClickItem,
  noItemText = 'Hiç bir şey yok',
  noItemTextByFilter = 'Bu filtreye göre hiç bir şey yok',
  renderItemAdditionalContent,
}) => {
  const [filterText, setFilterText] = useState('')
  const [isEnabledDropdown, setIsEnabledDropdown] = useState(false)

  const filterTextRef = useRef(null)
  const dropdownRef = useRef(null)

  const hasItems = items.length !== 0
  const filteredItems = items.filter(item => filterTextUtil(filterText, item.text))

  const handleDropdownClickOutside = () => {
    setIsEnabledDropdown(false)
  }
  useClickOutside(dropdownRef, handleDropdownClickOutside)

  const handleClickInput = () => {
    if (!isEnabledDropdown) {
      setIsEnabledDropdown(true)
      return
    }

    setIsEnabledDropdown(false)
    filterTextRef.current.blur()
  }

  const handleOnChangeFilterText = e => setFilterText(e.target.value)

  const handleOnClickDropdownItem = item => () => {
    handleOnClickItem(item)
  }

  const getDropdownContent = () => {
    switch (true) {
      case !hasItems:
        return <div className='action-group-permission-form-dropdown__no-item'>{noItemText}</div>
      case !!filterText && hasItems && filteredItems.length === 0:
        return <div className='action-group-permission-form-dropdown__no-item'>{noItemTextByFilter}</div>
      case hasItems:
        return filteredItems.map(item => (
          <div onClick={handleOnClickDropdownItem(item)} key={item.id} className='action-group-permission-form-dropdown__item'>
            <div className='action-group-permission-form-dropdown__title'>{item.text}</div>
            <div className='action-group-permission-form-dropdown__description'>{item.description}</div>
            {renderItemAdditionalContent && renderItemAdditionalContent(item)}
          </div>
        ))
    }
  }

  const allFilterInputNameClassNames = clsx('action-group-permission-form-dropdown__input', {
    'action-group-permission-form-dropdown__input--unvisible': !isEnabledDropdown,
    'action-group-permission-form-dropdown__input--visible': isEnabledDropdown,
  })

  const allDropdownClassNames = clsx('action-group-permission-form-dropdown__dropdown', {
    'action-group-permission-form-dropdown__dropdown--visible': isEnabledDropdown,
  })

  return (
    <div ref={dropdownRef} className='action-group-permission-form-dropdown'>
      <input
        ref={filterTextRef}
        onChange={handleOnChangeFilterText}
        onClick={handleClickInput}
        className={allFilterInputNameClassNames}
        placeholder={inputPlaceholder}
      />
      <div className={allDropdownClassNames}>{getDropdownContent()}</div>
    </div>
  )
}

export default ActionGroupPermissionFormDropdown
