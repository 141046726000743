import './buildApiSettingWrapper.scss'

const BuildApiSettingWrapper = ({ children, title }) => {
  return (
    <div className='build-api-setting-wrapper'>
      {title && <div className='build-api-setting-wrapper__title'>{title}</div>}
      <div className='build-api-setting-wrapper__content'>{children}</div>
    </div>
  )
}

export default BuildApiSettingWrapper
