import { useDispatch, useSelector } from 'react-redux'

import ManageActionsList from '@/components/ManageActionsList/ManageActionsList'
import { importApiCollectionReducerActions } from '@/store/reducers/importApiCollection'

import './importApiManageActionsStep.scss'

const ImportApiManageActionsStep = () => {
  const { allApi, selectedApiIndexes } = useSelector(state => {
    const { allApi, selectedApiIndexes } = state.importApiCollection

    return { allApi, selectedApiIndexes }
  })
  const dispatch = useDispatch()

  const handleOnChange = _selectedApiIndexes => {
    dispatch(importApiCollectionReducerActions.setSelectedApiIndexes(_selectedApiIndexes))
  }

  return <ManageActionsList itemList={allApi} selectedItemIds={selectedApiIndexes} handleOnChange={handleOnChange} />
}

export default ImportApiManageActionsStep
