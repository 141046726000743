import ConnectIcon from '@/assets/icons/connect.png'
import PostmanLogo from '@/assets/icons/postman-icon.webp'
import InavoLogo from '@/assets/logos/circle-green-logo.svg'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './step.scss'

const Step = ({
  children,
  handleOnClickContinueButton: _handleOnClickContinueButton,
  handleOnClickBackButton: _handleOnClickBackButton,
  title,
  isEnableActions = true,
  continueButtonText,
}) => {
  const handleOnClickContinueButton = () => {
    _handleOnClickContinueButton()
  }

  const handleOnClickBackButton = () => {
    _handleOnClickBackButton()
  }

  return (
    <div className='postman-build-step'>
      <div className='postman-build-step__brand'>
        <div className='postman-build-step__brand-img-wrapper'>
          <img className='postman-build-step__console-logo' src={InavoLogo} />
          <div className='postman-build-step__brand-text'>console</div>
        </div>
        <img className='postman-build-step__connect-icon' src={ConnectIcon} />
        <div className='postman-build-step__brand-img-wrapper'>
          <img className='postman-build-step__postman-logo' src={PostmanLogo} />
          <div className='postman-build-step__brand-text'>postman</div>
        </div>
      </div>
      <div className='postman-build-step__modal'>
        {title && (
          <div className='postman-build-step__top'>
            <div className='postman-build-step__title'>{title}</div>
          </div>
        )}
        <div className='postman-build-step__content'>{children}</div>
        {isEnableActions && (
          <div className='postman-build-step__actions'>
            {_handleOnClickBackButton && (
              <OutlinedButton onClick={handleOnClickBackButton} className='postman-build-step__action-btn'>
                Geri
              </OutlinedButton>
            )}
            {_handleOnClickContinueButton && (
              <PrimaryButton
                onClick={handleOnClickContinueButton}
                className='postman-build-step__action-btn postman-build-step__action-btn--continue'>
                {continueButtonText || 'Devam'}
              </PrimaryButton>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Step
