import { useRef } from 'react'

import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import ProjectLogDateFilter from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogDateFilter/ProjectLogDateFilter'
import ProjectLogOrderByFilter from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogOrderByFilter/ProjectLogOrderByFilter'
import ProjectLogTypeFilter from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogTypeFilter/ProjectLogTypeFilter'
import ProjectLogUserFilter from '@/pages/ProjectLogs/ProjectLogsFilters/ProjectLogUserFilter/ProjectLogUserFilter'

import './projectLogsFilters.scss'

const ProjectLogsFilters = ({
  handleGetInitialProjectLogs,
  handleClosePopup,
  filters,
  handleClearFilters,
  handleUpdateFilters,
  filledFiltersCount,
}) => {
  const hasFilledFilter = filledFiltersCount > 0

  const requestedWithFilters = useRef(null)

  const handleOnClickApplyButton = async () => {
    requestedWithFilters.current = hasFilledFilter
    await handleGetInitialProjectLogs(filters)
    handleClosePopup && handleClosePopup()
  }

  const handleOnClickClearButton = async () => {
    if (requestedWithFilters.current) {
      await handleGetInitialProjectLogs()
      requestedWithFilters.current = null
    }

    handleClearFilters()
  }

  return (
    <div className='project-log-filters'>
      <div className='project-log-filters__list'>
        <div className='project-log-filters__item'>
          <ProjectLogTypeFilter value={filters.types} handleUpdateFilters={handleUpdateFilters} />
        </div>
        <div className='project-log-filters__item'>
          <ProjectLogUserFilter value={filters.userId} handleUpdateFilters={handleUpdateFilters} />
        </div>
        <div className='project-log-filters__item'>
          <ProjectLogOrderByFilter value={filters.orderBy} handleUpdateFilters={handleUpdateFilters} />
        </div>
        <div className='project-log-filters__item'>
          <ProjectLogDateFilter endDate={filters.endDate} startDate={filters.startDate} handleUpdateFilters={handleUpdateFilters} />
        </div>
      </div>
      <div className='project-log-filters__actions'>
        {hasFilledFilter && (
          <OutlinedButton
            onClick={handleOnClickClearButton}
            className='project-log-filters__action-btn project-log-filters__action-btn--clear'>
            Temizle
          </OutlinedButton>
        )}
        <PrimaryButton
          onClick={handleOnClickApplyButton}
          className='project-log-filters__action-btn project-log-filters__action-btn--apply'>
          Uygula {hasFilledFilter && `(${filledFiltersCount})`}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ProjectLogsFilters
