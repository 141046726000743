import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import FileUpload from '@/components/FileUpload/FileUpload'
import { getAllActions, getDefaultVariables, getValidActions } from '@/containers/PostmanBuilder/Steps/PostmanDataStep/utils'
import { processAllActions } from '@/containers/PostmanBuilder/Steps/PostmanDataStep/utils/normalizeActions'
import Step from '@/containers/PostmanBuilder/components/Step/Step'
import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'
import { postmanBuilderReducerActions } from '@/store/reducers/postmanBuilder'
import { getParsedJsonFile } from '@/utils'

import './postmanDataStep.scss'

const fileTypes = ['json']

const PostmanDataStep = () => {
  const dispatch = useDispatch()

  const handleOnClickContinueButton = () => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.MANAGE_ACTIONS))
  }

  const handleProcessCollection = parsedJson => {
    try {
      const allActions = getAllActions(parsedJson)
      const allValidActions = getValidActions(allActions)

      const { normalizedActions, postmanDefaultHeader, postmanDefaultBody, postmanDefaultUrlQueryParam } = processAllActions({
        allActions: allValidActions,
      })
      dispatch(postmanBuilderReducerActions.setActions(normalizedActions))
      dispatch(
        postmanBuilderReducerActions.setSelectedActionIDs(
          Object.values(normalizedActions)
            .flat()
            .map(item => item.api.__id),
        ),
      )

      const { defaultHeader, defaultBody, defaultQueryParam } = getDefaultVariables({
        variables: parsedJson.variable,
        postmanDefaultHeader,
        postmanDefaultBody,
        postmanDefaultUrlQueryParam,
      })
      dispatch(postmanBuilderReducerActions.setDefaultHeader(defaultHeader))
      dispatch(postmanBuilderReducerActions.setDefaultBody(defaultBody))
      dispatch(postmanBuilderReducerActions.setDefaultUrlQueryParam(defaultQueryParam))
    } catch (err) {
      toast.error('Koleksiyon işlenirken bir hata meydana geldi.')
    }
  }

  const handleChangeFile = async file => {
    const parsedJson = await getParsedJsonFile(file)

    try {
      handleProcessCollection(parsedJson)

      handleOnClickContinueButton()
    } finally {
      /* empty */
    }
  }

  return (
    <Step title='Postman Builder' isEnableActions={false}>
      <div className='postman-data'>
        <FileUpload types={fileTypes} label='Bir koleksiyon seç' handleChange={handleChangeFile} />
      </div>
    </Step>
  )
}

export default PostmanDataStep
