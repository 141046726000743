import Popup from '@/components/Popup/Popup'
import UserUpdatePassword from '@/containers/Root/components/UserUpdatePassword/UserUpdatePassword'

import './userUpdatePasswordPopup.scss'

const UserUpdatePasswordPopup = ({ isVisible, handleClosePopup }) => {
  const handleAfterUpdatedPassword = () => {
    handleClosePopup()
  }

  return (
    <Popup title='Şifre Değiştir' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <UserUpdatePassword handleAfterUpdatedPassword={handleAfterUpdatedPassword} />
    </Popup>
  )
}

export default UserUpdatePasswordPopup
