import { useSelector } from 'react-redux'

import PageTitle from '@/components/PageTitle/PageTitle'
import { PROJECT_PERMISSIONS } from '@/constants/permissions'
import usePermission from '@/hooks/usePermission'
import HomeGridLayout from '@/pages/Home/HomeGridLayout/HomeGridLayout'

import './home.scss'

const Home = () => {
  const user = useSelector(state => state.auth.user)
  const { checkPermission } = usePermission()

  const hasWidgetPermission =
    checkPermission(PROJECT_PERMISSIONS.EDIT_HOME_WIDGETS) || checkPermission(PROJECT_PERMISSIONS.VIEW_HOME_WIDGETS)

  return (
    <>
      <PageTitle>
        <span className='home__title-prefix'>Hoş Geldin</span>, {user.name}!
      </PageTitle>
      {hasWidgetPermission && <HomeGridLayout />}
    </>
  )
}

export default Home
