import { useState } from 'react'
import { FaEdit } from 'react-icons/fa'

import RichTextEditorPopup from '@/components/FormFieldComponents/RichTextEditor/RichTextEditorPopup/RichTextEditorPopup'

import './richTextEditor.scss'

const RichTextEditor = ({ value, handleChangeValue }) => {
  const [isVisibleRichTextEditorPopup, setIsVisibleRichTextEditorPopup] = useState(false)

  const handleCloseRichTextEditorPopup = () => {
    setIsVisibleRichTextEditorPopup(false)
  }

  const handleClickEditButton = () => {
    setIsVisibleRichTextEditorPopup(true)
  }

  return (
    <div className='rich-text-editor-field'>
      <RichTextEditorPopup
        handleClosePopup={handleCloseRichTextEditorPopup}
        isVisible={isVisibleRichTextEditorPopup}
        value={value}
        handleChangeValue={handleChangeValue}
      />
      <div className='rich-text-editor-field__edit' onClick={handleClickEditButton}>
        <FaEdit className='rich-text-editor-field__edit-icon' />
        <span>Düzenlemek için tıklayın</span>
      </div>
    </div>
  )
}

export default RichTextEditor
