import clsx from 'clsx'

import CommonTextarea from '@/components/Textarea/Textarea'

import './textarea.scss'

const Textarea = ({ className, ...otherProps }) => {
  const allClassNames = clsx('build-api-textarea', className)

  return <CommonTextarea className={allClassNames} {...otherProps} />
}

export default Textarea
