import { BiHomeAlt2 } from 'react-icons/bi'

import MenuItemList from '@/containers/Root/components/common/MenuItemList/MenuItemList'
import useActiveLocation from '@/hooks/useActiveLocation'
import { isMobile } from '@/utils'

const GeneralMenuList = ({ handleClickMenuItem }) => {
  const { checkIsActiveLocation } = useActiveLocation()

  const MENU_LIST = [
    {
      title: 'GENEL',
      items: [
        {
          path: '/',
          Icon: BiHomeAlt2,
          text: 'Anasayfa',
          isActive: checkIsActiveLocation('/'),
        },
      ],
    },
  ]

  return <MenuItemList menuList={MENU_LIST} {...(isMobile() ? { handleClickMenuItem } : {})} />
}

export default GeneralMenuList
