import 'ace-builds/src-noconflict/ace'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-tomorrow'
import clsx from 'clsx'
import { useMemo, useRef } from 'react'
import AceEditor from 'react-ace'

import './jsonEditor.scss'

const JsonEditor = ({ className, value, onChange, ...otherProps }) => {
  const allClassNames = clsx('json-editor', className)
  const jsonEditorDirtyStatus = useRef(false)

  const beautifiedValue = useMemo(() => {
    if (jsonEditorDirtyStatus.current) return null

    try {
      const parsedJson = JSON.parse(value)
      return JSON.stringify(parsedJson, null, '\t')
    } catch (err) {
      return null
    }
  }, [value])

  const handleOnChange = (...payload) => {
    jsonEditorDirtyStatus.current = true
    onChange && onChange(...payload)
  }

  return (
    <AceEditor
      className={allClassNames}
      height={180}
      width={'100%'}
      mode='json'
      theme='tomorrow'
      name='json_editor'
      fontSize={14}
      lineHeight={19}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={false}
      setOptions={{
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: false,
      }}
      editorProps={{ $blockScrolling: true }}
      value={!jsonEditorDirtyStatus.current && beautifiedValue ? beautifiedValue : value}
      onChange={handleOnChange}
      {...otherProps}
    />
  )
}

export default JsonEditor
