import JsonView from '@/components/JsonView/JsonView'
import Popup from '@/components/Popup/Popup'

import './apiMoreInfoPopup.scss'

const ApiMoreInfoPopup = ({ isVisible, handleClosePopup, apiDetail }) => {
  return (
    <Popup modalClassName='api-more-info__popup' title='Detay' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <div className='api-more-info'>
        <div className='api-more-info__list'>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Başlık</div>
            <div className='api-more-info__content'>{apiDetail.title}</div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Grup</div>
            <div className='api-more-info__content'>{apiDetail.group?.name}</div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Açıklama</div>
            <div className='api-more-info__content'>{apiDetail.description}</div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>URL</div>
            <div className='api-more-info__content'>{apiDetail.url}</div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Tip</div>
            <div className='api-more-info__content'>{apiDetail.method}</div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Aksiyon Başlığı</div>
            <JsonView value={apiDetail.header} />
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Aksiyon Gövdesi</div>
            <div className='api-more-info__content'>
              <JsonView value={apiDetail.bodyFields} />
            </div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Varsayılan Aksiyon Gövdesi</div>
            <div className='api-more-info__content'>
              <JsonView value={apiDetail.defaultBody} />
            </div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Aksiyon URL Gövdesi</div>
            <div className='api-more-info__content'>
              <JsonView value={apiDetail.urlFields} />
            </div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Varsayılan Aksiyon URL Gövdesi</div>
            <div className='api-more-info__content'>
              <JsonView value={apiDetail.defaultUrlQueryParams} />
            </div>
          </div>
          <div className='api-more-info__item'>
            <div className='api-more-info__label'>Aksiyon Diğer Gövdeler</div>
            <div className='api-more-info__content'>
              <JsonView value={apiDetail.otherFields} />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default ApiMoreInfoPopup
