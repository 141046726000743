import InavoLoading from '@/components/InavoLoading/InavoLoading'

import './appLoading.scss'

const AppLoading = () => {
  return (
    <div className='app-loading'>
      <InavoLoading className='app-loading__animate' />
    </div>
  )
}

export default AppLoading
