import { BiUserCircle } from 'react-icons/bi'
import { IoIosArrowBack } from 'react-icons/io'
import { useSearchParams } from 'react-router-dom'

import ProjectUserPermissionsDetailPermissionList from '@/pages/ProjectUserPermissions/ProjectUserPermissionsDetail/ProjectUserPermissionsDetailPermissionList/ProjectUserPermissionsDetailPermissionList'

import './projectUserPermissionsDetail.scss'

const ProjectUserPermissionsDetail = ({ projectUser }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const renderUserProfileImage = () => {
    const { image } = projectUser.user

    return image ? (
      <img className='project-user-permissions-detail__img' src={image} />
    ) : (
      <BiUserCircle className='project-user-permissions-detail__default-img' />
    )
  }

  const handleOnClickBackButton = () => {
    searchParams.delete('projectUserId')
    setSearchParams(searchParams)
  }

  return (
    <div className='project-user-permissions-detail'>
      <div onClick={handleOnClickBackButton} className='project-user-permissions-detail__back-btn'>
        <IoIosArrowBack /> <span>geri</span>
      </div>
      <div className='project-user-permissions-detail__top'>
        {renderUserProfileImage()}
        <div className='project-user-permissions-detail__user-info'>
          <div className='project-user-permissions-detail__name'>{projectUser.user.name}</div>
          <div className='project-user-permissions-detail__email'>{projectUser.user.email}</div>
        </div>
      </div>
      <ProjectUserPermissionsDetailPermissionList />
    </div>
  )
}

export default ProjectUserPermissionsDetail
