export const POSTMAN_BUILDER_STEPS = {
  DATA: 'DATA',
  MANAGE_ACTIONS: 'MANAGE_ACTIONS',
  ADJUST_DEFAULT_VARIABLES: 'ADJUST_DEFAULT_VARIABLES',
  ASK_QUESTIONS: 'ASK_QUESTIONS',
  COMPLETE_PROCESS: 'COMPLETE_PROCESS',
}

export const POSTMAN_BODY_TYPES = {
  FORM_DATA: 'formdata',
  URL_ENCODED: 'urlencoded',
  RAW: 'raw',
  JSON: 'json',
  RAW_JSON: 'raw_json',
}
