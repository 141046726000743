import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { IoIosInformationCircle } from 'react-icons/io'
import Skeleton from 'react-loading-skeleton'

import { deleteChart, getChartDetail, postChart, updateChart } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import JsonEditorWithError from '@/components/JsonEditorWithError/JsonEditorWithError'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import Tooltip from '@/components/Tooltip/Tooltip'
import { API_METHODS } from '@/constants'
import ChartTypeSelect from '@/pages/Charts/ChartFormPopup/ChartTypeSelect/ChartTypeSelect'
import ChartUrlInput from '@/pages/Charts/ChartFormPopup/ChartUrlInput/ChartUrlInput'

import './chartFormPopup.scss'

const methodOptions = Object.values(API_METHODS).map(method => ({ value: method, label: method }))
const initialChartDetail = {
  type: '',
  label: '',
  url: '',
  header: {},
  method: '',
  dataKey: '',
  properties: {},
}
export const CHART_ACTION_TYPES = {
  CREATE: 'create',
}

const ChartFormPopup = ({ handleClosePopup, isVisible, chartId, handleAfterActionChart }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [chartDetail, setChartDetail] = useState(initialChartDetail)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEnabledDeleteChartContinuePopup, setIsEnabledDeleteChartContinuePopup] = useState(false)

  const isEdit = !!chartId
  const isValid = (() =>
    !!chartDetail.header &&
    !!chartDetail.label &&
    !!chartDetail.method &&
    !!chartDetail.properties &&
    !!chartDetail.type &&
    !!chartDetail.url)()

  const handleUpdateChartDetail = item => setChartDetail(prev => ({ ...prev, ...item }))
  const getPopupTitle = () => (isEdit ? 'Grafik Düzenle' : 'Grafik Oluştur')

  const handleChangeInputValue = key => e => handleUpdateChartDetail({ [key]: e.target.value })
  const handleChangeMethodOption = value => handleUpdateChartDetail({ method: value })
  const handleChangeHeader = value => handleUpdateChartDetail({ header: value })

  const handleOnClickBackButton = () => {
    handleClosePopup()
  }

  const handleCloseDeleteChartContinuePopup = () => {
    setIsEnabledDeleteChartContinuePopup(false)
  }

  const handleRemoveChart = async () => {
    try {
      handleCloseDeleteChartContinuePopup()
      setIsDeleting(true)

      await deleteChart(chartId)

      toast.success('Grafik başarıyla silindi')

      handleAfterActionChart()
      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsDeleting(false)
    }
  }

  const handleOnClickRemoveButton = async () => {
    setIsEnabledDeleteChartContinuePopup(true)
  }

  const handleUpdateChart = async () => {
    // eslint-disable-next-line no-unused-vars
    const { id, urlFields, bodyFields, projectId, ...restOfChartDetail } = chartDetail
    await updateChart(chartId, restOfChartDetail)
  }

  const handleOnClickSaveButton = async () => {
    try {
      setIsSaving(true)

      isEdit ? await handleUpdateChart() : await postChart(chartDetail)

      toast.success(isEdit ? 'Grafik başarıyla güncellendi' : 'Grafik başarıyla eklendi')

      handleAfterActionChart({
        type: CHART_ACTION_TYPES.CREATE,
      })
      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsSaving(false)
    }
  }

  const handleGetChartDetail = async () => {
    const chartDetailRes = await getChartDetail(chartId)
    setChartDetail(chartDetailRes.data.chart)
  }

  const handleAdjustChartDetail = async () => {
    try {
      setIsLoading(true)

      isEdit ? await handleGetChartDetail() : setChartDetail(initialChartDetail)
    } catch {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    isVisible && handleAdjustChartDetail()
  }, [isVisible])

  const selectedMethodIndex = methodOptions.findIndex(item => item.value === chartDetail.method)

  const getContent = () => {
    switch (true) {
      case isLoading:
        return <Skeleton count={4} className='chart-form-popup__item-skeleton' />
      default:
        return (
          <div className='chart-form-popup__list'>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>Başlık*</div>
              <div className='chart-form-popup__item-content'>
                <Input
                  value={chartDetail.label}
                  onChange={handleChangeInputValue('label')}
                  className='chart-form-popup__item-input'
                  placeholder='Başlık*'
                />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>Method*</div>
              <div className='chart-form-popup__item-content'>
                <InputDropdown
                  placeholder='Bir method seç*'
                  className='chart-form-popup__item-input'
                  items={methodOptions}
                  handleChange={handleChangeMethodOption}
                  selectedItemIndex={selectedMethodIndex}
                />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>Url*</div>
              <div className='chart-form-popup__item-content'>
                <ChartUrlInput value={chartDetail.url} handleUpdateChartDetail={handleUpdateChartDetail} />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>
                <span>Data Key</span>
                <Tooltip
                  className='chart-form-popup__data-key-info'
                  tooltipTextClassName='chart-form-popup__data-key-info--tooltip-text'
                  text='Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")'>
                  <IoIosInformationCircle />
                </Tooltip>
              </div>
              <div className='chart-form-popup__item-content'>
                <Input
                  value={chartDetail.dataKey}
                  onChange={handleChangeInputValue('dataKey')}
                  className='chart-form-popup__item-input'
                  placeholder='Data Key'
                />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>API Header*</div>
              <div className='chart-form-popup__item-content'>
                <JsonEditorWithError value={chartDetail.header} onChange={handleChangeHeader} />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-label'>Grafik Tipi</div>
              <div className='chart-form-popup__item-content'>
                <ChartTypeSelect
                  handleUpdateChartDetail={handleUpdateChartDetail}
                  value={chartDetail.type}
                  chartProperties={chartDetail.properties}
                />
              </div>
            </div>
            <div className='chart-form-popup__item'>
              <div className='chart-form-popup__item-content chart-form-popup__item-action'>
                <OutlinedButton onClick={handleOnClickBackButton}>Geri</OutlinedButton>
                {isEdit && (
                  <>
                    <ContinueAskPopup
                      title='Silmek istediğine emin misin?'
                      description='Bu grafik widget olarak kullanılıyorsa dahi silineceketir. İşlem tamamlandıktan sonra anasayfayı kontrol ediniz.'
                      isVisible={isEnabledDeleteChartContinuePopup}
                      handleClosePopup={handleCloseDeleteChartContinuePopup}
                      noButtonProps={{ onClick: handleCloseDeleteChartContinuePopup }}
                      yestButtonProps={{ onClick: handleRemoveChart }}
                    />
                    <OutlinedButton isLoading={isDeleting} className='chart-form-popup__remove-btn' onClick={handleOnClickRemoveButton}>
                      Sil
                    </OutlinedButton>
                  </>
                )}
                <PrimaryButton
                  disable={!isValid}
                  isLoading={isSaving}
                  className='chart-form-popup__save-btn'
                  onClick={handleOnClickSaveButton}>
                  Kaydet
                </PrimaryButton>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <Popup title={getPopupTitle()} isVisible={isVisible} handleClosePopup={handleClosePopup} modalClassName='chart-form-popup'>
      {getContent()}
    </Popup>
  )
}

export default ChartFormPopup
