import { useDispatch, useSelector } from 'react-redux'

import ManageActionsList from '@/components/ManageActionsList/ManageActionsList'
import Step from '@/containers/PostmanBuilder/components/Step/Step'
import { POSTMAN_BUILDER_STEPS } from '@/containers/PostmanBuilder/constants'
import { postmanBuilderReducerActions } from '@/store/reducers/postmanBuilder'

import './manageActions.scss'

const ManageActions = () => {
  const dispatch = useDispatch()

  const { actions, selectedActionIDs } = useSelector(app => app.postmanBuilder)

  const handleOnClickContinueButton = () => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.ADJUST_DEFAULT_VARIABLES))
  }

  const handleOnClickBackButton = () => {
    dispatch(postmanBuilderReducerActions.updateActiveStep(POSTMAN_BUILDER_STEPS.DATA))
  }

  const handleChangeFolderName = payload => {
    const { folderId, e } = payload
    const newFolderName = e.target.value

    const newActions = Object.entries(actions).reduce((acc, [folderName, items], index) => {
      if (index !== folderId) return { ...acc, [folderName]: items }

      return {
        ...acc,
        [newFolderName]: items,
      }
    }, {})

    dispatch(postmanBuilderReducerActions.setActions(newActions))
  }

  const handleChangeActionName = payload => {
    const { actionId, e } = payload
    const newActionName = e.target.value

    const newActions = Object.entries(actions).reduce((acc, [folderName, items]) => {
      return {
        ...acc,
        [folderName]: items.map(item => {
          if (item.api.__id !== actionId) return { ...item }

          return {
            ...item,
            api: { ...item.api, title: newActionName },
          }
        }),
      }
    }, {})

    dispatch(postmanBuilderReducerActions.setActions(newActions))
  }

  const handleOnChange = _selectedIDs => {
    dispatch(postmanBuilderReducerActions.setSelectedActionIDs([..._selectedIDs]))
  }

  const itemList = Object.entries(actions).map(([folderName, folderItem], index) => ({
    folderId: index,
    folderName: folderName,
    items: folderItem.map(({ api }) => ({ id: api.__id, label: api.title })),
  }))

  return (
    <Step
      handleOnClickContinueButton={handleOnClickContinueButton}
      handleOnClickBackButton={handleOnClickBackButton}
      title='Aksiyon Yönetme'>
      <ManageActionsList
        handleChangeFolderName={handleChangeFolderName}
        handleChangeActionName={handleChangeActionName}
        itemList={itemList}
        selectedItemIds={selectedActionIDs}
        handleOnChange={handleOnChange}
      />
    </Step>
  )
}

export default ManageActions
