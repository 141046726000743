import { Draggable, Droppable } from '@hello-pangea/dnd'
import clsx from 'clsx'
import { IoMove } from 'react-icons/io5'
import { MdDelete } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import BuildFormPageItem from '@/containers/BuildApi/BuildApiContent/BuildFormPage/BuildFormPageRenderItem/BuildFormPageItem'
import { FIELDS_PAGE_DROPPABLE_ID } from '@/containers/BuildApi/constants'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildFormPage.scss'

const BuildFormPage = () => {
  const dispatch = useDispatch()

  const { api, selectedFieldId } = useSelector(state => {
    const api = state.buildApi.api
    const selectedFieldId = state.buildApi.selectedFieldId

    return { api, selectedFieldId }
  })
  const fields = api.fields

  const checkIsSelectedField = fieldId => fieldId === selectedFieldId

  const handleOnClickItem = item => () => {
    dispatch(buildApiReducerActions.setSelectedFieldId(item.id))
    dispatch(buildApiReducerActions.setIsActiveRightPanel(true))
  }

  const handleClickDeleteIcon = selectedField => () => {
    const newApiFields = api.fields.filter(field => field.id !== selectedField.id)
    dispatch(buildApiReducerActions.setApiFields(newApiFields))

    dispatch(buildApiReducerActions.setSelectedFieldId())
    dispatch(buildApiReducerActions.setIsActiveRightPanel(false))
  }
  const renderItemActions = field => {
    return (
      <div className='build-form-page__draggable-item-actions'>
        <div
          onClick={handleClickDeleteIcon(field)}
          className='build-form-page__draggable-item-action build-form-page__draggable-item-action--delete'>
          <MdDelete />
        </div>
      </div>
    )
  }

  const renderWelcome = () => {
    return (
      <div className='build-form-page__draggable-welcome'>
        <IoMove />
        <span>Sol panelden buraya sürükle</span>
      </div>
    )
  }

  return (
    <div className='build-form-page'>
      <Droppable key={FIELDS_PAGE_DROPPABLE_ID} droppableId={FIELDS_PAGE_DROPPABLE_ID}>
        {(provided, snapshot) => (
          <div
            className={clsx('build-form-page__draggable-container', {
              'build-form-page__draggable-container--is-dragging': snapshot.isDraggingOver,
            })}
            ref={provided.innerRef}>
            {fields.length > 0
              ? fields.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={clsx('build-form-page__draggable-item', {
                          'build-form-page__draggable-item--selected': checkIsSelectedField(field.id),
                          'build-form-page__draggable-item--is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                        {...provided.dragHandleProps}
                        onClick={handleOnClickItem(field)}>
                        <BuildFormPageItem field={field} />
                        {renderItemActions(field)}
                      </div>
                    )}
                  </Draggable>
                ))
              : renderWelcome()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default BuildFormPage
