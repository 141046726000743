import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ManageActionsList from '@/components/ManageActionsList/ManageActionsList'
import { apiCollectionReducerActions } from '@/store/reducers/apiCollection'

import './ExportApiManageStep'

const ExportApiManageStep = () => {
  const [itemList, setItemList] = useState([])

  const selectedApiIds = useSelector(state => state.apiCollection.selectedApiIds)
  const projectApis = useSelector(state => state.app.projectApis)
  const dispatch = useDispatch()

  const handleOnChange = selectedIds => {
    dispatch(apiCollectionReducerActions.updateSelectedApiIds([...selectedIds]))
  }

  useEffect(() => {
    const preparedItemList = projectApis.map(({ id, name, api }) => ({
      folderId: id,
      folderName: name,
      items: api.map(item => ({ id: item.id, label: item.title })),
    }))

    setItemList(preparedItemList)
  }, [])

  return <ManageActionsList selectedItemIds={selectedApiIds} itemList={itemList} handleOnChange={handleOnChange} />
}

export default ExportApiManageStep
