import { clsx } from 'clsx'
import { useRef, useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'
import { IoIosLogOut } from 'react-icons/io'
import { RiUserSettingsLine } from 'react-icons/ri'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { TbPassword } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import ConnectIcon from '@/assets/icons/connect.png'
import { ROUTE_URLS } from '@/constants/routeUrls'
import UserSettingsPopup from '@/containers/Root/Header/UserSettingsPopup/UserSettingsPopup'
import UserUpdatePasswordPopup from '@/containers/Root/Header/UserUpdatePasswordPopup/UserUpdatePasswordPopup'
import CloseButton from '@/containers/Root/components/CloseButton/CloseButton'
import Search from '@/containers/Root/components/Search/Search'
import useClickOutside from '@/hooks/useClickOutside'
import usePermission from '@/hooks/usePermission'
import { appReducerActions } from '@/store/reducers/app'
import { isMobile } from '@/utils'

import './header.scss'

const Header = () => {
  const [isEnabledUserDropdown, setIsEnabledUserDropdown] = useState(false)
  const [isEnabledUserUpdatePasswordPopup, setIsEnabledUserUpdatePasswordPopup] = useState(false)
  const [isEnabledCreateApiDropdown, setIsEnabledCreateApiDropdown] = useState(false)

  const userButtonRef = useRef()
  const createApiButtonRef = useRef()
  const dispatch = useDispatch()

  const { isProjectAdmin } = usePermission()
  const { user, isEnabledUserSettingsDropdown } = useSelector(state => {
    const { user } = state.auth
    const { isEnabledUserSettingsDropdown } = state.app

    return { user, isEnabledUserSettingsDropdown }
  })

  useClickOutside(userButtonRef, () => {
    setIsEnabledUserDropdown(false)
  })

  useClickOutside(createApiButtonRef, () => {
    setIsEnabledCreateApiDropdown(false)
  })

  const handleOnClickUserSettings = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(true))
  }

  const renderUserImage = () => {
    const userImage = user.image

    return userImage ? <img className='header__user-img' src={userImage} /> : <BiUserCircle className='header__user-icon' />
  }

  const handleOnClickUserButton = () => {
    setIsEnabledUserDropdown(!isEnabledUserDropdown)
  }

  const handleCloseUserSettingsPopup = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(false))
  }

  const handleOnClickUserUpdatePassword = () => {
    setIsEnabledUserUpdatePasswordPopup(true)
  }

  const handleCloseUserUpdatePassword = () => {
    setIsEnabledUserUpdatePasswordPopup(false)
  }

  const handleOnClickCreateApiButton = () => {
    setIsEnabledCreateApiDropdown(prev => !prev)
  }

  return (
    <div className='header'>
      <UserSettingsPopup isVisible={isEnabledUserSettingsDropdown} handleClosePopup={handleCloseUserSettingsPopup} />
      <UserUpdatePasswordPopup isVisible={isEnabledUserUpdatePasswordPopup} handleClosePopup={handleCloseUserUpdatePassword} />
      <div className='header__item header__search-wrapper'>
        <Search />
      </div>
      {isProjectAdmin && !isMobile() && (
        <div className='header__item header__item--create-api-btn header__create-api-button-wrapper'>
          <div ref={createApiButtonRef} onClick={handleOnClickCreateApiButton} className='header__create-api-btn'>
            <span className='header__create-api-btn-text'>Aksiyon Oluştur</span>
            <RiArrowDropDownLine size={26} />
          </div>
          <div
            className={clsx('header__create-api-dropdown', {
              'header__create-api-dropdown--enabled': isEnabledCreateApiDropdown,
            })}>
            <Link to={ROUTE_URLS.BUILD_API} className='header__create-api-dropdown-item'>
              Manual Oluştur
            </Link>
            <Link to={ROUTE_URLS.POSTMAN_BUILDER} className='header__create-api-dropdown-item header__create-api-dropdown-item--postman'>
              <img src={ConnectIcon} className='header__create-api-postman-icon ' />
              <span>Postman Entegrasyonu</span>
            </Link>
          </div>
        </div>
      )}
      <div className='header__item header__user'>
        <div ref={userButtonRef} onClick={handleOnClickUserButton} className='header__user-button'>
          {renderUserImage()}
          <div className='header__user-name'>{user.name}</div>
        </div>
        <div className={clsx('header__user-dropdown', { 'header__user-dropdown--enabled': isEnabledUserDropdown })}>
          <div onClick={handleOnClickUserSettings} className='header__user-item'>
            <RiUserSettingsLine className='header__user-item-icon' />
            <span>Hesap Ayarları</span>
          </div>
          <div onClick={handleOnClickUserUpdatePassword} className='header__user-item'>
            <TbPassword className='header__user-item-icon' />
            <span>Şifre Değiştir</span>
          </div>
          <div className='header__user-item'>
            <CloseButton>
              <span className='header__close-button-content'>
                <IoIosLogOut className='header__user-item-icon' />
                Çıkış Yap
              </span>
            </CloseButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
