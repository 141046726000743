import { v4 as uuidv4 } from 'uuid'

import './fileInput.scss'

const FileInput = ({ id = uuidv4(), children, ...otherProps }) => {
  return (
    <div className='file-input'>
      <label className='file-input__text' htmlFor={id}>
        {children}
      </label>
      <input {...otherProps} id={id} type='file' className='file-input__input' />
    </div>
  )
}

export default FileInput
