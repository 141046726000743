import { useState } from 'react'
import toast from 'react-hot-toast'
import { FaCheck } from 'react-icons/fa6'
import { IoMdClose } from 'react-icons/io'
import { MdDeleteOutline } from 'react-icons/md'
import { MdOutlineEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { deleteProjectApiGroup, postProjectApiGroup, updateProjectApiGroup } from '@/api'
import Input from '@/components/Input/Input'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { buildApiReducerActions } from '@/store/reducers/buildApi'

import './buildApiRightPanelApiGroupPopup.scss'

const hasRelatedToApi = group => group._count.api > 0

const RenderEditItem = props => {
  const { group, handleClearEditingApiGroupId } = props

  const [editName, setEditName] = useState(group.name)

  const dispatch = useDispatch()

  const projectApiGroups = useSelector(state => state.buildApi.projectApiGroups)

  const handleChangeGroupName = e => {
    setEditName(e.target.value)
  }

  const handleCancelButton = () => {
    handleClearEditingApiGroupId()
  }

  const handleSaveButton = async () => {
    try {
      await updateProjectApiGroup(group.id, { name: editName })

      const newProjectApiGroups = projectApiGroups.map(apiGroup => (apiGroup.id !== group.id ? apiGroup : { ...apiGroup, name: editName }))
      dispatch(buildApiReducerActions.setProjectApiGroups(newProjectApiGroups))

      handleClearEditingApiGroupId()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    }
  }

  return (
    <div className='build-api-project-api-groups__edit-item'>
      <Input onChange={handleChangeGroupName} value={editName} className='build-api-project-api-groups__edit-input' />
      <div className='build-api-project-api-groups__edit-actions'>
        <IoMdClose
          onClick={handleCancelButton}
          className='build-api-project-api-groups__edit-action build-api-project-api-groups__edit-action--cancel'
        />
        <FaCheck
          onClick={handleSaveButton}
          className='build-api-project-api-groups__edit-action build-api-project-api-groups__edit-action--save'
        />
      </div>
    </div>
  )
}

const BuildApiRightPanelApiGroupPopup = ({ isVisible, handleClosePopup }) => {
  const [groupName, setGroupName] = useState('')
  const [isLoadingSave, setIsLoadingSave] = useState(false)
  const [editingApiGroupId, setEditingApiGroupId] = useState(null)

  const projectApiGroups = useSelector(state => state.buildApi.projectApiGroups)
  const isDisableSaveGroupButton = groupName === ''

  const dispatch = useDispatch()

  const handleClearEditingApiGroupId = () => {
    setEditingApiGroupId(null)
  }

  const handleClearGroupName = () => {
    setGroupName('')
  }

  const handleChangeGroupNameInput = e => {
    setGroupName(e.target.value)
  }

  const handleClickSaveGroupButton = async () => {
    try {
      setIsLoadingSave(true)

      const addedGroupRes = await postProjectApiGroup({ name: groupName })
      dispatch(buildApiReducerActions.addProjectApiGroups(addedGroupRes.data.group))

      handleClearGroupName()
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsLoadingSave(false)
    }
  }

  const handleOnClickDeleteApiGroup = projectApiGroupId => async () => {
    try {
      await deleteProjectApiGroup(projectApiGroupId)

      const newProjectApiGroups = projectApiGroups.filter(group => group.id !== projectApiGroupId)
      dispatch(buildApiReducerActions.setProjectApiGroups(newProjectApiGroups))
    } catch (err) {
      toast.error('Bir hata oluştu')
    }
  }

  const handleOnClickEditApiGroup = apiGroupId => () => {
    setEditingApiGroupId(apiGroupId)
  }

  const renderItemContent = group => {
    return (
      <div key={group.id} className='build-api-project-api-groups__item'>
        <div className='build-api-project-api-groups__name'>{group.name}</div>
        {hasRelatedToApi(group) && <div className='build-api-project-api-groups__api-count'> {group._count.api} tane aksiyon</div>}
        <div className='build-api-project-api-groups__action-list'>
          <div onClick={handleOnClickEditApiGroup(group.id)} className='build-api-project-api-groups__action'>
            <MdOutlineEdit />
          </div>
          {!hasRelatedToApi(group) && (
            <div
              onClick={handleOnClickDeleteApiGroup(group.id)}
              className='build-api-project-api-groups__action build-api-project-api-groups__action--delete'>
              <MdDeleteOutline />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Popup
      modalClassName='build-api-project-api-groups__popup'
      title='Aksiyon Grup'
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}>
      <div className='build-api-project-api-groups'>
        <div className='build-api-project-api-groups__top'>
          <Input
            placeholder='Grup ismi...'
            onChange={handleChangeGroupNameInput}
            value={groupName}
            className='build-api-project-api-groups__name-input'
          />
          <PrimaryButton
            onClick={handleClickSaveGroupButton}
            disable={isDisableSaveGroupButton}
            isLoading={isLoadingSave}
            className='build-api-project-api-groups__save-group-btn'>
            Kaydet
          </PrimaryButton>
        </div>
        <div className='build-api-project-api-groups__list'>
          {projectApiGroups.map(group =>
            group.id === editingApiGroupId ? (
              <RenderEditItem key={group.id} group={group} handleClearEditingApiGroupId={handleClearEditingApiGroupId} />
            ) : (
              renderItemContent(group)
            ),
          )}
        </div>
      </div>
    </Popup>
  )
}

export default BuildApiRightPanelApiGroupPopup
