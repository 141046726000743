import { useDispatch, useSelector } from 'react-redux'

import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import Input from '@/containers/BuildApi/components/Settings/Input/Input'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'

const BuildApiRightPanelToggleSwitchAdvancedSettings = () => {
  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const fieldProperties = selectedField?.properties || {}

  const dispatch = useDispatch()

  const handleChangeInput = key => e => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ [key]: e.target.value }))
  }

  return (
    <>
      <BuildApiSettingWrapper title='Aktif Değer'>
        <Input value={fieldProperties.onValue} onChange={handleChangeInput('onValue')} placeholder='Aktif Değer' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Pasif Değer'>
        <Input value={fieldProperties.offValue} onChange={handleChangeInput('offValue')} placeholder='Pasif Değer' />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelToggleSwitchAdvancedSettings
