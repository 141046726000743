import InputDropdown from '@/components/InputDropdown/InputDropdown'
import { ORDER_BYS } from '@/constants'

import './projectLogOrderByFilter.scss'

const items = [
  {
    label: 'Bugünden geçmişe',
    value: ORDER_BYS.DESC,
  },
  {
    label: 'Geçmişten bugüne',
    value: ORDER_BYS.ASC,
  },
]

const ProjectLogOrderByFilter = ({ value, handleUpdateFilters }) => {
  const selectedItemIndex = items.findIndex(item => item.value === value)

  const handleChangeSelect = _value => {
    handleUpdateFilters({
      orderBy: _value,
    })
  }

  return (
    <InputDropdown
      className='project-log-order-filter'
      items={items}
      selectedItemIndex={selectedItemIndex}
      handleChange={handleChangeSelect}
      isEnableDeleteItemIcon={false}
    />
  )
}

export default ProjectLogOrderByFilter
