import { useLocation } from 'react-router-dom'

const useActiveLocation = () => {
  const location = useLocation()

  const checkIsActiveLocation = path => location.pathname === path

  return {
    checkIsActiveLocation,
  }
}

export default useActiveLocation
