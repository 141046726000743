import ActionMenuList from '@/containers/Root/components/ActionMenuList/ActionMenuList'
import Copyright from '@/containers/Root/components/Copyright/Copyright'
import GeneralMenuList from '@/containers/Root/components/GeneralMenuList/GeneralMenuList'
import MenuList from '@/containers/Root/components/MenuList/MenuList'

import './leftMenuList.scss'

const LeftMenuList = () => {
  return (
    <div className='left-menu-list'>
      <div className='left-menu-list__wrapper'>
        <GeneralMenuList />
      </div>
      <div className='left-menu-list__wrapper'>
        <ActionMenuList />
      </div>
      <div className='left-menu-list__wrapper'>
        <MenuList />
      </div>
      <div className='left-menu-list__wrapper left-menu-list__wrapper--copyright'>
        <Copyright />
      </div>
    </div>
  )
}

export default LeftMenuList
