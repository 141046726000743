import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { IoMdRefresh } from 'react-icons/io'

import InavoLoading from '@/components/InavoLoading/InavoLoading'
import TablePagination from '@/components/Table/TablePagination/TablePagination'

import './table.scss'

const PAGINATION_ITEM_COUNT = 20
const MOCK_EMPTY_CELL_DATA = '-'
export const TABLE_ACTIONS = {
  REFRESH: 'REFRESH',
}
const TableLoading = () => (
  <div className='table__loading-wrapper'>
    <InavoLoading className='table__loading' />
  </div>
)

const Table = ({
  columns,
  data,
  label,
  totalResult: _totalResult,
  handleOnChangePagination: _handleOnChangePagination,
  disablePaginationItemLimit = false,
  isLoadingData,
  isLoadingContent,
  isRenderEmptyDataWrapper = false,
  paginationRangeSize,
  activeActions = [],
  handleOnClickRefreshAction,
}) => {
  const [currentPagination, setCurrentPagination] = useState(1)

  const tableContent = useRef()

  const preparedData = disablePaginationItemLimit
    ? data
    : data.slice((currentPagination - 1) * PAGINATION_ITEM_COUNT, currentPagination * PAGINATION_ITEM_COUNT)
  const totalResult = _totalResult ?? data.length
  const hasData = data.length > 0
  const isEnabledTableTop = !!label && activeActions.length > 0

  const checkHasAction = actionType => activeActions.includes(actionType)

  const handleChangePagination = _currentPagination => {
    setCurrentPagination(_currentPagination)

    tableContent.current.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const getCellRowData = data => {
    switch (true) {
      case Array.isArray(data):
        return data.join(',')
      case typeof data === 'object' && data !== null:
        return JSON.stringify(data)
      case !data && isRenderEmptyDataWrapper:
        return MOCK_EMPTY_CELL_DATA
      default:
        return data
    }
  }

  const renderRow = data => {
    const rowData = columns.map(({ dataIndex }) => _.get(data, dataIndex))

    return (
      <div className='table__row'>
        {rowData.map((data, index) => {
          const cellRowData = getCellRowData(data)

          return (
            <div title={cellRowData} key={`row_data_${index}`} className='table__cell'>
              {cellRowData}
            </div>
          )
        })}
      </div>
    )
  }

  const renderHeader = column => {
    const { title } = column

    return (
      <div title={title} className='table__header-title table__cell' key={`header_key_${column.dataIndex}`}>
        {title}
      </div>
    )
  }

  const renderTotalResult = () => {
    return totalResult && <div className='table__total-result'>Toplam {totalResult} veri</div>
  }

  const renderContent = () => {
    switch (true) {
      case isLoadingContent:
        return <TableLoading />
      case !hasData:
        return <div className='table__no-data'>Hiç veri yok</div>
      default:
        return preparedData.map(renderRow)
    }
  }

  useEffect(() => {
    _handleOnChangePagination && _handleOnChangePagination(currentPagination)
  }, [currentPagination])

  return (
    <div className='table'>
      <>
        {isEnabledTableTop && (
          <div className='table__section-row table__top'>
            {label && <div className='table__label'>{label}</div>}
            <div className='table__actions'>
              {checkHasAction(TABLE_ACTIONS.REFRESH) && <IoMdRefresh onClick={handleOnClickRefreshAction} className='table__action-icon' />}
            </div>
          </div>
        )}
        <div className='table__section-row table__header'>{columns.map(renderHeader)}</div>
        {isLoadingData ? (
          <TableLoading />
        ) : (
          <>
            <div ref={tableContent} className='table__content'>
              {renderContent()}
            </div>
            {hasData && (
              <div className='table__section-row table__bottom'>
                {renderTotalResult()}
                <div className='table__table-pagination'>
                  <TablePagination
                    total={totalResult}
                    handleChangePagination={handleChangePagination}
                    paginationRangeSize={paginationRangeSize || PAGINATION_ITEM_COUNT}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default Table
