import clsx from 'clsx'
import tr from 'date-fns/locale/tr'
import { useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import './datePicker.scss'

registerLocale('tr', tr)

const DatePicker = ({ className, onFocus, onBlur, ...props }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleOnFocus = () => {
    setIsFocused(true)
    onFocus && onFocus()
  }

  const handleOnBlur = () => {
    setIsFocused(false)
    onBlur && onBlur()
  }

  return (
    <ReactDatePicker
      locale='tr'
      className={clsx('date-picker', className, { 'date-picker--focused': isFocused })}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      {...props}
    />
  )
}

export default DatePicker
