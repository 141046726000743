import { useSelector } from 'react-redux'

import MixTagifyInput from '@/components/MixTagifyInput/MixTagifyInput'

import './chartUrlInput.scss'

const ChartUrlInput = ({ value, handleUpdateChartDetail }) => {
  const apiDefaultUrlQueryParams = useSelector(state => state.app.projectSettings.apiDefaultUrlQueryParams)

  const chartUrlWhiteList = Object.keys(apiDefaultUrlQueryParams).map(queryParam => {
    const preparedKey = `:${queryParam}`

    return { value: preparedKey, label: preparedKey }
  })

  const handleOnChangeChartUrl = val => handleUpdateChartDetail({ url: val.replace(/\s/g, '') })

  return <MixTagifyInput whiteList={chartUrlWhiteList} onChange={handleOnChangeChartUrl} value={value} />
}

export default ChartUrlInput
