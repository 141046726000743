import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import ErrorPage from '@/components/ErrorPage/ErrorPage'

// eslint-disable-next-line react/display-name
const withRootErrorBoundary = WrapperComponent => props => {
  return (
    <ErrorBoundary fallback={ErrorPage}>
      <WrapperComponent {...props} />
    </ErrorBoundary>
  )
}

export default withRootErrorBoundary
