import { FaCheck } from 'react-icons/fa'
import { RiAddFill, RiDragMove2Fill } from 'react-icons/ri'

import ArrowLineReverse from '@/assets/icons/right-arrow-reverse.png'
import ArrowLine from '@/assets/icons/right-arrow.png'
import CircleLightLogo from '@/assets/logos/circle-light-logo.svg'

import './homeWidgetGuide.scss'

const HomeWidgetGuide = () => {
  return (
    <div className='home-widget-guide'>
      <img src={CircleLightLogo} className='home-widget-guide__brand' />
      <div className='home-widget-guide__step'>
        <RiAddFill className='home-widget-guide__icon' />
        <div className='home-widget-guide__title'>Ekle</div>
        <div className='home-widget-guide__desc'>
          <span className='home-widget-guide__bold-word'>Grafik</span> veya <span className='home-widget-guide__bold-word'>Tablo</span>{' '}
          ekleyebilirsin.
        </div>
      </div>
      <img src={ArrowLine} className='home-widget-guide__arrow-icon' />
      <div className='home-widget-guide__step'>
        <RiDragMove2Fill className='home-widget-guide__icon' />
        <div className='home-widget-guide__title'>Düzenle</div>
        <div className='home-widget-guide__desc'>
          Eklediğin grafik ya da tabloyu anasayfanda sürükle/bırak ile istediğin gibi konumlandırabilirsin.
        </div>
      </div>
      <img src={ArrowLineReverse} className='home-widget-guide__arrow-icon' />
      <div className='home-widget-guide__step'>
        <FaCheck className='home-widget-guide__icon' />
        <div className='home-widget-guide__title'>Hazır</div>
        <div className='home-widget-guide__desc'>Anasayfan düzenlediğin şekilde kalır.</div>
      </div>
    </div>
  )
}

export default HomeWidgetGuide
