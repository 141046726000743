import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import { updateProject } from '@/api'
import ColorPickerInput from '@/components/ColorPickerInput/ColorPickerInput'
import InputWithLabel from '@/components/InputWithLabel/InputWithLabel'
import JsonEditorWithLabel from '@/components/JsonEditorWithLabel/JsonEditorWithLabel'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { appReducerActions } from '@/store/reducers/app'
import { setApexColor } from '@/utils'

import './projectAdvancedSettings.scss'

const ProjectAdvancedSettings = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [projectDetail, setProjectDetail] = useState({})
  const [projectJsonDetail, setProjectJsonDetail] = useState({})
  const [projectDetailErrors, setProjectDetailErrors] = useState({
    apiDefaultHeader: false,
    apiDefaultBody: false,
    apiDefaultUrlQueryParams: false,
    configs: false,
    url: false,
  })

  const dispatch = useDispatch()

  const projectSettings = useSelector(state => state.app.projectSettings)
  const { settings } = projectSettings

  const handleOnChangeDetail = item => setProjectDetail(prev => ({ ...prev, ...item }))
  const handleOnChangeJsonDetail = item => setProjectJsonDetail(prev => ({ ...prev, ...item }))
  const handleUpdateProjectDetailError = item => setProjectDetailErrors(prev => ({ ...prev, ...item }))

  const handleChangeInput = key => e => handleOnChangeDetail({ [key]: e.target.value })
  const handleOnChangeJson = key => value => {
    handleOnChangeJsonDetail({ [key]: value })

    try {
      JSON.parse(value)
      handleUpdateProjectDetailError({ [key]: false })
    } catch (err) {
      handleUpdateProjectDetailError({ [key]: true })
    }
  }
  const handleOnChangeSettings = item => {
    handleOnChangeDetail({
      settings: {
        ...projectDetail.settings,
        ...item,
      },
    })
  }

  const handleOnChangeBaseColor = value => {
    handleOnChangeSettings({ baseColor: value })
  }

  const handleChangeInputWithErrorCheck = key => e => {
    const value = e.target.value

    handleUpdateProjectDetailError({ [key]: !value })
    handleChangeInput(key)(e)
  }

  const validateProjectDetail = () => {
    const hasJsonDetailErrors = Object.values(projectDetailErrors).some(Boolean)

    return !hasJsonDetailErrors
  }

  const getAllProjectDetailAsFormData = () => {
    const formData = new FormData()

    Object.keys(projectDetail).forEach(detailKey => {
      const value = projectDetail[detailKey]
      const normalizedValue = typeof value === 'object' ? JSON.stringify(value) : value
      formData.append(detailKey, normalizedValue)
    })
    Object.keys(projectJsonDetail).forEach(jsonDetailKey => {
      formData.append(jsonDetailKey, projectJsonDetail[jsonDetailKey])
    })

    return formData
  }

  const handleAfterSaveProjectSettings = () => {
    const { settings } = projectDetail

    if (settings?.baseColor) {
      setApexColor(settings.baseColor)
    }
  }

  const handleOnClick = async () => {
    try {
      setIsSubmitting(true)

      const projectDetailFormData = getAllProjectDetailAsFormData()
      const updatedProjectRes = await updateProject(projectDetailFormData)

      const projectSettings = updatedProjectRes.data.updatedProject

      dispatch(appReducerActions.updateProjectSettings(projectSettings))

      toast.success('Bilgiler güncellendi')

      handleAfterSaveProjectSettings()
    } catch (err) {
      toast.error('Bir hata oluştu')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSetProjectDetail = () => {
    const { apiBaseUrl, apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs, settings } = projectSettings

    setProjectJsonDetail({
      apiDefaultHeader: JSON.stringify(apiDefaultHeader),
      apiDefaultBody: JSON.stringify(apiDefaultBody),
      apiDefaultUrlQueryParams: JSON.stringify(apiDefaultUrlQueryParams),
      configs: JSON.stringify(configs),
    })
    setProjectDetail({ apiBaseUrl, settings })
  }

  useEffect(() => {
    handleSetProjectDetail()
  }, [])

  return (
    <div className='project-advanced-settings'>
      <div className='project-advanced-settings__list'>
        <div className='project-advanced-settings__item'>
          <div className='project-advanced-settings__label'>Temel Renk</div>
          <div className='project-advanced-settings__content'>
            <ColorPickerInput value={settings?.baseColor} handleChangeValue={handleOnChangeBaseColor} />
          </div>
          <div>{!settings?.baseColor && <div className='project-advanced-settings__info'>Hiç renk girilmemiş</div>}</div>
        </div>
        <div className='project-advanced-settings__item'>
          <InputWithLabel
            inputProps={{
              className: 'project-advanced-settings__input',
              value: projectDetail.apiBaseUrl,
              onChange: handleChangeInputWithErrorCheck('apiBaseUrl'),
            }}
            label='URL'
          />
          {projectDetailErrors.apiBaseUrl && <div className='project-advanced-settings__item-error'>Boş olamaz</div>}
        </div>
        <div className='project-advanced-settings__item'>
          <JsonEditorWithLabel
            label='API Default Headers'
            jsonEditorProps={{
              value: projectJsonDetail.apiDefaultHeader,
              onChange: handleOnChangeJson('apiDefaultHeader'),
            }}
          />
          {projectDetailErrors.apiDefaultHeader && <div className='project-advanced-settings__item-error'>Geçersiz JSON</div>}
        </div>
        <div className='project-advanced-settings__item'>
          <JsonEditorWithLabel
            label='API Default Body'
            jsonEditorProps={{
              value: projectJsonDetail.apiDefaultBody,
              onChange: handleOnChangeJson('apiDefaultBody'),
            }}
          />
          {projectDetailErrors.apiDefaultBody && <div className='project-advanced-settings__item-error'>Geçersiz JSON</div>}
        </div>
        <div className='project-general-settings__item'>
          <JsonEditorWithLabel
            label='API Default URL Query Params'
            jsonEditorProps={{
              value: projectJsonDetail.apiDefaultUrlQueryParams,
              onChange: handleOnChangeJson('apiDefaultUrlQueryParams'),
            }}
          />
          {projectDetailErrors.apiDefaultUrlQueryParams && <div className='project-advanced-settings__item-error'>Geçersiz JSON</div>}
        </div>
        <div className='project-advanced-settings__item'>
          <JsonEditorWithLabel
            label='Configs'
            jsonEditorProps={{
              value: projectJsonDetail.configs,
              onChange: handleOnChangeJson('configs'),
            }}
          />
          {projectDetailErrors.configs && <div className='project-advanced-settings__item-error'>Geçersiz JSON</div>}
        </div>
      </div>
      <PrimaryButton
        disable={!validateProjectDetail()}
        isLoading={isSubmitting}
        className='project-advanced-settings__save-button'
        onClick={handleOnClick}>
        Kaydet
      </PrimaryButton>
    </div>
  )
}

export default ProjectAdvancedSettings
