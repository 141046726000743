import ApiDynamicForm from '@/pages/ApiExecute/components/DynamicForm/DynamicForm'

import './apiDynamicParametersForm.scss'

const ApiDynamicParametersForm = ({ handleChangeFieldValue, fieldValues, fields }) => {
  return (
    <ApiDynamicForm
      fieldValues={fieldValues}
      fields={fields}
      handleChangeFieldValue={handleChangeFieldValue}
      fieldWrapperClassName='dynamic-parameters__field-wrapper'
    />
  )
}

export default ApiDynamicParametersForm
