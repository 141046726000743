import MultipleInputList from '@/components/MultipleInputList/MultipleInputList'

import './tableColumns.scss'

const inputs = [
  {
    key: 'title',
    placeholder: 'Başlık',
  },
  {
    key: 'dataIndex',
    placeholder: 'Data Index Key',
  },
]

const TableColumns = ({ value, handleUpdateTableDetail }) => {
  const handleUpdateTableColumns = _newColumns => handleUpdateTableDetail({ columns: _newColumns })

  const handleValidateItem = item => Object.values(item).every(value => !!value)

  const handleOnSave = columnItem => {
    const isValid = Object.values(columnItem).every(value => !!value)
    if (!isValid) return

    handleUpdateTableColumns([...value, columnItem])
  }

  const handleOnDelete = columnIndex => {
    const newColumns = value.filter((_, index) => index !== columnIndex)
    handleUpdateTableColumns(newColumns)
  }

  const handleOnUpdate = newValue => handleUpdateTableColumns([...newValue])

  return (
    <MultipleInputList
      items={value}
      inputs={inputs}
      onSave={handleOnSave}
      onDelete={handleOnDelete}
      onUpdate={handleOnUpdate}
      validateItem={handleValidateItem}
      listClassName='table-columns__list'
    />
  )
}

export default TableColumns
