import { API_METHODS } from '@/constants'
import { POSTMAN_BODY_TYPES } from '@/containers/PostmanBuilder/constants'

const POSTMAN_REGEX = /\{\{[^{}]+\}\}/
export const POSTMAN_REPLACE_PATTERN = /{{(\w+)}}/g
export const CONSOLE_DYNAMIC_PREFIX_PATTERN = ':$1'

export const isDynamicPostmanVariable = (variable = '') => {
  if (typeof variable !== 'string') return false

  return POSTMAN_REGEX.test(variable)
}

export const replaceWithConsoleDynamicPrefix = text => text.replaceAll(POSTMAN_REPLACE_PATTERN, CONSOLE_DYNAMIC_PREFIX_PATTERN)

export const getAddedConsoleDynamicPrefix = text => `:${text}`

export const getBodyType = request => {
  switch (true) {
    case request?.body?.mode === POSTMAN_BODY_TYPES.FORM_DATA:
      return POSTMAN_BODY_TYPES.FORM_DATA
    case request?.body?.mode === POSTMAN_BODY_TYPES.URL_ENCODED:
      return POSTMAN_BODY_TYPES.URL_ENCODED
    case request?.body?.mode === POSTMAN_BODY_TYPES.RAW && request?.body?.options?.raw?.language === POSTMAN_BODY_TYPES.JSON:
      return POSTMAN_BODY_TYPES.RAW_JSON
    default:
      return null
  }
}

export const isValidMethod = method => Object.values(API_METHODS).includes(method)

export const getUrlQueryParameters = payload => {
  const { request } = payload
  const urlQueryParameters = request.url.query

  if (!urlQueryParameters) return null

  return request.url.query.filter(item => !item.disabled) || []
}

const getFormDataBody = request => {
  return request.body.formdata
    .filter(({ disabled }) => !disabled)
    .map(({ key, value }) => ({
      key,
      value,
    }))
}

const getUrlEncodedBody = request => {
  return request.body.urlencoded
    .filter(({ disabled }) => !disabled)
    .map(({ key, value }) => ({
      key,
      value,
    }))
}

const getRawJsonBody = request => {
  const jsonRawString = request.body.raw || '{}'
  const jsonBody = JSON.parse(jsonRawString)

  return Object.entries(jsonBody).map(([key, value]) => ({
    key,
    value,
  }))
}

export const getBody = payload => {
  const { request } = payload
  const bodyType = getBodyType(request)

  // We should create all actions smoothly
  try {
    switch (bodyType) {
      case POSTMAN_BODY_TYPES.FORM_DATA:
        return {
          items: getFormDataBody(request),
          type: POSTMAN_BODY_TYPES.FORM_DATA,
        }
      case POSTMAN_BODY_TYPES.URL_ENCODED:
        return {
          items: getUrlEncodedBody(request),
          type: POSTMAN_BODY_TYPES.URL_ENCODED,
        }
      case POSTMAN_BODY_TYPES.RAW_JSON:
        return {
          items: getRawJsonBody(request),
          type: POSTMAN_BODY_TYPES.RAW_JSON,
        }
      default:
        return null
    }
  } catch (err) {
    return null
  }
}

export const getUrl = request => {
  const _url = request.url
  const url = typeof _url === 'object' ? _url.raw : _url

  return url
}

export const getHeader = request => request.header

export const getDescription = request => request.description || ''

export const getMethod = request => request.method.toLowerCase()

const getFolderName = ({ isDirectAction, parentFolderName, name }) => {
  switch (true) {
    case isDirectAction:
      return 'Bilinmeyen Grup'
    case !!parentFolderName:
      return `${parentFolderName} ${name}`
    default:
      return name
  }
}

const collectAllItems = ({ itemsList, allItems, parentFolderName = '' }) => {
  itemsList.forEach(itemListItem => {
    const { name, item: items } = itemListItem
    const isDirectAction = !items // It means that it does not have any folder.

    const folderName = getFolderName({
      isDirectAction,
      parentFolderName,
      name,
    })

    if (isDirectAction) {
      allItems.push({ item: itemListItem, folderName })
      return
    }

    items.forEach(_item => {
      if (!_item.item) {
        allItems.push({ item: _item, folderName })
        return
      }

      collectAllItems({
        itemsList: [_item],
        allItems,
        parentFolderName: `${folderName} /`,
      })
    })
  })
}

export const getAllActions = collection => {
  const allActions = []
  collectAllItems({
    itemsList: collection.item,
    allItems: allActions,
  })

  return allActions
}

export const getValidActions = allActions =>
  allActions.filter(({ item }) => {
    const method = getMethod(item.request)
    if (!isValidMethod(method)) return false

    return true
  })

const reducerDefaultItem = variables => (acc, key) => ({
  ...acc,
  [key]: variables.find(item => item.key === key)?.value || '',
})
export const getDefaultVariables = ({
  variables,
  postmanDefaultHeader: _postmanDefaultHeader,
  postmanDefaultBody: _postmanDefaultBody,
  postmanDefaultUrlQueryParam: _postmanDefaultUrlQueryParam,
}) => {
  const postmanDefaultHeader = [..._postmanDefaultHeader]
  const postmanDefaultBody = [..._postmanDefaultBody]
  const postmanDefaultUrlQueryParam = [..._postmanDefaultUrlQueryParam]

  const defaultHeader = postmanDefaultHeader.reduce(reducerDefaultItem(variables), {})
  const defaultBody = postmanDefaultBody.reduce(reducerDefaultItem(variables), {})
  const defaultQueryParam = postmanDefaultUrlQueryParam.reduce(reducerDefaultItem(variables), {})

  return {
    defaultHeader,
    defaultBody,
    defaultQueryParam,
  }
}
