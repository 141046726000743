import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { IoIosInformationCircle } from 'react-icons/io'
import Skeleton from 'react-loading-skeleton'

import { deleteProjectTable, getProjectTableDetail, postProjectTable, updateProjectTable } from '@/api'
import ContinueAskPopup from '@/components/ContinueAskPopup/ContinueAskPopup'
import Input from '@/components/Input/Input'
import InputDropdown from '@/components/InputDropdown/InputDropdown'
import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import ToggleSwitch from '@/components/ToggleSwitch/ToggleSwitch'
import Tooltip from '@/components/Tooltip/Tooltip'
import { API_METHODS } from '@/constants'

import TableColumns from './TableColumns/TableColumns'
import TableOtherSettings from './TableOtherSettings/TableOtherSettings'
import TableUrlInput from './TableUrlInput/TableUrlInput'
import './tablePopupForm.scss'

const methodOptions = Object.values(API_METHODS).map(method => ({ value: method, label: method }))
const initialTableDetail = {
  label: '',
  url: '',
  header: {},
  method: API_METHODS.GET,
  dataKey: '',
  limitKey: '',
  totalKey: '',
  columns: [],
  properties: {},
}
export const TABLE_ACTIONS = {
  CREATE: 'create',
  DELETE: 'delete',
}

const TablePopupForm = ({ tableId, isVisible, handleClosePopup, handleAfterActionTable }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [tableDetail, setTableDetail] = useState(initialTableDetail)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isEnabledDeleteTableContinuePopup, setIsEnabledDeleteTableContinuePopup] = useState(false)
  const [isActivePaginationStatus, setIsActivePaginationStatus] = useState(false)

  const isValid = (() => {
    const { url, header, method, limitKey, totalKey, columns } = tableDetail

    const isValidPaginationParameters = !isActivePaginationStatus || (!!limitKey && !!totalKey)
    const shouldHaveColumns = columns.length > 0

    return !!url && typeof header === 'object' && !!method && isValidPaginationParameters && shouldHaveColumns
  })()
  const selectedMethodIndex = methodOptions.findIndex(item => item.value === tableDetail.method)
  const isEdit = !!tableId

  const handleUpdateTableDetail = item => setTableDetail(prev => ({ ...prev, ...item }))
  const handleUpdateTableDetailProperties = _properties =>
    setTableDetail(prev => ({ ...prev, properties: { ...prev.properties, ..._properties } }))

  const getTitle = () => (isEdit ? 'Tablo Düzenle' : 'Tablo Ekle')

  const handleChangeInput = key => e => handleUpdateTableDetail({ [key]: e.target.value })
  const handleOnChangeMethod = value => handleUpdateTableDetail({ method: value })
  const handleOnChangeIsActivePaginationStatus = e => {
    const currentStatus = e.target.checked

    setIsActivePaginationStatus(currentStatus)
    !currentStatus && handleUpdateTableDetail({ limitKey: '', totalKey: '' })
  }

  const handleOnClickRemoveButton = () => setIsEnabledDeleteTableContinuePopup(true)

  const handleCloseContinueDeleteTable = () => setIsEnabledDeleteTableContinuePopup(false)

  const handleUpdateTable = async () => {
    // eslint-disable-next-line no-unused-vars
    const { id, projectId, ...restOfTableDetail } = tableDetail
    await updateProjectTable(tableId, restOfTableDetail)
  }

  const handleClickSaveButton = async () => {
    try {
      setIsSaving(true)

      isEdit ? await handleUpdateTable() : await postProjectTable(tableDetail)

      toast.success(isEdit ? 'Tablo başarıyla güncellendi' : 'Tablo başarıyla eklendi')

      handleAfterActionTable({
        type: TABLE_ACTIONS.CREATE,
      })
      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsSaving(false)
    }
  }

  const handleDeleteTable = async () => {
    try {
      setIsDeleting(true)
      handleCloseContinueDeleteTable()

      await deleteProjectTable(tableId)

      handleAfterActionTable({ type: TABLE_ACTIONS.DELETE })
      handleClosePopup()
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsDeleting(false)
    }
  }

  const handleGetTableDetail = async () => {
    const tableDetailRes = await getProjectTableDetail(tableId)
    return tableDetailRes.data.table
  }

  const handleSetInitialStates = async () => {
    try {
      setIsLoading(true)

      const tableDetail = !isEdit ? initialTableDetail : await handleGetTableDetail()

      setTableDetail(tableDetail)
      setIsActivePaginationStatus(!!tableDetail.limitKey && !!tableDetail.totalKey)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleSetInitialStates()
  }, [isVisible])

  return (
    <Popup isVisible={isVisible} title={getTitle()} handleClosePopup={handleClosePopup}>
      {isLoading ? (
        <Skeleton className='table-form-popup__loading-skelton' count={3} />
      ) : (
        <div className='table-form-popup__list'>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>Başlık</div>
            <div className='table-form-popup__item-content'>
              <Input
                value={tableDetail.label}
                onChange={handleChangeInput('label')}
                className='table-form-popup__item-input'
                placeholder='Başlık'
              />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>Method*</div>
            <div className='table-form-popup__item-content'>
              <InputDropdown
                placeholder='Bir method seç*'
                className='table-form-popup__item-input'
                items={methodOptions}
                handleChange={handleOnChangeMethod}
                selectedItemIndex={selectedMethodIndex}
              />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>Url*</div>
            <div className='table-form-popup__item-content'>
              <TableUrlInput value={tableDetail.url} handleUpdateTableDetail={handleUpdateTableDetail} />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>
              Data Key
              <Tooltip
                className='table-form-popup__item-tooltip-info'
                tooltipTextClassName='table-form-popup__item-tooltip-info--tooltip-text'
                text='Belirlediğin url"den dönen data başka bir json içinde dönüyor ise, onun key değeri yazılmalıdır.(örn: "resData", "resData.scopedData")'>
                <IoIosInformationCircle />
              </Tooltip>
            </div>
            <div className='table-form-popup__item-content'>
              <Input
                value={tableDetail.dataKey}
                onChange={handleChangeInput('dataKey')}
                className='table-form-popup__item-input'
                placeholder='Data Key'
              />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>
              API Dinamik Sayfalama Durumu
              <Tooltip
                className='table-form-popup__item-tooltip-info'
                tooltipTextClassName='table-form-popup__item-tooltip-info--tooltip-text'
                text='Eğer verilerin parça parça geliyor ise pagination için Limit Key ve Total Key belirlemelisin.'>
                <IoIosInformationCircle />
              </Tooltip>
            </div>
            <div className='table-form-popup__item-content'>
              <ToggleSwitch checked={isActivePaginationStatus} handleChangeValue={handleOnChangeIsActivePaginationStatus} />
            </div>
          </div>
          {isActivePaginationStatus && (
            <>
              <div className='table-form-popup__item'>
                <div className='table-form-popup__item-label'>
                  Limit Key*
                  <Tooltip
                    className='table-form-popup__item-tooltip-info'
                    tooltipTextClassName='table-form-popup__item-tooltip-info--tooltip-text'
                    text='Pagination için limit key'>
                    <IoIosInformationCircle />
                  </Tooltip>
                </div>
                <div className='table-form-popup__item-content'>
                  <Input
                    value={tableDetail.limitKey}
                    onChange={handleChangeInput('limitKey')}
                    className='table-form-popup__item-input'
                    placeholder='Limit Key*'
                  />
                </div>
              </div>
              <div className='table-form-popup__item'>
                <div className='table-form-popup__item-label'>
                  Total Key*
                  <Tooltip
                    className='table-form-popup__item-tooltip-info'
                    tooltipTextClassName='table-form-popup__item-tooltip-info--tooltip-text'
                    text='Pagination için total count key'>
                    <IoIosInformationCircle />
                  </Tooltip>
                </div>
                <div className='table-form-popup__item-content'>
                  <Input
                    value={tableDetail.totalKey}
                    onChange={handleChangeInput('totalKey')}
                    className='table-form-popup__item-input'
                    placeholder='Total Key*'
                  />
                </div>
              </div>
            </>
          )}
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>Tablo Kolonları*</div>
            <div className='table-form-popup__item-content'>
              <TableColumns value={tableDetail.columns} handleUpdateTableDetail={handleUpdateTableDetail} />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-label'>Diğer</div>
            <div className='table-form-popup__item-content'>
              <TableOtherSettings
                tableDetailProperties={tableDetail.properties}
                handleUpdateTableDetailProperties={handleUpdateTableDetailProperties}
              />
            </div>
          </div>
          <div className='table-form-popup__item'>
            <div className='table-form-popup__item-content table-form-popup__item-action'>
              <OutlinedButton onClick={handleClosePopup}>Geri</OutlinedButton>
              {isEdit && (
                <>
                  <ContinueAskPopup
                    title='Silmek istediğine emin misin?'
                    description='Bu tablo widget olarak kullanılıyorsa dahi silineceketir. İşlem tamamlandıktan sonra anasayfayı kontrol ediniz.'
                    isVisible={isEnabledDeleteTableContinuePopup}
                    handleClosePopup={handleCloseContinueDeleteTable}
                    noButtonProps={{ onClick: handleCloseContinueDeleteTable }}
                    yestButtonProps={{ onClick: handleDeleteTable }}
                  />
                  <OutlinedButton isLoading={isDeleting} className='table-form-popup__remove-btn' onClick={handleOnClickRemoveButton}>
                    Sil
                  </OutlinedButton>
                </>
              )}
              <PrimaryButton disable={!isValid} isLoading={isSaving} className='table-form-popup__save-btn' onClick={handleClickSaveButton}>
                Kaydet
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </Popup>
  )
}

export default TablePopupForm
