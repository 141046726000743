import { useDispatch, useSelector } from 'react-redux'

import ColorPickerInput from '@/components/ColorPickerInput/ColorPickerInput'
import FileInput from '@/components/FileInput/FileInput'
import { DEFAULT_VALUES } from '@/components/FormFieldComponents/ToggleSwitch/ToggleSwitch'
import JsonEditorWithError from '@/components/JsonEditorWithError/JsonEditorWithError'
import { FIELD_SCOPE_TYPES, FIELD_TYPES } from '@/constants'
import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import Input from '@/containers/BuildApi/components/Settings/Input/Input'
import InputDropdown from '@/containers/BuildApi/components/Settings/InputDropdown/InputDropdown'
import ToggleSwitch from '@/containers/BuildApi/components/Settings/ToggleSwitch/ToggleSwitch'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'
import { convertToBase64 } from '@/utils'

import './buildApiRightPanelFieldGeneralSettings.scss'

const fieldScopeTypeOptions = Object.values(FIELD_SCOPE_TYPES).map(type => ({ label: type, value: type }))
const DISABLED_DEFAULT_VALUE_FIELD_TYPES = [FIELD_TYPES.FILE]

const BuildApiRightPanelFieldGeneralSettings = () => {
  const dispatch = useDispatch()

  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const selectedFieldProperties = selectedField?.properties || {}
  const selectedFieldScopeTypeIndex = fieldScopeTypeOptions.findIndex(type => type.value === selectedField.fieldScopeType)
  const isEnabledDefaultValueField = !DISABLED_DEFAULT_VALUE_FIELD_TYPES.includes(selectedField.type)

  const handleChangeKey = e => {
    dispatch(buildApiReducerActions.setApiFieldKey(e.target.value))
  }

  const handleChangeLabel = e => {
    dispatch(buildApiReducerActions.setApiFieldLabel(e.target.value))
  }

  const handleChangeInputDefaultValue = e => {
    dispatch(buildApiReducerActions.setApiFieldDefaultValue(e.target.value))
  }
  const handelChangeDefaultValue = value => {
    dispatch(buildApiReducerActions.setApiFieldDefaultValue(value))
  }

  const handleChangeFieldScopeType = value => {
    dispatch(buildApiReducerActions.setApiFieldScopeType(value))
  }

  const handleChangeFieldInvisibleStatus = e => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ invisible: e.target.checked }))
  }

  const handleChangeFieldDependencyStatus = e => {
    dispatch(buildApiReducerActions.setApiFieldProperties({ isDependency: e.target.checked }))
  }

  const renderDefaultValueSetting = () => {
    const { type, defaultValue } = selectedField

    switch (selectedField.type) {
      case FIELD_TYPES.SELECT: {
        const choices = selectedFieldProperties.choices
        const hasChoices = choices.length > 0
        const selectedChoiceItemIndex = choices.findIndex(choice => choice.value === defaultValue)

        return (
          <>
            {hasChoices ? (
              <InputDropdown
                items={choices}
                selectedItemIndex={selectedChoiceItemIndex}
                handleChange={handelChangeDefaultValue}
                placeholder='Varsayılan değer seç...'
              />
            ) : (
              <div className='build-api-field-general-setting__no-choices'>Hiç eklenmiş seçenek yok</div>
            )}
          </>
        )
      }
      case FIELD_TYPES.IMAGE: {
        const handleOnChange = async e => {
          const base64File = await convertToBase64(e.target.files[0])
          handelChangeDefaultValue(base64File)
        }
        return <FileInput onChange={handleOnChange}>Fotoğraf Seç</FileInput>
      }
      case FIELD_TYPES.TOGGLE_SWITCH: {
        const choices = [
          { label: 'Açık', value: selectedFieldProperties.onValue || DEFAULT_VALUES.ON },
          { label: 'Kapalı', value: selectedFieldProperties.offValue || DEFAULT_VALUES.OFF },
        ]
        const selectedChoiceItemIndex = choices.findIndex(choice => choice.value === defaultValue)

        return (
          <InputDropdown
            items={choices}
            selectedItemIndex={selectedChoiceItemIndex}
            handleChange={handelChangeDefaultValue}
            placeholder='Varsayılan değer seç...'
          />
        )
      }
      case FIELD_TYPES.DATE: {
        return <Input type='date' placeholder='Bir tarih seç...' value={defaultValue} onChange={handleChangeInputDefaultValue} />
      }
      case FIELD_TYPES.HOUR: {
        return <Input type='time' placeholder='Bir saat seç...' value={defaultValue} onChange={handleChangeInputDefaultValue} />
      }
      case FIELD_TYPES.COLOR_PICKER: {
        return <ColorPickerInput value={defaultValue} handleChangeValue={handelChangeDefaultValue} />
      }
      case FIELD_TYPES.BEAUTIFY_JSON: {
        return <JsonEditorWithError value={defaultValue} onChange={handelChangeDefaultValue} />
      }
      default: {
        const inputType = type === FIELD_TYPES.NUMBER ? 'number' : 'string'
        return <Input type={inputType} value={defaultValue} onChange={handleChangeInputDefaultValue} placeholder='Varsayılan Değer' />
      }
    }
  }

  return (
    <>
      <BuildApiSettingWrapper title='Key*'>
        <Input value={selectedField.key} onChange={handleChangeKey} placeholder='Key' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Başlık'>
        <Input value={selectedField.label} onChange={handleChangeLabel} placeholder='Başlık' />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Tip*'>
        <InputDropdown
          items={fieldScopeTypeOptions}
          selectedItemIndex={selectedFieldScopeTypeIndex}
          handleChange={handleChangeFieldScopeType}
          placeholder='Tip seç...'
        />
      </BuildApiSettingWrapper>
      {isEnabledDefaultValueField && (
        <BuildApiSettingWrapper title='Varsayılan Değer'>{renderDefaultValueSetting()}</BuildApiSettingWrapper>
      )}
      <BuildApiSettingWrapper title='Gizle'>
        <ToggleSwitch checked={selectedFieldProperties?.invisible} handleChangeValue={handleChangeFieldInvisibleStatus} />
      </BuildApiSettingWrapper>
      <BuildApiSettingWrapper title='Bağımlılık Durumu'>
        <ToggleSwitch checked={selectedFieldProperties?.isDependency} handleChangeValue={handleChangeFieldDependencyStatus} />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelFieldGeneralSettings
