import { useEffect, useState } from 'react'

import Checkbox from '@/components/Checkbox/Checkbox'

import './buildApiRightPanelFileAdvancedOnlyAcceptSetting.scss'

const ACCEPT_OPTIONS = [
  {
    text: 'Fotoğraf',
    value: 'image/*',
  },
  {
    text: 'Video',
    value: 'video/*',
  },
  {
    text: 'Ses',
    value: 'audio/*',
  },
]

const BuildApiRightPanelFileAdvancedOnlyAcceptSetting = ({ value, handleChangeProperties }) => {
  const [isCheckedAllOption, setIsCheckedAllOption] = useState()
  const hasOnlyAccepts = value.length > 0

  const checkIsAdded = acceptValue => value.includes(acceptValue)
  const handleChangeOnlyAcceptValue = newValue => handleChangeProperties({ onlyAccepts: newValue })

  const handleOnChange = targetAcceptValue => e => {
    const isChecked = e.target.checked

    const newValue = isChecked ? [...value, targetAcceptValue] : [...value].filter(item => item !== targetAcceptValue)

    handleChangeProperties({ onlyAccepts: newValue })
  }

  const handleOnChangeAllAccepts = e => {
    const isChecked = e.target.checked

    if (isChecked) {
      handleChangeOnlyAcceptValue([])
      setIsCheckedAllOption(true)
      return
    }

    setIsCheckedAllOption(false)
  }

  useEffect(() => {
    setIsCheckedAllOption(!hasOnlyAccepts)
  }, [value])

  return (
    <div className='file-advanced-only-accepts-setting'>
      <div className='file-advanced-only-accepts-setting__item'>
        <Checkbox checked={isCheckedAllOption} handleOnChange={handleOnChangeAllAccepts} text='Hepsi' />
      </div>
      {ACCEPT_OPTIONS.map(({ value, text }) => (
        <div key={value} className='file-advanced-only-accepts-setting__item'>
          <Checkbox handleOnChange={handleOnChange(value)} text={text} checked={checkIsAdded(value)} disabled={isCheckedAllOption} />
        </div>
      ))}
    </div>
  )
}

export default BuildApiRightPanelFileAdvancedOnlyAcceptSetting
