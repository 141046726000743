import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import { postProjectApiCollection } from '@/api'
import Popup from '@/components/Popup/Popup'
import ExportApiDefaultVariablesStep from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/ExportApiDefaultVariablesStep/ExportApiDefaultVariablesStep'
import ExportApiDownloadStep from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/ExportApiDownloadStep/ExportApiDownloadStep'
import ExportApiHomeStep from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/ExportApiHomeStep/ExportApiHomeStep'
import ExportApiManageStep from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/ExportApiManageStep/ExportApiManageStep'
import { EXPORT_API_STEPS } from '@/pages/ProjectSettings/ProjectOtherSettings/ExportApiPopupWizard/constants'
import { apiCollectionReducerActions } from '@/store/reducers/apiCollection'

import './exportApiPopupWizard.scss'

const DEFAULT_ACTIVE_STEP = EXPORT_API_STEPS.HOME

const ExportApiPopupWizard = ({ isVisible, handleClosePopup }) => {
  const [activeStep, setActiveStep] = useState(DEFAULT_ACTIVE_STEP)

  const dispatch = useDispatch()
  const { orgApiDefaultHeader, orgApiDefaultBody, orgApiDefaultUrlQueryParams, orgConfigs, projectApis, parameters, selectedApiIds } =
    useSelector(state => {
      const {
        apiDefaultHeader: orgApiDefaultHeader,
        apiDefaultBody: orgApiDefaultBody,
        apiDefaultUrlQueryParams: orgApiDefaultUrlQueryParams,
        configs: orgConfigs,
      } = state.app.projectSettings
      const projectApis = state.app.projectApis

      const { parameters, selectedApiIds } = state.apiCollection

      return {
        orgApiDefaultHeader,
        orgApiDefaultBody,
        orgApiDefaultUrlQueryParams,
        orgConfigs,
        projectApis,
        parameters,
        selectedApiIds,
      }
    })

  const renderContent = () => {
    switch (activeStep) {
      case EXPORT_API_STEPS.HOME:
        return <ExportApiHomeStep setActiveStep={setActiveStep} />
      case EXPORT_API_STEPS.MANAGE:
        return <ExportApiManageStep setActiveStep={setActiveStep} />
      case EXPORT_API_STEPS.DEFAULT_VARIABLES:
        return <ExportApiDefaultVariablesStep setActiveStep={setActiveStep} />
      case EXPORT_API_STEPS.DOWNLOAD:
        return <ExportApiDownloadStep setActiveStep={setActiveStep} />
    }
  }

  const handlePostApiCollection = async () => {
    try {
      setActiveStep(EXPORT_API_STEPS.DOWNLOAD)
      dispatch(apiCollectionReducerActions.updateIsLoading(true))

      const resApiCollection = await postProjectApiCollection({
        ...parameters,
        apiIds: selectedApiIds,
      })

      dispatch(apiCollectionReducerActions.updateDownloadUrl(resApiCollection.data.fileUrl))
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      dispatch(apiCollectionReducerActions.updateIsLoading(false))
    }
  }

  const handleOnClickNextButton = async () => {
    switch (activeStep) {
      case EXPORT_API_STEPS.HOME: {
        setActiveStep(EXPORT_API_STEPS.MANAGE)
        break
      }
      case EXPORT_API_STEPS.MANAGE: {
        setActiveStep(EXPORT_API_STEPS.DEFAULT_VARIABLES)
        break
      }
      case EXPORT_API_STEPS.DEFAULT_VARIABLES: {
        await handlePostApiCollection()
        break
      }
    }
  }

  const handleOnClickBackButton = () => {
    switch (activeStep) {
      case EXPORT_API_STEPS.MANAGE: {
        setActiveStep(EXPORT_API_STEPS.HOME)
        break
      }
      case EXPORT_API_STEPS.DEFAULT_VARIABLES: {
        setActiveStep(EXPORT_API_STEPS.MANAGE)
        break
      }
    }
  }

  const getIsVisibleStepActions = () => activeStep !== EXPORT_API_STEPS.DOWNLOAD
  const getIsVisibleBackButton = () => activeStep !== EXPORT_API_STEPS.HOME
  const getDynamicProps = () => (activeStep === EXPORT_API_STEPS.DEFAULT_VARIABLES ? { nextButtonText: 'Hazırla!' } : {})

  const handleClearStates = () => {
    setTimeout(() => {
      setActiveStep(DEFAULT_ACTIVE_STEP)
      dispatch(apiCollectionReducerActions.setInitialState())
    }, 300)
  }

  const handleSetInitialStates = () => {
    const allApiIds = projectApis.reduce((acc, item) => [...acc, ...item.api.map(({ id }) => id)], [])

    dispatch(
      apiCollectionReducerActions.updateParameters({
        apiDefaultHeader: orgApiDefaultHeader,
        apiDefaultBody: orgApiDefaultBody,
        apiDefaultUrlQueryParams: orgApiDefaultUrlQueryParams,
        configs: orgConfigs,
      }),
    )
    dispatch(apiCollectionReducerActions.updateSelectedApiIds(allApiIds))
  }

  useEffect(() => {
    if (isVisible) {
      handleSetInitialStates()
      return
    }

    handleClearStates()
  }, [isVisible])

  return (
    <Popup
      modalClassName='export-api-wizard-popup'
      title='Aksiyonları Dışa Aktar'
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}
      isVisibleStepActions={getIsVisibleStepActions()}
      isVisibleBackButton={getIsVisibleBackButton()}
      handleOnClickNextButton={handleOnClickNextButton}
      handleOnClickBackButton={handleOnClickBackButton}
      {...getDynamicProps()}>
      {renderContent()}
    </Popup>
  )
}

export default ExportApiPopupWizard
