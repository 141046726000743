import { useEffect, useState } from 'react'

import JsonEditor from '@/components/JsonEditor/JsonEditor'

import './jsonEditorWithError.scss'

const JsonEditorWithError = ({ value, onChange, ...otherProps }) => {
  const [temporaryStringJson, setTemporaryStringJson] = useState(JSON.stringify(value))
  const [hasParsedError, setHasParsedError] = useState(false)

  const handleOnChange = value => {
    setTemporaryStringJson(value)
  }

  useEffect(() => {
    try {
      const parsedJson = JSON.parse(temporaryStringJson)
      onChange && onChange(parsedJson)

      setHasParsedError(false)
    } catch (err) {
      setHasParsedError(true)
    }
  }, [temporaryStringJson])

  useEffect(() => {
    try {
      const parseValue = JSON.stringify(value)
      setTemporaryStringJson(parseValue)

      setHasParsedError(false)
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }, [])

  return (
    <>
      <JsonEditor onChange={handleOnChange} value={temporaryStringJson} {...otherProps} />
      {hasParsedError && <div className='json-editor__error'>Geçersiz JSON</div>}
    </>
  )
}

export default JsonEditorWithError
