import clsx from 'clsx'
import { IoIosArrowForward } from 'react-icons/io'
import { MdCircle } from 'react-icons/md'
import { Link } from 'react-router-dom'

import { isMobile } from '@/utils'

import './menuItemList.scss'

const MenuItemList = ({ menuList, handleClickMenuItem, menuListWrapperClassName }) => {
  const renderItem = ({ item, menuItemKey, key }) => {
    const isLinkElement = !!item?.path

    const _key = `${menuItemKey}_${key}`
    const allClassNames = clsx(`menu-item-list__item ${item.isActive ? 'menu-item-list__item--selected' : ''}`)

    const renderMobileIcon = isActive =>
      isActive ? (
        <MdCircle className='menu-item-list__mobile-icon menu-item-list__mobile-icon--active-icon' />
      ) : (
        <IoIosArrowForward className='menu-item-list__mobile-icon' />
      )
    const renderContent = () => (
      <>
        <item.Icon className='menu-item-list__icon' />
        <div className='menu-item-list__text'>{item.text}</div>
        {isMobile() && renderMobileIcon(item.isActive)}
      </>
    )

    switch (true) {
      case isLinkElement:
        return (
          <Link key={_key} to={item.path} className={allClassNames} {...(handleClickMenuItem ? { onClick: handleClickMenuItem } : {})}>
            {renderContent()}
          </Link>
        )
      default: {
        const { onClick } = item
        return (
          <div key={_key} className={allClassNames} {...(onClick ? { onClick } : {})}>
            {renderContent()}
          </div>
        )
      }
    }
  }

  return menuList.map((menuItem, menuItemKey) => (
    <div key={menuItemKey} className={clsx('menu-item-list__wrapper', menuListWrapperClassName)}>
      <div className='menu-item-list__title'>{menuItem.title}</div>
      <div className='menu-item-list__list'>{menuItem.items.map((item, key) => renderItem({ item, key, menuItemKey }))}</div>
    </div>
  ))
}

export default MenuItemList
