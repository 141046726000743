import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { postPostmanApis } from '@/api'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { ROUTE_URLS } from '@/constants/routeUrls'
import Step from '@/containers/PostmanBuilder/components/Step/Step'

import './completeProcess.scss'

const CompleteProcess = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const navigate = useNavigate()
  const { actions, selectedActionIDs, globalVariables } = useSelector(store => store.postmanBuilder)

  const handleOnClickCompleteButton = async () => {
    try {
      setIsSubmitting(true)

      // Only selected actions
      const selectedActions = Object.entries(actions).reduce((acc, [folderName, items]) => {
        const newItems = items.filter(item => selectedActionIDs.includes(item.api.__id))

        return {
          [folderName]: newItems,
          ...acc,
        }
      }, {})

      await postPostmanApis({ actions: selectedActions, globalVariables })
      setIsSuccess(true)

      setTimeout(() => {
        navigate(ROUTE_URLS.HOME)
        window.location.reload()
      }, 3000)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsSubmitting(true)
    }
  }

  const renderContent = () => {
    switch (true) {
      case isSuccess:
        return (
          <div className='complete-process__success'>
            <div className='complete-process__success-text'>Başarılı!</div>
            <div className='complete-process__success-icon-wrapper'>
              <DotLottieReact src='https://lottie.host/90f27da5-5b46-4273-b435-1e8dba9714cf/mCmw6X42T3.json' autoplay speed='1.1' />
            </div>
            <div className='complete-process__success-info'>Yönlendiriliyorsunuz...</div>
          </div>
        )
      default:
        return (
          <div className='complete-process'>
            <div className='complete-process__information-text'>
              Artık her şey hazır! Bu adımdan sonra aksiyonların otomatik olarak oluşturulmuş olacak.
            </div>
            <PrimaryButton isLoading={isSubmitting} onClick={handleOnClickCompleteButton} className='complete-process__complete-button'>
              Şimdi Oluştur
            </PrimaryButton>
          </div>
        )
    }
  }

  return (
    <Step title='Her şey hazır.' continueButtonText='Oluştur' isEnableActions={false}>
      {renderContent()}
    </Step>
  )
}

export default CompleteProcess
