import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import 'react-loading-skeleton/dist/skeleton.css'
import { Provider } from 'react-redux'

import App from '@/App'
import store from '@/store'
import '@/styles/default.scss'

if (import.meta.env.VITE_ENVIRONMENT === 'PROD') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [],
  })
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <Toaster containerStyle={{ zIndex: 99999 }} />
    </Provider>
  </React.StrictMode>,
)
