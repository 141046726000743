import { useState } from 'react'
import toast from 'react-hot-toast'

import { updatePassword } from '@/api'
import Input from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './userUpdatePassword.scss'

const INITIAL_PASSWORDS = { currentPassword: '', newPassword: '', repeatNewPassword: '' }

const UserUpdatePassword = ({ handleAfterUpdatedPassword }) => {
  const [passwords, setPasswords] = useState(INITIAL_PASSWORDS)
  const [isLoading, setIsLoading] = useState(false)

  const hasEmptyState = Object.values(passwords).some(item => item === '')
  const isSamePassword = passwords.newPassword === passwords.repeatNewPassword

  const handleChangePasswordInput = key => e => setPasswords(prev => ({ ...prev, [key]: e.target.value }))
  const handleSetInitialPasswords = () => setPasswords(INITIAL_PASSWORDS)

  const handleOnClickUpdatePasswordButton = async () => {
    try {
      setIsLoading(true)

      await updatePassword({ password: passwords.newPassword, currentPassword: passwords.currentPassword })

      handleAfterUpdatedPassword && handleAfterUpdatedPassword()
      handleSetInitialPasswords()
      toast.success('Şifre başarıyla değiştirildi')
      setTimeout(() => {
        toast.success('Diğer hesaplardan çıkış yapıldı')
      }, 1000)
    } catch (err) {
      const isWrongPasswordError = err.response.data.message === 'The password is not correct'

      toast.error(isWrongPasswordError ? 'Mevcut şifre yanlış' : 'Bir hata meydana geldi')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='user-update-password'>
      <div className='user-update-password__list'>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>Mevcut Şifre</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('currentPassword')}
              value={passwords.currentPassword}
            />
          </div>
        </div>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>Yeni Şifre</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('newPassword')}
              value={passwords.newPassword}
            />
          </div>
        </div>
        <div className='user-update-password__item'>
          <div className='user-update-password__label'>Yeni Şifre Tekrar</div>
          <div className='user-update-password__content'>
            <Input
              type='password'
              className='user-update-password__input'
              onChange={handleChangePasswordInput('repeatNewPassword')}
              value={passwords.repeatNewPassword}
            />
          </div>
        </div>
        <PrimaryButton
          disable={hasEmptyState || !isSamePassword}
          isLoading={isLoading}
          onClick={handleOnClickUpdatePasswordButton}
          className='user-update-password__update-password'>
          Güncelle
        </PrimaryButton>
      </div>
    </div>
  )
}

export default UserUpdatePassword
