import _ from 'lodash'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import FileUpload from '@/components/FileUpload/FileUpload'
import { IMPORT_API_STEPS } from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/constants'
import { importApiCollectionReducerActions } from '@/store/reducers/importApiCollection'
import { getValueOfJsonFile } from '@/utils'

import './importApiUploadFileStep.scss'

const fileTypes = ['json']

const ImportApiUploadFileStep = ({ setActiveStep }) => {
  const dispatch = useDispatch()

  const handleSetStateValues = ({ value, file }) => {
    const { apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs, api } = value

    const selectedApiIndexes = api.map(({ id }) => id)
    dispatch(importApiCollectionReducerActions.setSelectedApiIndexes(selectedApiIndexes))

    const groupedApi = _.groupBy(api, item => item.group.name)
    const actionsListItems = Object.entries(groupedApi).reduce(
      (acc, [groupName, items]) => [
        ...acc,
        { folderId: groupName, folderName: groupName, items: items.map(item => ({ id: item.id, label: item.title })) },
      ],
      [],
    )
    dispatch(importApiCollectionReducerActions.setAllApi(actionsListItems))

    dispatch(
      importApiCollectionReducerActions.setParameters({
        apiDefaultHeader,
        apiDefaultBody,
        apiDefaultUrlQueryParams,
        configs,
      }),
    )
    dispatch(importApiCollectionReducerActions.updateUploadedFile(file))
  }

  const validateValue = value => {
    switch (true) {
      case !value?.apiDefaultBody:
      case !value?.apiDefaultHeader:
      case !value?.apiDefaultUrlQueryParams:
      case !value?.configs:
      case !value?.api:
        return false
      default:
        return true
    }
  }

  const normalizeValue = value => {
    const api = value.api.map((item, id) => ({ ...item, id }))

    return {
      ...value,
      api,
    }
  }

  const handleOnChange = async file => {
    const value = await getValueOfJsonFile(file)

    if (!validateValue(value)) {
      toast.error('Geçersiz JSON yapısı')
      return
    }

    const normalizedValue = normalizeValue(value)
    handleSetStateValues({ value: normalizedValue, file })
    setActiveStep(IMPORT_API_STEPS.MANAGE)
  }

  return (
    <div className='import-api-upload'>
      <FileUpload maxSize={5} label='Bir koleksiyon yükle ya da sürükle bırak' handleChange={handleOnChange} types={fileTypes} />
    </div>
  )
}

export default ImportApiUploadFileStep
