import clsx from 'clsx'

import { getUserType } from '@/utils'

import './userTypeTag.scss'

const UserTypeTag = ({ isAdmin, className }) => (
  <div className={clsx('user-type-tag', className, { 'user-type-tag--admin': isAdmin })}>{getUserType(isAdmin)}</div>
)

export default UserTypeTag
