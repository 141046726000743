import { useDispatch, useSelector } from 'react-redux'

import MultipleInputList from '@/components/MultipleInputList/MultipleInputList'
import BuildApiSettingWrapper from '@/containers/BuildApi/components/BuildApiSettingWrapper/BuildApiSettingWrapper'
import { buildApiReducerActions, buildApiReducerSelectors } from '@/store/reducers/buildApi'

const choiceOptionInputs = [
  { key: 'label', placeholder: 'Başlık' },
  { key: 'value', placeholder: 'Değer' },
]

const BuildApiRightPanelSelectAdvancedSettings = () => {
  const dispatch = useDispatch()

  const selectedField = useSelector(buildApiReducerSelectors.selectedFieldSelector)
  const choices = selectedField.properties.choices

  const handleOnSaveChoice = newChoice => {
    const isValid = Object.values(newChoice).every(value => value !== '')
    if (!isValid) return

    dispatch(buildApiReducerActions.setApiFieldProperties({ choices: [...choices, newChoice] }))
  }

  const handleOnDeleteChoice = itemIndex => {
    const newChoices = choices.filter((_, index) => index !== itemIndex)
    dispatch(buildApiReducerActions.setApiFieldProperties({ choices: [...newChoices] }))
  }

  return (
    <>
      <BuildApiSettingWrapper title='Seçenekler'>
        <MultipleInputList
          items={selectedField.properties.choices}
          inputs={choiceOptionInputs}
          onSave={handleOnSaveChoice}
          onDelete={handleOnDeleteChoice}
        />
      </BuildApiSettingWrapper>
    </>
  )
}

export default BuildApiRightPanelSelectAdvancedSettings
