import { Outlet } from 'react-router-dom'

import MobileRootBottom from '@/containers/Root/MobileRoot/MobileRootBottom/MobileRootBottom'
import MobileRootTop from '@/containers/Root/MobileRoot/MobileRootTop/MobileRootTop'
import withRootLogic from '@/containers/Root/withRootLogic'

import './mobileRoot.scss'

const Content = () => {
  return (
    <div className='mobile-root'>
      <div className='mobile-root__top'>
        <MobileRootTop />
      </div>
      <div className='mobile-root__content'>
        <Outlet />
      </div>
      <div className='mobile-root__bottom'>
        <MobileRootBottom />
      </div>
    </div>
  )
}

const MobileRoot = ({ getContent }) => {
  return getContent(<Content />)
}

export default withRootLogic(MobileRoot)
