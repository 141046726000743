import { useState } from 'react'
import toast from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'

import { postRegister } from '@/api'
import CircleBlackLogo from '@/assets/logos/circle-black-logo.svg'
import AuthenticationFlow from '@/components/AuthenticationFlow/AuthenticationFlow'
import Input, { INPUT_VARIANTS } from '@/components/Input/Input'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'
import { isValidEmail } from '@/utils'

import './register.scss'
import { ROUTE_URLS } from '@/constants/routeUrls'

const Register = () => {
  const [userParams, setUserParams] = useState({
    name: '',
    email: '',
    password: '',
  })
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const isValidateUserParams = Object.values(userParams).every(value => value !== '') && isValidEmail(userParams.email)

  const handleChangeUserParam = item => setUserParams({ ...userParams, ...item })

  const handleOnChangeInput = userParamKey => e => handleChangeUserParam({ [userParamKey]: e.target.value })

  const handleRegister = async () => {
    try {
      setIsLoading(true)

      await postRegister(userParams)

      toast.success('Başarıyla kayıt olundu')
      setTimeout(() => {
        navigate('/login')
      }, 500)
    } catch (err) {
      if (err?.response?.status === 400 && err?.response?.data?.message === 'This user has already been added') {
        toast.error('Bu kullanıcı zaten kayıt olmuş')
        return
      }

      toast.error('Bir hata oluştu')
    } finally {
      setIsLoading(false)
    }
  }

  const handleClickRegisterButton = async () => {
    await handleRegister()
  }

  const handleOnKeyDown = e => {
    if (e.key !== 'Enter' || !isValidateUserParams) return

    handleRegister()
  }

  return (
    <AuthenticationFlow title='Kayıt Ol!'>
      <Input
        variant={INPUT_VARIANTS.BOLD}
        name='register_name'
        value={userParams.name}
        onChange={handleOnChangeInput('name')}
        onKeyDown={handleOnKeyDown}
        className='register__input'
        placeholder='İsim*'
      />
      <Input
        variant={INPUT_VARIANTS.BOLD}
        name='register_email'
        value={userParams.email}
        onChange={handleOnChangeInput('email')}
        onKeyDown={handleOnKeyDown}
        className='register__input'
        placeholder='Email*'
      />
      <Input
        variant={INPUT_VARIANTS.BOLD}
        type='password'
        name='register_password'
        value={userParams.password}
        onChange={handleOnChangeInput('password')}
        onKeyDown={handleOnKeyDown}
        className='register__input'
        placeholder='Şifre*'
      />
      <PrimaryButton onClick={handleClickRegisterButton} className='register__button' isLoading={isLoading} disable={!isValidateUserParams}>
        <img className='register__circle-logo' src={CircleBlackLogo} />
        <span>Kayıt Ol</span>
      </PrimaryButton>
      <Link to={ROUTE_URLS.LOGIN} className='register__text register__text--login'>
        Hesabın mı var? Giriş Yap
      </Link>
    </AuthenticationFlow>
  )
}

export default Register
