import { clsx } from 'clsx'
import { useEffect, useState } from 'react'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { FaFilter } from 'react-icons/fa'
import { IoMdRefresh } from 'react-icons/io'
import { IoCloseSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'

import { getData } from '@/api'
import DynamicChartFiltersDropdown from '@/components/DynamicChart/DynamicChartFiltersDropdown/DynamicChartFiltersDropdown'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'
import InavoLoading from '@/components/InavoLoading/InavoLoading'
import { renderChart } from '@/utils/dynamicCharts'
import { getAllFields, getFieldValues, getFieldValuesFromFields } from '@/utils/dynamicFields'

import './dynamicChart.scss'

const RenderChartWrapper = props => renderChart(props)

const DynamicChart = ({ chart, provideFullScreen = true, customActionsContent }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isEnabledFilterDropdown, setIsEnabledFilterDropdown] = useState(false)
  const [allFields, setAllFields] = useState([])
  const [fieldValues, setFieldValues] = useState([])
  const [data, setData] = useState({})
  const [isEnabledFullScreen, setIsEnabledFullScreen] = useState(false)

  const projectId = useSelector(state => state.app.activeProjectId)
  const hasFilters = allFields.length > 0

  const FallBackError = () => (
    <span className='dynamic-chart__fallback-error'>Grafik oluşturulurken bir hata oluştu.({`${chart.label}`})</span>
  )

  const handleToggleFullScreen = () => {
    setIsEnabledFullScreen(!isEnabledFullScreen)
  }

  const handleDisableFilterDropdown = () => {
    setIsEnabledFilterDropdown(false)
  }

  const handleSetInitialFieldValues = _allFields => {
    const allFieldValues = getFieldValuesFromFields(_allFields)
    setFieldValues([...allFieldValues])
  }

  const handleOnClickApply = async () => {
    const bodyFieldValues = getFieldValues(fieldValues, chart.bodyFields)
    const urlFieldValues = getFieldValues(fieldValues, chart.urlFields)

    handleDisableFilterDropdown()
    await handleGetData({ bodyFieldValues, urlFieldValues })
  }

  const handleOnClickFilterButton = () => {
    setIsEnabledFilterDropdown(!isEnabledFilterDropdown)
  }

  const handleGetData = async (payload = {}) => {
    try {
      setIsLoading(true)

      const { url, method, header, properties } = chart
      const { bodyFieldValues = [], urlFieldValues = [] } = payload

      const res = await getData({
        projectId,
        url,
        method,
        header,
        isForeign: properties.isForeign,
        urlFields: urlFieldValues,
        bodyFields: bodyFieldValues,
      })
      setData(res.data.response.data)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSetInitialStates = () => {
    const _allFields = getAllFields(chart)

    setAllFields([..._allFields])
    handleSetInitialFieldValues(_allFields)
  }

  const handleOnClickRefresh = async () => {
    await handleGetData()
  }

  useEffect(() => {
    handleGetData()
    handleSetInitialStates()
  }, [])

  return (
    <div className={clsx('dynamic-chart', { 'dynamic-chart__fullscreen': isEnabledFullScreen })}>
      {isEnabledFullScreen && <IoCloseSharp onClick={handleToggleFullScreen} className='dynamic-chart__fullscreen-close-button' />}
      {isLoading ? (
        <div className='dynamic-chart__loading-wrapper'>
          <InavoLoading className='dynamic-chart__loading' />
        </div>
      ) : (
        <ErrorBoundary fallback={FallBackError}>
          <div className='dynamic-chart__chart-wrapper'>
            <div className='dynamic-chart__top'>
              <div className='dynamic-chart__title'>{chart.label}</div>
              <div className='dynamic-chart__actions'>
                {hasFilters && (
                  <div className='dynamic-chart__filter'>
                    <div onClick={handleOnClickFilterButton} className='dynamic-chart__filter-button'>
                      <FaFilter />
                      <span className='dynamic-chart__filter-button-text'>Filtre</span>
                    </div>
                    <DynamicChartFiltersDropdown
                      fieldValues={fieldValues}
                      setFieldValues={setFieldValues}
                      allFields={allFields}
                      handleOnClickApply={handleOnClickApply}
                      isEnabled={isEnabledFilterDropdown}
                      handleDisableFilterDropdown={handleDisableFilterDropdown}
                    />
                  </div>
                )}
                <IoMdRefresh onClick={handleOnClickRefresh} className='dynamic-chart__action-icon dynamic-chart__action-icon--refresh' />
                {provideFullScreen && <AiOutlineFullscreen onClick={handleToggleFullScreen} className='dynamic-chart__action-icon' />}
                {customActionsContent}
              </div>
            </div>
            <div className='dynamic-chart__chart-content'>
              <RenderChartWrapper chart={chart} data={data} />
            </div>
          </div>
        </ErrorBoundary>
      )}
    </div>
  )
}

export default DynamicChart
