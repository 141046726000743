import Checkbox from '@/components/Checkbox/Checkbox'

import './tableOtherSettings.scss'

const TableOtherSettings = ({ tableDetailProperties, handleUpdateTableDetailProperties }) => {
  const handleOnChangeEmptyDataWrapperSetting = e => {
    handleUpdateTableDetailProperties({ isRenderEmptyDataWrapper: e.target.checked })
  }

  return (
    <div className='table-other-settings'>
      <Checkbox
        checked={!!tableDetailProperties.isRenderEmptyDataWrapper}
        handleOnChange={handleOnChangeEmptyDataWrapperSetting}
        text='Boş gelen veri yerine "-" karakteri yazılsın'
      />
    </div>
  )
}

export default TableOtherSettings
