import Popup from '@/components/Popup/Popup'
import UserSettings from '@/containers/Root/components/UserSettings/UserSettings'

import './userSettingsPopup.scss'

const UserSettingsPopup = ({ isVisible, handleClosePopup }) => {
  return (
    <Popup title='Hesap Ayarları' modalClassName='user-settings-popup__modal' isVisible={isVisible} handleClosePopup={handleClosePopup}>
      <UserSettings />
    </Popup>
  )
}

export default UserSettingsPopup
