import { Navigate } from 'react-router-dom'

import { getUserTokenStorage } from '@/utils/localStorageActions'

/* eslint-disable react/display-name */
const withAuthenticated = WrapperComponent => props => {
  const userToken = getUserTokenStorage()

  if (!userToken) {
    return <Navigate to='/login' />
  }

  return <WrapperComponent {...props} />
}

export default withAuthenticated
