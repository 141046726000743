import { FaChartBar } from 'react-icons/fa'
import { LuTable } from 'react-icons/lu'
import { PiKeyBold, PiUserBold, PiUsersThreeBold } from 'react-icons/pi'
import { RiUserSettingsLine } from 'react-icons/ri'
import { TbDeviceImacSearch } from 'react-icons/tb'
import { TbSettings } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'
import MenuItemList from '@/containers/Root/components/common/MenuItemList/MenuItemList'
import usePermission from '@/hooks/usePermission'
import { appReducerActions } from '@/store/reducers/app'
import { isMobile } from '@/utils'

import './menuList.scss'

const MenuList = ({ handleClickMenuItem }) => {
  const location = useLocation()
  const { isProjectAdmin, checkPagePermission } = usePermission()

  const dispatch = useDispatch()

  const checkActiveItem = path => location.pathname === path

  const handleClickAccountSettingsItem = () => {
    dispatch(appReducerActions.setIsEnabledUserSettingsDropdown(true))
  }

  // TODO: we should consider MENU_LIST permissions again.

  const MENU_LIST = [
    ...(checkPagePermission(ROUTE_URLS.CHARTS) || checkPagePermission(ROUTE_URLS.TABLES)
      ? [
          {
            title: 'MODÜL',
            items: [
              ...(checkPagePermission(ROUTE_URLS.CHARTS)
                ? [
                    {
                      path: ROUTE_URLS.CHARTS,
                      Icon: FaChartBar,
                      text: 'Grafikler',
                    },
                  ]
                : []),
              ...(checkPagePermission(ROUTE_URLS.TABLES)
                ? [
                    {
                      path: ROUTE_URLS.TABLES,
                      Icon: LuTable,
                      text: 'Tablolar',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    {
      title: 'PROJE',
      items: [
        ...(checkPagePermission(ROUTE_URLS.USERS)
          ? [
              {
                path: ROUTE_URLS.USERS,
                Icon: PiUsersThreeBold,
                text: 'Tüm Kullanıcılar',
              },
            ]
          : []),
        {
          path: ROUTE_URLS.PROJECT_USER_IMPRINT,
          Icon: PiUserBold,
          text: 'Kullanıcı Künyesi',
        },
        ...(checkPagePermission(ROUTE_URLS.PROJECT_LOGS)
          ? [
              {
                path: ROUTE_URLS.PROJECT_LOGS,
                Icon: TbDeviceImacSearch,
                text: 'Proje Hareketleri',
              },
            ]
          : []),
        ...(checkPagePermission(ROUTE_URLS.PROJECT_SETTINGS)
          ? [
              {
                path: ROUTE_URLS.PROJECT_SETTINGS,
                Icon: TbSettings,
                text: 'Proje Ayarları',
              },
            ]
          : []),
      ],
    },
    ...(isProjectAdmin
      ? [
          {
            title: 'İZİN',
            items: [
              ...(checkPagePermission(ROUTE_URLS.PROJECT_USER_PERMISSIONS)
                ? [
                    {
                      path: ROUTE_URLS.PROJECT_USER_PERMISSIONS,
                      Icon: RiUserSettingsLine,
                      text: 'Kullanıcı İzinleri',
                    },
                  ]
                : []),
              ...(checkPagePermission(ROUTE_URLS.ACTION_GROUP_PERMISSIONS)
                ? [
                    {
                      path: ROUTE_URLS.ACTION_GROUP_PERMISSIONS,
                      Icon: PiKeyBold,
                      text: 'Aksiyon İzinleri',
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isMobile()
      ? []
      : [
          {
            title: 'HESAP',
            items: [
              {
                Icon: RiUserSettingsLine,
                text: 'Hesap Ayarları',
                onClick: handleClickAccountSettingsItem,
              },
            ],
          },
        ]),
  ].map(({ items, ...payload }) => ({
    ...payload,
    items: items.map(item => ({
      ...item,
      isActive: checkActiveItem(item.path),
    })),
  }))

  return <MenuItemList menuList={MENU_LIST} handleClickMenuItem={handleClickMenuItem} menuListWrapperClassName='menu-list__wrapper' />
}

export default MenuList
