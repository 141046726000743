import { createBrowserRouter } from 'react-router-dom'

import { ROUTE_URLS } from '@/constants/routeUrls'
import BuildApi from '@/containers/BuildApi/BuildApi'
import ChangePassword from '@/containers/ChangePassword/ChangePassword'
import ForgetPassword from '@/containers/ForgetPassword/ForgetPassword'
import Login from '@/containers/Login/Login'
import PostmanBuilder from '@/containers/PostmanBuilder/PostmanBuilder'
import Register from '@/containers/Register/Register'
import MobileRoot from '@/containers/Root/MobileRoot/MobileRoot'
import Root from '@/containers/Root/Root'
import ActionGroupPermissions from '@/pages/ActionGroupPermissions/ActionGroupPermissions'
import ApiExecute from '@/pages/ApiExecute/ApiExecute'
import Charts from '@/pages/Charts/Charts'
import Home from '@/pages/Home/Home'
import ProjectLogs from '@/pages/ProjectLogs/ProjectLogs'
import ProjectSettings from '@/pages/ProjectSettings/ProjectSettings'
import ProjectUserImprint from '@/pages/ProjectUserImprint/ProjectUserImprint'
import ProjectUserPermissions from '@/pages/ProjectUserPermissions/ProjectUserPermissions'
import Tables from '@/pages/Tables/Tables'
import Users from '@/pages/Users/Users'
import ErrorBoundaryWrapper from '@/router/ErrorBoundaryWrapper'
import withAuthenticated from '@/router/withAuthenticated'
import withNotAuthenticated from '@/router/withNotAuthenticated'
import withRootErrorBoundary from '@/router/withRootErrorBoundary'
import withUserPermission from '@/router/withUserPermission'
import withWrapperRootLogic from '@/router/withWrapperRootLogic'
import { isMobile } from '@/utils'

const AuthenticatedRoot = withAuthenticated(Root)
const AuthenticatedMobileRoot = withAuthenticated(MobileRoot)
const NotAuthenticatedLogin = withNotAuthenticated(Login)
const NotAuthenticatedRegister = withNotAuthenticated(Register)
const NotAuthenticatedForgetPassword = withNotAuthenticated(ForgetPassword)
const NotAuthenticatedChangePassword = withNotAuthenticated(ChangePassword)
const _BuildApi = withWrapperRootLogic(withUserPermission(BuildApi))
const _PostmanBuilder = withWrapperRootLogic(PostmanBuilder)

const WrapperPageContent = WrapperComponent => withUserPermission(withRootErrorBoundary(WrapperComponent))

const _Home = withRootErrorBoundary(Home)
const _Users = WrapperPageContent(Users)
const _ActionGroupPermissions = WrapperPageContent(ActionGroupPermissions)
const _ApiExecute = WrapperPageContent(ApiExecute)
const _ProjectLogs = WrapperPageContent(ProjectLogs)
const _ProjectSettings = WrapperPageContent(ProjectSettings)
const _ProjectUserImprint = WrapperPageContent(ProjectUserImprint)
const _Charts = WrapperPageContent(Charts)
const _Tables = WrapperPageContent(Tables)
const _ProjectUserPermissions = WrapperPageContent(ProjectUserPermissions)

const router = createBrowserRouter([
  {
    element: <ErrorBoundaryWrapper />,
    children: [
      {
        path: '/',
        element: isMobile() ? <AuthenticatedMobileRoot /> : <AuthenticatedRoot />,
        children: [
          { path: ROUTE_URLS.HOME, element: <_Home /> },
          { path: ROUTE_URLS.USERS, element: <_Users /> },
          { path: ROUTE_URLS.ACTION_GROUP_PERMISSIONS, element: <_ActionGroupPermissions /> },
          {
            path: ROUTE_URLS.API_EXECUTE,
            element: <_ApiExecute />,
          },
          { path: ROUTE_URLS.PROJECT_LOGS, element: <_ProjectLogs /> },
          { path: ROUTE_URLS.PROJECT_SETTINGS, element: <_ProjectSettings /> },
          { path: ROUTE_URLS.PROJECT_USER_IMPRINT, element: <_ProjectUserImprint /> },
          { path: ROUTE_URLS.CHARTS, element: <_Charts /> },
          { path: ROUTE_URLS.TABLES, element: <_Tables /> },
          { path: ROUTE_URLS.PROJECT_USER_PERMISSIONS, element: <_ProjectUserPermissions /> },
        ],
      },
      {
        path: ROUTE_URLS.BUILD_API,
        element: <_BuildApi />,
      },
      {
        path: ROUTE_URLS.POSTMAN_BUILDER,
        element: <_PostmanBuilder />,
      },
      {
        path: ROUTE_URLS.LOGIN,
        element: <NotAuthenticatedLogin />,
      },
      {
        path: ROUTE_URLS.REGISTER,
        element: <NotAuthenticatedRegister />,
      },
      {
        path: ROUTE_URLS.FORGET_PASSWORD,
        element: <NotAuthenticatedForgetPassword />,
      },
      {
        path: ROUTE_URLS.CHANGE_PASSWORD,
        element: <NotAuthenticatedChangePassword />,
      },
    ],
  },
])

export default router
