import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'

import { postProjectApiCollectionExecute } from '@/api'
import Popup from '@/components/Popup/Popup'
import ImportApiDynamicValueStep from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/ImportApiDynamicValueStep/ImportApiDynamicValueStep'
import ImportApiManageActionsStep from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/ImportApiManageActionsStep/ImportApiManageActionsStep'
import ImportApiSuccessfulStep from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/ImportApiSuccessfulStep/ImportApiSuccessfulStep'
import ImportApiUploadFileStep from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/ImportApiUploadFileStep/ImportApiUploadFileStep'
import { IMPORT_API_STEPS } from '@/pages/ProjectSettings/ProjectOtherSettings/ImportApiPopupWizard/constants'
import { importApiCollectionReducerActions } from '@/store/reducers/importApiCollection'

import './importApiPopupWizard.scss'

const DEFAULT_ACTIVE_STEP = IMPORT_API_STEPS.UPLOAD_FILE

const ImportApiPopupWizard = ({ isVisible, handleClosePopup }) => {
  const [activeStep, setActiveStep] = useState(DEFAULT_ACTIVE_STEP)
  const [isExecuting, setIsExecuting] = useState(false)

  const { selectedApiIndexes, uploadedFile, apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs } = useSelector(state => {
    const { selectedApiIndexes, uploadedFile, parameters } = state.importApiCollection
    const { apiDefaultHeader, apiDefaultBody, apiDefaultUrlQueryParams, configs } = parameters

    return {
      selectedApiIndexes,
      uploadedFile,
      apiDefaultHeader,
      apiDefaultBody,
      apiDefaultUrlQueryParams,
      configs,
    }
  })

  const dispatch = useDispatch()

  const renderContent = () => {
    switch (activeStep) {
      case IMPORT_API_STEPS.UPLOAD_FILE:
        return <ImportApiUploadFileStep setActiveStep={setActiveStep} />
      case IMPORT_API_STEPS.MANAGE:
        return <ImportApiManageActionsStep setActiveStep={setActiveStep} />
      case IMPORT_API_STEPS.DEFAULT_VARIABLES:
        return <ImportApiDynamicValueStep setActiveStep={setActiveStep} />
      case IMPORT_API_STEPS.SUCCESSFUL:
        return <ImportApiSuccessfulStep setActiveStep={setActiveStep} />
    }
  }

  const handleCreateAllApi = async () => {
    try {
      setIsExecuting(true)

      await postProjectApiCollectionExecute({
        selectedApiIndexes,
        file: uploadedFile,
        apiDefaultHeader,
        apiDefaultBody,
        apiDefaultUrlQueryParams,
        configs,
      })

      setActiveStep(IMPORT_API_STEPS.SUCCESSFUL)
    } catch (err) {
      toast.error('Bir hata meydana geldi')
    } finally {
      setIsExecuting(false)
    }
  }

  const handleOnClickNextButton = async () => {
    switch (activeStep) {
      case IMPORT_API_STEPS.MANAGE: {
        setActiveStep(IMPORT_API_STEPS.DEFAULT_VARIABLES)
        return
      }
      case IMPORT_API_STEPS.DEFAULT_VARIABLES: {
        await handleCreateAllApi()
        return
      }
    }
  }

  const handleOnClickBackButton = () => {
    switch (activeStep) {
      case IMPORT_API_STEPS.DEFAULT_VARIABLES:
        setActiveStep(IMPORT_API_STEPS.MANAGE)
    }
  }

  const handleSetInitialStates = () => {
    setActiveStep(DEFAULT_ACTIVE_STEP)
  }

  const handleClearStates = () => {
    setTimeout(() => {
      setActiveStep(DEFAULT_ACTIVE_STEP)
      dispatch(importApiCollectionReducerActions.setInitialState())
    }, 300)
  }

  useEffect(() => {
    if (isVisible) {
      handleSetInitialStates()
      return
    }

    handleClearStates()
  }, [isVisible])

  const getDynamicProps = () => (activeStep === IMPORT_API_STEPS.DEFAULT_VARIABLES ? { nextButtonText: 'İçe Aktar' } : {})
  const getIsVisibleStepActions = () => {
    switch (activeStep) {
      case IMPORT_API_STEPS.UPLOAD_FILE:
      case IMPORT_API_STEPS.SUCCESSFUL:
        return false
      default:
        return true
    }
  }
  const getIsVisibleBackButton = () => {
    switch (activeStep) {
      case IMPORT_API_STEPS.MANAGE:
        return false
      default:
        return true
    }
  }

  return (
    <Popup
      modalClassName='import-api-wizard-popup'
      title='Aksiyonları İçe Aktar'
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}
      isVisibleStepActions={getIsVisibleStepActions()}
      isVisibleBackButton={getIsVisibleBackButton()}
      handleOnClickNextButton={handleOnClickNextButton}
      handleOnClickBackButton={handleOnClickBackButton}
      isDisableNextButton={isExecuting}
      {...getDynamicProps()}>
      {renderContent()}
    </Popup>
  )
}

export default ImportApiPopupWizard
