import clsx from 'clsx'

import OutlinedButton from '@/components/OutlinedButton/OutlinedButton'
import Popup from '@/components/Popup/Popup'
import PrimaryButton from '@/components/PrimaryButton/PrimaryButton'

import './continueAskPopup.scss'

const ContinueAskPopup = ({
  title,
  isVisible,
  handleClosePopup,
  isDisableCloseButton,
  isCenteredTitle,
  noButtonProps,
  yestButtonProps,
  description,
  yesButtonText,
  noButtonText,
}) => {
  const getNoButtonProps = () => {
    const { className, ...restOfNoButtonProps } = noButtonProps

    const noButtonClassNames = clsx('continue-ask-popup__button', className)

    return {
      className: noButtonClassNames,
      ...restOfNoButtonProps,
    }
  }

  const getYesButtonProps = () => {
    const { className, ...restOfYesButtonProps } = yestButtonProps

    const noButtonClassNames = clsx('continue-ask-popup__button', className)

    return {
      className: noButtonClassNames,
      ...restOfYesButtonProps,
    }
  }

  return (
    <Popup
      title={title}
      isVisible={isVisible}
      handleClosePopup={handleClosePopup}
      isDisableCloseButton={isDisableCloseButton}
      isCenteredTitle={isCenteredTitle}>
      {description && <div className='continue-ask-popup__description'>{description}</div>}
      <div className='continue-ask-popup'>
        <OutlinedButton {...getNoButtonProps()}>{noButtonText || 'Hayır'}</OutlinedButton>
        <PrimaryButton {...getYesButtonProps()}>{yesButtonText || 'Evet'}</PrimaryButton>
      </div>
    </Popup>
  )
}

export default ContinueAskPopup
